import {
  SHOW_ENTER_DATA_MODAL,
  ACTIVITY_LIST,
  BONUSPOINTS_LIST,
  ENTERED_DATA_LIST,
  FETCH_MY_DATA,
  SHOW_VIEW_DATA_MODAL,
  FETCH_MY_CHART_DATA,
  EVENTCLIENTLEGS_LIST,
  FETCH_CLASS_DATA,
  DATA_ENTRY_VIDEO_MODAL,
  MAX_DATA_ENTRY_CAP_MODAL,
} from "../actions";

export function showEnterDataModalReducer(state = {}, action) {
  if (action.type === SHOW_ENTER_DATA_MODAL) {
    return action.payload;
  }
  return state;
}

export function showViewDataModalReducer(state = {}, action) {
  if (action.type === SHOW_VIEW_DATA_MODAL) {
    return action.payload;
  }
  return state;
}

export function activityListReducer(state = {}, action) {
  if (action.type === ACTIVITY_LIST) {
    return action.payload;
  }
  return state;
}

export function bonusPointsListReducer(state = {}, action) {
  if (action.type === BONUSPOINTS_LIST) {
    return action.payload;
  }
  return state;
}

export function enterdDataListReducer(state = {}, action) {
  if (action.type === ENTERED_DATA_LIST) {
    return action.payload;
  }
  return state;
}

export function myDataListReducer(state = {}, action) {
  if (action.type === FETCH_MY_DATA) {
    return action.payload;
  }
  return state;
}

export const myChartDataReducer = (state = {}, action) => {
  if (action.type === FETCH_MY_CHART_DATA) {
    return action.payload;
  }
  return state;
};

export function eventClientLegsListReducer(state = {}, action) {
  if (action.type === EVENTCLIENTLEGS_LIST) {
    return action.payload;
  }
  return state;
}

export function classListData(state = {}, action) {
  if (action.type === FETCH_CLASS_DATA) {
    return action.payload;
  }
  return state;
}

export function dataVideoModal(state = false, action) {
  if (action.type === DATA_ENTRY_VIDEO_MODAL) {
    return action.payload;
  }
  return state;
}

export const maxDataEntryCapModal = (state = false, action) => {
  if (action.type === MAX_DATA_ENTRY_CAP_MODAL) return action.payload;
  return state;
};
