import axios from "axios";
import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { BASE_URL, APP_API_KEY } from "../../../components/Common/constant";

export const SHOW_PHOTO_VIEW_MODAL = "SHOW_MODAL";
export const FETCH_COURSE_VIDEO_DATA = "FETCH_COURSE_VIDEO_DATA";
export const COURSE_VIDEO_CURRENT_LEG = "COURSE_VIDEO_CURRENT_LEG";
export const BONUS_LEG_SHOW = "BONUS_LEG_SHOW";
export const FALSE_STATE_UPDATE = "FALSE_STATE_UPDATE";

export const falseStateUpdate = () => {
  return (dispatch) => {
    dispatch({ type: FALSE_STATE_UPDATE, payload: 0 });
  };
};

export function showPhotoViewModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_PHOTO_VIEW_MODAL, payload: obj });
  };
}

export function fetchCourseVideo(session, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchCourseVideo&eventid=${session.eventid}&userid=${session.userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: FETCH_COURSE_VIDEO_DATA,
          payload: { data: response.data },
        });
        callback({ success: 1, data: response.data });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}

export function fetchCurrentLeg(session, callback) {
  var filter = encodeURI(`(id=${session.teamid})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: COURSE_VIDEO_CURRENT_LEG,
          payload: { data: data.resource },
        });
        callback({
          success: 1,
          currentlegid:
            data.resource.length > 0 ? data.resource[0].currentlegidfk : 0,
        });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function isBonusLegShow(show = false) {
  return (dispatch) => {
    dispatch({ type: BONUS_LEG_SHOW, payload: show });
  };
}
