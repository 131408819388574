import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { regFormAction } from "../action";
import { useToasts } from "react-toast-notifications";

const renderFieldOptions = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="">
      <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <select
          name="gender"
          {...input}
          className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 text-gray-700 rounded-md"
        >
          <option value="">-- select --</option>
          <option value="forall">I want pedometers for all students</option>
          <option value="forsome">
            I want some pedometers that students can share
          </option>
          <option value="notrequired">
            I don't want any pedometers for students at all
          </option>
        </select>
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const Pedometer = (props) => {
  const {
    deviceSize,
    handleSubmit,
    pristine,
    submitting,
    regFormAction,
    session,
  } = props;

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const handlePedometer = (values) => {
    setLoading(true);
    let arr = [3, values];
    regFormAction(arr, (res) => {
      setLoading(false);
      if (res.success == 0 || res.success == 1) {
        let content = res.message;
        addToast(content, {
          appearance: "info",
          autoDismiss: true,
        });
      }
    });
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers, set up your class team
              </h2>

              <span className="p-6 text-center text-gray-700">
                Pedometers are a fun and motivating tool for tamariki to use
                during this program but are not required if you do not want
                them. With points being earned for activity time, eating well,
                drinking plenty of water, sleeping well, and more, the pedometer
                is not an essential part of the program. So you have the choice
                of whether you would like all students to have one, some to
                share, or none at all. We have selected pedometers with a
                battery life that is at least a year and can be replaced so they
                can be used year after year if taken good care of.
              </span>
            </div>
            <div className="bg-secondary-2 rounded-lg" style={{ height: 230 }}>
              <form onSubmit={handleSubmit(handlePedometer)}>
                <div className="px-12 pt-6">
                  <Field
                    name="pedometer"
                    type="text"
                    component={renderFieldOptions}
                    label="Please select you preferred option for pedometers"
                  />
                </div>

                {/* form submit */}
                <div className="flex justify-center m-6">
                  <button
                    type="submit"
                    disabled={pristine || submitting || loading}
                    className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                    style={{ width: 200 }}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  if (!values?.pedometer) {
    errors.pedometer = "Required*";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    classRegistrationResult: state.classRegistrationResult,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ regFormAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationFormThree",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Pedometer)
);
