import _ from "lodash";

import {
  FETCH_HOME_SCREEN_DATA,
  SHOW_MEMBER_MODAL,
  SET_HOME_DATA,
  SHOW_LEG_VIDEO_MODAL,
  SET_HOME_MARKER,
  SET_HOME_MAP,
  LAST_LEG_SHOW,
  FIREWORK_SHOW,
  LAST_LEG_ALREADY_SHOW,
} from "../actions";
import {
  loadLocalStorage,
  saveInLocalStorage,
} from "../../../reducers/localStorage";
import {
  APP_LOCALSTORAGE_KEYS,
  APP_POPUP_INIT,
  APP_POPUP_TYPES,
} from "../../../constants/types-constant";

export function homeScreenDataReducer(state = {}, action) {
  if (action.type === FETCH_HOME_SCREEN_DATA) {
    return action.payload;
  }
  return state;
}

export function showMemberModalReducer(state = {}, action) {
  if (action.type === SHOW_MEMBER_MODAL) {
    return action.payload;
  }
  return state;
}

export function homeData(state = {}, action) {
  if (action.type === SET_HOME_DATA) {
    return action.payload;
  }
  return state;
}

export function showLegVideo(state = {}, action) {
  if (action.type === SHOW_LEG_VIDEO_MODAL) {
    return action.payload;
  }
  return state;
}

export function homeMapList(state = [{ x: 0, y: 0 }], action) {
  if (action.type === SET_HOME_MAP) {
    return action.payload;
  }
  return state;
}

export function homeMarkerIndex(state = 0, action) {
  if (action.type === SET_HOME_MARKER) {
    return action.payload;
  }
  return state;
}

export function showLastLeg(state = false, action) {
  if (action.type === LAST_LEG_SHOW) {
    return action.payload;
  }
  return state;
}

export function showFirework(state = false, action) {
  if (action.type === FIREWORK_SHOW) {
    return action.payload;
  }

  let localPopup = loadLocalStorage(APP_LOCALSTORAGE_KEYS.popups);

  if (!localPopup) {
    // undefined
    localPopup = _.cloneDeep(APP_POPUP_INIT);
    localPopup[APP_POPUP_TYPES.fireworks] = state;
    saveInLocalStorage(localPopup, APP_LOCALSTORAGE_KEYS.popups);
    return state;
  } else {
    return localPopup[APP_POPUP_TYPES.fireworks];
  }
}

export function lastLegShown(state = false, action) {
  if (action.type === LAST_LEG_ALREADY_SHOW) {
    return action.payload;
  }
  return state;
}
