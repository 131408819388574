import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// constant
// apis
// components
// files
import cross from "../assets/img/cross.png";

const CustomModal = (props) => {
  const {
    // component props
    header = {
      show: false,
      close: false,
      label: null,
    },
    closeModalHandler = null,
    bodyComponent = null,
    footer = { show: false, buttons: [{ label: null, action: null }] },
    fullScreen = false,

    // state props
  } = props;

  const closeModal = () => {
    closeModalHandler && closeModalHandler();
  };

  return (
    <>
      <div className="modal">
        <div
          className={`modal-container`}
          style={{ ...(fullScreen ? { margin: 0, maxWidth: "unset" } : {}) }}
        >
          {/* ----- Content ----- */}
          <div className="modal-content">
            {/* ----- Header ----- */}
            {header?.show && (
              <>
                <div className="modal-header">
                  {header?.label && (
                    <>
                      <h3 className="text-base font-medium">{header?.label}</h3>
                    </>
                  )}

                  {header?.close && (
                    <>
                      <button className="modal-close-btn" onClick={closeModal}>
                        <img src={cross} />
                      </button>
                    </>
                  )}
                </div>
              </>
            )}

            {/* ----- Body -----*/}
            {bodyComponent ? (
              <>{bodyComponent}</>
            ) : (
              <div className="relative flex-auto"></div>
            )}

            {/* ----- Footer ----- */}
            {footer?.show && (
              <>
                <div className="flex items-center justify-end p-4 rounded-b border-t">
                  {_.map(footer?.buttons, (buttonItem, buttonItemkey) => {
                    if (_.isEmpty(buttonItem?.label)) return <></>;

                    return (
                      <button
                        key={`${_.toLower(buttonItem?.label).replace(
                          " ",
                          ""
                        )}${buttonItemkey}`}
                        className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer px-4 py-2"
                        onClick={buttonItem?.action ?? null}
                      >
                        <span className="text-sm">{buttonItem?.label}</span>
                      </button>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="modal-background"></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
