import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// constants
import { BASE_AZIONV2_URL, FILE_ACCESS_KEY } from "./Common/constant";
import LocaleStrings from "../languages";
import { loadLocalStorage, saveInLocalStorage } from "../reducers/localStorage";
import {
  APP_LOCALSTORAGE_KEYS,
  APP_POPUP_TYPES,
} from "../constants/types-constant";

// actions
import { logout } from "../actions";
import { fetchAvatarDBData } from "../pages/avatarBuilder/actions";
import { fetchHomeScreenData } from "../pages/home/actions";
import { setContactUs } from "../pages/registrationforms/action";
import { manageMaxDataEntryCapModal } from "../pages/mydata/actions";

// components
import CustomModal from "./CustomModal";

// files
import arrowRotation from "../assets/img/arrow-rotation-interface.png";
import menu from "../assets/img/menu.png";
import HomeIcon from "../assets/img/home.png";

const Navbar = (props) => {
  const {
    deviceSize,
    toggle,
    routes,
    isLoggedIn,
    eventDetails,
    session,
    logout,
    fetchHomeScreenData,
    fetchAvatarDBData,
    regSeriesCall,
    contactUsLayout,
    maxDataEntryCapModal,
  } = props;

  const [loadRefresh, setLoadRefresh] = useState(false);

  const history = useHistory();
  const location = useLocation();

  let contactEmail = eventDetails?.resource?.contactemail
    ? eventDetails?.resource?.contactemail
    : "";

  const createLinks = () => {
    // custom check to not to display Home in navbar
    const routesFiltered = _.filter(routes, function (list) {
      return list.display;
    });

    return routesFiltered.map((item, idx) => {
      var textcolor =
        item.path === location.pathname ? "text-point" : "text-refresh";
      // --- new changes ---
      if (session.ismanager) {
        if (session.eventtype == "company") {
          if (item.path != "/manage-class") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className={`p-4 ${textcolor}`}
                to={item.path}
              >
                {" "}
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} alt="" />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        } else {
          if (item.path != "/manage-team") {
            return (
              <Link
                key={`nav_key_${idx}`}
                className={`p-4 ${textcolor}`}
                to={item.path}
              >
                {item.name == "Home" ? (
                  <span className="">
                    <img src={HomeIcon} alt="" />
                  </span>
                ) : (
                  item.name
                )}
              </Link>
            );
          }
        }
      } else {
        if (item.path != "/manage-class" && item.path != "/manage-team") {
          return (
            <Link
              key={`nav_key_${idx}`}
              className={`p-4 ${textcolor}`}
              to={item.path}
            >
              {item.name == "Home" ? (
                <span className="">
                  <img src={HomeIcon} alt="" />
                </span>
              ) : (
                item.name
              )}
            </Link>
          );
        }
      }
    });
  };

  const handleLogout = () => {
    logout(session);
    history.push("/login");
  };

  const handleRefresh = () => {
    setLoadRefresh(true);
    fetchHomeScreenData(session, {}, (res) => {
      fetchAvatarDBData(session, session.userid, (res1) => {});
      setLoadRefresh(false);
    });
  };

  const _renderMaxDatEntryCapModal = () => {
    const closeModal = () => {
      props.manageMaxDataEntryCapModal(false);
    };

    const handleDontShowAgain = () => {
      let localPopups = loadLocalStorage(APP_LOCALSTORAGE_KEYS.popups);
      localPopups[APP_POPUP_TYPES.maxPoint] = true;

      saveInLocalStorage(localPopups, APP_LOCALSTORAGE_KEYS.popups);
    };

    const _rednerBody = () => {
      return (
        <>
          <div className=" max-w-md h-64 px-6 py-4 flex flex-col justify-between">
            {/* --- Desc text --- */}
            <p className=" text-base">{LocaleStrings.data_entry_max_cap}</p>

            {/* --- Dont show me again --- */}
            <div className="flex gap-2 items-center">
              <input
                className=" text-primary focus:ring-0 focus:outline-none"
                type="checkbox"
                name="maxDataPopup"
                id="maxDataPopup"
                onChange={(e) =>
                  e.target.checked ? handleDontShowAgain() : null
                }
              />
              <span className=" cursor-default">
                {LocaleStrings.dont_show_again}
              </span>
            </div>

            {/* --- Close modal --- */}
            <div className="flex justify-end">
              <button
                className=" bg-refresh text-white px-3 py-2 rounded"
                onClick={closeModal}
              >
                {LocaleStrings.ok}
              </button>
            </div>
          </div>
        </>
      );
    };

    return (
      <>
        <CustomModal bodyComponent={_rednerBody()} />
      </>
    );
  };

  let logo = `${BASE_AZIONV2_URL}/${
    eventDetails?.resource?.logo ?? "defaults/default_logo.png"
  }?api_key=${FILE_ACCESS_KEY}`;

  return (
    <>
      <nav
        className="grid grid-cols-9 flex- items-center lg:justify-between h-14 lg:h-20 bg-transparent m-2 md:my-0 md:mx-2 text-white relative font-sans"
        role="navigation"
      >
        {isLoggedIn ? (
          <div
            className="col-span-1 cursor-pointer bg-primary rounded-md lg:hidden"
            onClick={toggle}
            style={{ padding: "12px 10px" }}
          >
            <img src={menu} alt="" />
          </div>
        ) : (
          ""
        )}

        {isLoggedIn ? (
          <Link to="/" className="col-span-1 pl-4 md:pl-0 cursor-pointer">
            <div className="flex justify-start items-center pb-4 md:pb-1 lg:pb-0">
              <img
                className="bg-white h-full"
                src={logo}
                style={deviceSize.isMobile ? { height: 35 } : { maxHeight: 80 }}
                alt=""
              />
            </div>
          </Link>
        ) : (
          <div className="col-span-1 pl-4 md:pl-0 cursor-pointer h-full">
            <div className="flex justify-start items=center pb-4 md:pb-1 lg:pb-0 h-full">
              <img
                className="bg-white h-full"
                src={logo}
                style={deviceSize.isMobile ? { height: 35 } : { maxHeight: 80 }}
                alt=""
              />
            </div>
          </div>
        )}

        {isLoggedIn ? (
          <div
            className={
              deviceSize.width <= 640
                ? "hidden"
                : "col-span-8 pr-8 items-center sm:hidden lg:flex justify-end text-base"
            }
          >
            {createLinks()}
            <button
              className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2"
              onClick={handleRefresh}
            >
              {loadRefresh ? (
                <svg
                  className="animate-spin h-4 w-4 rounded-full border-t-2 border-b-2 border-white mr-0"
                  viewBox="0 0 24 24"
                ></svg>
              ) : (
                <img src={arrowRotation} alt="" />
              )}
              <span className="ml-2">{LocaleStrings.button_refresh}</span>
            </button>
            <button
              className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2 cursor-pointer"
              onClick={() => handleLogout()}
            >
              <span className="">{LocaleStrings.button_logout}</span>
            </button>
          </div>
        ) : regSeriesCall ? (
          <>
            <div
              className={
                deviceSize.width <= 640
                  ? "hidden"
                  : "col-span-8 flex items-center justify-end w-full pr-8 pb-4 sm:hidden- lg:flex text-base"
              }
            >
              {contactEmail !== "" && !contactUsLayout ? (
                <>
                  <button
                    className="bg-refresh rounded inline-flex items-center py-2 px-4 ml-2 cursor-pointer"
                    onClick={() => props.setContactUs(true)}
                  >
                    <span className="ml-2">{LocaleStrings.button_contact}</span>
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </nav>

      {/* ================ Modal popups starts ================ */}
      {maxDataEntryCapModal ? _renderMaxDatEntryCapModal() : ""}
      {/* ================ Modal popups ends ================ */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    eventDetails: state.eventDetails,
    deviceSize: state.deviceSize,
    contactUsLayout: state.contactUsLayout,
    maxDataEntryCapModal: state.maxDataEntryCapModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      fetchHomeScreenData,
      fetchAvatarDBData,
      setContactUs,
      manageMaxDataEntryCapModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
