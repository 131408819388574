import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

// constants
import LocaleStrings from "../../languages";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";

// actions
import {
  showCourseDetailsModal,
  showEditCourseModal,
  fetchCourseDetails,
  showEditCourse,
  fetchEventClient,
  fetchCourseMapDetails,
} from "./actions";

// components
import Loader from "../../components/Common/loader";
import CourseDetails from "./components/course-details";
import EditCourse from "./components/edit-course";

// files
import backArrow from "../../assets/img/backarrow.png";
import edit from "../../assets/img/edit.png";
import leftArrowWhite from "../../assets/img/leftarrowwhite.png";
import rightArrowWhite from "../../assets/img/rightarrowwhite.png";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseMap = (props) => {
  const {
    deviceSize,
    session,
    showCourseDetailsModalReducer,
    showEditCourseReducer,
    showEditCourseModalReducer,
  } = props;

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const [hotzoneArr, setHotzoneArr] = useState([]);
  const [bigMapSrc, setBigMapSrc] = useState(null);
  const [masterlegData, setMasterlegData] = useState(null);
  const [teamLeg, setTeamLeg] = useState([]);

  useEffect(() => {
    // Master leg carousel preparation
    props.fetchCourseDetails(session, (res) => {
      let currentOrder = _.filter(res?.data?.trackteam, (obj) => {
        return obj.team_by_teamidfk.id == session.teamid;
      });
      if (res?.data?.eventclientlegs) {
        let bonusLeg = false;
        let challengeArr = [];
        let bonusArr = [];
        if (currentOrder.length > 0) {
          challengeArr = _.filter(res?.data?.eventclientlegs, (obj) => {
            return obj.type == "challenge";
          });

          bonusArr = _.filter(res?.data?.eventclientlegs, (obj) => {
            return obj.type == "bonus";
          });
          let maxOrder = _.maxBy(challengeArr, (o) => {
            return o.order;
          });
          if (currentOrder[0].order > maxOrder.order && bonusArr.length > 0) {
            bonusLeg = true;
          }
        }
        let filterArr = [];

        if (bonusLeg) {
          filterArr = res.data.eventclientlegs;
        } else {
          filterArr = challengeArr;
        }
        setTeamLeg(filterArr);
      }
    });

    // --- Leg map with flasher preparation
    setLoading(true);
    handleCourseMapDetailsFetch();

    props.showEditCourse(session, (res) => {});
    props.fetchEventClient(session);
  }, []);

  const handleCourseMapDetailsFetch = (legid = null) => {
    setSecondaryLoading(true);
    props.fetchCourseMapDetails(session, { legid }, (res) => {
      if (res.success) {
        const masterLegData = res?.data?.masterleg;
        const hotzoneData = res?.data?.hotzones;

        setMasterlegData(masterLegData);

        let localHotzoneData = [];
        _.map(hotzoneData, (hotzone) => {
          if (hotzone?.isactive) {
            let localHotzone = _.cloneDeep(hotzone);
            localHotzone.position = JSON.parse(hotzone?.position);
            localHotzoneData.push(localHotzone);
          }
        });
        setHotzoneArr(localHotzoneData);

        // Dynamic Big map url set
        setBigMapSrc(
          `${BASE_AZIONV2_URL}${masterLegData?.masterleg_coursemap}?api_key=${FILE_ACCESS_KEY}`
        );

        setLoading(false);
        setSecondaryLoading(false);
      }
    });
  };

  const handleBack = () => {
    const path = "/";
    history.push(path);
  };

  const handleHotzoneDetails = (item) => {
    props.showCourseDetailsModal({ show: true, item });
  };

  const handleEditCourse = () => {
    props.showEditCourseModal({ show: true });
  };

  const handleCourseDetails = (item) => {
    handleCourseMapDetailsFetch(item?.id);
  };

  const _renderHeader = useCallback(() => {
    return (
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <div
            className="bg-primary p-3 rounded cursor-pointer"
            onClick={handleBack}
          >
            <img src={backArrow} alt="Press to go back" />
          </div>

          <div className="text-base font-medium">
            {LocaleStrings.leg} {masterlegData?.current_leg_no}:{" "}
            {masterlegData?.masterleg_name}
          </div>

          <div className="text-xs underline">
            {LocaleStrings.course_click_for_details}
          </div>
        </div>
        <div>
          {showEditCourseReducer?.data?.edit ? (
            <button
              className="btn-edit-course"
              style={{ height: 40, width: 153 }}
              onClick={handleEditCourse}
            >
              <img src={edit} className="" alt="press to edit" />
              <span className="ml-2 text-base">
                {LocaleStrings.edit_course}
              </span>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }, [masterlegData, showEditCourseReducer]);

  const _renderLegMap = useCallback(() => {
    return (
      <div className="pl-4 lg:pl-7 xl:pl-16 py-1 border-b coursemap-wrapper mapmarker-container">
        <div
          className="relative"
          style={{
            backgroundImage: `url("${bigMapSrc}")`,
            width: 1000,
            height: 595,
          }}
        >
          {hotzoneArr.length
            ? _.map(hotzoneArr, (hotzone, hotzoneKey) => {
                return (
                  <div
                    key={hotzone?.hotzoneid}
                    className={`absolute cursor-pointer animated-badge-container`}
                    style={{
                      top: hotzone.position.y - 25,
                      left: hotzone.position.x - 15,
                      zIndex: 1,
                    }}
                    onClick={() => handleHotzoneDetails(hotzone)}
                  >
                    <span className="static-badge static-dot animated-ping"></span>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    );
  }, [bigMapSrc, hotzoneArr, secondaryLoading]);

  return (
    <>
      {!loading ? (
        <div className="p-0 sm:p-2">
          <div
            className="bg-secondary p-2 sm:p-4"
            style={
              deviceSize.width > 640
                ? { borderRadius: 13 }
                : { borderRadius: 0 }
            }
          >
            {/* ----- Back / edit course header section ----- */}
            {_renderHeader()}

            {/* ----- Leg map with flash points - Carousel ----- */}
            <div className="mt-3 bg-white relative rounded-xl">
              {/* --- Leg map --- */}
              {_renderLegMap()}

              {/* ----- Event legs carousel ----- */}
              <div
                className="px-5 py-4 course-map-carousel-container"
                style={{ maxWidth: 1180 }}
              >
                <Carousel
                  plugins={[
                    "infinite",
                    "arrows",
                    {
                      resolve: slidesToShowPlugin,
                      options: {
                        numberOfSlides:
                          deviceSize.width >= 1400
                            ? 6
                            : deviceSize.width < 1400 &&
                              deviceSize.width >= 1280
                            ? 5
                            : deviceSize.width < 1280 &&
                              deviceSize.width >= 1140
                            ? 6
                            : deviceSize.width < 1140 && deviceSize.width >= 980
                            ? 5
                            : deviceSize.width < 980 && deviceSize.width >= 840
                            ? 4
                            : deviceSize.width < 840 && deviceSize.width >= 640
                            ? 3
                            : deviceSize.width < 640 && deviceSize.width >= 450
                            ? 2
                            : 1,
                      },
                    },
                    {
                      resolve: arrowsPlugin,
                      options: {
                        arrowLeft: (
                          <div className="carousel-arrow-container-course-map">
                            <img src={leftArrowWhite} alt="" />
                          </div>
                        ),
                        arrowLeftDisabled: (
                          <div
                            className="carousel-arrow-container-course-map-disabled"
                            style={{
                              backgroundColor: "#87BAF4",
                              cursor: "not-allowed",
                            }}
                          >
                            <img src={leftArrowWhite} alt="" />
                          </div>
                        ),
                        arrowRight: (
                          <div className="carousel-arrow-container-course-map">
                            <img src={rightArrowWhite} alt="" />
                          </div>
                        ),
                        arrowRightDisabled: (
                          <div
                            className="carousel-arrow-container-course-map-disabled"
                            style={{
                              backgroundColor: "#87BAF4",
                              cursor: "not-allowed",
                            }}
                          >
                            <img src={rightArrowWhite} alt="" />
                          </div>
                        ),
                        addArrowClickHandler: true,
                      },
                    },
                  ]}
                >
                  {_.map(teamLeg, (item, key) => {
                    const masterLegDetails = !_.isEmpty(
                      item?.masterleg_by_legidfk
                    )
                      ? item?.masterleg_by_legidfk
                      : null;

                    if (_.isEmpty(masterLegDetails)) return;

                    const src = !_.isEmpty(masterLegDetails?.coursemap)
                      ? `${BASE_AZIONV2_URL}${masterLegDetails?.coursemap}?api_key=${FILE_ACCESS_KEY}`
                      : "";

                    return (
                      <div
                        key={`teamleg_${key}`}
                        className="relative cursor-pointer"
                        onClick={() => handleCourseDetails(item)}
                      >
                        <img
                          className="rounded"
                          src={src}
                          style={{ height: 80, width: 140 }}
                          alt="indicator ball"
                        />
                        <div
                          className="course-name-inside-carousel"
                          style={{
                            bottom: 3,
                            background:
                              "linear-gradient(0deg, #000000a0, transparent)",
                          }}
                        >
                          {_.startCase(masterLegDetails?.name ?? "")}
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>

            {showCourseDetailsModalReducer.show ? <CourseDetails /> : ""}
            {showEditCourseModalReducer.show ? <EditCourse /> : ""}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    showCourseDetailsModalReducer: state.showCourseDetailsModalReducer,
    showEditCourseReducer: state.showEditCourseReducer,
    showEditCourseModalReducer: state.showEditCourseModalReducer,
    coursemapHotzoneReducer: state.coursemapHotzoneReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showCourseDetailsModal,
      showEditCourseModal,
      fetchCourseDetails,
      showEditCourse,
      fetchEventClient,
      fetchCourseMapDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseMap);
