import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { createPopper } from "@popperjs/core";

// constants
import LocaleStrings from "../../../languages";
import { DATE_FORMAT, MINIMUM_STEP } from "../../../components/Common/constant";

// apis
import {
  showEnterDataModal,
  fetchActivityList,
  fetchBonusPointsList,
  addMemberLiveData,
  fetchDataEntry,
  fetchMyDataList,
  fetchEventClientLegs,
  fetchClassDataList,
  updateLeg,
  manageMaxDataEntryCapModal,
} from "../actions";
import { fetchHomeScreenData } from "../../home/actions";

// components
import { renderHiddenFieldText } from "../../../components/Common/base-components";

// files
import cross from "../../../assets/img/cross.png";
import info_white from "../../../assets/img/info-white.svg";
import minus from "../../../assets/img/minus-danger-rounded.svg";
import plus from "../../../assets/img/plus-activity-points.svg";
import glass_of_water from "../../../assets/img/glass-of-water.png";
import grapes from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes.png";
import carrot from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot.png";
import burger_cola from "../../../assets/img/fastfood-burger-cola.png";
import food_spoon_jar from "../../../assets/img/kids-baby-food-spoon-jar.png";
import halloween from "../../../assets/img/halloween-moon-clouds-night.png";
import computers_phone from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone.png";
import brain from "../../../assets/img/brain.png";
import { ALLOWED_USER_TYPES } from "../../../constants/types-constant";

const formInitialData = {
  date: moment().format(DATE_FORMAT),
  activity: [{ name: "", intensity: "", duration: "" }],
};

const renderField = ({
  input,
  label,
  placeholder,
  type,
  max,
  min,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
      </label>
      <div className="mt-1">
        <input
          className="enter-data-steps-input"
          {...input}
          type={type}
          placeholder={placeholder}
          max={max}
          min={min}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderSelect = (field) => {
  return (
    <div className={field.margin ? field.margin : ""}>
      <div className="">
        <select
          className="enter-data-activity-select"
          {...field.input}
          style={{ width: field.width }}
        >
          <option value="" key={field.label}>
            {field.placeholder}
          </option>
          {field.opts.map((object, index) => {
            return (
              <option key={`${object.value}-${index}`} value={object.value}>
                {object.name}
              </option>
            );
          })}
        </select>
        <div className="text-sm text-red-500">
          {field.meta.touched ? field.meta.error : ""}
        </div>
      </div>
    </div>
  );
};

const renderActivity = ({
  fields,
  meta: { touched, error, submitFailed },
  activityOpts,
  intensityOpts,
  durationOpts,
}) => {
  return (
    <ul>
      {fields.map((activity, index) => (
        <li key={index} className={index == 0 ? "mt-4" : "mt-1"}>
          <label className="block text-sm text-secondary-4">
            {LocaleStrings.activity_text}#{index + 1}
          </label>

          <div className="mt-1 flex">
            <Field
              name={`${activity}.name`}
              label={`${LocaleStrings.activity_text} Name`}
              placeholder={`${LocaleStrings.activity_text}`}
              component={renderSelect}
              opts={activityOpts}
              width={108}
            />
            <Field
              name={`${activity}.intensity`}
              label={`${LocaleStrings.activity_text} ${LocaleStrings.activity_intencity}`}
              placeholder={`${LocaleStrings.activity_intencity}`}
              component={renderSelect}
              opts={intensityOpts}
              width={112}
              margin="ml-2"
            />
            <Field
              name={`${activity}.duration`}
              label={`${LocaleStrings.activity_text} ${LocaleStrings.activity_duration}`}
              placeholder={`${LocaleStrings.activity_duration}`}
              component={renderSelect}
              opts={durationOpts}
              width={87}
              margin="ml-2"
            />

            {index ? (
              <img
                className="ml-2 cursor-pointer"
                src={minus}
                alt={LocaleStrings.remove}
                onClick={() => fields.remove(index)}
              />
            ) : (
              ""
            )}
          </div>
        </li>
      ))}

      <li className="w-full flex justify-end mt-4 items-center">
        {fields.length > 7 ? (
          <div className="text-red-600 text-sm pr-2">
            {LocaleStrings.activity_max_8}
          </div>
        ) : (
          ""
        )}
        <div className="pr-8">
          <div
            className={
              fields.length <= 7
                ? "bg-primary rounded-md p-2 cursor-pointer"
                : "bg-primary rounded-md p-2 opacity-50 cursor-not-allowed"
            }
            onClick={() => fields.length <= 7 && fields.push({})}
          >
            <img src={plus} />
          </div>
        </div>
      </li>
      {submitFailed && error && (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </ul>
  );
};

const SLIDE_ARRAY_CONTENT = [
  {
    key: "water",
    colorstopval: 0,
    colorstopsecondarycolor: "#7EE076",
    value: -1,
    unit: "Cups",
  },
  {
    key: "fruit",
    colorstopval: 0,
    colorstopsecondarycolor: "#FF8327",
    value: -1,
    unit: "",
  },
  {
    key: "veges",
    colorstopval: 0,
    colorstopsecondarycolor: "#FF4A4A",
    value: -1,
    unit: "",
  },
  // {
  //   key: "foods",
  //   colorstopval: 0,
  //   colorstopsecondarycolor: "#7EE076",
  //   value: -1,
  //   unit: "",
  // },
  {
    key: "sugar",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD8326",
    value: -1,
    unit: "Tsp.",
  },
  {
    key: "sleep",
    colorstopval: 0,
    colorstopsecondarycolor: "#7EE076",
    value: 0,
    unit: "h",
  },
  {
    key: "screentime",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD4A4A",
    value: -1,
    unit: "h",
  },
  {
    key: "mindfulness",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD8326",
    value: -1,
    unit: "",
  },
];

const BONUS_FIELD_INIT = {
  data: [],
  min: 0,
  defaultvalue: 0,
  max: 0,
  step: 1,
};

const EnterData = (props) => {
  const {
    // state
    session,
    showModal,
    showEnterDataModal,
    deviceSize,
    homeScreenData,
    eventClientLegsList,
    eventDetails,

    // component
    forMember = false,
    memberId = null,

    // actions
    fetchActivityList,
    fetchBonusPointsList,
    fetchHomeScreenData,
    fetchMyDataList,
    fetchEventClientLegs,
    fetchClassDataList,

    // redux-form
    handleSubmit,
    submitting,
    reset,
  } = props;
  const history = useHistory();
  const { addToast } = useToasts();

  const intensityOpts = [
    { value: 1100, name: "High" },
    { value: 1020, name: "Medium" },
    { value: 1001, name: "Low" },
  ];
  const durationOpts = [
    { value: 10, name: "10 mins" },
    { value: 20, name: "20 mins" },
    { value: 30, name: "30 mins" },
    { value: 40, name: "40 mins" },
    { value: 50, name: "50 mins" },
    { value: 60, name: "60 mins" },
    { value: 70, name: "70 mins" },
    { value: 80, name: "80 mins" },
    { value: 90, name: "90 mins" },
  ];

  const [activityOpts, setActivityOpts] = useState([]);

  const [bonusField, setBonusField] = useState({
    water: BONUS_FIELD_INIT,
    fruit: BONUS_FIELD_INIT,
    veges: BONUS_FIELD_INIT,
    // foods: BONUS_FIELD_INIT,
    sugar: BONUS_FIELD_INIT,
    sleep: BONUS_FIELD_INIT,
    screentime: BONUS_FIELD_INIT,
    mindfulness: BONUS_FIELD_INIT,
  });

  const [loading, setLoading] = useState(false);
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [returnType, setReturnType] = useState("");

  const [sliderArr, setSliderArr] = useState(SLIDE_ARRAY_CONTENT);
  const [bonusPointsArr, setBonusPointsArr] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState("");

  useEffect(() => {
    fetchActivityList(session, (res) => {
      if (res.success) {
        let arr = [];
        _.map(res.data, (item) => {
          arr.push({ value: item.id, name: item.name });
        });

        setActivityOpts(arr);
      }
    });

    fetchBonusPointsList(session, (res) => {
      if (res.success) {
        let uniqArr = _.uniqBy(res.data, "bonustype");
        let uniqBonusTypes = _.map(uniqArr, "bonustype");

        let local_bonusField = _.cloneDeep(bonusField);

        _.map(SLIDE_ARRAY_CONTENT, (field) => {
          let filter = _.filter(res.data, (bonus) => {
            return (
              bonus?.bonustype === field.key &&
              !_.includes(["foods"], bonus?.bonustype)
            );
          });
          let sortByProp = _.includes(["sleep", "screentime"], field.key)
            ? "starttime"
            : "quantity";
          let sorted = _.sortBy(filter, [sortByProp]);
          let length = sorted.length;

          let commonItem = _.cloneDeep(local_bonusField[field.key]);
          commonItem.data = sorted;
          commonItem.defaultvalue = sorted[0][sortByProp];
          commonItem.min = sorted[0][sortByProp];
          commonItem.max = sorted[length - 1][sortByProp];
          commonItem.step = _.includes(["sleep"], field.key) ? 0.5 : 1;

          local_bonusField[field.key] = commonItem;
        });

        // setting the dynamic sliders
        let bonusArr = [];
        _.map(uniqBonusTypes, (type) => {
          let commonItem = {
            name: "",
            label: "",
            img: null,
            thumb: "",
            qty: "",
            unit: "",
            displayUnit: "",
            data: local_bonusField[type],
          };

          switch (type) {
            case "water":
              commonItem.name = "water";
              commonItem.label = LocaleStrings.bonus_water;
              commonItem.img = glass_of_water;
              commonItem.thumb = "glass-of-water-slider-thumb-with-bg.png";
              commonItem.qty = "6 Cups";
              commonItem.unit = "of Water";
              commonItem.displayUnit = "cups";
              break;

            case "fruit":
              commonItem.name = "fruit";
              commonItem.label = LocaleStrings.bonus_fruit;
              commonItem.img = grapes;
              commonItem.thumb =
                "fruits-and-vegetables-fruit-food-grapes-slider-thumb.png";
              commonItem.qty = "6";
              commonItem.unit = "Fruit";
              break;

            case "veges":
              commonItem.name = "veges";
              commonItem.label = LocaleStrings.bonus_veges;
              commonItem.img = carrot;
              commonItem.thumb =
                "fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png";
              commonItem.qty = "1";
              commonItem.unit = "Vegetables";
              break;

            case "foods":
              // commonItem.name = "foods";
              // commonItem.label = LocaleStrings.bonus_sugar;
              // commonItem.img = burger_cola;
              // commonItem.thumb = "fastfood-burger-cola.png";
              // commonItem.qty = "6";
              // commonItem.unit = "Snacks";
              // commonItem.displayUnit = "tsp";
              break;

            case "sugar":
              commonItem.name = "sugar";
              commonItem.label = LocaleStrings.bonus_sugar;
              commonItem.img = food_spoon_jar;
              commonItem.thumb = "kids-baby-food-spoon-jar-slider-thumb.png";
              commonItem.qty = "3 Tsp.";
              commonItem.unit = "of Sugar";
              commonItem.displayUnit = "tsp";
              break;

            case "sleep":
              commonItem.name = "sleep";
              commonItem.label = LocaleStrings.bonus_sleep;
              commonItem.img = halloween;
              commonItem.thumb = "halloween-moon-clouds-night-slider-thumb.png";
              commonItem.qty = "8h 53m";
              commonItem.unit = "Sleep Time";
              commonItem.displayUnit = "h";
              break;

            case "screentime":
              commonItem.name = "screentime";
              commonItem.label = LocaleStrings.bonus_screen;
              commonItem.img = computers_phone;
              commonItem.thumb =
                "computers-and-hardware-screen-pc-smart-phone-slider-thumb.png";
              commonItem.qty = "2h 30m";
              commonItem.unit = "Screen Time";
              commonItem.displayUnit = "h";
              break;

            case "mindfulness":
              commonItem.name = "mindfulness";
              commonItem.label = LocaleStrings.bonus_mindfulness;
              commonItem.img = brain;
              commonItem.thumb = "brain-slider-thumb.png";
              commonItem.qty = "1-10";
              commonItem.unit = "Mindfulness";
              break;

            default:
              break;
          }

          if (!_.isEmpty(commonItem.name)) bonusArr.push(commonItem);
        });

        setBonusField(local_bonusField);
        setBonusPointsArr(bonusArr);
      }
    });

    // for finding next clientlegid
    fetchEventClientLegs(session, (res) => {});
  }, []);

  const toggle = (index) => {
    if (index != tooltipOpen) setTooltipOpen(index);
    else setTooltipOpen(-1);
  };

  const closeModal = () => {
    showEnterDataModal({
      show: false,
      data: {
        callfrom: null,
        name: null,
        type: null,
        item: null,
      },
    });
    setSliderArr(SLIDE_ARRAY_CONTENT);
    setLoading(false);
    reset();
  };

  const handleChangeSlider = (e, item) => {
    let value = e.target.value;
    let arr = _.cloneDeep(sliderArr);
    let index = _.findIndex(arr, function (o) {
      return o.key == item.name;
    });
    let color_stop_val =
      (value - item.data.min) / (item.data.max - item.data.min);

    arr[index].colorstopval = color_stop_val;
    arr[index].value = value;

    setSliderArr(arr);
    toggle(item.name);
  };

  const onSubmitForm = (values, dispatch) => {
    if (!values.date) {
      values.date = showModal.data?.item?.date;
    } else {
      values.date = moment(values.date).format(DATE_FORMAT);
    }

    if (!_.isEmpty(values) && values.steps && values.date) {
      setLoading(true);
      setReturnType(values?.actionaftersave);

      let activityArr = [];
      if (values.activity && values.activity.length > 0) {
        _.map(values.activity, (item) => {
          if (item?.name && item?.intensity && item?.duration) {
            activityArr.push({
              activityidfk: _.toNumber(item.name),
              intensity:
                _.toNumber(item.intensity) == 1100
                  ? "high"
                  : _.toNumber(item.intensity) == 1020
                  ? "medium"
                  : _.toNumber(item.intensity) == 1001
                  ? "low"
                  : "",
              activitytime: _.toNumber(item.duration),
              activitypoint: parseFloat(
                (
                  (_.toNumber(item.intensity) / 60) *
                  _.toNumber(item.duration)
                ).toFixed(6)
              ),
              entrydate: values.date,
            });
          }
        });
      }

      let dataPointObj = {};
      _.map(SLIDE_ARRAY_CONTENT, (field) => {
        const selectedBonusField = bonusField[field.key];
        const searchProperty = _.includes(["sleep", "screentime"], field.key)
          ? "starttime"
          : "quantity";

        let filterValue = _.find(
          sliderArr,
          (fieldItem) => fieldItem.key === field.key
        );
        let filterData = _.find(selectedBonusField.data, (item) => {
          if (
            _.includes(["sleep"], field.key) &&
            (filterValue.value == item.starttime ||
              filterValue.value == item.endtime ||
              _.inRange(
                _.toNumber(filterValue.value),
                item.starttime,
                item.endtime
              ))
          ) {
            return item;
          } else if (filterValue.value == item[searchProperty]) {
            return item;
          }
        });

        dataPointObj[field.key] = {
          point: filterData?.points,
          start: selectedBonusField?.data[0][searchProperty],
          end: selectedBonusField?.data[selectedBonusField?.data.length - 1][
            searchProperty
          ],
          value: filterValue?.value,
        };
      });

      let teamid = homeScreenData?.data?.teamid
        ? typeof homeScreenData?.data?.teamid === "string"
          ? _.toNumber(homeScreenData.data.teamid)
          : homeScreenData?.data?.teamid
        : null;

      let teampoints = homeScreenData.data.teampoints
        ? typeof homeScreenData.data.teampoints === "string"
          ? _.toNumber(homeScreenData.data.teampoints)
          : homeScreenData.data.teampoints
        : null;

      let teamsteps = homeScreenData.data.teamsteps
        ? typeof homeScreenData.data.teamsteps === "string"
          ? _.toNumber(homeScreenData.data.teamsteps)
          : homeScreenData.data.teamsteps
        : null;

      let totalactivitypoints = 0;
      if (activityArr.length > 0) {
        _.map(activityArr, (item) => {
          if (typeof item.activitypoint === "string")
            totalactivitypoints += _.toNumber(item.activitypoint);
          else totalactivitypoints += item.activitypoint;
        });
      }
      let totalbonuspoints = 0;
      _.map(dataPointObj, (item) => {
        if (typeof item.point === "string")
          totalbonuspoints += _.toNumber(item.point);
        else totalbonuspoints += item.point;
      });

      let dailyMax =
        homeScreenData.data.currentdivision === "open"
          ? eventDetails?.resource?.dailymaxindividualopen
          : eventDetails?.resource?.dailymaxindividualadv;
      if (typeof dailyMax === "string") dailyMax = _.toNumber(dailyMax);

      // Max allocation
      let entrySteps =
        typeof values.steps === "string"
          ? _.toNumber(values.steps)
          : values.steps;

      let totalEntryPoints =
        entrySteps + totalactivitypoints + totalbonuspoints;

      let maxallocated = false;
      let newSteps = entrySteps;

      // --- Daily max wise data cap block
      if (totalEntryPoints > dailyMax) {
        props.manageMaxDataEntryCapModal(true);

        let maxEntryOver = totalEntryPoints - dailyMax;
        let maxExceed = entrySteps - MINIMUM_STEP - maxEntryOver;

        if (maxExceed >= 0) {
          newSteps = entrySteps - maxEntryOver;
          maxallocated = true;
        } else {
          while (maxExceed < 0) {
            let newactivitypoints = 0;
            activityArr.pop();

            _.map(activityArr, (item) => {
              newactivitypoints += item.activitypoint;
            });

            totalEntryPoints =
              entrySteps + newactivitypoints + totalbonuspoints;

            maxEntryOver = totalEntryPoints - dailyMax;
            maxExceed = entrySteps - MINIMUM_STEP - maxEntryOver;

            if (maxExceed >= 0) {
              newSteps = entrySteps - maxEntryOver;
              maxallocated = true;
              break;
            }
          }
        }
      }

      if (showModal.data?.type == "mydata") {
        let liveDataPayload = {
          memberidfk: forMember ? memberId : session.userid,
          teamidfk: _.toNumber(homeScreenData.data.teamid),
          steps:
            typeof values.steps === "string" ? _.toNumber(newSteps) : newSteps,
          entrydate: values.date,
          enteredby: forMember ? "manager" : "self",
          enteringuser: session.userid,
          maxallocated: maxallocated ? 1 : 0,
        };
        _.map(dataPointObj, (pointObj, pointType) => {
          liveDataPayload[`${pointType}`] = pointObj.point;
          liveDataPayload[`${pointType}startrange`] = pointObj.start;
          liveDataPayload[`${pointType}endrange`] = pointObj.end;
          liveDataPayload[`${pointType}selectedvalue`] = pointObj.value;
        });
        let resourceLiveData = [liveDataPayload];

        props.fetchDataEntry(
          session,
          homeScreenData.data.teamid,
          "mydata",
          values.date,
          forMember ? memberId : session.userid,
          (resDataEntry) => {
            if (resDataEntry.success) {
              if (resDataEntry.data.length > 0) {
                addToast(LocaleStrings.data_added_for_attendee, {
                  appearance: "error",
                  autoDismiss: true,
                });
                setLoading(false);
                closeModal();
              } else {
                let requestData = [];

                if (activityArr.length > 0) {
                  requestData = [
                    {
                      ...resourceLiveData[0],
                      memberliveactivity_by_memberlivedataidfk: activityArr,
                    },
                  ];
                } else {
                  requestData = [
                    {
                      ...resourceLiveData[0],
                    },
                  ];
                }

                props.addMemberLiveData(
                  session,
                  requestData,
                  {
                    dailyMax,
                    entrySteps,
                    totalactivitypoints,
                    totalbonuspoints,
                  },
                  (res) => {
                    if (res.success) {
                      let teampoints_mydata =
                        teampoints + totalactivitypoints + totalbonuspoints;
                      if (typeof newSteps === "string")
                        teampoints_mydata += _.toNumber(newSteps);
                      else teampoints_mydata += newSteps;

                      let nextLeg = false;
                      let nextLegId = "";

                      let eachLegReqPt = 0;
                      if (homeScreenData.data.currentdivision == "open") {
                        eachLegReqPt =
                          eventDetails.resource
                            ?.teamavgptreqtofinisheachlegopen;
                        if (
                          teampoints_mydata >=
                          _.toNumber(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegopen
                        ) {
                          nextLeg = true;
                        }
                      } else if (
                        homeScreenData.data.currentdivision == "advance"
                      ) {
                        eachLegReqPt =
                          eventDetails.resource?.teamavgptreqtofinisheachlegadv;
                        if (
                          teampoints_mydata >=
                          _.toNumber(homeScreenData.data?.legno) *
                            eventDetails.resource
                              ?.teamavgptreqtofinisheachlegadv
                        ) {
                          nextLeg = true;
                        }
                      }

                      if (nextLeg) {
                        let teammembersforteamdataentry = JSON.parse(
                          JSON.stringify(homeScreenData.data.allteammemberdata)
                        );
                        let teamavg =
                          teampoints_mydata /
                          teammembersforteamdataentry.length;

                        let order = Math.ceil(teamavg / eachLegReqPt);

                        let filterNextLeg = _.filter(eventClientLegsList.data, {
                          order: order,
                        });

                        if (filterNextLeg && filterNextLeg.length > 0) {
                          nextLegId = filterNextLeg[0].id;
                        }
                      }

                      let teamLeg = {};
                      teamLeg.teamid = teamid;
                      teamLeg.totalactivitypoints = totalactivitypoints;
                      teamLeg.totalbonuspoints = totalbonuspoints;
                      teamLeg.newSteps = newSteps;
                      teamLeg.membercount = 1;
                      teamLeg.enteredby = "self";
                      teamLeg.enteringuser = session.userid;
                      teamLeg.dailyMax = dailyMax;

                      props.updateLeg(session, teamLeg, (res2) => {
                        if (res2.success) {
                          fetchMyDataList(
                            session,
                            {
                              teamId: session.teamid,
                              userId:
                                values.actionaftersave &&
                                values.actionaftersave !== "home" &&
                                forMember
                                  ? memberId
                                  : session.userid,
                            },
                            (res) => {}
                          );
                          fetchHomeScreenData(
                            session,
                            {
                              userId:
                                values.actionaftersave &&
                                values.actionaftersave !== "home" &&
                                forMember
                                  ? memberId
                                  : session.userid,
                              userType: forMember
                                ? ALLOWED_USER_TYPES.student
                                : session.usertype,
                            },
                            (res) => {}
                          );
                          setLoading(false);

                          addToast(LocaleStrings.success_data_add, {
                            appearance: "success",
                            autoDismiss: true,
                          });

                          closeModal();
                          if (
                            values.actionaftersave &&
                            values.actionaftersave == "home"
                          ) {
                            history.push("/");
                          }
                        } else {
                          addToast(LocaleStrings.common_fail_message, {
                            appearance: "error",
                            autoDismiss: true,
                          });

                          closeModal();
                        }
                      });
                    } else {
                      addToast(LocaleStrings.common_fail_message, {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    }
                  }
                );
              }
            } else {
              addToast(LocaleStrings.common_fail_message, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
        );
      } else {
        // Class Entry
        props.fetchDataEntry(
          session,
          homeScreenData.data.teamid,
          "classdata",
          values.date,
          null,
          (resDataEntry) => {
            if (resDataEntry.success) {
              if (
                resDataEntry.data.length ==
                homeScreenData.data.allteammemberdata.length
              ) {
                addToast(LocaleStrings.data_added_for_members, {
                  appearance: "error",
                  autoDismiss: true,
                });

                setLoading(false);
                closeModal();
              } else {
                let teammembersforteamdataentry = [];

                if (resDataEntry.data.length == 0) {
                  teammembersforteamdataentry = _.cloneDeep(
                    homeScreenData.data.allteammemberdata
                  );
                } else {
                  _.map(homeScreenData.data.allteammemberdata, (item) => {
                    let filter = _.filter(resDataEntry.data, function (o) {
                      return o.memberidfk == item.id;
                    });
                    if (filter.length == 0) {
                      teammembersforteamdataentry.push(item);
                    }
                  });
                }

                let resourceLiveDataArr = [];
                _.map(teammembersforteamdataentry, (item) => {
                  let liveDataPayload = {
                    memberidfk:
                      typeof item.id === "string"
                        ? _.toNumber(item.id)
                        : item.id,
                    teamidfk:
                      typeof homeScreenData.data.teamid === "string"
                        ? _.toNumber(homeScreenData.data.teamid)
                        : homeScreenData.data.teamid,
                    steps:
                      typeof newSteps === "string"
                        ? _.toNumber(newSteps)
                        : newSteps,
                    entrydate: values.date,
                    enteredby: item.ismanager == 1 ? "self" : "manager",
                    enteringuser: session.userid,
                    maxallocated: maxallocated ? 1 : 0,
                  };
                  _.map(dataPointObj, (pointObj, pointType) => {
                    liveDataPayload[`${pointType}`] = pointObj.point;
                    liveDataPayload[`${pointType}startrange`] = pointObj.start;
                    liveDataPayload[`${pointType}endrange`] = pointObj.end;
                    liveDataPayload[`${pointType}selectedvalue`] =
                      pointObj.value;
                  });

                  if (activityArr.length > 0)
                    liveDataPayload.memberliveactivity_by_memberlivedataidfk =
                      activityArr;

                  resourceLiveDataArr.push(liveDataPayload);
                });

                let requestData = resourceLiveDataArr;
                props.addMemberLiveData(session, requestData, null, (res) => {
                  if (res.success) {
                    let teampoints_classdata =
                      teampoints +
                      parseFloat(
                        (
                          (_.toNumber(newSteps) +
                            totalactivitypoints +
                            totalbonuspoints) *
                          resourceLiveDataArr.length
                        ).toFixed(6)
                      );
                    let nextLeg = false;
                    let nextLegId = "";
                    let eachLegReqPt = 0;
                    if (homeScreenData.data.currentdivision == "open") {
                      eachLegReqPt =
                        eventDetails.resource?.teamavgptreqtofinisheachlegopen;
                      if (
                        teampoints_classdata >=
                        _.toNumber(homeScreenData.data?.legno) *
                          eventDetails.resource?.teamavgptreqtofinisheachlegopen
                      ) {
                        nextLeg = true;
                      }
                    } else if (
                      homeScreenData.data.currentdivision == "advance"
                    ) {
                      eachLegReqPt =
                        eventDetails.resource?.teamavgptreqtofinisheachlegadv;
                      if (
                        teampoints_classdata >=
                        _.toNumber(homeScreenData.data?.legno) *
                          eventDetails.resource?.teamavgptreqtofinisheachlegadv
                      ) {
                        nextLeg = true;
                      }
                    }
                    if (nextLeg) {
                      let teamavg =
                        teampoints_classdata /
                        teammembersforteamdataentry.length;

                      let order = Math.ceil(teamavg / eachLegReqPt);

                      let filterNextLeg = _.filter(eventClientLegsList.data, {
                        order: order,
                      });

                      if (filterNextLeg && filterNextLeg.length > 0) {
                        nextLegId = filterNextLeg[0].id;
                      }
                    }

                    let teamLeg = {};
                    teamLeg.teamid = teamid;
                    teamLeg.totalactivitypoints = totalactivitypoints;
                    teamLeg.totalbonuspoints = totalbonuspoints;
                    teamLeg.newSteps = newSteps;
                    teamLeg.membercount = resourceLiveDataArr.length;
                    teamLeg.enteredby = "manager";
                    teamLeg.enteringuser = session.userid;
                    teamLeg.dailyMax = dailyMax;

                    props.updateLeg(session, teamLeg, (res2) => {
                      if (res2.success) {
                        fetchHomeScreenData(
                          session,
                          {
                            userId:
                              values.actionaftersave &&
                              values.actionaftersave !== "home" &&
                              forMember
                                ? memberId
                                : session.userid,
                            userType: forMember
                              ? ALLOWED_USER_TYPES.student
                              : session.usertype,
                          },
                          (res) => {}
                        );
                        fetchClassDataList(
                          session,
                          homeScreenData.data.teamid,
                          (res) => {}
                        );
                        setLoading(false);
                        addToast(LocaleStrings.success_data_add, {
                          appearance: "success",
                          autoDismiss: true,
                        });
                        closeModal();

                        if (
                          values.actionaftersave &&
                          values.actionaftersave == "home"
                        ) {
                          history.push("/");
                        }
                      } else {
                        addToast(LocaleStrings.common_fail_message, {
                          appearance: "error",
                          autoDismiss: true,
                        });
                        closeModal();
                      }
                    });
                  } else {
                    addToast(LocaleStrings.common_fail_message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                    closeModal();
                  }
                });
              }
            } else {
              addToast(LocaleStrings.common_fail_message, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
        );
      }
    } else {
      addToast(LocaleStrings.provide_required, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const submitHome = (key) => {
    setReturnType(key);
    props.autofill("actionaftersave", key);
  };

  const openLeftTooltip = (event, tooltipID) => {
    let element = event.target;
    createPopper(element, document.getElementById(tooltipID), {
      placement: "top",
    });
    setTooltipShow(tooltipID);
  };

  const closeLeftTooltip = () => {
    setTooltipShow("");
  };

  return (
    <>
      {showModal && showModal?.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">
                      {showModal.data?.callfrom == "home"
                        ? showModal.data?.type == "mydata"
                          ? LocaleStrings.data_entry_for_yourself
                          : session.eventtype == "school"
                          ? LocaleStrings.data_entry_for_your_class
                          : LocaleStrings.data_entry_for_your_team
                        : showModal.data?.callfrom == "dataentry"
                        ? moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          ) === moment().format("DD MMM YYYY")
                          ? `Today, ${showModal.data?.name}`
                          : `${moment(showModal.data?.item?.date).format(
                              "DD MMM YYYY"
                            )}, ${showModal.data?.name}`
                        : showModal.data?.callfrom == "classentry"
                        ? moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          ) === moment().format("DD MMM YYYY")
                          ? `Today, ${showModal.data?.name}`
                          : `${moment(showModal.data?.item?.date).format(
                              "DD MMM YYYY"
                            )}, ${showModal.data?.name}`
                        : ""}
                    </h3>
                  </div>
                  <button className="modal-close-btn" onClick={closeModal}>
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="px-2">
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <Field
                      name="actionaftersave"
                      type="text"
                      component={renderHiddenFieldText}
                    />
                    <Field
                      name="date"
                      type="text"
                      component={renderHiddenFieldText}
                    />
                    <div className="enter-data-modal-body relative flex-auto">
                      <div className="grid sm:grid-cols-1 md:grid-cols-2">
                        <div className="sm:border-0 md:border-r">
                          <div className="points-entry-container">
                            <div>{LocaleStrings.activity_points_entry}</div>
                            <div>
                              <Popup
                                trigger={
                                  <img
                                    className="cursor-pointer"
                                    src={info_white}
                                  />
                                }
                                position="left center"
                                on="hover"
                                style={{ width: 300 }}
                                className="myPopup"
                              >
                                <div
                                  className="w-full text-sm"
                                  style={{
                                    maxHeight: 200,
                                    overflow: "auto",
                                  }}
                                >
                                  <p className="px-3 pb-2">
                                    {LocaleStrings.activity_desc}
                                  </p>
                                  <p className="px-3 pb-2">
                                    {LocaleStrings.activity_desc_intencity_high}
                                  </p>
                                  <p className="px-3 pb-2">
                                    {
                                      LocaleStrings.activity_desc_intencity_medium
                                    }
                                  </p>
                                  <p className="px-3 pb-2">
                                    {LocaleStrings.activity_desc_intencity_low}
                                  </p>
                                </div>
                              </Popup>
                            </div>
                          </div>

                          <div className="p-4">
                            <Field
                              name="steps"
                              type="number"
                              component={renderField}
                              label="Steps"
                              placeholder="Enter Steps"
                              min="1"
                            />

                            <div className="" style={{ width: 400 }}>
                              <span className="text-sm text-red-900">
                                {LocaleStrings.modal_long_text}
                              </span>
                            </div>

                            <FieldArray
                              name="activity"
                              component={renderActivity}
                              activityOpts={activityOpts}
                              intensityOpts={intensityOpts}
                              durationOpts={durationOpts}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="points-entry-container">
                            <div>{LocaleStrings.bonus_points_entry}</div>
                            <div>
                              <Popup
                                trigger={
                                  <img
                                    className="cursor-pointer"
                                    src={info_white}
                                  />
                                }
                                position="left center"
                                on="hover"
                                className="myPopup"
                              >
                                <div
                                  className="w-full text-sm"
                                  style={{
                                    maxHeight: 200,
                                    overflow: "auto",
                                  }}
                                >
                                  {/* fruit */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_fruit}: </b>
                                    <span>
                                      {LocaleStrings.bonus_fruit_desc}
                                    </span>
                                  </p>
                                  {/* water */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_water}: </b>
                                    <span>
                                      {LocaleStrings.bonus_water_desc}
                                    </span>
                                  </p>
                                  {/* veges */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_veges}: </b>
                                    <span>
                                      {LocaleStrings.bonus_veges_desc}
                                    </span>
                                  </p>
                                  {/* sugar */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_sugar}: </b>
                                    <span>
                                      {LocaleStrings.bonus_sugar_desc}
                                    </span>
                                  </p>
                                  {/* mindfulness */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_mindfulness}: </b>
                                    <span>
                                      {LocaleStrings.bonus_mindfulness_desc}
                                    </span>
                                  </p>
                                  {/* sleep */}
                                  <p className="mb-2">
                                    <b>{LocaleStrings.bonus_sleep}: </b>
                                    <span>
                                      {LocaleStrings.bonus_sleep_desc}
                                    </span>
                                  </p>
                                  {/* screen time */}
                                  <p>
                                    <b>{LocaleStrings.bonus_screen}: </b>
                                    <span>
                                      {LocaleStrings.bonus_screen_desc}
                                    </span>
                                  </p>
                                </div>
                              </Popup>
                            </div>
                          </div>

                          {_.map(bonusPointsArr, (item, i) => {
                            let filter = _.find(sliderArr, (o) => {
                              return o.key == item.name;
                            });

                            return (
                              <div
                                className="p-2 border-b flex items-center"
                                onTouchStart={
                                  deviceSize.isMobile
                                    ? (e) => openLeftTooltip(e, item.name)
                                    : null
                                }
                                onTouchEnd={
                                  deviceSize.isMobile
                                    ? () => closeLeftTooltip()
                                    : null
                                }
                              >
                                <div className="ml-4">
                                  <div>
                                    <input
                                      className={"slider-" + item.name}
                                      type="range"
                                      min={item.data.min}
                                      max={item.data.max}
                                      step={item.data.step}
                                      defaultValue={item.data.defaultvalue}
                                      // value='1'
                                      onChange={(e) =>
                                        handleChangeSlider(e, item)
                                      }
                                      style={
                                        filter?.colorstopval > 0
                                          ? {
                                              backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${filter?.colorstopval}, ${filter?.colorstopsecondarycolor}), color-stop(${filter?.colorstopval}, #e6e8f2))`,
                                            }
                                          : {}
                                      }
                                      onMouseEnter={
                                        !deviceSize.isMobile
                                          ? (e) => openLeftTooltip(e, item.name)
                                          : null
                                      }
                                      onMouseLeave={
                                        !deviceSize.isMobile
                                          ? () => closeLeftTooltip()
                                          : null
                                      }
                                    />

                                    <div
                                      className={
                                        (tooltipShow == item.name
                                          ? ""
                                          : "hidden ") +
                                        "bg-gray-100 border-0 mb-3 z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                                      }
                                      id={item.name}
                                    >
                                      <div>
                                        <div className="bg-gray-200 text-black font-semibold opacity-75 p-2 mb-0 border border-gray-400 rounded">
                                          {item.label}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="ml-4 flex justify-end"
                                  style={{ width: 52 }}
                                >
                                  <div>
                                    <div className="text-right text-sm font-medium">
                                      {!_.isEmpty(filter) &&
                                        filter?.value != -1 && (
                                          <div className="text-right text-sm font-medium">
                                            <span>
                                              {filter?.key == "screentime"
                                                ? filter?.value % 60
                                                : filter?.value}
                                            </span>
                                            <span>
                                              {" "}
                                              {filter?.unit ??
                                                item?.displayUnit}
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <div className="mt-1 p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                            <div>
                              <button
                                type="submit"
                                className="w-full bg-shades-of-green flex items-center justify-center text-sm text-black font-medium rounded-lg p-4"
                                style={{ height: 37 }}
                                disabled={submitting || loading}
                                onClick={() => submitHome("home")}
                              >
                                {returnType == "home" && loading ? (
                                  <svg
                                    className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                                    viewBox="0 0 24 24"
                                  ></svg>
                                ) : (
                                  ""
                                )}
                                {LocaleStrings.submit_and_return_home}
                              </button>
                            </div>
                            <div>
                              <button
                                type="submit"
                                className="w-full bg-shades-of-cyan flex items-center justify-center text-sm text-black font-medium rounded-lg p-4"
                                style={{ height: 37 }}
                                disabled={submitting || loading}
                                onClick={() => submitHome("stay")}
                              >
                                {returnType == "stay" && loading ? (
                                  <svg
                                    className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                                    viewBox="0 0 24 24"
                                  ></svg>
                                ) : (
                                  ""
                                )}
                                {LocaleStrings.submit_and_enter_more}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values.steps) {
    errors.steps = "Required";
  }
  if (values.steps && values.steps < MINIMUM_STEP) {
    errors.steps = `Minimum steps entry is ${MINIMUM_STEP}.`;
  }
  return errors;
};

const mapStateToProps = (state) => {
  var initial = formInitialData;

  initial.date =
    state.showEnterDataModalReducer.data?.callfrom == "dataentry" ||
    state.showEnterDataModalReducer.data?.callfrom == "classentry"
      ? state.showEnterDataModalReducer.data &&
        state.showEnterDataModalReducer.data.item &&
        state.showEnterDataModalReducer.data.item.date
        ? moment(state.showEnterDataModalReducer.data.item.date).format(
            DATE_FORMAT
          )
        : moment().format(DATE_FORMAT)
      : moment().format(DATE_FORMAT);

  return {
    deviceSize: state.deviceSize,
    showModal: state.showEnterDataModalReducer,
    session: state.session,
    activityList: state.activityListReducer,
    bonusPointsList: state.bonusPointsListReducer,
    homeScreenData: state.homeScreenDataReducer,
    initialValues: initial,
    eventDetails: state.eventDetails,
    eventClientLegsList: state.eventClientLegsListReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showEnterDataModal,
      fetchActivityList,
      fetchBonusPointsList,
      addMemberLiveData,
      fetchHomeScreenData,
      fetchDataEntry,
      fetchMyDataList,
      fetchEventClientLegs,
      fetchClassDataList,
      updateLeg,
      manageMaxDataEntryCapModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "DataEntryForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EnterData)
);
