import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useParams } from "react-router-dom";

const Certificate = (props) => {
  const { deviceSize } = props;
  const [detailsObj, setDetailsObj] = useState({
    dynamicImg: "",
    member: "",
    event: "",
    team: "",
    logo: "",
  });

  const param = useParams();

  useEffect(() => {
    let encodedUri = param?.details;

    let encodedString = decodeURIComponent(encodedUri);

    let decodedString = window.atob(encodedString);

    let decodedObj = JSON.parse(decodedString);

    setDetailsObj(decodedObj);
  }, []);

  return (
    <div
      className={
        deviceSize.isMobile ? "p-0 sm:p-2 content-container" : "p-0 sm:p-2"
      }
    >
      <div
        className="bg-secondary p-2"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 5 }
        }
      >
        <div className="p-2 coursemap-wrapper relative mapmarker-container">
          <div>
            <div className="grid grid-cols-2 gap-1 items-center bg-white">
              {/* logo */}
              <div className="col-span-1">
                <div className="flex justify-center p-2 bg-white">
                  <img src={detailsObj?.logo} style={{ height: 148 }} />
                </div>
              </div>

              {/* details */}
              <div className="col-span-1" style={{ paddingRight: "38px" }}>
                <div className="flex justify-center text-xl text-green-500">
                  <span>Congratulations!</span>
                </div>
                <div className="flex justify-center text-lg">
                  <span>{detailsObj?.member}</span>
                </div>
                <div className="flex justify-center">You completed the</div>
                <div className="flex justify-center text-lg">
                  <span>{detailsObj?.event}</span>
                </div>
                <div className="flex justify-center">
                  with your teammates from
                </div>
                <div className="flex justify-center text-lg">
                  <span>{detailsObj?.team}</span>
                </div>
              </div>
            </div>
            {/* image */}
            <div className="flex justify-center px-2 pb-2 pt-4 bg-white">
              <img src={detailsObj?.dynamicImg} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
