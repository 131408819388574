/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// constants
import LocaleStrings from "../../../languages";
import { ALLOWED_USER_TYPES } from "../../../constants/types-constant";

// actions
import { showMemberModal } from "../actions";

// components
import AvatarBuilder from "../../avatarBuilder";
import MemberAvatar from "../../avatarBuilder/MemberAvatar";

// files
import cross from "../../../assets/img/cross.png";

const Member = (props) => {
  const { session, showModal, showMemberModal, redirectHandler } = props;

  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const closeModal = () => {
    showMemberModal({ show: false, data: null });
    setShowAvatarModal(false);
  };

  const handleEditMemberAvatar = () => {
    setShowAvatarModal(true);
  };

  const handleEnterDataRedirection = () => {
    const memberId =
      typeof showModal.data?.id === "string"
        ? parseInt(showModal.data?.id)
        : showModal.data?.id;
    closeModal();
    redirectHandler(memberId);
  };

  return (
    <>
      {showModal && showModal.show && !showAvatarModal ? (
        <>
          <div className={`modal`}>
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header flex justify-between items-center">
                  <h3 className="text-base font-medium">
                    {`${showModal.data.firstname} ${
                      showModal.data.lastname
                        ? showModal.data.lastname.charAt(0)
                        : ""
                    }`}
                  </h3>

                  <div className="flex gap-2 items-center">
                    {session?.usertype === ALLOWED_USER_TYPES.teacher && (
                      <span
                        className="text-primary underline text-xs cursor-pointer"
                        onClick={handleEditMemberAvatar}
                      >
                        {LocaleStrings.edit_avatar}
                      </span>
                    )}

                    <button className="modal-close-btn" onClick={closeModal}>
                      <img src={cross} />
                    </button>
                  </div>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="flex justify-center items-center mt-4">
                    <MemberAvatar />
                  </div>

                  <div
                    className="rounded-lg m-4 bg-shades_of_blue_1"
                    style={{ width: 300 }}
                  >
                    <div className="flex justify-between p-2">
                      <div className="">
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.name}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {/* {showModal.data.firstname} */}
                          {showModal.data.firstname.split(" ").length > 1
                            ? showModal.data.lastname
                              ? showModal.data.firstname.split(" ")[0] +
                                " " +
                                showModal.data.lastname.charAt(0)
                              : showModal.data.firstname.split(" ")[0]
                            : showModal.data.lastname
                            ? showModal.data.firstname +
                              " " +
                              showModal.data.lastname.charAt(0)
                            : showModal.data.firstname}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.daily_avg}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(showModal.data.dailyavg ?? 0)}
                        </div>
                      </div>
                      <div>
                        <div className="text-secondary text-sm font-thin">
                          {LocaleStrings.total}
                        </div>
                        <div className="pt-1 text-sm sm:text-base">
                          {Math.floor(showModal.data.totalpoints ?? 0)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-between p-4 rounded-b border-t">
                  {session?.usertype === ALLOWED_USER_TYPES.teacher && (
                    <span
                      className="underline text-xs cursor-pointer"
                      onClick={handleEnterDataRedirection}
                    >
                      {_.replace(
                        LocaleStrings.enter_member_data,
                        "#MEMBER#",
                        showModal.data.firstname
                      )}
                    </span>
                  )}

                  <button
                    className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer p-2"
                    onClick={closeModal}
                  >
                    <span className="text-sm">
                      {LocaleStrings.button_close}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}

      {/* --- Own avatar builder --- */}
      {showAvatarModal ? (
        <AvatarBuilder
          showAvatarModal={showAvatarModal}
          setShowAvatarModal={setShowAvatarModal}
          memberData={showModal?.data}
          showMemberModal={showMemberModal}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    showModal: state.showMemberModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showMemberModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Member);
