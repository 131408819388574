import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showLegVideoModal } from "../actions";
import LocaleStrings from "../../../languages";
import {
  BASE_FILES_URL,
  FILE_ACCESS_KEY,
  BASE_AZIONV2_URL,
} from "../../../components/Common/constant";
// image imports
import cross from "../../../assets/img/cross.png";
import { isLastLegShow } from "../actions";

const FinishLeg = (props) => {
  const { showLastLeg } = props;

  const closeModal = () => {
    props.isLastLegShow(false);
  };

  return (
    <>
      {showLastLeg ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <h3 className="text-base font-medium"></h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="flex justify-center items-center mt-4"></div>

                  <div className="rounded-lg m-4" style={{ width: 400 }}>
                    <div className="flex items-center justify-center p-2">
                      Great work, your team has completed all the bonus legs. If
                      you wish you can keep entering your data for the remaining
                      days the course is open so your team total keeps
                      increasing, you will just not see the map update to new
                      leg. Then when data entry has closed we will display your
                      final Team Avg. Daily Points and create your Finishing
                      certificate.
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 rounded-b border-t">
                  <button
                    className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer p-2"
                    onClick={() => closeModal()}
                  >
                    <span className="text-sm">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showLastLeg: state.showLastLeg,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ isLastLegShow }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinishLeg);
