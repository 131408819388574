import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Popup from "reactjs-popup";

// constants
import { ALLOWED_EVENT_TYPES } from "../../constants/types-constant";
import LocaleStrings from "../../languages";
import Loader from "../../components/Common/loader";
import { HighlightText } from "../../utils/common-utils";

// actions
import {
  fetchTeamDetails,
  addTrack,
  deleteTrack,
  handleFetchedTeamSearch,
} from "./actions";
import { LOCAL_TIMEZONE } from "../../components/Common/constant";

const ViewAllTeams = (props) => {
  const {
    session,
    deviceSize,
    teamDetailsList,
    addTrack,
    deleteTrack,
    eventDetails,
  } = props;

  const { addToast } = useToasts();
  const selectOpts = [
    { name: LocaleStrings.view_teams_opt_all, value: "all" },
    { name: LocaleStrings.view_teams_opt_tracked, value: "tracked" },
    {
      name:
        eventDetails?.resource?.type === ALLOWED_EVENT_TYPES.school
          ? LocaleStrings.view_teams_opt_my_school
          : LocaleStrings.view_teams_opt_my_company,
      value: "my",
    },
  ];
  const selectDivisionOpts = [
    { name: LocaleStrings.division_open, value: "open" },
    { name: LocaleStrings.division_advance, value: "advance" },
  ];

  const [loading, setLoading] = useState(true);
  const [teamType, setTeamType] = useState(selectOpts[0].value);
  const [divisionType, setDivisionType] = useState(selectDivisionOpts[0].value);
  const [trackChangeLoading, setTrackChangeLoading] = useState(false);

  const tableTitle = _.upperFirst(session?.eventtype);

  useEffect(() => {
    handleFetchTeams();
  }, []);

  const handleFetchTeams = (
    value = { type: teamType, division: divisionType }
  ) => {
    const payloadValue = {
      eventid: session?.eventid,
      userid: session?.userid,
      teamtype: value.type,
      divisiontype: value.division,
      timezone: LOCAL_TIMEZONE,
    };

    props.fetchTeamDetails(session, payloadValue, (res) => {
      setLoading(false);
    });
  };

  const handleTeamChange = (e) => {
    let type = e.target.value;

    setTeamType(type);
    setLoading(true);
    handleFetchTeams({ type, division: divisionType });
  };

  const handleDivisionChange = (e) => {
    let division = e.target.value;

    setDivisionType(division);
    setLoading(true);
    handleFetchTeams({ type: teamType, division });
  };

  const handleTrackChange = (e, item) => {
    if (teamType == "tracked") {
      setLoading(true);
    }

    if (!e.target.checked) {
      setTrackChangeLoading(true);
      deleteTrack(session, parseInt(item.id), (res) => {
        if (res.success) {
          handleFetchTeams();
          setTrackChangeLoading(false);
          addToast(LocaleStrings.view_teams_trackteam_removed, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          if (teamType == "tracked") {
            setLoading(false);
          }
          setTrackChangeLoading(false);
          addToast(LocaleStrings.common_fail_message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    } else {
      const payloadValue = {
        eventid: session?.eventid,
        userid: session?.userid,
        teamtype: teamType,
        divisiontype: divisionType,
        timezone: LOCAL_TIMEZONE,
      };

      props.fetchTeamDetails(session, payloadValue, (res) => {
        if (res.success) {
          let teamTracked = _.filter(res.data, (obj) => {
            return obj.istracked;
          });

          if (teamTracked.length < 5) {
            setTrackChangeLoading(true);
            let resource = [
              {
                eventidfk: session.eventid,
                memberidfk: session.userid,
                teamidfk: parseInt(item.id),
              },
            ];

            addTrack(session, resource, (res) => {
              if (res.success) {
                handleFetchTeams();
                setTrackChangeLoading(false);
                addToast(LocaleStrings.view_teams_trackteam_added, {
                  appearance: "success",
                  autoDismiss: true,
                });
              }
              //
              else {
                setTrackChangeLoading(false);
                addToast(LocaleStrings.common_fail_message, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            });
          } else {
            handleFetchTeams();
            addToast(LocaleStrings.view_teams_trackteam_validation, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      });
    }
  };

  const handleTeamSearch = (isSearch, search) => {
    let payloadObj = _.cloneDeep(teamDetailsList);

    if (!isSearch) {
      payloadObj.search = null;
      payloadObj.searchedList = [];
    } else {
      let currentList = _.cloneDeep(teamDetailsList?.data);

      let filteredList = _.filter(
        currentList,
        (team) =>
          _.includes(_.toLower(team?.teamname), _.toLower(search)) ||
          _.includes(
            _.toLower(
              team?.clientname !== null ||
                team?.clientname !== "null" ||
                team?.clientname !== ""
                ? team?.clientname
                : team.schoolname
            ),
            _.toLower(search)
          )
      );

      payloadObj.search = search;
      payloadObj.searchedList = filteredList;
    }

    props.handleFetchedTeamSearch(payloadObj);
  };

  return (
    <div className="p-0 sm:p-2">
      <div
        className="bg-secondary p-2 sm:p-4"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div className="rounded-lg">
          {/* --- table header --- */}
          <div className="px-5 py-4 rounded-t-lg bg-white">
            <h3 className="text-base font-medium">
              {LocaleStrings.view_all_teams}
            </h3>
          </div>

          <div className="bg-secondary-2 px-5 py-2 flex justify-between w-full">
            <div className="flex justify-start items-center w-1/2 gap-2">
              {/* --- Filter type --- */}
              <div className="flex items-center gap-1">
                <label
                  htmlFor="select-team"
                  className="text-sm text-secondary-4"
                >
                  {LocaleStrings.select_team}
                </label>
                <select
                  id="select-team"
                  name="select-team"
                  className="select"
                  defaultValue={selectOpts[0].name}
                  style={{ width: 160 }}
                  onChange={(e) => handleTeamChange(e)}
                >
                  {_.map(selectOpts, (item, key) => {
                    return (
                      <option
                        key={`type_${key}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* --- Division type --- */}
              <div className="flex items-center gap-1">
                <label
                  htmlFor="select-team"
                  className="text-sm text-secondary-4"
                >
                  {LocaleStrings.select_division}
                </label>
                <select
                  id="select-division"
                  name="select-division"
                  className="select"
                  defaultValue={selectDivisionOpts[0].name}
                  style={{ width: 160 }}
                  onChange={(e) => handleDivisionChange(e)}
                >
                  {_.map(selectDivisionOpts, (item, key) => {
                    return (
                      <option
                        key={`division_${key}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* --- Search --- */}
            <div className="flex justify-end items-center w-1/2">
              <div className="bg-white w-72 flex items-center gap-1 rounded pl-1">
                <input
                  type="text"
                  name="search-team"
                  className="w-4/5 border-none no-ring-shadow"
                  placeholder={LocaleStrings.type_to_search}
                  value={teamDetailsList?.search ?? ""}
                  onChange={(e) => handleTeamSearch(true, e.target.value)}
                />
                {teamDetailsList?.search?.length ? (
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => handleTeamSearch(false)}
                  >
                    {LocaleStrings.clear}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {!loading ? (
            <>
              {/* --- Table --- */}
              <div className="bg-white rounded-b-none sm:rounded-b-lg">
                {deviceSize.width > 640 ? (
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg view-all-team-table-container min-w-full">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-white w-full">
                              <tr>
                                {/* - Team name - */}
                                <th
                                  scope="col"
                                  className="table-col-th"
                                  style={{ width: "20%" }}
                                >
                                  {LocaleStrings.team_name}
                                </th>
                                {/* - Type - */}
                                <th
                                  scope="col"
                                  className="table-col-th"
                                  style={{ width: "25%" }}
                                >
                                  {tableTitle}
                                </th>
                                {/* - Current leg - */}
                                <th
                                  scope="col"
                                  className="table-col-th text-center"
                                  style={{ width: "5%" }}
                                >
                                  {LocaleStrings.leg}
                                </th>
                                {/* - % Complete - */}
                                <th
                                  scope="col"
                                  className="table-col-th text-center"
                                  style={{ width: "11%" }}
                                >
                                  % {LocaleStrings.complete}
                                </th>
                                {/* - Track team - */}
                                <th
                                  scope="col"
                                  className="table-col-th text-center"
                                  style={{ width: "11%" }}
                                >
                                  {LocaleStrings.track_name}
                                </th>
                                {/* - Team size - */}
                                <th
                                  scope="col"
                                  className="table-col-th text-center"
                                  style={{ width: "11%" }}
                                >
                                  {LocaleStrings.in_team}
                                </th>
                                {/* - Team avg daily total - */}
                                <th
                                  scope="col"
                                  className="table-col-th text-center"
                                  style={{ width: "11%" }}
                                >
                                  {LocaleStrings.avg_daily_points}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 w-full">
                              {_.map(
                                teamDetailsList?.search
                                  ? teamDetailsList?.searchedList
                                  : teamDetailsList?.data,
                                (team, teamKey) => {
                                  const isAtFinalLeg =
                                    parseInt(team?.currentleg) ===
                                    eventDetails?.resource?.nooflegs +
                                      eventDetails?.resource?.noofbonuslegs;

                                  return (
                                    <tr
                                      key={teamKey + team?.id}
                                      className={`${
                                        session.teamid === parseInt(team.id)
                                          ? "bg-yellow-50"
                                          : ""
                                      }`}
                                    >
                                      {/* - Team name - */}
                                      <td
                                        className="table-col-td text-wrap"
                                        style={{ width: "20%" }}
                                      >
                                        <HighlightText
                                          text={team.teamname}
                                          highlight={
                                            teamDetailsList?.search ?? ""
                                          }
                                        />
                                      </td>
                                      {/* - Type name - */}
                                      <td
                                        className="table-col-td text-wrap"
                                        style={{ width: "25%" }}
                                      >
                                        <HighlightText
                                          text={
                                            team?.clientname !== null ||
                                            team?.clientname !== "null" ||
                                            team?.clientname !== ""
                                              ? team?.clientname
                                              : team.schoolname
                                          }
                                          highlight={
                                            teamDetailsList?.search ?? ""
                                          }
                                        />
                                      </td>
                                      {/* - Current leg - */}
                                      <td
                                        className="table-col-td text-center"
                                        style={{ width: "5%" }}
                                      >
                                        {team.currentleg}
                                      </td>
                                      {/* - % Complete - */}
                                      <td
                                        className="table-col-td text-center"
                                        style={{ width: "11%" }}
                                      >
                                        <span>
                                          {team?.percentagecoursecomplete >= 100
                                            ? 100
                                            : team?.percentagecoursecomplete}
                                        </span>

                                        {team?.percentagecoursecomplete >=
                                          100 && isAtFinalLeg ? (
                                          <Popup
                                            trigger={
                                              <span className="text-red-500 hover:cursor-pointer">
                                                (F)
                                              </span>
                                            }
                                            position="left center"
                                            on="hover"
                                            style={{ width: 400, height: 30 }}
                                            className="myPopup"
                                          >
                                            <span>
                                              {
                                                LocaleStrings.view_teams_all_legs_complete
                                              }
                                            </span>
                                          </Popup>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      {/* - Track - */}
                                      <td
                                        className="table-col-td text-center"
                                        style={{ width: "11%" }}
                                      >
                                        {session.teamid !==
                                        parseInt(team.id) ? (
                                          <>
                                            <input
                                              id="track"
                                              aria-describedby="track"
                                              name="track"
                                              type="checkbox"
                                              className={`checkbox cursor-${
                                                trackChangeLoading
                                                  ? "progress"
                                                  : "pointer"
                                              }`}
                                              style={{ borderRadius: 3 }}
                                              checked={team.istracked}
                                              //defaultChecked={team.istracked}
                                              onChange={(e) =>
                                                handleTrackChange(e, team)
                                              }
                                              disabled={trackChangeLoading}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              id="track"
                                              aria-describedby="track"
                                              name="track"
                                              type="checkbox"
                                              className="checkbox"
                                              style={{ borderRadius: 3 }}
                                              checked={true}
                                              onChange={() => null}
                                              disabled={true}
                                            />
                                          </>
                                        )}
                                      </td>
                                      {/* - Team size - */}
                                      <td
                                        className="table-col-td text-center"
                                        style={{ width: "11%" }}
                                      >
                                        {team.totalmembers}
                                      </td>
                                      {/* - Team avg total - */}
                                      <td
                                        className="table-col-td text-center"
                                        style={{ width: "11%" }}
                                      >
                                        {/* {team.teamavgtotal} */}
                                        {team.teamdailyavgtotal}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="shadow overflow-hidden border-b border-gray-200 rounded-b-lg overflow-y-auto"
                    style={{ height: 540 }}
                  >
                    {_.map(teamDetailsList?.data, (team, i) => {
                      let className = "flex justify-between p-4 border-b";
                      if (i == teamDetailsList?.count - 1) {
                        className = "flex justify-between p-4 rounded-b-lg";
                      }
                      return (
                        <div className={className}>
                          <div className="flex items-start">
                            {session.teamid !== parseInt(team.id) ? (
                              <input
                                id="track"
                                aria-describedby="track"
                                name="track"
                                type="checkbox"
                                className="checkbox"
                                style={{ borderRadius: 3 }}
                                defaultChecked={team.istracked}
                                onChange={() => handleTrackChange(team)}
                                disabled={trackChangeLoading}
                              />
                            ) : (
                              <div style={{ width: 20 }}></div>
                            )}

                            <div className="ml-4">
                              <div className="text-secondary-4 text-sm font-medium leading-none">
                                {team?.clientname !== null ||
                                team?.clientname !== "null" ||
                                team?.clientname !== ""
                                  ? team?.clientname
                                  : team.schoolname}
                              </div>
                              <div className="mt-2 text-primary-black text-sm font-medium">
                                {team.teamname}
                              </div>
                              <div className="mt-2 flex items-center">
                                <div className="rounded-full px-3 py-1 bg-primary-black text-xs text-white">
                                  {`${LocaleStrings.leg} ${team.currentleg}`}
                                </div>
                                <div className="text-secondary-4 text-xs ml-2">
                                  {`${team.totalmembers} ${LocaleStrings.in_team_2}`}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-primary-black flex items-center text-sm font-medium">
                            {/* {team.teamavgtotal} */}
                            {team.teamdailyavgtotal}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-screen">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    teamDetailsList: state.teamDetailsListReducer,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchTeamDetails, addTrack, deleteTrack, handleFetchedTeamSearch },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllTeams);
