import React, { useState, useEffect, forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _, { indexOf, max } from "lodash";
import { chartWiseData, resetChartReducer } from "./actions";
import moment from "moment";

// react-DatePicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

// svg import
import calendar from "../../assets/Group_376.svg";
import l_arrow from "../../assets/img/leftarrowwhite.png";
import r_arrow from "../../assets/img/rightarrowwhite.png";
import Loader from "../../components/Common/loader";

// component imports
import TotalPoints from "./components/TotalPoints";
import StepsActivity from "./components/StepsActivity";
import FoodDrink from "./components/FoodDrink";
import SleepScreen from "./components/SleepScreen";
import Mindfulness from "./components/Mindfulness";
import LocaleStrings from "../../languages";

const BarCharts = (props) => {
  const {
    deviceSize,
    chartWiseData,
    resetChartReducer,
    session,
    groupData,
    groupDataMonth,
    eventDetails,
  } = props;

  const [component, setComponent] = useState("totalPoints");
  const [dateRange, setDateRange] = useState([null, null]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [eventDateRange, setEventDateRange] = useState([
    new Date(),
    new Date(),
  ]);
  const [preEvntStrtDate, setPreEvntStrtDate] = useState(new Date());
  const [chartOption, setChartOption] = useState("weekly");
  const [chartOptionLimit, setChartOptionLimit] = useState(10);
  const [datePickerDisabled, setDatePickerDisabled] = useState(false);
  const [chartIndex, setChartIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [noData, setNoData] = useState("");

  var dateArray = [],
    [startDate, endDate] = dateRange,
    [eventStartDate, eventEndDate] = eventDateRange,
    eventStartDay = eventStartDate?.getDay(), // sun 0 > 5
    eventEndDay = eventEndDate?.getDay(), // sun 0 > 2
    totalPoints = "0",
    calBarThickness,
    startDateTime,
    endDateTime,
    totalP = 0,
    totalP_max = false,
    totalAc = 0,
    step = 0,
    sleep = 0,
    sleep_points = 0,
    screentime = 0,
    screentime_points = 0,
    fruit = 0,
    fruit_points = 0,
    // foods = 0,
    water = 0,
    water_points = 0,
    mindfulness = 0,
    mindfulness_points = 0,
    sugar = 0,
    sugar_points = 0,
    veges = 0,
    veges_points = 0,
    chartLabelArray = [],
    labelArray = [],
    cSteps = [],
    cActivity = [],
    cTotaLPoints = [],
    cTotalPoints_max = [],
    cSleep = [],
    cScreen = [],
    cFruit = [],
    // cFoods = [],
    cWater = [],
    cMind = [],
    cSugar = [],
    cVeges = [],
    cSleep_points = [],
    cScreen_points = [],
    cFruit_points = [],
    cWater_points = [],
    cMind_points = [],
    cSugar_points = [],
    cVeges_points = [],
    dSteps = [],
    dActivity = [],
    dTotaLPoints = [],
    dTotaLPoints_max = [],
    dSleep = [],
    dScreen = [],
    dFruit = [],
    // dFoods = [],
    dWater = [],
    dMind = [],
    dSugar = [],
    dVeges = [],
    dSleep_points = [],
    dScreen_points = [],
    dFruit_points = [],
    dWater_points = [],
    dMind_points = [],
    dSugar_points = [],
    dVeges_points = [];

  useEffect(() => {
    var sdate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]
        ?.challengecoursestartdate;
    var edate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]
        ?.challengecourseclosedate;
    var currentDate = moment();
    // resetChartReducer();
    if (!currentDate.isBefore(sdate, "date")) {
      fetchDataForChart(sdate, edate, component, "weekly");
    } else {
      setLoader(false);
      setNoData("Challenge not started yet");
    }
    setEventDateRange([new Date(sdate), new Date(edate)]);

    var d = new Date(eventDetails?.resource?.activestart);
    setPreEvntStrtDate(new Date(d.setDate(d.getDate() - 1)));

    setDateRange([new Date(sdate), new Date(edate)]);
    setDatePickerDisabled(true);
    setChartOptionLimit(7);
    setChartIndex(0);
  }, []);

  if (deviceSize.isMobile || deviceSize.width <= 640) {
    calBarThickness = 20;
  } else if (deviceSize.width >= 640 && deviceSize.width < 1024) {
    calBarThickness = 45;
  } else {
    calBarThickness = 75;
  }

  var dateObject = {
    startDate: {
      date: 0,
      month: 0,
      year: 0,
    },
    endDate: {
      date: 0,
      month: 0,
      year: 0,
    },
    totalDays: 0,
    dateRange,
  };

  const handleComponent = (e) => {
    setComponent(e.target.value);
  };

  const fetchDataForChart = (startDate, endDate, type, option) => {
    var newStartDate = startDate == "" ? "" : dateFormatHandler(startDate);
    var newEndDate = endDate == "" ? "" : dateFormatHandler(endDate);
    chartWiseData(
      session,
      newStartDate,
      newEndDate,
      type,
      option,
      (callback) => {
        if (callback?.data.length > 0) {
          setLoader(false);
        } else if (callback?.data.length == 0) {
          setNoDataFound(true);
          setNoData(LocaleStrings.no_data_entered);
        }
      }
    );
  };

  const dateFormatHandler = (date) => {
    var d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  };

  const handleChartOption = (e) => {
    var sdate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]
        ?.challengecoursestartdate;
    var edate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]
        ?.challengecourseclosedate;
    let currentDate = moment();
    setChartOption(e.target.value);
    switch (e.target.value) {
      case "custom":
        setDateRange([null, null]);
        setDatePickerDisabled(false);
        setChartOptionLimit(8);
        setChartIndex(0);
        resetChartReducer();
        if (!currentDate.isBefore(sdate, "date")) {
          fetchDataForChart(eventStartDate, eventEndDate, component, "custom");
        }
        break;

      case "weekly":
        setDateRange([eventStartDate, eventEndDate]);
        setDatePickerDisabled(true);
        setChartOptionLimit(7);
        setChartIndex(0);
        resetChartReducer();
        if (!currentDate.isBefore(sdate, "date")) {
          fetchDataForChart(eventStartDate, eventEndDate, component, "weekly");
        }
        break;

      case "monthly":
        setDateRange([eventStartDate, eventEndDate]);
        setDatePickerDisabled(true);
        setChartOptionLimit(6);
        setChartIndex(0);
        resetChartReducer();
        if (!currentDate.isBefore(sdate, "date")) {
          fetchDataForChart(eventStartDate, eventEndDate, component, "monthly");
        }
        break;

      default:
        break;
    }
  };

  const CustomDatePicker = forwardRef(
    ({ onChange, placeholder, value, onClick }, ref) => (
      <input
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        ref={ref}
        className={datePickerDisabled ? "cursor-not-allowed" : ""}
        style={{ width: 200 }}
      />
    )
  );

  const DatePickerComponent = () => {
    return (
      <span className="block p-3 border-0 text-sm bg-white font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-r-md">
        <DatePicker
          selectsRange={true}
          filterDate={(d) => {
            return d >= preEvntStrtDate && d <= eventEndDate;
          }}
          placeholderText="Select an Date range"
          dateFormat="d MMM yyyy"
          startDate={startDate}
          endDate={endDate}
          minDate={preEvntStrtDate}
          maxDate={eventEndDate}
          value={dateRange}
          onChange={(update) => {
            setDateRange(update);
          }}
          customInput={<CustomDatePicker />}
          isClearable={chartOption === "custom" ? true : false}
          disabled={datePickerDisabled}
          showDisabledMonthNavigation
          shouldCloseOnSelect={false}
          disabledKeyboardNavigation
        />
      </span>
    );
  };

  dateRange.map((ele, index) => {
    if (ele !== null) {
      if (index === 0) {
        dateObject.startDate.date = ele.getDate();
        dateObject.startDate.month = ele.getMonth() + 1;
        dateObject.startDate.year = ele.getFullYear();
        startDateTime = ele.getTime();
      }
      if (index === 1) {
        dateObject.endDate.date = ele.getDate();
        dateObject.endDate.month = ele.getMonth() + 1;
        dateObject.endDate.year = ele.getFullYear();
        endDateTime = ele.getTime();
      }
    }
  });

  dateObject.totalDays =
    Math.round((endDateTime - startDateTime) / (1000 * 60 * 60 * 24)) + 1;

  var newStartDate = new Date(
    `${dateObject.startDate.year}-${dateObject.startDate.month}-${dateObject.startDate.date}`
  );
  var newEndDate = new Date(
    `${dateObject.endDate.year}-${dateObject.endDate.month}-${dateObject.endDate.date}`
  );

  const getDateArray = (start, end) => {
    var arr = [],
      dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  if (chartOption === "weekly") {
    var preEventStart = eventStartDay == 0 ? 6 : eventStartDay - 1;
    var postEventEnd = eventEndDay == 0 ? 0 : 6 - eventEndDay;

    var weekStart = new Date(
      newStartDate.setDate(newStartDate.getDate() - preEventStart)
    );
    var weekEnd = new Date(
      newEndDate.setDate(newEndDate.getDate() + postEventEnd)
    );

    dateArray = getDateArray(weekStart, weekEnd);
  } else {
    dateArray = getDateArray(newStartDate, newEndDate);
  }

  const checkMatchDate = (date) => {
    var checkDate = moment(date).format("YYYY-MM-DD");
    var matchData = groupData[checkDate];

    if (matchData && matchData.length > 0) {
      cTotaLPoints.push(matchData[0].totalPoints);
      cTotalPoints_max.push(matchData[0].totalPoints_max);
      cSteps.push(matchData[0].total_steps);
      cActivity.push(matchData[0].total_activity);
      cSleep.push(matchData[0].total_sleep);
      cSleep_points.push(matchData[0].total_sleep_points);
      cScreen.push(matchData[0].total_screentime * 60);
      cScreen_points.push(matchData[0].total_screentime_points);
      cFruit.push(matchData[0].total_fruit);
      cFruit_points.push(matchData[0].total_fruit_points);
      // cFoods.push(matchData[0].total_foods);
      cWater.push(matchData[0].total_water);
      cWater_points.push(matchData[0].total_water_points);
      cMind.push(matchData[0].total_mindfulness);
      cMind_points.push(matchData[0].total_mindfulness_points);
      cSugar.push(matchData[0].total_sugar);
      cSugar_points.push(matchData[0].total_sugar_points);
      cVeges.push(matchData[0].total_veges);
      cVeges_points.push(matchData[0].total_veges_points);
    } else {
      pushBlankDataIntoArray();
    }
  };

  const checkMatchMonth = (date) => {
    var checkMonth = moment(date).format("YYYY-MM");
    var matchDataArray = groupDataMonth[checkMonth];

    if (matchDataArray && matchDataArray.length > 0) {
      var tp = 0;
      matchDataArray.map((data) => {
        tp += data.totalPoints;
      });
      var st = 0;
      matchDataArray.map((data) => {
        st += data.total_steps;
      });
      var ac = 0;
      matchDataArray.map((data) => {
        ac += data.total_activity;
      });
      var slp = 0;
      var slp_points = 0;
      matchDataArray.map((data) => {
        slp += data.total_sleep;
        slp_points += data.total_sleep_points;
      });
      var scrt = 0;
      var scrt_points = 0;
      matchDataArray.map((data) => {
        scrt += data.total_screentime;
        scrt_points += data.total_screentime_points;
      });
      var frt = 0;
      var frt_points = 0;
      matchDataArray.map((data) => {
        frt += data.total_fruit;
        frt_points += data.total_fruit_points;
      });
      // var fd = 0;
      // matchDataArray.map((data) => {
      //   fd += data.total_foods;
      // });
      var wtr = 0;
      var wtr_points = 0;
      matchDataArray.map((data) => {
        wtr += data.total_water;
        wtr_points += data.total_water_points;
      });
      var mnd = 0;
      var mnd_points = 0;
      matchDataArray.map((data) => {
        mnd += data.total_mindfulness;
        mnd_points += data.total_mindfulness_points;
      });
      var sg = 0;
      var sg_points = 0;
      matchDataArray.map((data) => {
        sg += data.total_sugar;
        sg_points += data.total_sugar_points;
      });
      var vg = 0;
      var vg_points = 0;
      matchDataArray.map((data) => {
        vg += data.total_veges;
        vg_points += data.total_veges_points;
      });
      totalP = tp;
      totalP_max = false;
      step = st;
      totalAc = ac;
      sleep = slp;
      sleep_points = slp_points;
      screentime = scrt * 60;
      screentime_points = scrt_points;
      fruit = frt;
      fruit_points = frt_points;
      // foods = fd;
      water = wtr;
      water_points = wtr_points;
      mindfulness = mnd;
      mindfulness_points = mnd_points;
      sugar = sg;
      sugar_points = sg_points;
      veges = vg;
      veges_points = vg_points;
    } else {
      totalP = 0;
      totalP_max = false;
      step = 0;
      totalAc = 0;
      sleep = 0;
      sleep_points = 0;
      screentime = 0;
      screentime_points = 0;
      fruit = 0;
      fruit_points = 0;
      // foods = 0;
      water = 0;
      water_points = 0;
      mindfulness = 0;
      mindfulness_points = 0;
      sugar = 0;
      sugar_points = 0;
      veges = 0;
      veges_points = 0;
    }
  };

  const pushBlankDataIntoArray = () => {
    cTotaLPoints.push(0);
    cTotalPoints_max.push(false);
    cSteps.push(0);
    cActivity.push(0);
    cSleep.push(0);
    cScreen.push(0);
    cFruit.push(0);
    // cFoods.push(0);
    cWater.push(0);
    cMind.push(0);
    cSugar.push(0);
    cVeges.push(0);
    cSleep_points.push(0);
    cScreen_points.push(0);
    cFruit_points.push(0);
    cWater_points.push(0);
    cMind_points.push(0);
    cSugar_points.push(0);
    cVeges_points.push(0);
  };

  if (chartOption !== "monthly") {
    dateArray.length !== null &&
      dateArray.map((date) => {
        var d = date.getDate();
        var day = date.toLocaleDateString("en-us", { weekday: "short" });
        var m = date.toLocaleDateString("en-us", { month: "long" });
        var ds = `${day}, ${d} ${m}`;
        chartLabelArray.push(ds); // data set for label
        if (
          date.getDate() === eventStartDate.getDate() &&
          date.getMonth() === eventStartDate.getMonth() &&
          date.getFullYear() === eventStartDate.getFullYear()
        ) {
          checkMatchDate(date);
        } else if (date >= eventStartDate && date <= eventEndDate) {
          checkMatchDate(date);
        } else {
          pushBlankDataIntoArray();
        }
      });
  } else {
    dateArray = getDateArray(startDate, endDate);
    dateArray.map((date) => {
      var m = date.toLocaleDateString("en-us", { month: "long" });
      checkMatchMonth(date);
      if (chartLabelArray.length == 0 || chartLabelArray.indexOf(m) == -1) {
        chartLabelArray.push(m);

        cTotaLPoints.push(totalP);
        cTotalPoints_max.push(totalP_max);
        cSteps.push(step);
        cActivity.push(totalAc);
        cSleep.push(sleep);
        cSleep_points.push(sleep_points);
        cScreen.push(screentime);
        cScreen_points.push(screentime_points);
        cFruit.push(fruit);
        cFruit_points.push(fruit_points);
        // cFoods.push(foods);
        cWater.push(water);
        cWater_points.push(water_points);
        cMind.push(mindfulness);
        cMind_points.push(mindfulness_points);
        cSugar.push(sugar);
        cSugar_points.push(sugar_points);
        cVeges.push(veges);
        cVeges_points.push(veges_points);
      }
    });
  }

  let limit = chartOptionLimit;
  let res = chartLabelArray.length / limit;
  for (let index = 0; index < res; index++) {
    labelArray[index] = chartLabelArray.splice(0, limit);
    dTotaLPoints[index] = cTotaLPoints.splice(0, limit);
    dTotaLPoints_max[index] = cTotalPoints_max.splice(0, limit);
    dSteps[index] = cSteps.splice(0, limit);
    dActivity[index] = cActivity.splice(0, limit);
    dSleep[index] = cSleep.splice(0, limit);
    dSleep_points[index] = cSleep_points.splice(0, limit);
    dScreen[index] = cScreen.splice(0, limit);
    dScreen_points[index] = cScreen_points.splice(0, limit);
    dFruit[index] = cFruit.splice(0, limit);
    dFruit_points[index] = cFruit_points.splice(0, limit);
    // dFoods[index] = cFoods.splice(0, limit);
    dWater[index] = cWater.splice(0, limit);
    dWater_points[index] = cWater_points.splice(0, limit);
    dMind[index] = cMind.splice(0, limit);
    dMind_points[index] = cMind_points.splice(0, limit);
    dSugar[index] = cSugar.splice(0, limit);
    dSugar_points[index] = cSugar_points.splice(0, limit);
    dVeges[index] = cVeges.splice(0, limit);
    dVeges_points[index] = cVeges_points.splice(0, limit);
  }

  const loadPreviousData = () => {
    chartIndex !== 0 ? setChartIndex(chartIndex - 1) : setChartIndex(0);
  };
  const loadNextData = () => {
    chartIndex !== labelArray.length - 1
      ? setChartIndex(chartIndex + 1)
      : setChartIndex(labelArray.length - 1);
  };

  var chartDataObject = {
    // data for the chart to be plotted
    labels: labelArray[chartIndex], // x-axis labels
    datasets: [
      {
        data: dTotaLPoints[chartIndex], // actual data
        display_max: dTotaLPoints_max[chartIndex],
        backgroundColor: "#1B75BC",
        barThickness: calBarThickness,
      },
    ],
  };

  switch (component) {
    case "totalPoints":
      chartDataObject = {
        labels: labelArray[chartIndex],
        datasets: [
          {
            data: dTotaLPoints[chartIndex],
            display_max: dTotaLPoints_max[chartIndex],
            backgroundColor: "#1B75BC",
            barThickness: calBarThickness,
          },
        ],
      };

      break;

    case "stepsActivity":
      chartDataObject = {
        labels: labelArray[chartIndex],
        datasets: [
          {
            label: LocaleStrings.activity_text,
            data: dActivity[chartIndex],
            backgroundColor: "#49B749",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
          {
            label: LocaleStrings.steps,
            data: dSteps[chartIndex],
            backgroundColor: "#264653",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
        ],
      };
      break;

    case "foodDrink":
      chartDataObject = {
        labels: labelArray[chartIndex],
        datasets: [
          {
            label: LocaleStrings.bonus_water,
            display_data: dWater[chartIndex],
            data: dWater_points[chartIndex],
            backgroundColor: "#264653",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
          {
            label: LocaleStrings.bonus_fruit,
            display_data: dFruit[chartIndex],
            data: dFruit_points[chartIndex],
            backgroundColor: "#FED346",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
          {
            label: LocaleStrings.bonus_veges,
            display_data: dVeges[chartIndex],
            data: dVeges_points[chartIndex],
            backgroundColor: "#49B749",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
          // {
          //   label: LocaleStrings.bonus_snacks,
          //   data: dFoods[chartIndex],
          //   backgroundColor: "#1B75BC",
          //   barThickness: calBarThickness,
          //   pointStyle: "circle",
          // },
          {
            label: LocaleStrings.bonus_sugar,
            display_data: dSugar[chartIndex],
            data: dSugar_points[chartIndex],
            backgroundColor: "#653B9F",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
        ],
      };
      break;

    case "sleepAndScreenTime":
      chartDataObject = {
        labels: labelArray[chartIndex],
        datasets: [
          {
            label: LocaleStrings.bonus_sleep,
            display_data: dSleep[chartIndex],
            data: dSleep_points[chartIndex],
            backgroundColor: "#1B75BC",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
          {
            label: LocaleStrings.bonus_screen,
            display_data: dScreen[chartIndex],
            data: dScreen_points[chartIndex],
            backgroundColor: "#FF6C52",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
        ],
      };
      break;

    case "mindfulness":
      chartDataObject = {
        labels: labelArray[chartIndex],
        datasets: [
          {
            label: LocaleStrings.bonus_mindfulness,
            display_data: dMind[chartIndex],
            data: dMind_points[chartIndex],
            backgroundColor: "#264653",
            barThickness: calBarThickness,
            pointStyle: "circle",
          },
        ],
      };
      break;

    default:
      break;
  }

  var componentObject = {
    chartDataObject,
    showComponent: component,
    isMobile: deviceSize.isMobile,
    calBarThickness,
    totalPoints,
    chartOption,
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="px-5 py-4 rounded-t-lg bg-white">
              <h3 className="text-base font-medium">{LocaleStrings.my_data}</h3>
            </div>
            {!loader ? (
              noData !== "" ? (
                <>
                  <div className="flex justify-center items-center h-screen">
                    <span>{noData}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 bg-secondary-2 px-5 py-2 justify-start items-center">
                    <div
                      className="flex gap-3"
                      style={{
                        flexDirection:
                          deviceSize.width > 750 && deviceSize.width < 870
                            ? "column"
                            : "row",
                      }}
                    >
                      <div className="flex">
                        <select
                          id="dropdown-1"
                          name="dropdown-1"
                          className="block pl-3 pr-7 py-3 border-0 text-sm font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-md cursor-pointer"
                          defaultValue="totalPoints"
                          style={{ width: 176 }}
                          onChange={handleComponent}
                        >
                          <option value="totalPoints">
                            {LocaleStrings.total_points}
                          </option>
                          <option value="stepsActivity">
                            {LocaleStrings.steps} &{" "}
                            {LocaleStrings.activity_text}
                          </option>
                          <option value="foodDrink">Food & Drinks</option>
                          <option value="sleepAndScreenTime">
                            {LocaleStrings.bonus_sleep} &{" "}
                            {LocaleStrings.bonus_screen}
                          </option>
                          <option value="mindfulness">
                            {LocaleStrings.bonus_mindfulness}
                          </option>
                        </select>
                        <select
                          id="dropdown-2"
                          name="dropdown-2"
                          className="ml-4 block pl-3 pr-10 py-3 border-0 text-sm font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-md cursor-pointer"
                          defaultValue="weekly"
                          style={{ width: 106 }}
                          onChange={handleChartOption}
                        >
                          <option value="weekly">{LocaleStrings.weekly}</option>
                          <option value="monthly">
                            {LocaleStrings.monthly}
                          </option>
                          <option value="custom">{LocaleStrings.custom}</option>
                        </select>
                        <div className="flex rounded-lg lg:justify-center invisible md:visible lg:visible pl-3">
                          <img
                            src={calendar}
                            alt="calender goes here"
                            className="ml-3 block p-3 text-sm bg-white font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-l-md"
                          />
                          <DatePickerComponent />
                        </div>
                      </div>
                      <div className="">
                        {chartOption !== "custom" ||
                        (chartOption === "custom" && endDate) ? (
                          <div className="flex justify-end invisible md:visible lg:invisible pl-10">
                            <div className="pr-3">
                              <span
                                className="rounded-md h-12 w-12 flex items-center justify-center cursor-pointer"
                                onClick={loadPreviousData}
                                style={{
                                  disabled: chartIndex === 0 ? true : false,
                                  cursor:
                                    chartIndex === 0
                                      ? "not-allowed"
                                      : "pointer",
                                  backgroundColor:
                                    chartIndex === 0 ? "#87BAF4" : "#1B75BC",
                                }}
                              >
                                <img src={l_arrow} alt="" />
                              </span>
                            </div>
                            <div className="">
                              <span
                                className="rounded-md h-12 w-12 flex items-center justify-center cursor-pointer"
                                onClick={loadNextData}
                                style={{
                                  disabled:
                                    chartIndex === labelArray.length - 1
                                      ? true
                                      : false,
                                  cursor:
                                    chartIndex === labelArray.length - 1
                                      ? "not-allowed"
                                      : "pointer",
                                  backgroundColor:
                                    chartIndex === labelArray.length - 1
                                      ? "#87BAF4"
                                      : "#1B75BC",
                                }}
                              >
                                <img src={r_arrow} alt="" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="flex rounded-lg lg:justify-center md:invisible lg:invisible pl-6">
                      <span className="block p-3 text-sm bg-white font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-l-md">
                        <img
                          src={calendar}
                          alt="calender goes here"
                          style={{ alignSelf: "stretch" }}
                        />
                      </span>
                      <DatePickerComponent />
                    </div>
                    {chartOption !== "custom" ||
                    (chartOption === "custom" && endDate) ? (
                      <div className="flex justify-end md:invisible lg:visible">
                        <div className="pr-3">
                          <span
                            className="rounded-md h-12 w-12 flex items-center justify-center cursor-pointer"
                            onClick={loadPreviousData}
                            style={{
                              disabled: chartIndex === 0 ? true : false,
                              cursor:
                                chartIndex === 0 ? "not-allowed" : "pointer",
                              backgroundColor:
                                chartIndex === 0 ? "#87BAF4" : "#1B75BC",
                            }}
                          >
                            <img src={l_arrow} alt="" />
                          </span>
                        </div>
                        <div className="">
                          <span
                            className="rounded-md h-12 w-12 flex items-center justify-center cursor-pointer"
                            onClick={loadNextData}
                            style={{
                              disabled:
                                chartIndex === labelArray.length - 1
                                  ? true
                                  : false,
                              cursor:
                                chartIndex === labelArray.length - 1
                                  ? "not-allowed"
                                  : "pointer",
                              backgroundColor:
                                chartIndex === labelArray.length - 1
                                  ? "#87BAF4"
                                  : "#1B75BC",
                            }}
                          >
                            <img src={r_arrow} alt="" />
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {chartOption !== "custom" ||
                  (chartOption === "custom" && endDate) ? (
                    <div className="bg-white rounded-b-lg">
                      {deviceSize.isMobile ? (
                        <div className="rounded-b-lg " style={{ height: 400 }}>
                          <TotalPoints componentObject={componentObject} />
                          <StepsActivity componentObject={componentObject} />
                          <FoodDrink componentObject={componentObject} />
                          <SleepScreen componentObject={componentObject} />
                          <Mindfulness componentObject={componentObject} />
                        </div>
                      ) : (
                        <div
                          className="p-3 rounded-b-lg "
                          style={
                            deviceSize.width >= 1800
                              ? { height: 600 }
                              : { height: 580 }
                          }
                        >
                          <TotalPoints componentObject={componentObject} />
                          <StepsActivity componentObject={componentObject} />
                          <FoodDrink componentObject={componentObject} />
                          <SleepScreen componentObject={componentObject} />
                          <Mindfulness componentObject={componentObject} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="bg-white p-3 rounded-b-lg"
                      style={{ height: 400 }}
                    >
                      <span className="p-4 text-sm text-secondary">
                        {LocaleStrings.select_daterange}
                      </span>
                    </div>
                  )}
                </>
              )
            ) : noDataFound && noData !== "" ? (
              <>
                <div className="flex justify-center items-center h-screen">
                  <span>{noData}</span>
                </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-screen">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const groupData = _.groupBy(state?.myChartDataReducer?.data, (date) =>
    moment(date.date).format("YYYY-MM-DD")
  );

  const groupDataMonth = _.groupBy(state?.myChartDataReducer?.data, (date) =>
    moment(date.date).format("YYYY-MM")
  );

  return {
    deviceSize: state.deviceSize,
    myChartDataReducer: state.myChartDataReducer,
    session: state.session,
    groupData,
    groupDataMonth,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ chartWiseData, resetChartReducer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BarCharts);
