/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { passwordReset } from "../../actions";
import {
  BASE_FILES_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";
import { renderFieldText } from "../../components/Common/base-components";

const ResetPassword = (props) => {
  const { deviceSize, passwordReset, session, eventDetails, handleSubmit } =
    props;

  const { addToast } = useToasts();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [loginBg, setLoginBg] = useState([]);
  const [currentBgInd, setCurrentBgInd] = useState(0);

  let loginbgimgArr = [];
  let src = "";

  useEffect(() => {
    let search = new URLSearchParams(location.search);

    let email = search.get("email");
    let code = search.get("code");
    if (email) {
      setEmail(email);
    }
    if (code) {
      setCode(code);
    }
  });

  const onSubmitForm = (values, dispatch) => {
    setLoading(true);
    if (email) {
      let eventid = eventDetails.resource.eventid;
      values.eventid = eventid;
      values.email = email;
      passwordReset(values, (response) => {
        setLoading(false);

        if (response.success === 1) {
          reset();
          let content = "Password Change Successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
          history.push("/login");
        } else {
          reset();
          addToast(response.desc, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    } else {
      addToast("Please enter  Email/Username.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="p-2">
      <div
        className="bg-cover p-4 flex items-center justify-center login-bg-image-container"
        style={{
          height: 450,
          borderRadius: 12,
        }}
      >
        <div className="bg-white rounded-lg" style={{ width: 350 }}>
          <div className="border-b px-4 pt-6">
            <div>
              <div className="text-xl-1 text-black font-semibold">
                Reset Password
              </div>
            </div>

            <div className="mt-6.5">
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div>
                  <Field
                    name="resetcode"
                    type="text"
                    component={renderFieldText}
                    label="Confirm Code"
                    placeholder="Confirm Code"
                    readonly
                  />
                </div>
                <div>
                  <Field
                    name="password"
                    type="password"
                    component={renderFieldText}
                    label="Password"
                    placeholder="Password"
                  />
                </div>
                <div>
                  <Field
                    name="confirm_password"
                    type="password"
                    component={renderFieldText}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn my-4 bg-refresh text-white h-10"
                    style={{ borderRadius: 6, height: 47 }}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className="text-sm">Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values.resetcode) {
    errors.resetcode = "required";
  }
  if (!values.password) {
    errors.password = "required";
  } else if (values.password.length < 6) {
    errors.password = "minimum 6 character length";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "required";
  }

  if (values.password && values.confirm_password) {
    if (values.confirm_password != values.password) {
      errors.confirm_password = "Password and Confirm Password Mismatch";
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ passwordReset }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ResetForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ResetPassword)
);
