import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import Loader from "../../components/Common/loader";

import { fetchFAQ } from "./actions";

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Support = (props) => {
  const { session, deviceSize, fetchFAQ, faqList } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFAQ(session, (res) => {
      setLoading(false);
    });
  }, []);

  return !loading ? (
    <div className="p-0 sm:p-2">
      <div
        className="bg-secondary p-2 sm:p-4"
        style={
          deviceSize.width >= 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div
          className="rounded-lg bg-white p-4 faq-container"
          style={
            deviceSize.width > 1440
              ? { height: deviceSize.height - 240 }
              : deviceSize.width >= 640 && deviceSize.width <= 1440
              ? { height: deviceSize.height - 140 }
              : { height: deviceSize.height - 110 }
          }
        >
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
              {/* <h2 className='text-center text-3xl font-extrabold text-gray-900 sm:text-4xl'>
                Frequently asked questions
              </h2> */}
              <dl className="space-y-6 divide-y divide-gray-200">
                {faqList.data.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                            <span className="font-medium text-gray-900">
                              {faq.question}
                            </span>
                            <span className="ml-6 h-7 flex items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-6 w-6 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <Loader />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    faqList: state.faqListReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchFAQ }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Support);
