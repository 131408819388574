import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { regFormAction } from "../action";
import { Modal } from "reactstrap";
import _ from "lodash";
import cross from "../../../assets/img/cross.png";
import { useToasts } from "react-toast-notifications";

const renderField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="mt-2">
      <label className="block text-sm sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <input
          className="enter-data-steps-input"
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderFieldOptions = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="mt-2">
      <label className="block text-sm sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <select
          name="gender"
          {...input}
          className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 text-gray-700 rounded-md"
        >
          <option value="">-- select --</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const specialCharReplace = (str) => {
  return str.replace(/[&\/\\#,+()$~%.\_`@'":*?<^>{!-=}]/g, "");
};
const firstNameSpecialCharReplace = (str) => {
  str = str.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, "");
  str = str.replace(/,/gi, "");
  str = str.replace(/-/gi, "");
  return str;
};
const lastNameSpecialCharReplace = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};
const userNameSpecialCharReplace = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, _.random(1, 9));
};

const StudentDetails = (props) => {
  const {
    deviceSize,
    handleSubmit,
    reset,
    submitting,
    regFormAction,
    session,
    minMember,
    maxMember,
  } = props;

  const addToast = useToasts();
  const specialChars = `/[&\/\\#,+()$~%.\_@'":*?<^>{!-=}]/\``;
  const [boysArr, setBoysArr] = useState([]);
  const [girlsArr, setGirlsArr] = useState([]);
  const [students, setStudents] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showMaxMember, setShowMaxMember] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const min = 100000;
  const max = 999999;
  const random = Math.floor(min + Math.random() * (max - min));
  var password = random;

  const renderTableBoys = useCallback(() => {
    var arr = boysArr;
    const deleteStudent = (stu) => {
      let details = _.findIndex(arr, { username: stu.username });

      arr.splice(details, 1);
      setBoysArr(arr);

      let stuArr = students;
      stuArr.splice(details, 1);
      setStudents(stuArr);
      setTrigger(trigger + 1);
    };

    return (
      <table className="" style={{ width: 240 }}>
        <tbody>
          {_.map(boysArr, (stu, key) => {
            return (
              <tr key={key} className="mt-1">
                <td>{stu.firstname}</td>
                <td>{stu.lastinitial}</td>
                <td
                  className="round-close-button"
                  onClick={() => deleteStudent(stu)}
                >
                  <span className="">x</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [boysArr, students, trigger]);

  const renderTableGirls = useCallback(() => {
    var arr = girlsArr;
    const deleteStudent = (stu) => {
      let details = _.findIndex(arr, { username: stu.username });

      arr.splice(details, 1);
      setGirlsArr(arr);

      let stuArr = students;
      stuArr.splice(details, 1);
      setStudents(stuArr);
      setTrigger(trigger + 1);
    };

    return (
      <table className="" style={{ width: 240 }}>
        <tbody>
          {_.map(girlsArr, (stu, key) => {
            return (
              <tr key={key} className="mt-1">
                <td>{stu.firstname}</td>
                <td>{stu.lastinitial}</td>
                <td
                  className="round-close-button"
                  onClick={() => deleteStudent(stu)}
                >
                  <span className="">x</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [girlsArr, students, trigger]);

  const onStudentAdd = (values) => {
    if (students.length < maxMember) {
      // values.firstname = specialCharReplace(values.firstname);
      values.firstname = firstNameSpecialCharReplace(values.firstname);
      values.lastinitial = lastNameSpecialCharReplace(values.lastinitial);

      let user = (values.firstname + values.lastinitial)
        .split(" ")
        .join("")
        .toLowerCase();
      user = userNameSpecialCharReplace(user);

      values = {
        ...values,
        username: user,
        password: values.setpassword ? values.setpassword : password,
        membertype: "student",
        ismanager: 0,
      };

      switch (values.gender) {
        case "male":
          setBoysArr([...boysArr, values]);
          setStudents([...students, values]);
          reset();
          break;
        case "female":
          setGirlsArr([...girlsArr, values]);
          setStudents([...students, values]);
          reset();
          break;

        default:
          break;
      }
      setErrorMsg(false);
    } else {
      // setShowMaxMember(true);

      // let content = `Any special character will be removed ( Except emails ) ${specialChars}`;
      // addToast(content, {
      //   appearance: "warning",
      //   autoDismiss: true,
      // });

      // setTimeout(() => {
      //   addStudents();
      // }, 3000);
      // addStudents();

      if (students.length > 0) {
        let values = { members: students };
        let arr = [2, values];
        regFormAction(arr, (res) => {});
      } else {
        setErrorMsg(true);
      }
    }
  };

  const addStudents = () => {
    if (students.length > 0) {
      let values = { members: students };
      let arr = [2, values];
      regFormAction(arr, (res) => {});
    } else {
      setErrorMsg(true);
    }
  };

  const handleNextButtonClick = () => {
    if (students.length >= minMember) {
      setShowConfirmModal(true);
      // addStudents();
    } else setErrorMsg(true);
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-3xl md:text-5xl pb-3 pt-3 mb-3">
                Teachers, set up your class team
              </h2>
              <span className="">Please enter your students details below</span>
              <br />
              <span className="text-red-600">
                NOTE: For privacy purposes, please enter their first name and
                last initial, gender and click Add. When you have entered your
                whole class, please click Next
              </span>
            </div>
            <div
              className="bg-secondary-2 py-2 rounded-lg"
              style={
                deviceSize.width > 768
                  ? { minHeight: 350, overflow: "hidden" }
                  : { minHeight: 450, overflow: "hidden" }
              }
            >
              <form onSubmit={handleSubmit(onStudentAdd)}>
                {errorMsg ? (
                  <span className="text-red-600 px-6">
                    Sorry, you need to add minimum of {minMember} entrant
                    details here.
                  </span>
                ) : showMaxMember ? (
                  <>
                    <span className="text-red-600 px-6">
                      Sorry, you can only add maximum of {maxMember} entrants.
                    </span>
                  </>
                ) : (
                  ""
                )}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center items-end px-6 md:px-12">
                  <div className="col-span-1 md:col-span-2">
                    <div className="flex gap-4">
                      <Field
                        name="firstname"
                        type="text"
                        component={renderField}
                        label="First Name"
                        placeholder="First name"
                      />
                      <Field
                        name="lastinitial"
                        type="text"
                        component={renderField}
                        label="Last Initial"
                        placeholder="Last initial"
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <Field
                      name="gender"
                      type="text"
                      component={renderFieldOptions}
                      label="Gender"
                    />
                  </div>
                  <div className="col-span-1 md:col-span-3">
                    <button
                      type="submit"
                      disabled={submitting}
                      className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                      style={{ width: 100 }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </form>

              {/* --- boy girl display table starts --- */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6 md:px-12 mt-4 text-gray-700 font-bold">
                <div className="col-span-1">
                  <div className="border-b-4 border-secondary-2">Boys</div>
                  {renderTableBoys()}
                </div>

                <div className="col-span-1">
                  <div className="border-b-4 border-secondary-2">Girls</div>
                  {renderTableGirls()}
                </div>
              </div>
              {/* ---  boy girl display table ends  --- */}

              {/* form submit */}
              <div className="flex justify-center m-6">
                <button
                  type="button"
                  className="mt-10 btn bg-refresh text-white rounded-lg h-9"
                  style={{ width: 200 }}
                  // onClick={addStudents}
                  onClick={handleNextButtonClick}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* confirmation modal */}
      {showConfirmModal ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header border-b">
                  <h3 className="text-base font-medium">
                    Have you entered data correctly?
                  </h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => setShowConfirmModal(false)}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="">
                  <div className="p-4">
                    {/* <div className="">
                      <span className="text-red-600">
                        *After any special character removal
                      </span>
                    </div>
                    <div className="">{specialChars}</div> */}
                    {/* {_.map(students, (student, key) => {
                      return (
                        <>
                          <div key={`interest_key_${key}`} className="mb-2">
                            <div className="">
                              <span className="">
                                First Name: {student?.firstname}
                              </span>
                            </div>
                            <div className="">
                              <span className="">
                                Last Initial: {student?.lastinitial}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })} */}
                    <span>Proceed?</span>
                  </div>

                  <div className="flex items-center justify-between text-sm p-4 rounded-b border-t">
                    <button
                      className="bg-gray-400 text-gray-600 rounded-lg inline-flex items-center justify-center cursor-pointer mr-1 py-2 px-4"
                      onClick={() => setShowConfirmModal(false)}
                    >
                      <span className="text-sm">
                        No, return to the list to edit
                      </span>
                    </button>
                    <button
                      className="bg-refresh text-white rounded-lg inline-flex items-center justify-center cursor-pointer ml-1 py-2 px-4"
                      onClick={addStudents}
                    >
                      <span className="text-sm">Yes, submit my class now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values.firstname) {
    errors.firstname = "Required*";
  } else if (values.firstname.length > 15) {
    errors.firstname = "Must be 15 characters or less";
  }
  if (!values.lastinitial) {
    errors.lastinitial = "Required*";
  } else if (values.lastinitial.length > 1) {
    errors.lastinitial = "Must be only be the initial";
  }

  if (!values.gender) {
    errors.gender = "Required*";
  }

  return errors;
};

const mapStateToProps = (state) => {
  let minMember =
    !_.isEmpty(state.saveRegReqDetailsReducer) &&
    !_.isEmpty(state.saveRegReqDetailsReducer?.client_info_by_schoolid)
      ? state.saveRegReqDetailsReducer?.client_info_by_schoolid?.minteammember
      : 1;
  let maxMember =
    !_.isEmpty(state.saveRegReqDetailsReducer) &&
    !_.isEmpty(state.saveRegReqDetailsReducer?.client_info_by_schoolid)
      ? state.saveRegReqDetailsReducer?.client_info_by_schoolid?.maxteammember
      : 98;

  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    minMember,
    maxMember,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ regFormAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationFormTwo",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(StudentDetails)
);
