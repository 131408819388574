export const APP_POPUP_TYPES = {
  maxPoint: "hideMaxPointPopup",
  bonus: "bonusLegComplete",
  courseComplete: "courseLegComplete",
  fireworks: "disableFireWorks",
  coveredLegs: "coveredLegs",
};
export const APP_POPUP_INIT = {
  hideMaxPointPopup: false,
  bonusLegComplete: false,
  courseLegComplete: false,
  disableFireWorks: false,
  coveredLegs: [],
};

export const APP_LOCALSTORAGE_KEYS = {
  popups: "popups",
  state: "state",
};

export const ALLOWED_EVENT_TYPES = {
  school: "school",
  company: "company",
  guardian: "guardian",
};

export const AVATAR_TYPE = {
  hairColor: {
    h1: "#D5D5D5",
    h2: "#B53A3A",
    h3: "#E6BA29",
    h4: "#D08F37",
    h5: "#663308",
    h6: "#000000",
  },
  skinColor: {
    s1: "#CC9966",
    s2: "#996614",
    s3: "#FEE4BE",
    s4: "#FD9966",
  },
  parts: {
    top: "top",
    bottom: "bottom",
    shoes: "shoes",
    head: "head",
    hair: "hair",
    eyes: "eyes",
    eyeBrow: "eye_brow",
    nose: "nose",
    mouth: "mouth",
    beard: "beard",
    glasses: "glasses",
  },
  bodyParts: ["top", "bottom", "shoes"],
  counts: {
    hairColor: 6,
    skinColor: 4,
    top: 9,
    bottom: 10,
    shoes: 9,
    head: 7,
    hair: 16,
    eyes: 9,
    eye_brow: 10,
    nose: 9,
    mouth: 10,
    beard: 9,
    glasses: 9,
  },
  noBeard: "02", // part number
  noGlass: "02", // part number
};

export const DEFAULT_DB_AVATAR = {
  skincolor: "s1",
  haircolor: "h1",
  beardcolor: "h4",
  eyebrowcolor: "h1",
  avatar: [
    {
      partNumber: "05",
      partName: "top",
    },
    {
      partNumber: "06",
      partName: "bottom",
    },
    {
      partNumber: "01",
      partName: "shoes",
    },
    {
      partNumber: "01",
      partName: "head",
    },
    {
      partNumber: "11",
      partName: "hair",
    },
    {
      partNumber: "08",
      partName: "eyes",
    },
    {
      partNumber: "10",
      partName: "eye_brow",
    },
    {
      partNumber: "05",
      partName: "nose",
    },
    {
      partNumber: "02",
      partName: "mouth",
    },
    {
      partNumber: "02",
      partName: "beard",
    },
    {
      partNumber: "09",
      partName: "glasses",
    },
  ],
  face: [
    {
      partNumber: "01",
      partName: "head",
    },
    {
      partNumber: "11",
      partName: "hair",
    },
    {
      partNumber: "08",
      partName: "eyes",
    },
    {
      partNumber: "10",
      partName: "eye_brow",
    },
    {
      partNumber: "05",
      partName: "nose",
    },
    {
      partNumber: "02",
      partName: "mouth",
    },
    {
      partNumber: "02",
      partName: "beard",
    },
    {
      partNumber: "09",
      partName: "glasses",
    },
  ],
};

export const ALLOWED_USER_TYPES = {
  teacher: "teacher",
  student: "student",
};
