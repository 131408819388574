import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// constants
import { CONTACT_EMAIL } from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";

const regFormThanks = (props) => {
  const {
    // state props
    deviceSize,
    session,
    eventDetails,
    classRegistrationResult,

    // component props
    wrongId = false, // wrong link for class registration
    formExpired = false, // class registration not allowed
    regSeries = false, // class registration done
    regForm = false, // request done
    linkExpired = false, // class registration time over
    problem = false, // registration done, but some entrants not registered
    submitted = false, // registration request status 3, class already created
    eventMismatch = false, // if link event and fetched event mismatched
  } = props;

  let hostname = window.location.hostname;
  hostname = hostname.split(".")[0];
  let subdomain = hostname !== "localhost" ? hostname : session?.subdomain;
  let link = `https://${subdomain}.healthiestschools.co.nz/#/registration-form`;

  let doneMembers = [];
  let leftMembers = [];
  if (problem && !_.isEmpty(classRegistrationResult)) {
    doneMembers = classRegistrationResult.other_members;
    leftMembers = classRegistrationResult.problem_members;
  }

  const _renderTextWithHRefs = ({
    text = "",
    find = "",
    replace = "",
    type = "mail",
  }) => {
    const words = text.split(" ");
    return (
      <>
        <span>
          {_.map(words, (word, wordKey) => {
            if (word === find) {
              return (
                <a
                  key={`word_${wordKey}`}
                  className="mail-text-hover"
                  target="_blank"
                  href={type === "mail" ? `mailto: ${replace}` : replace}
                >
                  {replace}{" "}
                </a>
              );
            } else return <span key={`word_${wordKey}`}>{word} </span>;
          })}
        </span>
      </>
    );
  };

  const _renderLocalReachText = (text) => {
    const words = text.split(" ");
    return (
      <>
        <span>
          {_.map(words, (word, wordKey) => {
            if (_.includes(word, "BOLD")) {
              const local_word = _.split(word, "BOLD").pop();
              return <b key={`word_${wordKey}`}>{local_word} </b>;
            } else if (_.includes(word, "ITALIC")) {
              let local_word = _.split(word, "ITALIC").pop();
              local_word = _.split(local_word, "_").join(" ");
              return <i key={`word_${wordKey}`}>{local_word} </i>;
            } else return <span key={`word_${wordKey}`}>{word} </span>;
          })}
        </span>
      </>
    );
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center"></div>
            <div
              className="bg-secondary-2 p-12 rounded-lg text-gray-600"
              style={{ height: 300 }}
            >
              {eventMismatch ? (
                _renderTextWithHRefs({
                  text: LocaleStrings.registration_event_mismatch,
                  find: "#CONTACT_EMAIL#",
                  replace: CONTACT_EMAIL,
                  type: "mail",
                })
              ) : wrongId ? (
                _renderTextWithHRefs({
                  text: LocaleStrings.registration_wrongid,
                  find: "#CONTACT_EMAIL#",
                  replace: CONTACT_EMAIL,
                  type: "mail",
                })
              ) : formExpired ? (
                _renderTextWithHRefs({
                  text: LocaleStrings.registration_form_expired,
                  find: "#REDIRECT_LINK#",
                  replace: link,
                })
              ) : linkExpired ? (
                _renderTextWithHRefs({
                  text: LocaleStrings.registration_link_expired,
                  find: "#CONTACT_EMAIL#",
                  replace: CONTACT_EMAIL,
                  type: "mail",
                })
              ) : submitted ? (
                _renderLocalReachText(
                  LocaleStrings.registration_already_sumitted
                )
              ) : regForm ? (
                <>
                  <span>{LocaleStrings.registration_registered}</span>
                </>
              ) : regSeries ? (
                <>
                  <span>{LocaleStrings.registration_team_created}</span>
                </>
              ) : problem ? (
                <>
                  {/* ----- error text ------ */}
                  {doneMembers.length > 0 || leftMembers.length > 0 ? (
                    <>
                      <span className="">
                        Your Class Registration is{" "}
                        <strong>almost complete</strong>. While creatiing you
                        desired team we had some issues creating the team with
                        the details of some entrants. Please get the details
                        from below. Contact Admin at{" "}
                        <a
                          className="mail-text-hover"
                          target="_blank"
                          href={`mailto: kim.harvey@youngandhealthy.org.nz`}
                        >
                          kim.harvey@youngandhealthy.org.nz
                        </a>{" "}
                        for further assistance.
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="">
                        {classRegistrationResult.message}
                      </span>
                    </>
                  )}

                  {/* ----- done - left member list ---- */}
                  {doneMembers.length > 0 || leftMembers.length > 0 ? (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6 md:px-12 mt-4 text-gray-700 font-bold">
                        <div className="col-span-1">
                          <div className="border-b-4 border-secondary-2">
                            Successfully Registered
                          </div>
                          <table className="" style={{ width: 240 }}>
                            <tbody>
                              {_.map(doneMembers, (stu, key) => {
                                return (
                                  <tr key={key} className="mt-1">
                                    <td>{stu.firstname}</td>
                                    <td>{stu.lastinitial}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-span-1">
                          <div className="border-b-4 border-secondary-2">
                            Had Issues
                          </div>
                          <table className="" style={{ width: 240 }}>
                            <tbody>
                              {_.map(leftMembers, (stu, key) => {
                                return (
                                  <tr key={key} className="mt-1">
                                    <td>{stu.firstname}</td>
                                    <td>{stu.lastinitial}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    classRegistrationResult: state.classRegistrationResult,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(regFormThanks);
