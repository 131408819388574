import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useToasts } from "react-toast-notifications";

// constants
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";

// actions
import {
  showEditCourseModal,
  addEventClientLegs,
  removeEventClientLegs,
  updateEventClientLegs,
  showEditCourse,
  fetchCourseDetails,
} from "../actions";

// files
import cross from "../../../assets/img/cross.png";
import info from "../../../assets/img/info.svg";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const EditCourse = (props) => {
  const {
    showModal,
    showEditCourseModal,
    deviceSize,
    showEditCourseReducer,
    session,
    addEventClientLegs,
    removeEventClientLegs,
    updateEventClientLegs,
    eventClientReducer,
    showEditCourse,
    fetchCourseDetails,
  } = props;

  const { addToast } = useToasts();

  const state1 = [];
  _.map(showEditCourseReducer.data?.yourcourse, (item) => {
    state1.push({
      id: `${item.eventlegid}`,
      name: item.masterleg_by_materlegidfk?.name.capitalize(),
      img: `${BASE_AZIONV2_URL}/${item.leggallerymain?.masterleggallery_by_leggalleryidfk?.path}?api_key=${FILE_ACCESS_KEY}`,
      materlegid: item.materlegidfk,
      type: item.type,
    });
  });

  const state2 = [];
  _.map(showEditCourseReducer.data?.alternatelegs, (item) => {
    state2.push({
      id: `${item.eventlegid}`,
      name: item.masterleg_by_materlegidfk?.name.capitalize(),
      img: `${BASE_AZIONV2_URL}/${item.leggallerymain?.masterleggallery_by_leggalleryidfk?.path}?api_key=${FILE_ACCESS_KEY}`,
      materlegid: item.materlegidfk,
      type: item.type,
    });
  });

  const [state, setState] = useState([state1, state2]);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const closeModal = () => {
    showEditCourseModal({ show: false });
  };

  const getListStyle = (isDraggingOver, ind) =>
    ind == 0 && state[0]?.length < state[1]?.length
      ? {
          // background: isDraggingOver ? "lightblue" : "white",
          background: isDraggingOver ? "white" : "white",
          height: 100 * state[1].length,
        }
      : ind == 1 && state[1]?.length < state[0]?.length
      ? {
          background: isDraggingOver ? "white" : "white",
          height: 100 * state[0].length,
        }
      : {
          background: isDraggingOver ? "white" : "white",
          maxHeight: 488,
        };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "white",
    background: isDragging ? "white" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /** Moves an item from one list to another list. */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
      setDisableConfirm(false);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      // setState(newState.filter((group) => group.length));
      setState(newState);
      setDisableConfirm(false);
    }
  }

  const handleConfirmCourse = () => {
    setLoadingConfirm(true);

    const yourcourseState = JSON.parse(JSON.stringify(state[0]));

    if (yourcourseState.length) {
      _.map(yourcourseState, (item, i) => {
        item.order = i + 1;
      });
    }

    // Added Legs
    let addedLegs = [];
    _.map(yourcourseState, (item) => {
      let filter = _.filter(showEditCourseReducer.data.yourcourse, {
        eventlegid: parseInt(item.id),
      });

      if (filter.length == 0) {
        addedLegs.push({
          eventidfk: session.eventid,
          eventclientidfk: session.eventclientid,
          clientidfk: eventClientReducer.data[0]?.clientidfk,
          eventlegidfk: parseInt(item.id),
          legidfk: item.materlegid,
          type: item.type,
          order: item.order,
        });
      }
    });

    // Removed Legs
    let removedLegs = [];
    _.map(showEditCourseReducer.data.yourcourse, (item) => {
      let filter = _.filter(yourcourseState, {
        id: `${item.eventlegid}`,
      });

      if (filter.length == 0) {
        removedLegs.push({
          eventidfk: session.eventid,
          eventclientidfk: session.eventclientid,
          clientidfk: eventClientReducer.data[0]?.clientidfk,
          eventlegidfk: item.eventlegid,
          legidfk: item.materlegidfk,
          type: item.type,
          order: item.order,
        });
      }
    });

    //Update Legs
    let updateLegs = [];
    _.map(yourcourseState, (item) => {
      updateLegs.push({
        eventidfk: session.eventid,
        eventclientidfk: session.eventclientid,
        clientidfk: eventClientReducer.data[0]?.clientidfk,
        eventlegidfk: parseInt(item.id),
        legidfk: item.materlegid,
        type: item.type,
        order: item.order,
      });
    });

    if (addedLegs.length > 0) {
      let resource = [];
      _.map(addedLegs, (item) => {
        resource.push(item);
      });

      addEventClientLegs(session, resource, (res) => {
        if (res.success) {
          addToast(LocaleStrings.course_legs_added, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          let content = LocaleStrings.common_fail_message;
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }

    if (removedLegs.length > 0) {
      let arrPromise = [];
      let counter = removedLegs.length;
      let uploadCounter = 0;

      _.map(removedLegs, (item) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            removeEventClientLegs(session, item, (res) => {
              if (res.success) {
                uploadCounter++;
                resolve();
              } else {
                resolve();
              }
            });
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (counter == uploadCounter) {
          addToast(LocaleStrings.course_legs_added, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          let content = LocaleStrings.common_fail_message;
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }

    if (updateLegs.length > 0) {
      let arrPromise = [];
      let counter = updateLegs.length;
      let uploadCounter = 0;

      _.map(updateLegs, (item) => {
        arrPromise.push(
          new Promise((resolve, reject) => {
            updateEventClientLegs(session, item, (res) => {
              if (res.success) {
                uploadCounter++;
                resolve();
              } else {
                resolve();
              }
            });
          })
        );
      });

      Promise.all(arrPromise).then(() => {
        if (counter == uploadCounter) {
          setLoadingConfirm(false);
          fetchCourseDetails(session);
          showEditCourse(session);
          closeModal();

          addToast(LocaleStrings.course_legs_added, {
            appearance: "success",
            autoDismiss: true,
          });
        }
        //
        else {
          setLoadingConfirm(false);
          let content = LocaleStrings.common_fail_message;
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">Edit Course</h3>
                    <img className="ml-3" src={info} />
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="grid grid-cols-2 divide-x divide-gray-300">
                    <div className="bg-primary px-5 py-4 text-white text-sm font-medium">
                      Your Course
                    </div>
                    <div className="bg-primary-black px-5 py-4 text-white text-sm font-medium">
                      Alternate Legs
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                      {state.map((el, ind) => (
                        <div>
                          <Droppable key={ind} droppableId={`${ind}`}>
                            {(provided, snapshot) => (
                              <div
                                className="grid grid-cols-1 gap-y-3.5 gap-x-10 p-4 pr-10 overflow-y-auto"
                                ref={provided.innerRef}
                                style={getListStyle(
                                  snapshot.isDraggingOver,
                                  ind
                                )}
                                {...provided.droppableProps}
                              >
                                {el.map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <div className="relative cursor-pointer">
                                          <img
                                            className="rounded"
                                            src={item.img}
                                            style={{ height: 80, width: 140 }}
                                          />
                                          <div
                                            className="absolute text-base text-white w-full text-center"
                                            style={{ bottom: 6 }}
                                          >
                                            {item.name}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      ))}
                    </DragDropContext>
                  </div>
                </div>
                {/* -------------------------------- END * React Beautiful DND -------------------------------- */}

                {/*footer*/}
                <div className="modal-footer">
                  <button
                    className={
                      disableConfirm
                        ? "bg-refresh text-white rounded inline-flex items-center justify-center cursor-not-allowed"
                        : "bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer"
                    }
                    style={{
                      height: 37,
                      width: deviceSize.width >= 414 ? 367 : "100%",
                    }}
                    onClick={() => handleConfirmCourse()}
                    disabled={disableConfirm}
                  >
                    {loadingConfirm ? (
                      <svg
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className="text-base">
                      {LocaleStrings.confirm} {LocaleStrings.course}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    showModal: state.showEditCourseModalReducer,
    showEditCourseReducer: state.showEditCourseReducer,
    eventClientReducer: state.eventClientReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showEditCourseModal,
      addEventClientLegs,
      removeEventClientLegs,
      updateEventClientLegs,
      showEditCourse,
      fetchCourseDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
