import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useToasts } from "react-toast-notifications";
import AsyncSelect from "react-select/async";

// constants
import { OPEN_ACCESS } from "../../components/Common/constant";
import { isValidNZPhoneNumber } from "../../components/Common/base-components";

// actions
import {
  addTeacherInterest,
  validateRequestUser,
  fetchMasterRegion,
  fetchSchoolInfo,
} from "./action";

// components
import RegThanks from "./pages/regFormThanks";
import ContactUsForm from "./pages/ContactUsForm";

// files
import cross from "../../assets/img/cross.png";

const data = {
  classes: [
    {
      firstname: "",
      lastname: "",
      schooldata: null,
      region: "",
      noofmembers: null,
      email: "",
      confirmemail: "",
      mobileno: "",
    },
  ],
};

const renderField = ({
  input,
  label,
  type,
  placeholder,
  max,
  min,
  meta: { touched, error, warning },
}) => {
  return (
    <div className="">
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <input
          className="enter-data-steps-input"
          {...input}
          type={type}
          placeholder={placeholder}
          max={max ?? null}
          min={min ?? null}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderFieldSelect = (field) => {
  const {
    input,
    label,
    placeholder,
    meta: { touched, error, warning },
    loadOptions,
    commonSelects,
    setCommonSelects,
  } = field;

  const handleOnChange = (v) => {
    input.onChange(v);
    let obj = commonSelects;
    obj.schooldata = v;
    setCommonSelects(obj);
  };

  return (
    <div className="">
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <AsyncSelect
          className="enter-data-steps-input"
          name={input.name}
          value={input.value || null}
          loadOptions={loadOptions}
          cacheOptions
          defaultOptions
          isClearable={true}
          onChange={(v) => {
            handleOnChange(v);
          }}
          placeholder={placeholder}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderFieldOptions = (props) => {
  const {
    input,
    label,
    meta: { touched, error, warning },
    ddList,
    commonSelects,
    setCommonSelects,
  } = props;

  const handleOnChange = (v) => {
    input.onChange(v);
    if (label == "Region") {
      let obj = commonSelects;
      obj.region = v.target.value;
      setCommonSelects(obj);
    }
  };

  return (
    <div className="">
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <select
          {...input}
          value={input.value || null}
          onChange={(v) => {
            handleOnChange(v);
          }}
          className="enter-data-steps-input"
        >
          <option value="">-- select --</option>
          {!_.isEmpty(ddList) ? (
            <>
              {_.map(ddList, (item, key) => {
                if (item && item.trim() !== "") {
                  return (
                    <>
                      <option value={item}>{_.startCase(item)}</option>
                    </>
                  );
                }
              })}
            </>
          ) : (
            ""
          )}
        </select>
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderPhoneField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1 phone-number-field">
        <PhoneInput
          inputClass="enter-data-steps-input"
          country={"nz"}
          onlyCountries={["nz"]}
          autoFormat={false}
          {...input}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderClasses = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    customProps,
    handleSearch,
    commonSelects,
    setCommonSelects,
  } = props;
  // reset();

  return (
    <ul>
      {fields.map((cl, index) => (
        <li key={index}>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <div className="">
                <span className="">Class: {index + 1}</span>
              </div>
              <div className="text-sm">
                {index == 0 ? (
                  ""
                ) : (
                  <button
                    type="button"
                    title="Remove Member"
                    className="mt-2 btn bg-red-700 text-white rounded-lg h-9"
                    style={{ width: 80 }}
                    onClick={() => fields.remove(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.firstname`}
                  type="text"
                  component={renderField}
                  label="First Name"
                  placeholder="Enter first name"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.lastname`}
                  type="text"
                  component={renderField}
                  label="Last Name"
                  placeholder="Enter last name"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.schooldata`}
                  label="School Name"
                  placeholder="Start typing, then select from list"
                  component={renderFieldSelect}
                  mandatory="true"
                  loadOptions={handleSearch}
                  commonSelects={commonSelects}
                  setCommonSelects={setCommonSelects}
                  value={commonSelects.schooldata}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                {/* <Field
                  name={`${cl}.region`}
                  type="text"
                  component={renderField}
                  label="Region"
                  placeholder="Enter your school region"
                /> */}
                <Field
                  name={`${cl}.region`}
                  type="text"
                  component={renderFieldOptions}
                  label="Region"
                  ddList={customProps?.regions}
                  value={commonSelects.region}
                  commonSelects={commonSelects}
                  setCommonSelects={setCommonSelects}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.noofmembers`}
                  type="number"
                  component={renderField}
                  label="No. of Students"
                  placeholder="Select a number"
                  max={99}
                  min={1}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.mobileno`}
                  type="number"
                  component={renderPhoneField}
                  label="Mobile Number"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.year`}
                  type="text"
                  component={renderFieldOptions}
                  label="Year"
                  ddList={customProps?.years}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.email`}
                  type="email"
                  component={renderField}
                  label="Email Address"
                  placeholder="Enter Your Email"
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Field
                  name={`${cl}.confirmemail`}
                  type="email"
                  component={renderField}
                  label="Confirm Email"
                  placeholder="Renter Your Email"
                />
              </div>
            </div>
          </div>
        </li>
      ))}
      <li>
        <div className="flex mt-6 ml-6 items-center">
          <button
            type="button"
            className="mt-2 btn bg-refresh text-white rounded-lg h-9"
            style={{ width: 100 }}
            onClick={() =>
              fields.push({
                firstname: "",
                lastname: "",
                // schooldata: null,
                schooldata: commonSelects.schooldata,
                // region: "",
                region: commonSelects.region,
                noofmembers: null,
                email: "",
                confirmemail: "",
              })
            }
          >
            Add
          </button>
          <span className="ml-8 text-gray-700">
            Click here if you want to add more classes
          </span>
        </div>
        {submitFailed && error && <span>{error}</span>}
      </li>
    </ul>
  );
};

const specialCharReplace = (str) => {
  return str.replace(/[&\/\\#,+()$~%.\_`@'":*?<^>{!-=}]/g, "");
};

const firstNameSpecialCharReplace = (str) => {
  str = str.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, "");
  str = str.replace(/,/gi, "");
  str = str.replace(/-/gi, "");
  return str;
};
const lastNameSpecialCharReplace = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};

const RegForm = (props) => {
  const {
    deviceSize,
    session,
    eventDetails,
    handleSubmit,
    pristine,
    reset,
    submitting,
    contactUsLayout,
  } = props;

  const customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };
  const { addToast } = useToasts();
  const specialChars = `/[&\/\\#,+()$~%.\_@'":*?<^>{!-=}]/\``;
  const years = [
    "Year 1",
    "Year 2",
    "Year 3",
    "Year 4",
    "Year 5",
    "Year 6",
    "Year 7",
    "Year 8",
    "Year 9",
    "Year 10",
    "Other",
  ];

  const [regions, setRegions] = useState([]);
  const [regSuccess, setRegSuccess] = useState(false);
  const [existingEmailsArr, setExistingEmailsArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalDataArr, setFinalDataArr] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commonSelects, setCommonSelects] = useState({
    region: "",
    schooldata: null,
  });

  let customProps = {
    regions: regions,
    years: years,
  };

  useEffect(() => {
    setRegSuccess(false);
    // call table to access region table's data here
    props.fetchMasterRegion(customSession, (res) => {
      if (res.success) {
        let arr = [];
        _.map(res.data, (item) => {
          arr.push(item.region);
        });
        setRegions(arr);
      } else {
        let content = "Something went wrong while fetching regions";
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
    // setRegion()
  }, []);

  const handleSearch = (e, callback) => {
    if (!e) {
      var params = {
        type: "initial",
      };
      props.fetchSchoolInfo(customSession, params, (res) => {
        let opts = [];
        if (res.success) {
          if (res.data.length > 0 || res.count > 0) {
            _.map(res.data, (school) => {
              let obj = {
                value: school.id,
                label: school.orgname,
              };
              opts.push(obj);
            });
          }
          return callback(opts);
        } else {
          let content = "Something went wrong while fetching school info";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
          return callback(opts);
        }
      });
      // return callback([]);
    } else {
      const keywords = e;

      var params = {
        type: "nameSearch",
        name: keywords,
      };

      // setSearchQuery(keywords);

      props.fetchSchoolInfo(customSession, params, (res) => {
        let opts = [];
        if (res.success) {
          if (res.data.length > 0 || res.count > 0) {
            _.map(res.data, (school) => {
              let obj = {
                value: school.id,
                label: school.orgname,
              };
              opts.push(obj);
            });
          }
          return callback(opts);
        } else {
          let content = "Something went wrong while fetching school info";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
          return callback(opts);
        }
      });
    }
  };

  const onSubmitForm = (values) => {
    var data = [];
    var filterClass = [];
    let arrPromise = [];
    let counter = values?.classes?.length;
    let uploadCounter = 0;
    setExistingEmailsArr([]);

    setLoading(true);

    _.map(values?.classes, (ele) => {
      let obj = {
        eventidfk: session.eventid,
        // firstname: _.startCase(specialCharReplace(ele.firstname)),
        // lastname: _.startCase(specialCharReplace(ele.lastname)),
        firstname: firstNameSpecialCharReplace(ele.firstname),
        lastname: lastNameSpecialCharReplace(ele.lastname),
        email: _.trim(ele.email),
        schoolid: ele.schooldata.value,
        noofmembers: ele.noofmembers,
        region: ele.region,
        mobile: ele.mobileno,
        year: ele.year,
      };
      data.push(obj);

      arrPromise.push(
        new Promise((resolve, reject) => {
          props.validateRequestUser(customSession, obj.email, (res) => {
            if (res.success === 1) {
              // is in system
              resolve();
              setExistingEmailsArr([...existingEmailsArr, obj.email]);
            } else if (res.success === 2) {
              // nothing as new data
              uploadCounter++;
              resolve();
            } else {
              let content = `Authentication problem for ${obj.email}`;
              addToast(content, {
                appearance: "error",
                autoDismiss: true,
              });
              resolve();
            }
          });
        })
      );
    });

    setFinalDataArr(data);
    // setShowConfirmModal(true);

    // let content = `Any special character will be removed ( Except emails ) ${specialChars}`;
    // addToast(content, {
    //   appearance: "warning",
    //   autoDismiss: true,
    // });

    // setTimeout(() => {
    //   addInterests();
    // }, 3000);
    // addInterests();
    Promise.all(arrPromise).then(() => {
      if (counter == uploadCounter) {
        props.addTeacherInterest(customSession, data, (res) => {
          if (res.success) {
            setLoading(false);
            setRegSuccess(true);
          } else {
            setLoading(false);
            let content = res.message;
            addToast(content, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
      } else {
        setLoading(false);
        let content = "Something went wrong in validation";
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  const addInterests = () => {
    // setShowConfirmModal(false);
    setLoading(true);

    let arrPromise = [];
    let counter = finalDataArr.length;
    let uploadCounter = 0;

    _.map(finalDataArr, (ele) => {
      arrPromise.push(
        new Promise((resolve, reject) => {
          props.validateRequestUser(customSession, ele.email, (res) => {
            if (res.success === 1) {
              // is in system
              resolve();
              setExistingEmailsArr([...existingEmailsArr, ele.email]);
            } else if (res.success === 2) {
              // nothing as new data
              uploadCounter++;
              resolve();
            } else {
              let content = `Authentication problem for ${ele.email}`;
              addToast(content, {
                appearance: "error",
                autoDismiss: true,
              });
              resolve();
            }
          });
        })
      );
    });

    Promise.all(arrPromise).then(() => {
      if (counter == uploadCounter) {
        props.addTeacherInterest(customSession, finalDataArr, (res) => {
          if (res.success) {
            setLoading(false);
            setRegSuccess(true);
          } else {
            setLoading(false);
            let content = res.message;
            addToast(content, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
      } else {
        setLoading(false);
        let content = "Something went wrong in validation";
        addToast(content, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  return (
    <>
      {
        <>
          {contactUsLayout ? (
            <>
              <ContactUsForm />
            </>
          ) : !regSuccess ? (
            <>
              <div className="p-0 sm:p-2">
                <div
                  className="bg-secondary p-2 sm:p-4"
                  style={
                    deviceSize.width > 640
                      ? { borderRadius: 13 }
                      : { borderRadius: 0 }
                  }
                >
                  <div className="rounded-lg">
                    <div className="rounded-lg lg:justify-center pl-3 text-center">
                      <h2 className="text-5xl pb-3 pt-3 mb-3">
                        Teachers Register Your Interest
                      </h2>
                      {_.isEmpty(existingEmailsArr) ? (
                        <>
                          <span className="">
                            Please fill the details below, then click submit.
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-red-600">
                            These emails are already registered. Please use
                            another valid email. <br />
                            {_.map(existingEmailsArr, (mail, k) => {
                              return (
                                <>
                                  <span>{mail} </span>
                                </>
                              );
                            })}
                          </span>
                        </>
                      )}
                    </div>
                    <div
                      className="bg-secondary-2 py-2 rounded-lg"
                      style={
                        deviceSize.width > 768
                          ? { minHeight: 450, overflow: "hidden" }
                          : { minHeight: 850, overflow: "hidden" }
                      }
                      // style={deviceSize.width > 768 ? { height: 550 } : { height: 950 }}
                    >
                      <form onSubmit={handleSubmit(onSubmitForm)}>
                        <div className="grid grid-cols-3 gap-6 px-12 mt-5">
                          <div className="col-span-3">
                            <FieldArray
                              name="classes"
                              component={renderClasses}
                              customProps={customProps}
                              handleSearch={handleSearch}
                              commonSelects={commonSelects}
                              setCommonSelects={setCommonSelects}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center mb-3 mt-6">
                          <button
                            type="submit"
                            disabled={submitting || loading}
                            className="mt-4 btn bg-refresh text-white rounded-lg h-9"
                            style={{ width: 150 }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              ""
                            )}
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* confirmation modal */}
              {showConfirmModal ? (
                <>
                  <div className="modal">
                    <div className="modal-container">
                      {/*content*/}
                      <div className="modal-content">
                        {/*header*/}
                        <div className="modal-header border-b">
                          <h3 className="text-base font-medium">
                            Form Data Submission Confirmation
                          </h3>
                          <button
                            className="modal-close-btn"
                            onClick={() => setShowConfirmModal(false)}
                          >
                            <img src={cross} />
                          </button>
                        </div>

                        {/*body*/}
                        <div className="">
                          <div className="p-4">
                            <div className="">
                              <span className="text-red-600">
                                *Any special character will be removed ( Except
                                emails )
                              </span>
                            </div>
                            <div className="">
                              <span>{specialChars}</span>
                            </div>
                            {/* {_.map(finalDataArr, (interest, key) => {
                              return (
                                <>
                                  <div className="mb-2">
                                    <span className="text-blue-600">Team:</span>{" "}
                                    {key + 1}
                                    <div
                                      className=""
                                      key={`interest_key_${key}`}
                                    >
                                      <span className="">
                                        First Name: {interest?.firstname}
                                      </span>
                                    </div>
                                    <div className="">
                                      <span className="">
                                        Last Name: {interest?.lastname}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })} */}
                            <span>Proceed?</span>
                          </div>
                          <div className="flex items-center justify-between text-sm p-4 rounded-b border-t">
                            <button
                              className="bg-gray-400 text-gray-600 rounded-lg inline-flex items-center justify-center cursor-pointer mr-1 py-2 px-4"
                              onClick={() => setShowConfirmModal(false)}
                            >
                              <span className="text-sm">
                                No, return to the form to edit
                              </span>
                            </button>
                            <button
                              className="bg-refresh text-white rounded-lg inline-flex items-center justify-center cursor-pointer ml-1 py-2 px-4"
                              onClick={addInterests}
                            >
                              <span className="text-sm">Yes, proceed</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-background"></div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <RegThanks regForm />
            </>
          )}
        </>
      }
    </>
  );
};

const validate = (values) => {
  var errors = {
    classes: [],
  };

  var emailArr = [];
  values?.classes &&
    values?.classes.length > 0 &&
    _.map(values?.classes, (v, i) => {
      errors.classes.push({});
      if (!v.firstname) {
        errors.classes[i].firstname = "Required*";
      } else if (v.firstname.length > 15) {
        errors.classes[i].firstname = "Must be 15 characters or less";
      }
      if (!v.lastname) {
        errors.classes[i].lastname = "Required*";
      } else if (v.lastname.length > 15) {
        errors.classes[i].lastname = "Must be 15 characters or less";
      }

      if (!v.schooldata) {
        errors.classes[i].schooldata = "Required*";
      }
      if (!v.region) {
        errors.classes[i].region = "Required*";
      }

      if (!v.noofmembers) {
        errors.classes[i].noofmembers = "Required*";
      } else if (isNaN(Number(v.noofmembers))) {
        errors.classes[i].noofmembers = "Must be a number";
      }

      if (!v.mobileno || v.mobileno.length < 7) {
        errors.classes[i].mobileno = "Required*";
      } else if (!isValidNZPhoneNumber(v.mobileno)) {
        errors.classes[i].mobileno = "Invalid number (e.g. +6491234567)";
      }

      if (!v.year) {
        errors.classes[i].year = "Required*";
      }

      if (!v.email) {
        errors.classes[i].email = "Required*";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.email)) {
        errors.classes[i].email = "Invalid email address";
      }

      if (!v.confirmemail) {
        errors.classes[i].confirmemail = "Required*";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v.confirmemail)
      ) {
        errors.classes[i].confirmemail = "Invalid email address";
      } else if (v.email != v.confirmemail) {
        errors.classes[i].confirmemail = "Emails do not match";
      } else {
        emailArr.push(v.email);
      }
    });

  var isDuplicate = _.map(emailArr, (item, idx) => {
    return emailArr.indexOf(item) != idx
      ? { item: item, currIndex: idx, matchedIndex: emailArr.indexOf(item) }
      : {};
  });

  !_.isEmpty(isDuplicate) &&
    _.map(isDuplicate, (c, i) => {
      if (c?.item && c.item !== "") {
        errors.classes[c.matchedIndex].email = "Duplicate email";
        errors.classes[c.currIndex].email = "Duplicate email";
      }
    });

  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    contactUsLayout: state.contactUsLayout,
    initialValues: data,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addTeacherInterest,
      validateRequestUser,
      fetchMasterRegion,
      fetchSchoolInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RegForm)
);
