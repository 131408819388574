import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { format } from "date-fns";

// actions & consts
import { fetchRegistrationRequests } from "../action";
import {
  OPEN_ACCESS,
  LOCAL_TO_GMT_TIME,
} from "../../../components/Common/constant";

// components
import ClassDetails from "../components/ClassDetails";
import Pedometer from "../components/Pedometer";
import StudentDetails from "../components/StudentDetails";
import RegThanks from "../pages/regFormThanks";
import Loader from "../../../components/Common/loader";
import ContactUsForm from "./ContactUsForm";
import TandCPage from "../components/TandCPage";

const RegFormSeries = (props) => {
  const {
    regSteps,
    session,
    eventDetails,
    classRegistrationResult,
    trigger,
    triggerdata,
    contactUsLayout,
    problem,
  } = props;

  const { addToast } = useToasts();
  const specialChars = `/[&\/\\#,+()$~%.\_@'":*?<^>{!-=}]/\``;
  const customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };
  const [wrongId, setWrongId] = useState(false);
  const [loading, setLoading] = useState(true);
  const [classForm, setClassForm] = useState({});
  const [formExpired, setFormExpired] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eventMismatch, setEventMismatch] = useState(false);
  const [showtermsAndConditions, setShowtermsAndConditions] = useState(false);

  let param = useParams();

  useEffect(() => {
    if (_.includes(window.location.hash, "terms-and-conditions")) {
      setShowtermsAndConditions(true);
    } else {
      props.fetchRegistrationRequests(
        customSession,
        param?.requestid,
        (res) => {
          setLoading(false);

          if (!res.success) {
            setWrongId(true);
          } else if (
            !_.isEmpty(res.data?.eventidfk) &&
            _.toInteger(res.data?.eventidfk) != eventDetails.resource.eventid
          ) {
            setEventMismatch(true);
          } else if (res.linkExpired) {
            setLinkExpired(true);
          } else if (res.data?.status == "4") {
            setFormExpired(true);
          } else if (res.success == 2 && res.data?.status == "3") {
            setSubmitted(true);
          } else {
            let content = res.message;
            addToast(content, {
              appearance:
                res.data.length == 0 || _.isNull(res.data?.teamidfk)
                  ? "error"
                  : "success",
              autoDismiss: true,
            });

            // setTimeout(() => {
            //   let content = `Any special character will be removed ( Except emails ) ${specialChars}`;
            //   addToast(content, {
            //     appearance: "warning",
            //     autoDismiss: true,
            //   });
            // }, 5000);

            let obj = {
              registrationid: res.data.id,
              eventid: res.data.eventidfk,
              eventclientid: res.data.eventclientidfk,
              teamid: res.data.teamidfk,
              schoolname: res.data?.client_info_by_schoolid?.orgname,
              schooladdress: res.data?.client_info_by_schoolid?.postaladdress,
              schoolsuburb: res.data?.client_info_by_schoolid?.postalsuburb,
              schoolcity: res.data?.client_info_by_schoolid?.postalcity,
              postalcode: res.data?.client_info_by_schoolid?.postalzip,
              region: res.data?.region,
              firstname: res.data?.firstname,
              lastname: res.data?.lastname,
              mobile: res.data?.mobile,
              email: res.data?.email,
              // confirmeddate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
              confirmeddate: LOCAL_TO_GMT_TIME,
            };
            setClassForm(obj);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (trigger && !_.includes(window.location.hash, "terms-and-conditions")) {
      let content = triggerdata.message;
      addToast(content, {
        appearance: triggerdata.type,
        autoDismiss: true,
      });

      if (classRegistrationResult.success == 1) {
        addToast("You may not receive completion mails", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  }, [trigger]);

  return (
    <>
      {loading && !showtermsAndConditions ? (
        <>
          <div className="flex justify-center items-center h-screen">
            <Loader />
          </div>
        </>
      ) : contactUsLayout ? (
        <>
          <ContactUsForm />
        </>
      ) : showtermsAndConditions ? (
        <TandCPage part={1} />
      ) : wrongId ||
        formExpired ||
        linkExpired ||
        problem ||
        submitted ||
        eventMismatch ? (
        <>
          <RegThanks
            linkExpired={linkExpired}
            wrongId={wrongId}
            formExpired={formExpired}
            problem={problem}
            submitted={submitted}
            eventMismatch={eventMismatch}
          />
        </>
      ) : (
        <div>
          {regSteps == 0 ? (
            <>
              {!_.isEmpty(classForm) ? (
                <>
                  <ClassDetails classForm={classForm} />
                </>
              ) : (
                <>
                  <div className="flex justify-center items-center h-screen">
                    <Loader />
                  </div>
                </>
              )}
            </>
          ) : regSteps == 1 ? (
            <StudentDetails />
          ) : regSteps == 2 ? (
            <Pedometer />
          ) : regSteps == 3 ? (
            <RegThanks regSeries />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  let trigger = false,
    triggerdata = { message: "", type: "" },
    problem = false,
    regResReducer = state.classRegistrationResult;

  if (!_.isEmpty(regResReducer)) {
    if (regResReducer.success == 0) {
      triggerdata.message = regResReducer.message;
      triggerdata.type = "error";
    } else if (regResReducer.success == 1) {
      triggerdata.message = regResReducer.message;
      triggerdata.type = "warning";
      problem = true;
    } else if (regResReducer.success == 2) {
      triggerdata.message = regResReducer.message;

      if (!_.isEmpty(regResReducer.data.problem_members)) {
        triggerdata.type = "warning";
        problem = true;
      } else {
        triggerdata.type = "success";
      }
    }
    trigger = true;
  }

  return {
    session: state.session,
    eventDetails: state.eventDetails,
    regSteps: state.regFormSeriesStepCounterReducer,
    classRegistrationResult: state.classRegistrationResult,
    contactUsLayout: state.contactUsLayout,
    trigger,
    triggerdata,
    problem,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchRegistrationRequests }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegFormSeries);
