import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";

import Loader from "../../../components/Common/loader";
import LocaleStrings from "../../../languages";

import EnterData from "./enterdata";
import ViewData from "./viewdata";
import DataentryVideo from "./dataentry-video";
import info from "../../../assets/img/info.png";

import { fetchHomeScreenData } from "../../home/actions";
import {
  showEnterDataModal,
  fetchMyDataList,
  showViewDataModal,
  fetchEventClientLegs,
  showDataEntryVideo,
} from "../actions";

import backArrow from "../../../assets/img/backarrow.png";
import plus from "../../../assets/img/plus-enter-data.svg";
import { ALLOWED_USER_TYPES } from "../../../constants/types-constant";

const MyData = (props) => {
  const {
    session,
    deviceSize,
    showEnterDataModal,
    fetchMyDataList,
    homeScreenData,
    myDataList,
    showViewDataModal,
    showViewDataModalReducer,
    fetchHomeScreenData,
    fetchEventClientLegs,
    dataVideoModal,
    showDataEntryModal,
  } = props;

  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const memberId = _.chain(window.location.hash)
    .split("/")
    .pop()
    .toInteger()
    .value();
  const forMember = !_.isNull(memberId) && memberId > 0;

  useEffect(() => {
    fetchHomeScreenData(
      session,
      {
        userId: forMember ? memberId : session.userid,
        userType: forMember ? ALLOWED_USER_TYPES.student : session.usertype,
      },
      (res) => {}
    );
    fetchMyDataList(
      session,
      {
        teamId: session.teamid,
        userId: forMember ? memberId : session.userid,
      },
      (res) => {
        setLoading(false);
      }
    );
    fetchEventClientLegs(session, (res) => {});
  }, []);

  const data = [
    {
      id: 1,
      date: "Today",
      activity_points: "",
      bonus_points: "",
      total_points: "",
      action: "Enter Data",
    },
    {
      id: 2,
      date: "12 Jan 2021",
      activity_points: "",
      bonus_points: "",
      total_points: "",
      action: "Enter Data",
    },
    {
      id: 3,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 4,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 5,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 6,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 7,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 8,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 8,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
    {
      id: 8,
      date: "12 Jan 2021",
      activity_points: 10018,
      bonus_points: 8252,
      total_points: 18270,
      action: "View Data",
    },
  ];

  const handleBack = () => {
    const path = "/";
    history.push(path);
  };

  const handleEnterData = (data) => {
    showEnterDataModal({
      show: true,
      data: {
        callfrom: "dataentry",
        name:
          homeScreenData?.data?.membername?.split(" ")[0] +
          " " +
          homeScreenData?.data?.membername?.split(" ")[1]?.charAt(0),
        type: "mydata",
        item: data,
      },
    });
  };

  const handleViewData = (data, callfrom) => {
    showViewDataModal({
      show: true,
      data: {
        callfrom: callfrom,
        name:
          homeScreenData?.data?.membername?.split(" ")[0] +
          " " +
          homeScreenData?.data?.membername?.split(" ")[1]?.charAt(0),
        type: "viewdata",
        item: data,
      },
    });
  };

  const _renderList = () => {
    return _.map(myDataList?.data, (item, i) => {
      return (
        <tr key={`key_${i}`}>
          <td className="table-col-td">
            {moment(item.date).format("DD MMM YYYY") ===
            moment().format("DD MMM YYYY")
              ? "Today"
              : moment(item.date).format("DD MMM YYYY")}
          </td>
          <td className="table-col-td-without-color text-primary">
            {item.activitypoints ?? ""}
          </td>

          <td className="table-col-td-without-color text-purple">
            {item.bonuspoints ? Math.floor(item.bonuspoints) : ""}
          </td>
          <td className="table-col-td">
            {item.allpoints ?? ""}{" "}
            {item.maxallocated ? (
              <span className="font-bold text-red-500 text-sm">Max.</span>
            ) : (
              ""
            )}
          </td>
          <td className="table-col-td-without-color">
            {item.action == "enter" ? (
              <button
                href="#/"
                className="bg-shades-of-green data-entry-action-btn"
                style={{
                  height: 36,
                  width: deviceSize.width >= 870 ? 127 : "100%",
                  borderRadius: 7,
                }}
                onClick={() => handleEnterData(item)}
              >
                {deviceSize.width >= 870 ? <img src={plus} /> : ""}
                <span className="ml-1 text-sm font-medium">
                  {LocaleStrings.enter_data}
                </span>
              </button>
            ) : item.action == "view" ? (
              <button
                href="#/"
                className="bg-refresh data-entry-action-btn"
                style={{
                  height: 36,
                  width: deviceSize.width >= 870 ? 127 : "100%",
                  borderRadius: 7,
                }}
                onClick={() => handleViewData(item, "dataentry")}
              >
                <span className="ml-1 text-sm font-medium">
                  {LocaleStrings.view_data}
                </span>
              </button>
            ) : item.action == "dataentered" ? (
              <button
                href="#/"
                className="bg-refresh data-entry-action-btn opacity-70"
                style={{
                  height: 36,
                  width: deviceSize.width >= 870 ? 127 : "100%",
                  borderRadius: 7,
                }}
                onClick={() => handleViewData(item, "classentry")}
              >
                <span className="ml-1 text-sm font-medium">
                  {LocaleStrings.data_entered}
                </span>
              </button>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="p-0 sm:p-2">
      <div
        className="bg-secondary p-2 sm:p-4"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div className="grid grid-cols-2">
          <div className="flex items-center">
            <div
              className="bg-primary p-3 rounded cursor-pointer"
              onClick={() => handleBack()}
            >
              <img src={backArrow} />
            </div>
            <div className="text-base ml-3 font-medium">
              {LocaleStrings.data_entry}
            </div>
          </div>
          <div className="flex items-center justify-end cursor-pointer">
            {/* <img src={info} onClick={() => props.showDataEntryVideo(true)} /> */}
          </div>
        </div>

        <div className="rounded-lg pt-2.5 sm:pt-3">
          <div className="px-5 py-4 rounded-t-lg bg-secondary-2">
            <h3 className="text-base text-primary font-medium">
              {LocaleStrings.name_text} :{" "}
              {homeScreenData?.data?.membername?.split(" ")[0] ?? ""}{" "}
              {homeScreenData?.data?.membername?.split(" ")[1]?.charAt(0) ?? ""}
            </h3>
          </div>

          {!loading ? (
            <div className="bg-white rounded-b-none sm:rounded-b-lg">
              {deviceSize.width > 640 ? (
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg data-entry-table-container">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-white">
                            <tr>
                              <th
                                scope="col"
                                className="table-col-th"
                                // style={{ width: "7%" }}
                              >
                                {LocaleStrings.date}
                              </th>
                              <th
                                scope="col"
                                className="table-col-th"
                                // style={{ width: "30%" }}
                              >
                                {LocaleStrings.activity_points}
                              </th>
                              <th scope="col" className="table-col-th">
                                {LocaleStrings.bonus_points}
                              </th>
                              <th scope="col" className="table-col-th">
                                {LocaleStrings.total_points}
                              </th>
                              <th scope="col" className="table-col-th">
                                {LocaleStrings.action}
                              </th>
                              {/* <th scope='col' className='relative px-6 py-3'>
                            <span className='sr-only'>Action</span>
                          </th> */}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {_renderList()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="shadow overflow-hidden border-b border-gray-200 rounded-b-lg overflow-y-auto"
                  style={{ maxHeight: 540 }}
                >
                  {myDataList.data.map((item, i) => {
                    let className = "px-3 py-4 border-b";
                    if (i == data.length - 1) {
                      className = "px-3 py-4 rounded-b-lg";
                    }
                    return (
                      <div className={className}>
                        <div className="flex justify-between items-center">
                          <div className="text-sm text-primary-black font-medium">
                            {moment(item.date).format("DD MMM YYYY") ===
                            moment().format("DD MMM YYYY")
                              ? "Today"
                              : moment(item.date).format("DD MMM YYYY")}
                          </div>
                          {item.action == "enter" ? (
                            <button
                              href="#/"
                              className="bg-shades-of-green data-entry-action-btn"
                              style={{
                                height: 36,
                                width: 127,
                                borderRadius: 7,
                              }}
                              onClick={() => handleEnterData(item)}
                            >
                              <img src={plus} />
                              <span className="ml-1 text-sm font-medium">
                                {LocaleStrings.enter_data}
                              </span>
                            </button>
                          ) : item.action == "view" ? (
                            <button
                              href="#/"
                              className="bg-refresh data-entry-action-btn"
                              style={{
                                height: 36,
                                width: 127,
                                borderRadius: 7,
                              }}
                              onClick={() => handleViewData(item, "dataentry")}
                            >
                              <span className="ml-1 text-sm font-medium">
                                {LocaleStrings.view_data}
                              </span>
                            </button>
                          ) : item.action == "dataentered" ? (
                            <button
                              href="#/"
                              className="bg-refresh data-entry-action-btn opacity-70"
                              style={{
                                height: 36,
                                width: 127,
                                borderRadius: 7,
                              }}
                              onClick={() => handleViewData(item, "classentry")}
                            >
                              <span className="ml-1 text-sm font-medium">
                                {LocaleStrings.data_entered}
                              </span>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mt-4 flex justify-between">
                          {item.activitypoints ? (
                            <div>
                              <div className="text-sm text-secondary-4">
                                {LocaleStrings.activity_points}
                              </div>
                              <div className="text-sm text-primary font-medium">
                                {item.activitypoints}
                              </div>
                            </div>
                          ) : (
                            <div className="m-5"></div>
                          )}

                          {item.bonuspoints ? (
                            <div>
                              <div className="text-sm text-secondary-4">
                                {LocaleStrings.bonus_points}
                              </div>
                              <div className="text-sm text-purple font-medium">
                                {item.bonuspoints
                                  ? Math.floor(item.bonuspoints)
                                  : ""}
                              </div>
                            </div>
                          ) : (
                            <div className="m-5"></div>
                          )}

                          {item.allpoints ? (
                            <div>
                              <div className="text-sm text-secondary-4">
                                {LocaleStrings.total_points}
                              </div>
                              <div className="text-sm text-primary-black font-medium">
                                {item.allpoints}
                              </div>
                            </div>
                          ) : (
                            <div className="m-5"></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center h-screen">
              <Loader />
            </div>
          )}
        </div>

        {showDataEntryModal?.show && (
          <EnterData memberId={memberId} forMember={forMember} />
        )}

        {/* <ViewData /> */}
        {showViewDataModalReducer && showViewDataModalReducer.show && (
          <ViewData />
        )}

        {dataVideoModal && dataVideoModal.showModal ? <DataentryVideo /> : ""}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    showDataEntryModal: state.showEnterDataModalReducer,
    showViewDataModalReducer: state.showViewDataModalReducer,
    myDataList: state.myDataListReducer,
    homeScreenData: state.homeScreenDataReducer,
    eventClientLegsListReducer: state.eventClientLegsListReducer,
    dataVideoModal: state.dataVideoModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showEnterDataModal,
      fetchMyDataList,
      showViewDataModal,
      fetchHomeScreenData,
      fetchEventClientLegs,
      showDataEntryVideo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyData);
