import _ from "lodash";

import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";
import {
  BASE_URL,
  CUSTOM_ITEM_COUNT,
  LOCAL_TIMEZONE,
} from "../../../components/Common/constant";
import { saveInLocalStorage } from "../../../reducers/localStorage";

export const TEAM_MEMBERS = "TEAM_MEMBERS";
export const ACTIVE_TEAM_MEMBERS = "ACTIVE_TEAM_MEMBERS";
export const TEAM_DETAILS = "TEAM_DETAILS";
export const TEAM_CLIENT_ENTRANT_COUNT = "TEAM_CLIENT_ENTRANT_COUNT";
export const ADD_TEAM_MEMBER_MODAL = "ADD_TEAM_MEMBER_MODAL";
export const ADD_TEAM_MODAL = "ADD_TEAM_MODAL";

const table_teamMember_call = {
  fields: [
    "id",
    "dfid",
    "eventidfk",
    "eventclientidfk",
    "teamidfk",
    "membertype",
    "firstname",
    "lastname",
    "username",
    "password",
    "mobile",
    "gender",
    "ismanager",
    "withdrawndate",
    "avatar",
  ],
  related:
    "eventclient_by_eventclientidfk&eventclient_by_eventclientidfk.fields=maxteammember,totalmembers",
  order: "ismanager%20DESC%2C%20createdon%20DESC",
};

export const fetchTeamMembers = (session, pageCount = 0, callback = null) => {
  let perPage = CUSTOM_ITEM_COUNT;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offSetQuery = `limit=${perPage}&offset=${offset}`;
  let filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(isremoved=0)`
  );
  let url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})&include_count=true&fields=${table_teamMember_call.fields.join(
    ","
  )}&related=${table_teamMember_call.related}&order=${
    table_teamMember_call.order
  }&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEAM_MEMBERS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback &&
          callback({
            success: 1,
            data: response.resource,
            count: response.meta.count,
          });
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
};

export const fetchAllTeamMembers = (session, callback) => {
  let filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(isremoved=0)`
  );
  let fetchUrl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?include_count=true&fields=${table_teamMember_call.fields.join(
    ","
  )}&filter=(${filter})&order=${table_teamMember_call.order}`;

  return (dispatch) => {
    getRequest(
      fetchUrl,
      session,
      dispatch,
      (res) => {
        callback({
          success: 1,
          data: { resource: res?.resource, count: res?.meta?.count },
          error: null,
        });
      },
      (error) => {
        callback({ success: 0, data: null, error });
      }
    );
  };
};

export function fetchActiveTeamMembers(session, callback = null) {
  var filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(isremoved=0)AND(withdrawndate IS NULL)`
  );

  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?include_count=true&fields=${table_teamMember_call.fields.join(
    ","
  )}&filter=(${filter})&order=${table_teamMember_call.order}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: ACTIVE_TEAM_MEMBERS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
}

export function fetchTeamData(session, callback = null) {
  var filter = encodeURI(`id=${session.teamid}`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})&related=eventclient_by_eventclientidfk`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEAM_DETAILS,
          payload: { data: response?.resource?.[0] },
        });
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
}

export function fetchTeamClientEntrantCount(session, callback = null) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = {
    type: "eventcliententrantcount",
    teamid: session.teamid,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEAM_CLIENT_ENTRANT_COUNT,
          payload: { data: response },
        });
        callback && callback({ success: 1, data: response });
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
}

export function createTeamMember(session, values, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { ...values, type: "createmember" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.is_error) {
          callback({ success: 0 });
        } else {
          callback({ success: 1, data: response.member });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function handleAddTeamMemberModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MEMBER_MODAL,
      payload: obj,
    });
  };
}

export function updateTeamMember(session, values, callback = null) {
  const url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers`;
  let body = { resource: [{ ...values }] };

  let memberPatchBody = _.cloneDeep(values);
  delete memberPatchBody.username;
  delete memberPatchBody.password;
  let memberPatchBodyResource = { resource: [{ ...memberPatchBody }] };

  return (dispatch) => {
    if (values?.setpassword) {
      var urlPass = `${BASE_URL}api/v2/system/user/${values.dfid}`;
      var bodypass = { password: values.setpassword };

      patchRequest(
        urlPass,
        bodypass,
        session,
        dispatch,
        (response) => {
          if (values.ismanager) {
            session.sessionToken = response.session_token;

            var stateToSave = {
              isLoggedIn: true,
              session: session,
            };
            saveInLocalStorage(stateToSave);

            patchRequest(
              url,
              memberPatchBodyResource,
              session,
              dispatch,
              (response) => {
                var filter = encodeURI(`dfid=${values.dfid}`);
                var dfurl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
                var dfbody = { resource: [{ password: values.setpassword }] };
                patchRequest(
                  dfurl,
                  dfbody,
                  session,
                  dispatch,
                  (response) => {
                    callback && callback({ success: 1 });
                  },
                  (error) => {
                    callback && callback({ success: 0 });
                  }
                );
              },
              (error) => {
                callback && callback({ success: 0 });
              }
            );
          } else {
            patchRequest(
              url,
              memberPatchBodyResource,
              session,
              dispatch,
              (response) => {
                callback && callback({ success: 1 });
              },
              (error) => {
                callback && callback({ success: 0 });
              }
            );
          }
        },
        (error) => {
          callback && callback({ success: 0, message: error });
        }
      );
    } else {
      patchRequest(
        url,
        memberPatchBodyResource,
        session,
        dispatch,
        (response) => {
          callback && callback({ success: 1 });
        },
        (error) => {
          callback && callback({ success: 0 });
        }
      );
    }
  };
}

export function handleAddTeamModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MODAL,
      payload: obj,
    });
  };
}

export function validateTeamName(session, values, callback = null) {
  var filter = encodeURI(
    `(eventidfk=${values.eventidfk})AND(eventclientidfk=${values.eventclientidfk})AND(teamname=${values.teamname})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource.length > 0) {
          callback && callback({ success: 2 });
        } else {
          callback && callback({ success: 1 });
        }
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
}

export function updateTeam(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function withdrawAllTeamMember(session, teamid = null, date, callback) {
  var filter = encodeURI(`(teamidfk=${teamid})AND(withdrawndate IS NULL)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
  var body = { resource: [{ withdrawndate: date }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteTeamMemberSession(session, dfid, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { dfid: dfid, type: "deleteusersession" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function teamWithdraw(session, values, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = {
    teamid: values.teamid,
    withdrawndate: values.withdrawndate,
    type: "withdraw",
    subtype: "teamwithdraw",
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function singleWithdraw(session, values, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = {
    id: values.id,
    withdrawndate: values.withdrawndate,
    type: "withdraw",
    subtype: "singleWithdraw",
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function addEntrantPoints(session, userid, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { userid: userid, timezone: LOCAL_TIMEZONE, type: "addEntrant" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.success) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}
