/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

// constants
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";

// actions
import { login } from "../../actions";

// import { validateEmail } from "../../components/Common/base-components";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const Login = (props) => {
  const { deviceSize, login, session, eventDetails } = props;

  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberme, setRememberme] = useState(false);
  const [loginBg, setLoginBg] = useState([]);
  const [currentBgInd, setCurrentBgInd] = useState(0);

  let loginbgimgArr = [];
  let src = "";
  if (!_.isEmpty(eventDetails.resource)) {
    if (eventDetails.resource.loginbgimage) {
      src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage1) {
      src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage1}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage2) {
      src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage2}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage3) {
      src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage3}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }

    if (eventDetails.resource.loginbgimage4) {
      src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage4}?api_key=${FILE_ACCESS_KEY}`;
      loginbgimgArr.push({ src: src });
    }
  }

  useEffect(() => {
    // let loginbgimgArr = [];
    // let src = "";
    // if (eventDetails?.resource?.bgtype === "image") {
    //   if (eventDetails.resource.loginbgimage) {
    //     src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage1) {
    //     src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage1}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage2) {
    //     src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage2}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage3) {
    //     src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage3}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   if (eventDetails.resource.loginbgimage4) {
    //     src = `${BASE_AZIONV2_URL}/${eventDetails.resource.loginbgimage4}?api_key=${FILE_ACCESS_KEY}`;
    //     loginbgimgArr.push({ src: src });
    //   }
    //   setLoginBg(loginbgimgArr);
    // }

    // let interval = setInterval(() => changeBackgroundImage(), 1000);

    // return () => {
    //   if (interval) {
    //     clearInterval(interval);
    //   }
    // };

    setTimeout(() => {
      let newCurrentInd = 0;
      const noOfImages = loginbgimgArr.length;
      if (currentBgInd !== noOfImages - 1) {
        newCurrentInd = currentBgInd + 1;
      }
      setCurrentBgInd(newCurrentInd);
    }, 10000);
  });

  // const changeBackgroundImage = () => {
  //   let newCurrentInd = 0;
  //   const noOfImages = loginBg.length;

  //   if (currentBgInd !== noOfImages - 1) {
  //     newCurrentInd = currentBgInd + 1;
  //   }

  //   setCurrentBgInd(newCurrentInd);
  // };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberme = (e) => {
    setRememberme(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let values = {};
    values.email = email;
    values.password = password;
    values.rememberme = rememberme;

    if (!validateEmail(email)) {
      values.email = email + "@teamchallenge.com";
    }

    setLoading(true);

    let currentDate = moment();
    let siteCloseDate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]?.siteclosedate;

    if (
      moment(currentDate).isSame(siteCloseDate) ||
      moment(currentDate).isAfter(moment(siteCloseDate), "date")
    ) {
      let content = "Sorry, site is closed.";
      addToast(content, {
        appearance: "error",
        autoDismiss: true,
      });
      setLoading(false);
    } else {
      login(session, values, (response) => {
        setLoading(false);

        if (response.success === 1) {
          let content = "Logged in successful.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
          history.push("/");
        } else if (response.success === 2) {
          let content = "Inactive profile.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          let content = "Email/Password is incorrect.";
          addToast(content, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
  };

  return (
    <div className="p-2">
      <div
        className="bg-cover p-4 flex items-center justify-center login-bg-image-container"
        style={{
          height: "82vh",
          borderRadius: 12,

          backgroundImage:
            loginbgimgArr.length > 0
              ? `url("${loginbgimgArr[currentBgInd].src}")`
              : "",
        }}
      >
        <div
          className="bg-white rounded-lg"
          style={{ width: 350, boxShadow: "0 0 3.125rem #00000020" }}
        >
          <div className="border-b px-4 pt-6">
            <div>
              <div className="text-xl-1 text-black font-semibold">Login</div>
              <div className="text-sm text-secondary-2 pt-1">
                Enter Your Email & Password to Login
              </div>
            </div>

            <div className="mt-6.5">
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="text-sm text-primary-black">
                    Username / Email Address
                  </label>
                  <input
                    className="input-text-login mt-1"
                    style={{ borderRadius: 6 }}
                    id="email"
                    type="text"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={handleChangeEmail}
                    required
                  />
                </div>
                <div className="mt-1">
                  <label className="text-sm text-primary-black">Password</label>
                  <input
                    className="input-text-login mt-1"
                    style={{ borderRadius: 6, height: 47 }}
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={handleChangePassword}
                    required
                  />
                </div>
                <div className="mt-3">
                  <label className="custom-container-checkbox text-primary-black text-sm">
                    <input
                      className=""
                      id="rememberme"
                      type="checkbox"
                      value={rememberme}
                      onChange={handleRememberme}
                    />
                    <span className="checkmark" />
                    Remember Me
                  </label>
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn my-4 bg-refresh text-white h-10"
                    style={{ borderRadius: 6, height: 47 }}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className="text-sm">Login</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="p-4 text-primary text-sm flex justify-center items-center cursor-pointer"
            onClick={() => history.push("/forgot-password")}
          >
            Recover Password
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
