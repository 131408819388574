import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";
import { handleAddTeamModal, validateTeamName, updateTeam } from "../actions";
import { renderFieldText } from "../../../components/Common/base-components";
import {
  LABEL_POSITION_TOP,
  COMMON_FAIL_MESSAGE,
  DEFAULT_PASSWORD,
} from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";
import cross from "../../../assets/img/cross.png";

const AddTeam = (props) => {
  var {
    session,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    teamAddModal,
    toEdit,
    initialValues,
  } = props;
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.handleAddTeamModal({ showModal: false });
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    var data = { id: values.id, teamname: values.teamname.trim() };

    // Update Team
    props.validateTeamName(session, values, (res) => {
      if (res.success === 1) {
        props.updateTeam(session, data, (response) => {
          setLoading(false);
          if (response.success === 0) {
            addToast(LocaleStrings.common_fail_message, {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            addToast(LocaleStrings.team_form_updated_success, {
              appearance: "success",
              autoDismiss: true,
            });
            props.finishOperationsCallback();
            closeModal();
          }
        });
      } else if (res.success === 2) {
        setLoading(false);
        addToast(LocaleStrings.team_form_validateion_already_exit, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        setLoading(false);
        addToast(LocaleStrings.common_fail_message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    });
  };

  return (
    <>
      {teamAddModal?.showModal ? (
        <>
          <div className="modal">
            <div className="relative w-80	md:w-2/6 my-6 mx-2">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">
                      {LocaleStrings.team_edit_title}
                    </h3>
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  {/*body*/}
                  <div className="modal-body">
                    <Field
                      name="teamname"
                      label={LocaleStrings.team_form_label_fname}
                      placeholder={LocaleStrings.team_form_ph_fname}
                      type="text"
                      mandatory="true"
                      component={renderFieldText}
                      labelposition={LABEL_POSITION_TOP}
                    />
                  </div>
                  {/*footer*/}
                  <div className="modal-footer">
                    <button
                      className="btn-secondary-md mr-2"
                      onClick={() => closeModal()}
                    >
                      <span className="text-base">
                        {LocaleStrings.button_close}
                      </span>
                    </button>
                    <button
                      type="submit"
                      className={classnames(
                        pristine || invalid || submitting || loading
                          ? "btn-primary-disabled-md"
                          : "btn-primary-md"
                      )}
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <span className="text-base">
                        {loading
                          ? LocaleStrings.button_saving
                          : LocaleStrings.button_save}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var teamname = values["teamname"];

  if (!teamname || teamname.trim() === "") {
    errors["teamname"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.teamAddModal?.value);
  var initVals = {};

  if (toEdit) {
    initVals = state?.teamAddModal?.value;
  }

  return {
    session: state.session,
    teamAddModal: state.teamAddModal,
    initialValues: initVals,
    toEdit,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { handleAddTeamModal, validateTeamName, updateTeam },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "TeamForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddTeam)
);
