import LocalizedStrings from "react-localization";

const LocaleStrings = new LocalizedStrings({
  en: {
    // Misslenious
    lorem_ipsum: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    common_fail_message: "Something went wrong.",
    male: "Male",
    female: "Female",
    other: "Other",
    requird: "Required",
    points: "Points",
    provide_required: "Please provide required fields.",
    success_data_add: "Data added successfully.",
    remove: "Remove",
    ok: "Ok",
    dont_show_again: "Do not show again",
    leg: "Leg",
    pt: "pt",
    name: "Name",
    daily_avg: "Daily Avg.",
    total: "Total",
    target: "Target",
    actual: "Actual",
    move: "Move",
    to: "to",
    confirm: "Confirm",
    enter: "Enter",
    weekly: "Weekly",
    monthly: "Monthly",
    custom: "Custom",
    yes: "Yes",
    no: "No",
    print: "Print",
    preview: "Preview",
    complete: "Complete",
    type_to_search: "Type to search",
    clear: "Clear",

    // Menu or Routes
    Home: "Home",
    view_all_teams: "View All Teams",
    view_my_team: "View My Team",
    manage_team: "Manage Team",
    manage_class: "Manage Class",
    my_data: "My Data",
    support: "Support",
    faq: "FAQ",
    language: "Language",
    data_entry: "Data Entry",
    class_entry: "Data Entry for Class",
    options_for_data_entry: "Options for Data Entry",
    opt_for_data_entry: "Opt. for Data Entry",

    // buttons
    button_logout: "Logout",
    button_refresh: "Refresh",
    button_save: "Save",
    button_saving: "Saving..",
    button_edit: "Edit",
    button_close: "Close",
    button_cancel: "Cancel",
    button_withdraw: "Withdraw",
    button_withdrawn: "Withdrawn",
    button_add_entrant: "Add Entrant",
    button_previous: "Previous",
    button_next: "Next",
    button_password_generate: "Regenerate",
    button_contact: "Contact",
    button_view_video_now: "View Video now",
    button_view_later: "View Later",
    button_print_avatar: "Print Avatars",
    button_print_logins: "Print Logins",

    // Home
    edit_your_avatar: "Edit Your Avatar",
    edit_avatar: "Edit Avatar",
    enter_my_data: "Enter My Data",
    enter_team_data: "Enter Team Data",
    enter_class_data: "Enter Class Data",
    team_avg_daily: "Team Avg. Daily Points",
    team_avg_total: "Team Avg. Total Points",
    percentage_of_course_completed: "Percentage of course completed",
    course_close_in: "Course Close in",
    points_required_to_finish: "Points to finish leg",
    percentage_complete: "Percent Complete",
    course_video: "Course Videos & Photos",
    click_to_watch_your_videos: "Click to view videos & photos",
    click_to_watch_your_videos_short: "Click to view",
    course_map: "Course Map",
    view_the_full_standings_map: "View details of each leg",
    congratulation_on_complete: "Congratulations you have completed #LEGNAME#",
    congratulation_on_complete_challenge:
      "The Challenge Course is now closed, however you have until midnight on #TIME# to enter any final data and then we will create your Certificate and confirm the finishing listing based on Team Average Total Points.",
    congratulation_on_complete_course: "Course Completed",
    congratulation_on_complete_course_desc:
      "Congratulations, your team has completed all the standard and bonus legs of the event. Your data entry will now be closed but you can still track progress of other teams until the event closes on [data entry close date] and then we will load up your certificate and confirm the final placings.",
    congratulation_on_complete_standard_legs:
      "Well done on finishing all the standard legs of the event, great work! We have assigned you additional bonus legs so you can keep going.",

    // Avatar parts
    avatar: "Avatar",
    avatar_builder: "Avatar Builder",
    avatar_top: "Top",
    avatar_bottom: "Bottom",
    avatar_shoes: "Shoes",
    avatar_head: "Head",
    avatar_hair: "Hair",
    avatar_eyes: "Eyes",
    avatar_eyeBrow: "Eyebrows",
    avatar_nose: "Nose",
    avatar_mouth: "Mouth",
    avatar_beard: "Beard",
    avatar_glasses: "Glasses",
    avatar_regenerate: "Regenerate",
    avatar_save_success: "Avatar successfully updated!",

    // Data Entry
    data_entry: "Data Entry",
    today: "Today",
    enter_data_for_all_members: "Enter data for all members",
    activity_points_entry: "Activity Points Entry",
    bonus_points_entry: "Bonus Points Entry",
    submit_data: "Submit Data",
    data_entry_for_yourself: "Data entry for yourself",
    data_entry_for_your_team: "Data entry for your team",
    data_entry_for_your_class: "Data entry for your class",
    data_entry_for_your_class_how: "How to enter data for class",
    date: "Date",
    activity_points: "Activity Points",
    steps: "Steps",
    bonus_points: "Bonus Points",
    total_points: "Total Points",
    action: "Action",
    enter_data: "Enter Data",
    enter_member_data: "Enter data for #MEMBER#",
    view_data: "View Data",
    data_entered: "Data Entered",
    name_text: "Name",
    modal_long_text:
      "Please only enter activities below that you have not already counted with a pedometer",
    submit_and_return_home: "Submit & return to Home",
    submit_and_enter_more: "Submit & enter more data",
    bonus_sugar: "Sugar",
    bonus_sugar_desc:
      "There are about 4 grams of sugar in a teaspoon, for example a can of coke has 35 grams of sugar = 9 teaspoons of sugar",
    bonus_fruit: "Fruit",
    bonus_fruit_desc:
      "Select fruit eaten. E.g. If you had 1 apple, 1 banana then drag slider to 2.",
    bonus_water: "Water",
    bonus_water_desc:
      "Select how many measures of 200mls you had e.g. If you drank a litre of water drag the slider to 5.",
    bonus_veges: "Veges",
    bonus_veges_desc:
      "Select vegetables eaten. E.g. If you had 1 carrot, 1 serving of broccoli then drag slider to 2",
    bonus_snacks: "Snacks",
    bonus_snacks_desc:
      "These are things like biscuits, chocolate, ice cream, chips. Drag the slider to the total.",
    bonus_mindfulness: "Mindfulness",
    bonus_mindfulness_desc:
      "How many times did you pause in your day to just focus on your breathing and relax?",
    bonus_sleep: "Sleep Time",
    bonus_sleep_desc:
      "How long did you sleep for? Just drag the slider to the closest ½ hour.",
    bonus_screen: "Screen Time",
    bonus_screen_desc:
      "This is the time you spent playing video games, watching TV or Youtube. Do not count school or study / work screen time.",
    activity_desc:
      "Please select the Activity you participated in, then the Intensity level and Time you did it for.",
    activity_desc_intencity_high:
      "High Intensity is when you are breathing heavily and can not carry on a conversation - you usually only do high intensity for a short period of time e.g. Sprinting, riding up a steep hill",
    activity_desc_intencity_medium:
      "Medium Intensity is when you are breathing faster and you heart rate is higher but you could carry on a conversation. E.g. riding a bike on the flat, low hills.",
    activity_desc_intencity_low:
      "Low Intensity is when you are exercising but not breathing heavily and can talk easily e.g. going for a walk.",
    data_added_for_members:
      "Data is already entered on this day for all team members",
    data_added_for_attendee:
      "Data is already entered on this day for this user",
    activity_max_8: "You can add maximum 8 activities",
    activity_text: "Activity",
    activity_intencity: "Intensity",
    activity_duration: "Duration",
    data_entry_max_cap:
      "As this is a team event, there is a maximum number of points that can be allocated to individual team members for any single day, the data you have entered has exceeded the maximum points so we have awarded you the maximum.",
    max_data_cap:
      "Max. Total points Allocated, Your step entry was reduced so your Total Points did not exceed the Maximum.",
    data_entered_by_teacher: "Data Entered by Teacher",
    select_daterange: "Select date-range to display data",
    no_data_entered:
      "No data enterted yet. Please enter data if challenge has started.",
    data_entry_opens_on:
      "Data Entry opens when the Challenge starts on #STARTIME#.",

    // View all teams
    select_team: "Select Team",
    leg: "Leg",
    title_school: "School",
    title_company: "Company",
    select_division: "Select Division",
    division_open: "Open Division",
    division_advance: "Advance Division",
    team_name: "Team Name",
    track_name: "Track Team",
    in_team: "# In Team",
    in_team_2: "In Team",
    avg_total_points: "Avg Total Points",
    avg_daily_points: "Avg Daily Points",
    avg_activity_points: "Avg Activity Points",
    avg_bonus_points: "Avg Bonus Points",
    view_teams_opt_all: "All Teams",
    view_teams_opt_tracked: "Tracked Teams",
    view_teams_opt_my_school: "My School",
    view_teams_opt_my_company: "My Company",
    view_teams_trackteam_validation: "You can track maximum 5 teams.",
    view_teams_trackteam_added: "Team is tracked successfully.",
    view_teams_trackteam_removed: "Team tracking removed successfully.",
    view_teams_all_legs_complete: "Team has finished all legs of the event",

    // Course Video
    from: "From",
    watch_your_video: "Watch Your Video",
    watch_video: "Watch Video",

    // Course Map
    course_map: "Course Map",
    edit_course: "Edit Course",
    view_course_details: "View Course Details",
    overview: "Overview",
    course_legs_added: "Leg(s) added successfully.",
    course: "Course",

    // Manage Team/Class
    team_edit_title: "Edit Team",
    team_form_label_fname: "Team Name",
    team_form_ph_fname: "Enter team name",
    team_form_validateion_already_exit: "Team name already exist!",
    team_form_updated_success: "Team name updated successfully!",
    team_pdf_toast: "Preparing the PDF. DO NOT REFRESH!",

    team_withdraw_confirm_title: "Withdraw Team",
    team_withdraw_confirm_subtitle: "Are you sure you want to withdraw team",
    team_withdraw_confirm_warning:
      "Please note selecting the option will withdraw your whole team and your current data will be lost.",
    team_withdraw_alert_success: "Team withdrawn successfully!",

    teammember_text_manage_class: "Manage Class",
    teammember_th_fname: "First Name",
    teammember_th_lname: "Last Initial",
    teammember_th_uname: "Username",
    teammember_th_password: "Password",
    teammember_th_action: "Actions",

    teammember_text_team_size_full: "Team Full",
    teammember_add_title: "Add Entrant",
    teammember_edit_title: "Edit Entrant",
    teammember_add_section_change_name: "Set Name",
    teammember_edit_section_change_name: "Change Name",
    teammember_form_label_uname: "Username",
    teammember_form_label_fname: "First Name",
    teammember_form_ph_fname: "Enter first name",
    teammember_form_label_lname: "Last Initial",
    teammember_form_ph_lname: "Enter last initial name",
    teammember_form_label_gender: "Gender",
    teammember_form_ph_gender: "Select gender",
    teammember_edit_section_set_password: "Set Password",
    teammember_add_section_set_password: "Set Password",
    teammember_form_label_password: "Password",
    teammember_form_validation_lastname_max_length:
      "Last initial name shoud be 1 char.",
    teammember_form_validation_team_full:
      "Required no. of entrants is reached, No speace available.",
    teammember_form_create_success: "Team member created successfully!",
    teammember_form_updated_success: "Team member updated successfully!",
    teammember_form_create_success_points_not_updated:
      "Team member created successfully! but points not adjusted",

    teammember_withdraw_confirm_title: "Withdraw Team Member",
    teammember_withdraw_confirm_subtitle: "Are you sure you want to withdraw",
    teammember_withdraw_alert_success: "Member withdrawn successfully!",

    course_click_for_details: "Click any icons to view details",
    // Registration
    registration_event_mismatch:
      "Sorry. No data is found with the provided id supplied in the URL. Event Mismatched. Please contact at #CONTACT_EMAIL# for assistance.",
    registration_wrongid:
      "Sorry. No data is found with the provided id supplied in the URL. Please contact at #CONTACT_EMAIL# for assistance.",
    registration_form_expired:
      "As you didn’t complete your class set up in the required time, you have been withdrawn so we could allocate places to another class. If you do still wish to participate, please click here #REDIRECT_LINK# to register your interest again and we will see if we have places left for you.",
    registration_link_expired:
      "It looks like your invitation to participate has expired, but you can still participate if there are places left.  Please contact #CONTACT_EMAIL# for assistance.",
    registration_registered:
      "Thank you for registering your interest in participating in the Virtual Adventure. Please check your email Inbox for more information. NOTE: If you do not see a brief email from us soon, you may need to check your BIN or SPAM folder, then add our email address to your favourites to make sure you receive them.",
    registration_team_created:
      "Thank you for registering your Class Team, please check your Inbox for an email with more details about the Virtual Adventure.",
    registration_already_sumitted:
      "Hi! you have already BOLDregistered your class and we ITALICthank_you for your interest in participating in the Virtual Adventure. Please check your email Inbox for more information. NOTE: If you do not see a brief email from us soon, you may need to check your BIN or SPAM folder, then add our email address to your favourites to make sure you receive them.",
  },
  it: {
    // Menu
    Home: "Home",
  },
});

export default LocaleStrings;
