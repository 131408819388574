import _ from "lodash";
import moment from "moment";

import {
  deleteRequest,
  getRequest,
  postRequest,
  patchRequest,
} from "../../../components/Common/network-call";

import {
  BASE_URL,
  LOCAL_TIMEZONE,
  OPEN_ACCESS,
} from "../../../components/Common/constant";
import {
  loadLocalStorage,
  saveInLocalStorage,
} from "../../../reducers/localStorage";
import {
  APP_LOCALSTORAGE_KEYS,
  APP_POPUP_INIT,
  APP_POPUP_TYPES,
} from "../../../constants/types-constant";

export const SHOW_ENTER_DATA_MODAL = "SHOW_ENTER_DATA_MODAL";
export const SHOW_VIEW_DATA_MODAL = "SHOW_VIEW_DATA_MODAL";
export const ACTIVITY_LIST = "ACTIVITY_LIST";
export const BONUSPOINTS_LIST = "BONUSPOINTS_LIST";
export const ENTERED_DATA_LIST = "ENTERED_DATA_LIST";
export const FETCH_MY_DATA = "FETCH_MY_DATA";
export const FETCH_MY_CHART_DATA = "FETCH_MY_CHART_DATA";
export const EVENTCLIENTLEGS_LIST = "EVENTCLIENTLEGS_LIST";
export const FETCH_CLASS_DATA = "FETCH_CLASS_DATA";
export const DATA_ENTRY_VIDEO_MODAL = "DATA_ENTRY_VIDEO_MODAL";
export const MAX_DATA_ENTRY_CAP_MODAL = "MAX_DATA_ENTRY_CAP_MODAL";

export function showEnterDataModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_ENTER_DATA_MODAL, payload: obj });
  };
}

export function showViewDataModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_VIEW_DATA_MODAL, payload: obj });
  };
}

export function fetchActivityList(session, callback) {
  var filter = encodeURI(`(isdeleted=0) and (eventtype=${session.eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ACTIVITY_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchBonusPointsList(session, callback) {
  var filter = encodeURI(`(eventtype=${session.eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: BONUSPOINTS_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const addMemberLiveData = (
  session,
  resource,
  extra = {
    dailyMax: null,
    entrySteps: null,
    totalactivitypoints: null,
    totalbonuspoints: null,
  },
  callback
) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/memberlivedata`;
  var body = {
    resource: resource,
    metaData: extra,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback && callback({ success: 1, response: response.resource });
      },
      (error) => {
        callback && callback(error);
      }
    );
  };
};

export function updateTeamData(session, id, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function fetchDataEntry(session, teamid, type, date, userId, callback) {
  if (type == "mydata") {
    var filter = encodeURI(
      `(memberidfk=${userId})AND(teamidfk=${teamid})AND(entrydate=${date})`
    );
  } else {
    var filter = encodeURI(`(teamidfk=${teamid})AND(entrydate=${date})`);
  }

  var url = `${BASE_URL}api/v2/teamchallenge/_table/memberlivedata?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ENTERED_DATA_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const fetchMyDataList = (
  session,
  extra = { teamId: null, userId: null },
  callback
) => {
  if (_.isNull(extra?.teamId)) return (dispatch) => {};

  const userId = !_.isNil(extra?.userId) ? extra?.userId : session.userid;

  var url = `${BASE_URL}api/v2/custom_script?type=fetchMyData&eventid=${session.eventid}&userid=${userId}&teamid=${extra?.teamId}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: FETCH_MY_DATA,
          payload: { data: response.dataentry },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
};

const numberCheck = (num) => {
  if (num == -1) {
    return 0;
  }
  return num;
};

export const groupBy = (data, keys) => {
  return Object.values(
    data.reduce((acc, val) => {
      const date = val.entrydate;
      let arr = [];
      let total_activity = 0;

      val.memberliveactivity.length > 0 &&
        val.memberliveactivity.map((o) => {
          var obj = {
            name: o.name,
            points: parseInt(o.activitypoint),
            time: o.activitytime,
            intensity: o.intensity,
          };
          arr.push(obj);
          total_activity += parseInt(o.activitypoint);
        });

      let screenTime =
        val.screentimeselectedvalue == -1
          ? 0
          : parseInt(val.screentimeselectedvalue);
      var screentimehours =
        val.screentimeselectedvalue != -1
          ? moment(val.screentimeselectedvalue, "hours").format("hh:mm")
          : "00:00";

      var sleepinmin = parseInt(val.sleepselectedvalue) * 60;

      if (acc[date]) {
        acc[date].steps.push(parseInt(val.steps));
        acc[date].total_steps += parseInt(val.steps);

        acc[date].total_activity = total_activity;
        acc[date].memberliveactivity = arr;

        acc[date].sleep.push(parseInt(sleepinmin));
        acc[date].sleep_points.push(parseInt(val.steps));
        acc[date].total_sleep += parseInt(sleepinmin);
        acc[date].total_sleep_points += parseInt(val.steps);
        acc[date].screentime.push(screenTime);
        acc[date].screentime_points.push(parseInt(val.screentime));
        acc[date].total_screentime += screenTime;
        acc[date].total_screentime_points += parseInt(val.screentime);

        acc[date].fruit.push(numberCheck(parseInt(val.fruitselectedvalue)));
        acc[date].fruit_points.push(numberCheck(parseInt(val.fruit)));
        acc[date].total_fruit += numberCheck(parseInt(val.fruitselectedvalue));
        acc[date].total_fruit_points += numberCheck(parseInt(val.fruit));
        // acc[date].foods.push(numberCheck(parseInt(val.foodsselectedvalue)));
        // acc[date].total_foods += numberCheck(parseInt(val.foodsselectedvalue));
        acc[date].water.push(numberCheck(parseInt(val.waterselectedvalue)));
        acc[date].water_points.push(numberCheck(parseInt(val.water)));
        acc[date].total_water += numberCheck(parseInt(val.waterselectedvalue));
        acc[date].total_water_points += numberCheck(parseInt(val.water));
        acc[date].sugar.push(numberCheck(parseInt(val.sugarselectedvalue)));
        acc[date].sugar_points.push(numberCheck(parseInt(val.sugar)));
        acc[date].total_sugar += numberCheck(parseInt(val.sugarselectedvalue));
        acc[date].total_sugar_points += numberCheck(parseInt(val.sugar));
        acc[date].veges.push(numberCheck(parseInt(val.vegesselectedvalue)));
        acc[date].veges_points.push(numberCheck(parseInt(val.veges)));
        acc[date].total_veges += numberCheck(parseInt(val.vegesselectedvalue));
        acc[date].total_veges_points += numberCheck(parseInt(val.veges));

        acc[date].mindfulness.push(
          numberCheck(parseInt(val.mindfulnessselectedvalue))
        );
        acc[date].mindfulness_points.push(
          numberCheck(parseInt(val.mindfulness))
        );
        acc[date].total_mindfulness += numberCheck(
          parseInt(val.mindfulnessselectedvalue)
        );
        acc[date].total_mindfulness_points += numberCheck(
          parseInt(val.mindfulness)
        );

        acc[date].totalPoints +=
          parseInt(val.steps) +
          parseInt(val.sleep) +
          parseInt(val.screentime) +
          parseInt(val.fruit) +
          // parseInt(val.foods) +
          parseInt(val.water) +
          parseInt(val.mindfulness) +
          parseInt(val.sugar) +
          parseInt(val.veges) +
          total_activity;
        acc[date].totalPoints_max = val.maxallocated == 1;

        acc[date].moments = val.mindfulnessselectedvalue;
        acc[date].sleep_hour = val.sleepselectedvalue;
        acc[date].screen_hour = screentimehours;
      } else {
        acc[date] = {
          date,
          total_steps: parseInt(val.steps),
          steps: [parseInt(val.steps)],
          total_activity,
          memberliveactivity: arr,

          sleep: [parseInt(sleepinmin)],
          sleep_points: [parseInt(val.sleep)],
          total_sleep: parseInt(sleepinmin),
          total_sleep_points: parseInt(val.sleep),
          screentime: [screenTime],
          screentime_points: [parseInt(val.screentime)],
          total_screentime: screenTime,
          total_screentime_points: parseInt(val.screentime),

          fruit: [numberCheck(parseInt(val.fruitselectedvalue))],
          fruit_points: [numberCheck(parseInt(val.fruit))],
          total_fruit: numberCheck(parseInt(val.fruitselectedvalue)),
          total_fruit_points: numberCheck(parseInt(val.fruit)),
          // foods: [numberCheck(parseInt(val.foodsselectedvalue))],
          // total_foods: numberCheck(parseInt(val.foodsselectedvalue)),
          water: [numberCheck(parseInt(val.waterselectedvalue))],
          water_points: [numberCheck(parseInt(val.water))],
          total_water: numberCheck(parseInt(val.waterselectedvalue)),
          total_water_points: numberCheck(parseInt(val.water)),
          sugar: [numberCheck(parseInt(val.sugarselectedvalue))],
          sugar_points: [numberCheck(parseInt(val.sugar))],
          total_sugar: numberCheck(parseInt(val.sugarselectedvalue)),
          total_sugar_points: numberCheck(parseInt(val.sugar)),
          veges: [numberCheck(parseInt(val.vegesselectedvalue))],
          veges_points: [numberCheck(parseInt(val.veges))],
          total_veges: numberCheck(parseInt(val.vegesselectedvalue)),
          total_veges_points: numberCheck(parseInt(val.veges)),

          mindfulness: [numberCheck(parseInt(val.mindfulnessselectedvalue))],
          mindfulness_points: [numberCheck(parseInt(val.mindfulness))],
          total_mindfulness: numberCheck(
            parseInt(val.mindfulnessselectedvalue)
          ),
          total_mindfulness_points: numberCheck(parseInt(val.mindfulness)),

          totalPoints:
            parseInt(val.steps) +
            parseInt(val.sleep) +
            parseInt(val.screentime) +
            parseInt(val.fruit) +
            // parseInt(val.foods) +
            parseInt(val.water) +
            parseInt(val.mindfulness) +
            parseInt(val.sugar) +
            parseInt(val.veges) +
            total_activity,
          totalPoints_max: val.maxallocated == 1,

          moments: val.mindfulnessselectedvalue,
          sleep_hour: val.sleepselectedvalue,
          screen_hour: screentimehours,
        };
      }
      return acc;
    }, {})
  );
};

export const chartWiseData = (
  session,
  startDate,
  endDate,
  chartType,
  chartOption,
  callback
) => {
  var url = `${BASE_URL}api/v2/custom_script?type=mydatachart&eventid=${session.eventid}&userid=${session.userid}&charttype=${chartType}&chartoption=${chartOption}&start_date=${startDate}&end_date=${endDate}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.data && response.data.length > 0) {
          var data = groupBy(response.data, ["month", "year"]);

          dispatch({
            type: FETCH_MY_CHART_DATA,
            payload: { data: data },
          });

          callback({ success: 1, data: data });
        } else {
          dispatch({
            type: FETCH_MY_CHART_DATA,
            payload: { data: response.data },
          });
          callback({ success: 1, data: response.data });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
};

export const fetchEventClientLegs = (session, callback) => {
  let filter = encodeURI(
    `(eventidfk=${session.eventid})AND(eventclientidfk=${session.eventclientid})`
  );
  let url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs?filter=(${filter})&order=order%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: EVENTCLIENTLEGS_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1, data: data.resource });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
};

export function fetchClassDataList(session, teamid, callback) {
  var url = `${BASE_URL}api/v2/custom_script?type=fetchClassData&eventid=${session.eventid}&teamid=${teamid}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: FETCH_CLASS_DATA,
          payload: { data: response.dataentry },
        });
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: [] });
      }
    );
  };
}

export const resetChartReducer = () => {
  return (dispatch) =>
    dispatch({
      type: FETCH_MY_CHART_DATA,
      payload: { data: {} },
    });
};

export function showDataEntryVideo(showModal = false) {
  return (dispatch) => {
    dispatch({
      type: DATA_ENTRY_VIDEO_MODAL,
      payload: { showModal: showModal },
    });
  };
}

export const updateLeg = (session, values, callback) => {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = {
    teamid: values.teamid,
    eventid: session.eventid,
    userid: session.userid,
    totalactivitypoints: values.totalactivitypoints,
    totalbonuspoints: values.totalbonuspoints,
    steps: values.newSteps,
    enteredby: values.enteredby,
    membercount: values.membercount,
    type: "updateLeg",
  };

  let postBody = {
      eventid: session.eventid,
      userid: session.userid,
      called_from: "data-entry",
    },
    postUrl = `${BASE_URL}api/v2/videoqueue_script`;
  let customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.success) {
          callback({ success: 1 });

          // ----- this is for the video queue
          postRequest(
            postUrl,
            postBody,
            customSession,
            dispatch,
            (res) => {},
            (error) => {}
          );
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
};

export const manageMaxDataEntryCapModal = (value) => {
  const localPopups = loadLocalStorage(APP_LOCALSTORAGE_KEYS.popups);
  if (!localPopups) {
    saveInLocalStorage(APP_POPUP_INIT, APP_LOCALSTORAGE_KEYS.popups);
  }

  const popupEnabled = localPopups
    ? localPopups?.hideMaxPointPopup
    : APP_POPUP_INIT[APP_POPUP_TYPES.maxPoint];

  return (dispatch) =>
    dispatch({
      type: MAX_DATA_ENTRY_CAP_MODAL,
      payload: popupEnabled && value ? false : value,
    });
};
