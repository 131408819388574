/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { initiateReset } from "../../actions";
import {
  BASE_FILES_URL,
  FILE_ACCESS_KEY,
} from "../../components/Common/constant";
import { validateEmail } from "../../components/Common/base-components";

const ForgotPassword = (props) => {
  const { deviceSize, initiateReset, session, eventDetails } = props;

  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [loginBg, setLoginBg] = useState([]);
  const [currentBgInd, setCurrentBgInd] = useState(0);

  let loginbgimgArr = [];
  let src = "";

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email) {
      setLoading(true);
      let values = {};
      let eventid = eventDetails.resource.eventid;
      values.eventid = eventid;
      values.email = email;

      initiateReset(values, (response) => {
        setLoading(false);

        if (response.success === 1) {
          setEmail("");
          let content = "Email Sent Successfully.";
          addToast(content, {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          setEmail("");
          addToast(response.desc, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    } else {
      addToast("Please enter  Email/Username.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="p-2">
      <div
        className="bg-cover p-4 flex items-center justify-center login-bg-image-container"
        style={{
          height: 450,
          borderRadius: 12,
        }}
      >
        <div className="bg-white rounded-lg" style={{ width: 350 }}>
          <div className="border-b px-4 pt-6">
            <div>
              <div className="text-xl-1 text-black font-semibold">
                Forgot Password
              </div>
            </div>

            <div className="mt-6.5">
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="text-sm text-primary-black">
                    Email/Username
                  </label>
                  <input
                    className="input-text-login mt-1"
                    style={{ borderRadius: 6 }}
                    id="email"
                    type="text"
                    placeholder="Enter your email/username"
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    required
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn my-4 bg-refresh text-white h-10"
                    style={{ borderRadius: 6, height: 47 }}
                  >
                    {loading ? (
                      <svg
                        className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                        viewBox="0 0 24 24"
                      ></svg>
                    ) : (
                      ""
                    )}
                    <span className="text-sm">Reset Password</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            className="p-4 text-primary text-sm flex justify-center items-center cursor-pointer"
            onClick={() => history.push("/login")}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ initiateReset }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
