import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, autofill } from "redux-form";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";

// constant
import { SITE_URL } from "../../../components/Common/constant";
import { isValidNZPhoneNumber } from "../../../components/Common/base-components";

// actions
import { regFormAction } from "../action";

// components
import TandCPage from "./TandCPage";
import Loader from "../../../components/Common/loader";

// files
import cross from "../../../assets/img/cross.png";

const renderField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <input
          className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderFieldCheckbox = ({
  input,
  span,
  type,
  extra = { spanTnC: null, session: null },
  meta: { touched, error },
}) => {
  return (
    <div className="pb-4">
      {touched && error && (
        <span className="text-sm text-red-500">{error}</span>
      )}
      <br />
      <input {...input} type={type} />
      <span className="pl-2">{span}</span>

      {!_.isEmpty(extra?.spanTnC) && !_.isEmpty(extra?.session) && (
        <a
          className="mail-text-hover pl-2 underline"
          target="_blank"
          href={`${window.location.origin}/#/terms-and-conditions`}
        >
          {extra?.spanTnC}
        </a>
      )}
    </div>
  );
};

const renderFieldOptions = (props) => {
  const {
    input,
    label,
    meta: { touched, error, warning },
    ddList,
  } = props;

  return (
    <div className="">
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1">
        <select
          {...input}
          className="enter-data-steps-input"
          // className="focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 text-gray-700 rounded-md"
        >
          <option value="">-- select --</option>
          {!_.isEmpty(ddList) ? (
            <>
              {_.map(ddList, (item, key) => {
                if (item && item.trim() !== "") {
                  return (
                    <>
                      <option value={item}>{_.startCase(item)}</option>
                    </>
                  );
                }
              })}
            </>
          ) : (
            ""
          )}
          {/* <option value="male">Male</option>
          <option value="female">Female</option> */}
        </select>
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const renderPhoneField = ({
  input,
  label,
  type,
  placeholder,
  mobileno,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
        {label}
        <span className="text-red-600">*</span>
      </label>
      <div className="mt-1 phone-number-field">
        <PhoneInput
          inputClass="enter-data-steps-input"
          country={"nz"}
          onlyCountries={["nz"]}
          autoFormat={false}
          {...input}
          value={mobileno || null}
        />
        {touched && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </div>
    </div>
  );
};

const specialCharReplace = (str) => {
  return str.replace(/[&\/\\#,+()$~%.\_`@'":*?<^>{!-=}]/g, "");
};

const firstNameSpecialCharReplace = (str) => {
  str = str.replace(/[&\/\\#^+()$~%.'":*?<>{}!@]/g, "");
  str = str.replace(/,/gi, "");
  str = str.replace(/-/gi, "");
  return str;
};
const lastNameSpecialCharReplace = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};

const ClassDetails = (props) => {
  const {
    session,
    deviceSize,
    handleSubmit,
    pristine,
    submitting,
    regFormAction,
    regReqDetails,
    classForm,
  } = props;

  const specialChars = `/[&\/\\#,+()$~%.\_@'":*?<^>{!-=}]/\``;
  const [errMessage, setErrMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [finalObj, setFinalObj] = useState({});

  const onSubmitForm = (values) => {
    values.classname = firstNameSpecialCharReplace(values.classname);
    values.teamname = firstNameSpecialCharReplace(values.teamname);
    let obj = Object.assign(classForm, values);

    setFinalObj(obj);

    if (_.isNull(classForm.teamid)) {
      setErrMessage(
        `Registration process stopped as Team is not associated with this request ( ID: ${classForm?.registrationid} ). Contact Admin for help.`
      );
    } else {
      let arr = [1, obj];
      regFormAction(arr, (res) => {});
    }
  };

  const addClassDetails = () => {
    if (_.isNull(classForm.teamid)) {
      setErrMessage(
        `Registration process stopped as Team is not associated with this request ( ID: ${classForm?.registrationid} ). Contact Admin for help.`
      );
    } else {
      let arr = [1, finalObj];
      regFormAction(arr, (res) => {});
    }
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center mb-3">
              <h2 className="text-5xl pb-3 pt-3 mb-3">
                Teachers, Set up your class team
              </h2>
              <span className="">
                Please fill the details below, then click submit.
              </span>
              <br />
              <div className="px-2">
                {errMessage !== "" ? (
                  <>
                    <span className="text-red-800">{errMessage}</span>
                  </>
                ) : (
                  <>
                    <span className="text-red-600">
                      NOTE: Your email address is your Username for logging in
                      later so please remember that along with the password your
                      create below.
                    </span>
                  </>
                )}
              </div>
            </div>

            {!_.isEmpty(classForm) ? (
              <>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div
                    className="bg-secondary-2 py-2 rounded-lg"
                    style={
                      deviceSize.width >= 768
                        ? { minHeight: 630, overflow: "hidden" }
                        : { minHeight: 1350, overflow: "hidden" }
                    }
                  >
                    <div className="flex px-6 md:px-6 lg:px-12 text-xl justify-center md:justify-start md:text-3xl">
                      <span className="mb-2">Class Details</span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12">
                      <div className="">
                        <Field
                          name="classname"
                          type="text"
                          component={renderField}
                          label="Class name or room number"
                          placeholder="Enter name/room no."
                        />
                      </div>
                      <div className="">
                        <Field
                          name="teamname"
                          type="text"
                          component={renderField}
                          label="Team Name"
                          placeholder="Enter team name"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12 my-5">
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          School Name (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="schoolname"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.schoolname}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          School Address (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="schooladdress"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.schooladdress}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          School Suburb (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="schoolsuburb"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.schoolsuburb}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          School City (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="schoolcity"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.schoolcity}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          Postal Code (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="postalcode"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.postalcode}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          Region (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="region"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.region}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    {/* ------------- teacher details section ------------ */}
                    <div className="flex px-6 md:px-6 lg:px-12 text-xl justify-center md:justify-start md:text-3xl pt-3">
                      <span className="mb-2">Teacher Details</span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12">
                      <div className="">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          First Name (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="firstname"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.firstname}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          Last Name Initial (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="lastname"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.lastname}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 md:gap-2 lg:gap-4 xl:gap-6 px-6 md:px-6 lg:px-12 my-5">
                      <div className="col-span-4 md:col-span-1">
                        {/* <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          Mobile No. (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="mobile"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="text"
                            placeholder={classForm?.mobile}
                            disabled
                          />
                        </div> */}
                        {/* --- Adding editable mobile number field for 2024 --- */}
                        <Field
                          name="mobile"
                          type="number"
                          component={renderPhoneField}
                          label="Mobile Number"
                          mobileno={classForm?.mobile}
                        />
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <label className="block text-sm text-gray-700 sm:mt-px sm:pt-2">
                          Email (no change)
                        </label>
                        <div className="mt-1">
                          <input
                            name="email"
                            className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                            type="email"
                            placeholder={classForm?.email}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <Field
                          name="teacher_gender"
                          type="text"
                          component={renderFieldOptions}
                          label="Gender"
                          ddList={["male", "female", "others"]}
                        />
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <Field
                          name="password"
                          type="password"
                          component={renderField}
                          label="Password"
                          placeholder="Enter password"
                        />
                      </div>
                      <div className="col-span-4 md:col-span-1">
                        <Field
                          name="confirmpassword"
                          type="password"
                          component={renderField}
                          label="Confirm Password"
                          placeholder="Reenter password"
                        />
                      </div>
                    </div>
                    {/* terms and conditions */}
                    <div
                      className="px-12 enter-data-modal-body-"
                      // style={{
                      //   height: deviceSize.height - 89,
                      //   // overflow: "auto",
                      // }}
                    >
                      {/* Removing to seperate route for 2024 */}
                      {/* <TandCPage part={1} /> */}
                      <div className="px-6">
                        <Field
                          name="terms"
                          type="checkbox"
                          component={renderFieldCheckbox}
                          span="Yes, I agree to the Terms and Conditions"
                          extra={{
                            spanTnC: "View T&Cs and Privacy Policy.",
                            session,
                          }}
                        />
                      </div>
                      <TandCPage part={2} />
                      <div className="px-6">
                        <Field
                          name="consent"
                          type="checkbox"
                          component={renderFieldCheckbox}
                          span="I give consent to receive communcation from the Young and Helthy 2019 Trust"
                        />
                      </div>
                    </div>
                  </div>

                  {/* form submit */}
                  <div className="flex justify-center m-6">
                    <button
                      type="submit"
                      disabled={pristine || submitting}
                      className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                      style={{ width: 200 }}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="flex justify-center items-center h-screen">
                  <Loader />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* confirmation modal */}
      {showConfirmModal ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header border-b">
                  <h3 className="text-base font-medium">
                    Class Details From Data
                  </h3>
                  <button
                    className="modal-close-btn"
                    onClick={() => setShowConfirmModal(false)}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="">
                  <div className="p-4">
                    <div className="">
                      <span className="text-red-600">
                        *Any special character will be removed ( Except
                        passwords )
                      </span>
                    </div>
                    <div className="">
                      <span>{specialChars}</span>
                    </div>
                    {/* <div className="">
                      <span className="">
                        <span className="text-blue-600">Class Name:</span>{" "}
                        {finalObj?.classname}
                      </span>
                    </div>
                    <div className="">
                      <span className="">
                        <span className="text-blue-600">Team Name:</span>{" "}
                        {finalObj?.teamname}
                      </span>
                    </div> */}
                    <span>Proceed?</span>
                  </div>
                  <div className="flex items-center justify-between text-sm p-4 rounded-b border-t">
                    <button
                      className="bg-gray-400 text-gray-600 rounded-lg inline-flex items-center justify-center cursor-pointer mr-1 py-2 px-4"
                      onClick={() => setShowConfirmModal(false)}
                    >
                      <span className="text-sm">
                        No, return to the form to edit
                      </span>
                    </button>
                    <button
                      className="bg-refresh text-white rounded-lg inline-flex items-center justify-center cursor-pointer ml-1 py-2 px-4"
                      onClick={addClassDetails}
                    >
                      <span className="text-sm">Yes, proceed</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values.classname) {
    errors.classname = "Required*";
  }
  if (!values.teamname) {
    errors.teamname = "Required*";
  }

  if (!values.schoolname) {
    errors.schoolname = "Required*";
  }
  if (!values.schooladdress) {
    errors.schooladdress = "Required*";
  }
  if (!values.schoolsuburb) {
    errors.schoolsuburb = "Required*";
  }
  if (!values.schoolcity) {
    errors.schoolcity = "Required*";
  }

  if (!values.postalcode) {
    errors.postalcode = "Required*";
  } else if (
    values.postalcode.length != 4 ||
    !/^\d{6}?$/.test(values.postalcode)
  ) {
    errors.postalcode = "Wrong format";
  }

  if (!values.firstname) {
    errors.firstname = "Required*";
  } else if (values.firstname.length > 15) {
    errors.firstname = "Must be 15 characters or less";
  }
  if (!values.lastname) {
    errors.lastname = "Required*";
  } else if (values.lastname.length > 1) {
    errors.lastname = "Must be initial";
  }

  // if (!values.mobile) {
  //   errors.mobile = "Required*";
  // } else if (isNaN(Number(values.mobile))) {
  //   errors.mobile = "Must be a number";
  // }
  // if (!values.mobile || values.mobile.length < 7) {
  //   errors.mobile = "Required*";
  // }
  if (!values.mobile || values.mobile.length < 7) {
    errors.mobile = "Required*";
  } else if (!isValidNZPhoneNumber(values.mobile)) {
    errors.mobile = "Invalid number (e.g. +6491234567)";
  }

  if (!values.teacher_gender) {
    errors.teacher_gender = "Required*";
  }
  if (!values.password) {
    errors.password = "Required*";
  }
  if (values.password && values.password.length < 6) {
    errors.password = "Minumum password length is 6";
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = "Required*";
  } else if (values.password != values.confirmpassword) {
    errors.confirmpassword = "Passwords do not match";
  }

  if (!values.terms) {
    errors.terms = "Required*";
  }
  // if (!values.consent) {
  //   errors.consent = "Required*";
  // }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    session: state.session,
    eventDetails: state.eventDetails,
    regReqDetails: state.saveRegReqDetailsReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ regFormAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegistrationFormOne",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ClassDetails)
);
