import {
  REGISTRATION_STEP_COUNTER,
  SAVE_REG_REQ_DETAILS,
  CLASS_REGISTRATION_RESULT,
  SET_CONTACT_US,
} from "../action";

export const regFormSeriesStepCounterReducer = (state = 0, action) => {
  if (action.type == REGISTRATION_STEP_COUNTER) {
    return action.payload;
  }
  return state;
};

export const saveRegReqDetailsReducer = (state = {}, action) => {
  if (action.type == SAVE_REG_REQ_DETAILS) {
    return action.payload;
  }
  return state;
};

export const classRegistrationResult = (state = {}, action) => {
  if (action.type == CLASS_REGISTRATION_RESULT) {
    return action.payload;
  }
  return state;
};

export const contactUsLayout = (state = false, action) => {
  if (action.type == SET_CONTACT_US) {
    return action.payload;
  }
  return state;
};
