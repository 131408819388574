import _ from "lodash";

import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";

import { BASE_URL } from "../../../components/Common/constant";

export const TEAM_DETAILS_LIST = "TEAM_DETAILS_LIST";

export const fetchTeamDetails = (session, payloadValue, callback = null) => {
  const url = `${BASE_URL}api/v2/custom_script?type=fetchTeamDetails`;

  return (dispatch) => {
    postRequest(
      url,
      payloadValue,
      session,
      dispatch,
      (response) => {
        const ownTeam = response?.own;
        const filteredTeams = !response?.is_error
          ? _.orderBy(
              response?.data,
              ["currentleg", "teamdailyavgtotal"], // ["currentleg", "teamavgtotal"],
              ["desc", "desc"]
            )
          : [];
        const allData = _.concat(ownTeam, filteredTeams);

        dispatch({
          type: TEAM_DETAILS_LIST,
          payload: {
            data: allData,
            count: allData.length,
            search: null,
            searchedList: [],
          },
        });
        callback &&
          callback({
            success: 1,
            data: allData,
            count: allData.length,
            search: null,
            searchedList: [],
            error: null,
          });
      },
      (error) => {
        callback && callback({ success: 0, data: null, error });
      }
    );
  };
};

export const handleFetchedTeamSearch = (payloadObj) => {
  return (dispatch) =>
    dispatch({ type: TEAM_DETAILS_LIST, payload: payloadObj });
};

export const addTrack = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/trackteam`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function deleteTrack(session, teamid, callback) {
  var filter = encodeURI(
    `(eventidfk=${session.eventid}) and (memberidfk=${session.userid}) and (teamidfk=${teamid})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/trackteam?filter=(${filter})`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}
