import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// constants
import LocaleStrings from "../../../languages";
import {
  FILE_ACCESS_KEY,
  BASE_AZIONV2_URL,
} from "../../../components/Common/constant";

// apis
import { showDataEntryVideo } from "../actions";

// files
import cross from "../../../assets/img/cross.png";

const DataentryVideo = (props) => {
  const { dataVideoModal, showDataEntryVideo } = props;

  const closeModal = () => {
    showDataEntryVideo(false);
  };

  return (
    <>
      {dataVideoModal && dataVideoModal.showModal ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <h3 className="text-base font-medium">
                    {LocaleStrings.data_entry_for_your_class_how}
                  </h3>
                  <button className="modal-close-btn" onClick={closeModal}>
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto">
                  <div className="flex justify-center items-center mt-4"></div>

                  <div className="rounded-lg m-4" style={{ width: 400 }}>
                    <div className="flex items-center justify-center p-2">
                      <video controls>
                        <source
                          src={
                            `${BASE_AZIONV2_URL}/dataentryvideo/class_entry.mp4` +
                            "?api_key=" +
                            FILE_ACCESS_KEY
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-4 rounded-b border-t">
                  <button
                    className="bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer p-2"
                    onClick={closeModal}
                  >
                    <span className="text-sm">
                      {LocaleStrings.button_close}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    dataVideoModal: state.dataVideoModal,
    homeScreenData: state.homeScreenDataReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showDataEntryVideo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataentryVideo);
