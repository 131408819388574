/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React from "react";
import { Bar } from "react-chartjs-2";

const StepsActivity = (props) => {
  const { componentObject } = props;

  const chartData = componentObject.chartDataObject;

  var padding;
  componentObject.isMobile ? (padding = 3) : (padding = 15);

  const totalPoints = componentObject.totalPoints; // upon raw data, convert to string JSON.stringify()

  // custom chart-tooltip function starts
  const externalTooltipHandler = (context) => {
    if (!context) {
      return;
    }

    // destructure
    const { chart, tooltip } = context;

    // tooltip element
    var tooltipEl = chart?.canvas?.parentNode.querySelector("div"); // create
    if (!tooltipEl) {
      tooltipEl = document.createElement("div"); // create tooltip
      tooltipEl.classList.add("tooltipMainDiv", "rounded-md");
    }

    // hide the tooltip when mouseout
    if (
      (tooltip?.body[0]?.lines[0] == "Activity: 0" &&
        tooltip?.body[1]?.lines[0] == "Steps: 0") ||
      tooltip?.opacity === 0
    ) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // maindiv sub ul-li
    let tooltipUL = document.createElement("ul");
    let tooltipLI = document.createElement("li");

    // set custom tooltip, inner html
    if (tooltip?.body) {
      var titleLines = tooltip.title || [];
      // var bodyLines = tooltip.body.map((ele) => ele.lines) || [];
    }

    // set inner html to tooltip
    // title
    let titleDiv = document.createElement("div"); // title div
    titleDiv.classList.add("titleDivClass", "rounded-t-md");
    // isMobile ? titleDiv.classList.add("pl-8") : "";
    let titleHeading = document.createElement("h4"); // text as heading
    titleHeading.classList.add("titleHeadingClass");
    let daySpan, dateSpan;
    titleLines.forEach((title) => {
      daySpan = document.createElement("span"); // span for day
      dateSpan = document.createElement("span"); // span for date

      var tooltipTitleDay = document.createTextNode(title); // day node
      var tooltipTitleDate = document.createTextNode(""); // date node
      // var tooltipTitleDate = document.createTextNode(", 12th January"); // date node

      daySpan.appendChild(tooltipTitleDay);
      dateSpan.appendChild(tooltipTitleDate);
    });
    titleHeading.appendChild(daySpan); // day to heading
    titleHeading.appendChild(dateSpan); // date to heading
    titleDiv.appendChild(titleHeading); // heading top title div

    // body
    let bodyDiv = document.createElement("div"); // body
    bodyDiv.classList.add(
      "bodyDivClass",
      "rounded-b-lg",
      "p-4",
      "text-sm",
      "font-medium",
      "border-r",
      "border-b"
    );

    let tableContainingDiv = document.createElement("div");
    componentObject.isMobile ? tableContainingDiv.classList.add("pl-7") : "";

    let table = document.createElement("table"); // table to lad the data
    // table.classList.add() // // add styles to table
    // let tableRow = document.createElement("tr"); // individual table row
    // tableRow.classList.add() // add styles to rows

    let tableRow,
      tableRowDataColor,
      tableRowDataDivLabel,
      tabelRowDataDivValue,
      tableRowDataDivSec,
      tableRowDataDiv;
    tooltip.dataPoints.forEach((e, i) => {
      // create row everytime
      tableRow = document.createElement("tr"); // individual table row
      tableRow.classList.add("my-2"); // add styles to rows

      // helping brake element <br />
      var brake = document.createElement("br");

      // color
      tableRowDataColor = document.createElement("td"); // cell for color
      tableRowDataColor.classList.add("colorTd"); // color styles
      let color = e?.dataset.backgroundColor; // color data var
      let colorCircle = document.createElement("span"); // color loading span
      colorCircle.classList.add("colorCircle"); // add styles
      colorCircle.style.backgroundColor = color;
      tableRowDataColor.appendChild(colorCircle);
      colorCircle.style.pointStyle = e?.dataset?.pointStyle;

      // data div
      tableRowDataDivSec = document.createElement("td");
      tableRowDataDiv = document.createElement("div");

      // label
      tableRowDataDivLabel = document.createElement("h5"); // cell to load label "water"
      tableRowDataDivLabel.classList.add("ml-3");
      let labelNode = document.createTextNode(e?.dataset?.label);
      tableRowDataDivLabel.appendChild(labelNode);
      tableRowDataDivLabel.style.color = color; // sets label color acc. to circle color

      // value
      tabelRowDataDivValue = document.createElement("span"); // cell to load value
      tabelRowDataDivValue.classList.add("ml-3");
      let value = document.createTextNode(e?.raw); // if use formattedValue, append directly
      tabelRowDataDivValue.appendChild(value);
      tabelRowDataDivValue.style.color = "#717B89";

      // lebelwise total point
      let tableRowDataDivLabelPoints = document.createElement("span"); // footer div
      tableRowDataDivLabelPoints.classList.add(
        "p-3",
        "mt-5",
        "font-solid",
        "bold"
      );
      // static text
      let pointStatic = document.createElement("span"); // span to load static text
      let textNode = document.createTextNode(`${e.dataset.label} Points: `); // static text
      // let textNode = document.createTextNode(
      //   `${e.dataset.label} Point Accured: `
      // ); // static text
      pointStatic.appendChild(textNode); // text to textSpan
      // value
      let pointValue = document.createElement("span"); // span to load value
      // let totalPointNode = document.createTextNode(totalPoints);
      let totalPointNode = document.createTextNode(e?.raw);
      pointValue.appendChild(totalPointNode);
      // append to footer
      if (!componentObject.isMobile) {
        tableRowDataDivLabelPoints.appendChild(pointStatic);
        tableRowDataDivLabelPoints.appendChild(pointValue);
      } else {
        tableRowDataDivLabelPoints.appendChild(pointStatic);
        tableRowDataDivLabelPoints.appendChild(brake);
        pointValue.classList.add("ml-3");
        tableRowDataDivLabelPoints.appendChild(pointValue);
      }

      // append to tableRowDataDiv
      tableRowDataDiv.appendChild(tableRowDataDivLabel);
      // tableRowDataDiv.appendChild(tabelRowDataDivValue);
      // tableRowDataDiv.appendChild(brake);
      tableRowDataDiv.appendChild(tableRowDataDivLabelPoints);

      // append tableRowDataDiv to tableRowDataDivSec
      tableRowDataDivSec.appendChild(tableRowDataDiv);

      // appends to each row
      tableRow.appendChild(tableRowDataColor);
      tableRow.appendChild(tableRowDataDivSec);

      table.appendChild(tableRow); // each row to table
    });

    tableContainingDiv.appendChild(table); // table to tableContainingDiv
    bodyDiv.appendChild(tableContainingDiv); // table to bodyDiv
    // bodyDiv.appendChild(bodyFooter); // footer to bodyDiv

    tooltipLI.appendChild(titleDiv); // title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // body div to tooltip element

    tooltipUL.appendChild(tooltipLI);
    tooltipEl.appendChild(tooltipUL);

    // remove old children
    var ULnode = tooltipEl.querySelector("ul");
    while (ULnode.firstChild) {
      ULnode.firstChild.remove();
    }
    // add new children
    ULnode.appendChild(tooltipLI);
    tooltipLI.appendChild(titleDiv); // redo title div to tooltip element
    tooltipLI.appendChild(bodyDiv); // redo body div to tooltip element
    tooltipEl.style.opacity = 1; // resetting opacity

    // render the tooltip
    chart?.canvas?.parentNode.appendChild(tooltipEl); // tooltip appended to chart

    // calculate position
    const { offsetLeft: positionX, offsetTop: positionY } = chart?.canvas;
    if (!componentObject.isMobile) {
      tooltipEl.style.left = positionX + tooltip.caretX + 80 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 150 + "px";
    } else {
      tooltipEl.style.left = positionX + tooltip.caretX - 30 + "px";
      tooltipEl.style.top = positionY + tooltip.caretY - 350 + "px";
    }
  };

  return (
    <>
      {componentObject.showComponent === "stepsActivity" ? (
        <Bar
          className="rounded-b-lg"
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: {
                grid: {
                  display: false,
                },
                stacked: true,
                ticks: {
                  display: true,
                  padding: 15,
                },
              },
              yAxes: {
                grid: {
                  display: false,
                },
                stacked: true,
                ticks: {
                  display: true,
                  stepSize: 25000,
                  padding: padding,
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                external: externalTooltipHandler,
              },
            },
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StepsActivity;
