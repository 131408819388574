import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { LABEL_POSITION_LEFT, DATE_FORMAT } from "./constant";

export const renderFieldText = (field) => {
  var { meta, labelposition, label, placeholder, type } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        {type === "password" ? (
          <input
            className="form-control input-field"
            type={type}
            placeholder={placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
            autoComplete="new-password"
          />
        ) : (
          <input
            className="form-control input-field"
            type={type}
            placeholder={placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
          />
        )}
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderHiddenFieldText = (field) => {
  var { meta } = field;
  var divClassName = `${meta.touched && meta.error ? "has-danger" : ""}`;

  return (
    <div className={divClassName}>
      <div style={{ display: "none" }}>
        <input className="form-control" type={field.type} {...field.input} />
      </div>
      <div className="text-help">{meta.touched ? meta.error : ""}</div>
    </div>
  );
};

export const renderFieldTextarea = (field) => {
  var { label, labelposition, meta } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <textarea
          className="form-control-no-height textarea-field"
          placeholder={field.placeholder}
          {...field.input}
          rows={field?.rows ? field.rows : 2}
          maxLength={field.maxlength ? field.maxlength : ""}
        ></textarea>
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderSelect = (field) => {
  var { label, labelposition, meta } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <select className="form-control input-field" {...field.input}>
          {field.placeholder ? (
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
          ) : (
            ""
          )}
          {field.opts.map((object, index) => {
            return (
              <option key={`${object.value}-${index}`} value={object.value}>
                {object.text}
              </option>
            );
          })}
        </select>
        <div className="text-help label-text-help">
          {meta.touched ? meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderCheckbox = (field) => {
  let { label, opts, input, labelposition, meta } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        {opts.map((option, index) => {
          return (
            <div key={index}>
              <label className="custom-container-checkbox custom-text1-normal">
                {option.text}
                <input
                  type="checkbox"
                  name={`${input.name}[${index}]`}
                  value={option.value}
                  checked={input.value.indexOf(option.value) !== -1}
                  onChange={(event) => {
                    const newValue = [...input.value];
                    if (event.target.checked) {
                      newValue.push(option.value);
                    } else {
                      newValue.splice(newValue.indexOf(option.value), 1);
                    }
                    return input.onChange(newValue);
                  }}
                  onBlur={() => input.onBlur(input.value)}
                />
                <span className="checkmark" />
              </label>
            </div>
          );
        })}
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderFieldRadio = (field) => {
  return (
    <div className="form-group">
      <label className="custom-container-radio control-label-top mb-0">
        <input {...field.input} type={field.type} />
        <span className="radiomark"></span>
        {field.label}
      </label>
    </div>
  );
};

export const renderFieldCheckbox = (field) => {
  return (
    <div className="form-group">
      <label className="custom-container-checkbox control-label-top">
        <input type={field.type} {...field.input} />
        <span className="checkmark" />
        {field.label}
      </label>
    </div>
  );
};

export const renderDatePickerWithLabel = (field) => {
  var { input, selected, disabled, label, labelposition, meta } = field;

  var value = input.value
    ? moment(input.value).format(DATE_FORMAT)
    : selected
    ? moment(selected).format(DATE_FORMAT)
    : null;
  var selectedValue = value
    ? moment(value)
    : selected
    ? selected
    : moment(moment().format(DATE_FORMAT));

  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <DatePicker
          className="form-control"
          name={input.name}
          placeholderText={field?.placeholder ? field.placeholder : ""}
          {...input}
          value={value}
          dateFormat={DATE_FORMAT}
          disabled={disabled}
        />
        <div className="text-help label-text-help">
          {meta.touched ? meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderDateTimePickerWithLabel = (field) => {
  var { input, disabled, label, labelposition, meta } = field;
  var DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";
  var selectedValue = input?.value
    ? input.value
    : field?.selected
    ? field.selected
    : null;

  if (typeof selectedValue == "string") {
    selectedValue = new Date(selectedValue);
  }

  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <DatePicker
          className="form-control"
          isClearable
          placeholderText={field?.placeholder ? field.placeholder : ""}
          name={input.name}
          {...input}
          selected={selectedValue}
          showTimeSelect
          timeIntervals={10}
          timeFormat={`HH:mm`}
          dateFormat={DATE_TIME_FORMAT}
          disabled={disabled}
          onChange={input.onChange}
        />
        <div className="text-help label-text-help">
          {meta.touched ? meta.error : ""}
        </div>
      </div>
    </div>
  );
};

export const renderHtmlInputDatePicker = (field) => {
  var { meta, labelposition, label, placeholder, type } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <input
          className="form-control input-field"
          type="date"
          placeholder={placeholder}
          {...field.input}
        />

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const renderHtmlInputTimePicker = (field) => {
  var { meta, labelposition, label, placeholder, type } = field;
  var divClassName = `form-group row ${
    meta.touched && meta.error ? "has-danger" : ""
  }`;
  var labelClasses = "control-label-top col-sm-12";
  var inputClasses = "col-sm-12 col-sm-offset-0";

  if (labelposition === LABEL_POSITION_LEFT) {
    labelClasses = "control-label col-xs-12 col-sm-12 col-md-3";
    inputClasses = "col-xs-12 col-sm-12 col-md-9 col-sm-offset-0";
  }

  return (
    <div className={divClassName}>
      {label && label !== "" ? (
        <label className={labelClasses}>
          {label}
          <span className="label-mandatory">
            {field.mandatory === "true" ? "*" : ""}
          </span>
        </label>
      ) : null}

      <div className={inputClasses}>
        <input
          className="form-control input-field"
          type="time"
          placeholder={placeholder}
          {...field.input}
        />

        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    </div>
  );
};

export const validateEmail = (email) => {
  // var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
  return re.test(email);
};

export const validatePhone = (phone) => {
  let re =
    /^[\+]?[(]?[0-9]{2,5}[)]?[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{2,5}[-\s\.]?[0-9]{4,6}$/im;
  // let re = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/
  return re.test(phone);
};

export const isValidNZPhoneNumber = (input) => {
  const nzPhoneNumberRegex =
    /^(?:(?:\+?64|0)[2-9]\d{7,9}|0210\d{6,7}|022\d{7,8}|02[3-7]\d{6,7}|028\d{7,8}|029\d{7,8}|0508\d{6,8}|0800\d{6,8})$/;
  return nzPhoneNumberRegex.test(input);
};

export const validateUrl = (url) => {
  // var re = /^(?:(http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/;
  var re =
    /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  return re.test(url);
};

export const validateOnlyNums = (value) => {
  // return value.replace(/[^\d]/g, "");
  // let check = !isNaN(value);
  var re = /^\d+$/;
  var check = re.test(value);

  return check;
};
