import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useParams } from "react-router-dom";

const GalleryImage = (props) => {
  const { deviceSize } = props;

  const [imageUrl, setImageUrl] = useState("");

  const param = useParams();

  useEffect(() => {
    let encodedUri = param?.details;
    // let encodedString = `aHR0cHM6Ly9pbWcudGMudGVrcy5jby5pbi9nZXRpbWFnZS5waHA/Z2FsbGVyeT1uei1nYWxsZXJ5JmF2YXRhcj1hdjAxJnNob2VzPTgmZXllcz0xJmJlYXJkPTMmaGFpcj00JnNraW49MHhjYzk5NjYmcGFudHM9NyZzaGlydD01JmdhbGxlcnlmcmFtZT0xJnVpZD0xNzE1NTY5ODU4NTM2MDAw`;
    // let url = `https://img.tc.teks.co.in/getimage.php?gallery=rome-gallery&avatar=av01&shoes=9&eyes=9&beard=2&hair=3&skin=0xcc9966&pants=7&shirt=3&galleryframe=1&uid=1715948148702602`;

    let encodedString = decodeURIComponent(encodedUri);

    let decodedString = window.atob(encodedString);

    setImageUrl(decodedString);
  }, []);

  return (
    <div
      className={
        deviceSize.isMobile ? "p-0 sm:p-2 content-container" : "p-0 sm:p-2"
      }
    >
      <div
        className="bg-secondary p-2"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 5 }
        }
      >
        <div className="p-2 coursemap-wrapper relative mapmarker-container">
          {deviceSize.isMobile ? (
            <div
              className="my-0 md:my-1 lg:my-2 xl:my-3 rounded-lg relative coursemap-wrapper mapmarker-container"
              style={{
                backgroundImage: `url('${imageUrl}')`,
                width: 1000,
                height: 576,
              }}
            ></div>
          ) : (
            <div
              className="my-0 md:my-1 lg:my-2 xl:my-3 bg-no-repeat bg-cover bg-center rounded-lg relative coursemap-wrapper mapmarker-container"
              style={{
                backgroundImage: `url('${imageUrl}')`,
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GalleryImage);
