import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import timer from "../../../assets/img/5-timer.png";
import LocaleStrings from "../../../languages";
import moment from "moment";

const Timer = (props) => {
  const { data, eventDetails } = props;

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  //   const timerComponents = [];

  // ------ Timer Calculation -------
  const calculateTimeLeft = () => {
    let starttime = data?.challengecoursestartdate;
    let endtime = data?.challengecourseclosedate;
    let siteclosedate =
      eventDetails?.resource?.eventsettings_by_eventidfk[0]?.siteclosedate;

    let currentDate = moment();
    let daysleft = "";
    let daysText = "";

    if (
      currentDate.isSameOrAfter(moment(starttime)) &&
      currentDate.isSameOrBefore(moment(endtime))
    ) {
      daysText = "Course Close in";
      let edate = moment(endtime, "YYYY-MM-DD");
      daysleft = edate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
    } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(endtime)) {
      daysText = "Course Close in";
      let edate = moment(endtime, "YYYY-MM-DD");
      daysleft = edate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
    } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(starttime)) {
      daysText = "Course Close in";
      let edate = moment(endtime, "YYYY-MM-DD");
      daysleft = edate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
    } else if (currentDate.isAfter(moment(endtime), "date")) {
      daysText = "Challenge Site Closes in";
      let edate = moment(siteclosedate, "YYYY-MM-DD");
      daysleft = edate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
    } else {
      let sdate = moment(starttime, "YYYY-MM-DD");
      daysleft = sdate.diff(currentDate, "days");
      daysleft += 1; // to include today's date
      daysText = "Challenge Starts in";
    }

    let obj = { daysleft: daysleft, daysText: daysText };

    return obj;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  return (
    <>
      <div
        className="h-12 rounded-b-lg p-4 flex justify-between"
        style={{ backgroundColor: "white" }}
      >
        <div className="inline-flex items-center">
          <div>
            <img src={timer} />
          </div>
          <div className="text-sm ml-2">{timeLeft.daysText}</div>
        </div>

        <div className="text-sm">
          {timeLeft.daysleft
            ? parseInt(timeLeft.daysleft) > 1
              ? `${timeLeft.daysleft} days`
              : `${timeLeft.daysleft} day`
            : ""}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
