import axios from "axios";
import axiosFileupload from "axios-fileupload";

import {
  getHeaders,
  getImageHeaders,
  getMultiPartHeaders,
  getHeadersWithParams,
  getExtraImageHeaders,
  getHeadersMultiPart,
} from "./common-utils";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { logout, resetAll } from "../../actions";

export function getRequest(
  url,
  session = null,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.get(url, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function getCustomRequest(
  url,
  params,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.get(url, getHeadersWithParams(session, params)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function postCustomRequest(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getHeadersWithParams(session, postBody)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function postMultipartRequest(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getMultiPartHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function postRequest(
  url,
  postBody,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.post(url, postBody, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function patchRequest(
  url,
  patchBody,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  dispatch(hideLoading());
  dispatch(showLoading());
  _handleResponse(
    axios.patch(url, patchBody, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function deleteRequest(
  url,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.delete(url, getHeaders(session)),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function deleteBulkRequest(
  url,
  session,
  deleteBody,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axios.delete(url, { data: deleteBody, headers: getHeaders(session) }),
    dispatch,
    session,
    successCallback,
    errorCallback
  );
}

export function fileUploadRequest(
  url,
  fileData,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleResponse(
    axiosFileupload(url, fileData),
    dispatch,
    successCallback,
    errorCallback
  );
}

export function uploadEncodedFile(
  url,
  session,
  filedata,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.post(url, filedata, getImageHeaders(session)),
    dispatch,
    successCallback,
    errorCallback
  );
}

export function uploadEncodedFileLarge(
  url,
  session,
  filedata,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.post(url, filedata, getHeadersMultiPart(session)),
    dispatch,
    successCallback,
    errorCallback
  );
}

export function deleteEncodedFile(
  url,
  session,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.delete(url, getImageHeaders(session)),
    dispatch,
    successCallback,
    errorCallback
  );
}

export function uploadEncodedFileRemote(
  url,
  session,
  filedata,
  filename,
  dispatch,
  successCallback,
  errorCallback
) {
  if (dispatch) {
    dispatch(hideLoading());
    dispatch(showLoading());
  }
  _handleFileResponse(
    axios.post(url, filedata, getExtraImageHeaders(session, filename)),
    dispatch,
    successCallback,
    errorCallback
  );
}

function _handleFileResponse(
  response,
  dispatch,
  successCallback,
  errorCallback
) {
  response.then(
    (response) => {
      if (dispatch) dispatch(hideLoading());

      if (response && response.data && response.data.resource) {
        successCallback(response.data.resource[0]);
      } else {
        successCallback(response);
      }
    },
    (error) => {
      if (dispatch) dispatch(hideLoading());
      errorCallback(error);
    }
  );
}

function _handleResponse(
  response,
  dispatch,
  session = null,
  successCallback,
  errorCallback
) {
  response
    .then(({ data }) => {
      if (dispatch) dispatch(hideLoading());
      successCallback(data);
    })
    .catch((error) => {
      if (dispatch) dispatch(hideLoading());
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        if (dispatch) {
          if (session) {
            dispatch(logout(session));
          }
          dispatch(resetAll());
        }
      } else {
        if (error.response) errorCallback(error.response.data.error);
        else errorCallback(error);
      }
    });
}
