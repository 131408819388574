import {
  SHOW_COURSE_DETAILS_MODAL,
  SHOW_EDIT_COURSE_MODAL,
  FETCH_COURSE_DETAILS_DATA,
  SHOW_EDIT_COURSE,
  EVENT_CLIENT_DATA,
  COURSEMAP_HOTZONE_DETAILS,
} from "../actions";

export function showCourseDetailsModalReducer(state = {}, action) {
  if (action.type === SHOW_COURSE_DETAILS_MODAL) {
    return action.payload;
  }
  return state;
}

export function showEditCourseModalReducer(state = {}, action) {
  if (action.type === SHOW_EDIT_COURSE_MODAL) {
    return action.payload;
  }
  return state;
}

export function courseDetailsReducer(state = {}, action) {
  if (action.type === FETCH_COURSE_DETAILS_DATA) {
    return action.payload;
  }
  return state;
}

export function showEditCourseReducer(state = {}, action) {
  if (action.type === SHOW_EDIT_COURSE) {
    return action.payload;
  }
  return state;
}

export function eventClientReducer(state = {}, action) {
  if (action.type === EVENT_CLIENT_DATA) {
    return action.payload;
  }
  return state;
}

export const coursemapHotzoneReducer = (state = {}, action) => {
  if (action.type === COURSEMAP_HOTZONE_DETAILS) return action.payload;
  return state;
};
