import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// constants
import {
  CUSTOM_ITEM_COUNT,
  LOCAL_TO_GMT_TIME,
} from "../../components/Common/constant";
import Loader from "../../components/Common/loader";
import LocaleStrings from "../../languages";

// actions
import {
  fetchTeamMembers,
  fetchActiveTeamMembers,
  fetchTeamData,
  fetchTeamClientEntrantCount,
  handleAddTeamMemberModal,
  handleAddTeamModal,
  updateTeam,
  withdrawAllTeamMember,
  deleteTeamMemberSession,
  teamWithdraw,
  fetchAllTeamMembers,
} from "./actions";
import { logout } from "../../actions";

// components
import Pagination from "../../components/Common/pagination";
import ItemList from "./components/item-list";
import AddTeamMember from "./components/teammember-add";
import AddTeam from "./components/team-add";
import CustomModal from "../../components/CustomModal";

// files
import editicon from "../../assets/img/edit.png";
import MemberAvatar from "../avatarBuilder/MemberAvatar";

const OPEN_MODAL_INIT = { state: false, type: "", data: null };
const OPEN_MODAL_TYPES = { login: "login", avatar: "avatar" };

const ManageTeam = (props) => {
  const {
    session,
    deviceSize,
    teamMembers,
    teamDetails,
    teamClientEntrantCount,
    teamMemberAddModal,
    teamAddModal,
    activeTeamMembers,
  } = props;

  const history = useHistory();
  const credtableRef = useRef();
  const { addToast } = useToasts();
  const [pageNumber, setPageNumber] = useState(1);
  const [openCustomModal, setOpenCustomModal] = useState(OPEN_MODAL_INIT);

  useEffect(() => {
    props.fetchTeamMembers(session);
    props.fetchActiveTeamMembers(session);
    props.fetchTeamClientEntrantCount(session, (res) => {
      props.fetchTeamData(session);
    });
  }, []);

  const openAddTeam = () => {
    props.handleAddTeamMemberModal({ showModal: true });
  };

  const openTeamName = () => {
    props.handleAddTeamModal({ showModal: true, value: teamDetails?.data });
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const additionTeamCallback = () => {
    props.fetchTeamData(session);
  };

  const paginationCallback = (pageNum) => {
    props.fetchTeamMembers(session, pageNum, (response) => {});

    setPageNumber(pageNum);
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = {
      teamid: teamDetails?.data?.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    var dfids = _.map(activeTeamMembers?.data, "dfid");

    confirmAlert({
      title: LocaleStrings.team_withdraw_confirm_title,
      message: `${LocaleStrings.team_withdraw_confirm_subtitle} ${teamDetails?.data?.teamname}?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            props.teamWithdraw(session, values, (response) => {
              if (response.success === 0) {
                addToast(LocaleStrings.common_fail_message, {
                  appearance: "error",
                  autoDismiss: true,
                });
              } else {
                addToast(LocaleStrings.team_withdraw_alert_success, {
                  appearance: "success",
                  autoDismiss: true,
                });
                if (dfids?.length > 0) {
                  props.deleteTeamMemberSession(session, dfids.join(","));
                }
                props.logout(session);
                history.push("/login");
              }
            });
          },
        },
      ],
      childrenElement: () => (
        <div className="mt-5 mb-5 font-bold">
          {LocaleStrings.team_withdraw_confirm_warning}
        </div>
      ),
    });
  };

  const generatePDF = (type) => {
    const content = credtableRef.current;

    if (!content) return;

    const pageHeight = 841.89; // A4 page height in pixels (approx 297mm)
    const totalPages = Math.ceil(content.scrollHeight / pageHeight);
    const pdf = new jsPDF("p", "mm", "a4");

    const printPage = (pageNumber) => {
      const startY = pageNumber * pageHeight;
      const endY =
        startY + pageHeight > content.scrollHeight
          ? content.scrollHeight
          : startY + pageHeight;

      html2canvas(content, {
        scrollY: startY,
        windowHeight: pageHeight,
        windowWidth: content.clientWidth,
        x: 0,
        y: startY,
        width: content.clientWidth,
        height: pageHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // A4 dimensions

        if (pageNumber < totalPages - 1) {
          pdf.addPage();
          printPage(pageNumber + 1); // Call printPage recursively for the next page
        } else {
          pdf.save(
            `${session.eventid}E${session.teamid}T_${
              type === OPEN_MODAL_TYPES.login
                ? "login_details"
                : "avatar_details"
            }.pdf`
          );

          closeCustomModal();
        }
      });
    };

    printPage(0); // Start from page 0
  };

  const _renderList = () => {
    let pageDataLength = teamMembers?.data ? teamMembers?.data?.length : 0;

    return _.map(teamMembers?.data, (item, index) => {
      return (
        <ItemList
          key={`key_${index}`}
          printList={item}
          currentPage={pageNumber}
          pageDataLength={pageDataLength}
          pagination={paginationCallback}
        />
      );
    });
  };

  const handlePrintTeam = (type) => {
    props.fetchAllTeamMembers(session, (res) => {
      if (res.success) {
        addToast(LocaleStrings.team_pdf_toast, {
          appearance: "warning",
          autoDismiss: true,
        });

        setOpenCustomModal({ state: true, type, data: res?.data });
        setTimeout(() => {
          generatePDF(type);
        }, 2000);
      } else
        addToast(LocaleStrings.common_fail_message, {
          appearance: "error",
          autoDismiss: true,
        });
    });
  };

  const closeCustomModal = () => {
    setOpenCustomModal(OPEN_MODAL_INIT);
  };

  const _rednerLoginCredModalBody = () => {
    return (
      <>
        <div
          className=" bg-white h-screen overflow-y-auto scrollbar-hide"
          style={{ width: "36rem" }}
        >
          <div ref={credtableRef} className="flex flex-col gap-2 p-2 w-full">
            <label className=" text-xl flex gap-2 px-4">
              <span>{LocaleStrings.team_form_label_fname}:</span>
              <span className=" font-semibold" onClick={closeCustomModal}>
                {teamDetails?.data?.teamname}
              </span>
            </label>

            <div className="divide-y divide-gray-200 w-full px-3">
              <div
                className="flex grid- grid-cols-8- bg-white text-sm"
                style={{ width: "97%" }}
              >
                <div
                  className="px-3 py-2"
                  style={{ width: "20%", fontSize: "12px" }}
                >
                  {LocaleStrings.teammember_th_fname}
                </div>
                <div
                  className="px-3 py-2"
                  style={{ width: "20%", fontSize: "12px" }}
                >
                  {LocaleStrings.teammember_th_lname}
                </div>
                <div
                  className="px-3 py-2"
                  style={{ width: "35%", fontSize: "12px" }}
                >
                  {LocaleStrings.teammember_th_uname}
                </div>
                <div
                  className="px-3 py-2"
                  style={{ width: "15%", fontSize: "12px" }}
                >
                  {LocaleStrings.teammember_th_password}
                </div>
                <div
                  className="px-3 py-2"
                  style={{ width: "10%", fontSize: "12px" }}
                >
                  {LocaleStrings.button_withdrawn}
                </div>
              </div>

              {_.map(
                openCustomModal?.data?.resource,
                (teamMember, memberKey) => {
                  return (
                    <div
                      key={teamMember?.id}
                      className="flex bg-white text-sm px-3"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="px-3 py-2"
                        style={{ width: "20%", fontSize: "12px" }}
                      >
                        {teamMember?.firstname}
                      </div>
                      <div
                        className="px-3 py-2"
                        style={{ width: "20%", fontSize: "12px" }}
                      >
                        {teamMember?.lastname}
                      </div>
                      <div
                        className="px-3 py-2"
                        style={{ width: "35%", fontSize: "12px" }}
                      >
                        {teamMember?.username}
                      </div>
                      <div
                        className="px-3 py-2"
                        style={{ width: "15%", fontSize: "12px" }}
                      >
                        {teamMember?.password}
                      </div>
                      <div
                        className="px-3 py-2"
                        style={{ width: "10%", fontSize: "12px" }}
                      >
                        {teamMember?.withdrawndate
                          ? LocaleStrings.yes
                          : LocaleStrings.no}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const _rednerTeamAvatarModalBody = () => {
    if (_.isEmpty(openCustomModal?.data?.resource)) return <></>;

    const mainChunk = _.chunk(openCustomModal?.data?.resource, 4);

    return (
      <>
        <div
          className=" bg-white h-screen overflow-y-auto scrollbar-hide"
          style={{ width: "36rem" }}
        >
          <div ref={credtableRef} className="flex flex-col gap-2 p-2 w-full">
            <label className=" text-xl flex gap-2 mb-2 px-4">
              <span>{LocaleStrings.team_form_label_fname}:</span>
              <span className=" font-semibold">
                {teamDetails?.data?.teamname}
              </span>
            </label>

            <div className="w-full">
              {_.map(mainChunk, (rows, rowKey) => {
                const isNewPage = !rowKey ? false : rowKey % 4 === 0;

                return (
                  <div
                    key={`row_${rowKey}`}
                    className={`flex justify-start ${
                      !rowKey || !isNewPage ? "mt-2" : "mt-12"
                    }`}
                    style={{ width: "100%" }}
                  >
                    {_.map(rows, (teamMember, key) => {
                      if (_.isEmpty(teamMember)) return <></>;

                      return (
                        <div
                          key={`teammember_${teamMember?.id}${key}`}
                          className="flex flex-col gap-1 items-center"
                          style={{ width: "25%" }}
                        >
                          {/* - Avatar - */}
                          <div className="">
                            <MemberAvatar
                              avatarData={teamMember}
                              customHeight={"h-teammember-print"}
                            />
                          </div>

                          {/* - Member name - */}
                          <div className="">
                            <span>
                              {teamMember?.firstname} {teamMember?.lastname}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="p-0 sm:pt-2">
      <div
        className="bg-secondary p-2 sm:p-4"
        style={
          deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
        }
      >
        <div className="rounded-lg">
          <div className="px-5 py-4 rounded-t-lg bg-white">
            <h3 className="text-base font-medium">
              {LocaleStrings.teammember_text_manage_class}
            </h3>
          </div>

          <div className="flex items-center justify-between bg-secondary-2">
            <div className="px-5 py-2 flex gap-3 items-center">
              <span>{teamDetails?.data?.teamname}</span>

              {/* Edit Team */}
              {!_.isEmpty(teamDetails?.data) && (
                <button
                  className="btn-primary-md mr-2 min-height2_5"
                  onClick={openTeamName}
                >
                  <img src={editicon} alt="Edit" />
                </button>
              )}
            </div>

            <div className="px-5 py-2 flex items-center gap-2 h-16">
              {teamDetails?.data ? (
                <>
                  {/* Withdraw Team */}
                  <button
                    type="button"
                    className="btn-primary-md"
                    onClick={handleWithdraw}
                  >
                    <span className="">{LocaleStrings.button_withdraw}</span>
                  </button>

                  {/* Add Entrant */}
                  {teamDetails?.data?.eventclient_by_eventclientidfk
                    ?.maxteammember >
                    teamClientEntrantCount?.data?.teamentrantcount &&
                  teamDetails?.data?.eventclient_by_eventclientidfk
                    ?.totalmembers >
                    teamClientEntrantCount?.data?.cliententrantcount ? (
                    <button
                      type="button"
                      className="btn-primary-md"
                      onClick={openAddTeam}
                    >
                      <span className="">
                        {LocaleStrings.button_add_entrant}
                      </span>
                    </button>
                  ) : (
                    <button type="button" className="btn-primary-disabled-md">
                      <span className="">
                        {LocaleStrings.teammember_text_team_size_full}
                      </span>
                    </button>
                  )}

                  {/* Print team avatars */}
                  <button
                    type="button"
                    className="btn-primary-md"
                    onClick={() => handlePrintTeam(OPEN_MODAL_TYPES.avatar)}
                  >
                    <span className="">
                      {LocaleStrings.button_print_avatar}
                    </span>
                  </button>

                  {/* Print team logins */}
                  <button
                    type="button"
                    className="btn-primary-md"
                    onClick={() => handlePrintTeam(OPEN_MODAL_TYPES.login)}
                  >
                    <span className="">
                      {LocaleStrings.button_print_logins}
                    </span>
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {teamMembers?.data ? (
            <div className="bg-white rounded-b-none sm:rounded-b-lg">
              <div
                className="flex flex-col"
                style={
                  !deviceSize.isMobile ? { height: "calc(100vh - 272px)" } : {}
                }
              >
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
                  <div className="pt-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 h-full">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg view-all-team-table-container h-full">
                      <table className="bg-white min-w-full divide-y divide-gray-200 overflow-y-auto h-100">
                        <thead>
                          <tr>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_fname}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_lname}
                            </th>
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_uname}
                            </th>
                            {/* <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_password}
                            </th> */}
                            <th scope="col" className="table-col-th">
                              {LocaleStrings.teammember_th_action}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className="divide-y divide-gray-200"
                          style={
                            !deviceSize.isMobile
                              ? { height: "calc(100vh - 388px)" }
                              : {}
                          }
                        >
                          {_renderList()}
                        </tbody>
                      </table>

                      {teamMembers?.count > CUSTOM_ITEM_COUNT ? (
                        <Pagination
                          activePage={pageNumber}
                          itemsCountPerPage={CUSTOM_ITEM_COUNT}
                          totalItemsCount={teamMembers.count}
                          pageRangeDisplayed={3}
                          callbackCall={paginationCallback}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center h-screen">
              <Loader />
            </div>
          )}
        </div>
      </div>

      {teamMemberAddModal?.showModal ? (
        <AddTeamMember finishOperationsCallback={additionCallback} />
      ) : (
        ""
      )}
      {teamAddModal?.showModal ? (
        <AddTeam finishOperationsCallback={additionTeamCallback} />
      ) : (
        ""
      )}
      {openCustomModal.state ? (
        <CustomModal
          bodyComponent={
            openCustomModal.type === OPEN_MODAL_TYPES.login
              ? _rednerLoginCredModalBody()
              : openCustomModal.type === OPEN_MODAL_TYPES.avatar
              ? _rednerTeamAvatarModalBody()
              : ""
          }
          fullScreen
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    teamMembers: state.teamMembers,
    teamDetails: state.teamDetails,
    teamClientEntrantCount: state.teamClientEntrantCount,
    teamMemberAddModal: state.teamMemberAddModal,
    teamAddModal: state.teamAddModal,
    activeTeamMembers: state.activeTeamMembers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTeamMembers,
      fetchActiveTeamMembers,
      fetchTeamData,
      fetchTeamClientEntrantCount,
      handleAddTeamMemberModal,
      handleAddTeamModal,
      updateTeam,
      withdrawAllTeamMember,
      deleteTeamMemberSession,
      logout,
      teamWithdraw,
      fetchAllTeamMembers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageTeam);
