import _ from "lodash";

// constants
import { BASE_URL, OPEN_ACCESS } from "../../../components/Common/constant";
import {
  patchRequest,
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import {
  ALLOWED_USER_TYPES,
  AVATAR_TYPE,
  DEFAULT_DB_AVATAR,
} from "../../../constants/types-constant";

export const SAVE_AVATAR_OPTION_DATA = "SAVE_AVATAR_OPTION_DATA";
export const FETCH_AVATAR_OPTION_DATA = "FETCH_AVATAR_OPTION_DATA";

export const saveAvatarOptData = (avatarOpt) => {
  let skincolor = avatarOpt?.skincolor;
  let haircolor = avatarOpt?.haircolor;
  let beardcolor = avatarOpt?.beardcolor;
  let eyebrowcolor = avatarOpt?.eyebrowcolor;
  let avatar = avatarOpt?.avatar;

  let avatarObject = {
    skincolor: skincolor,
    haircolor: haircolor,
    beardcolor: beardcolor,
    eyebrowcolor: eyebrowcolor,
    avatar: [],
    face: [],
  };

  avatar.map((obj, index) => {
    if (index >= 3) {
      avatarObject.face.push(obj);
    }
    avatarObject.avatar.push(obj);
  });

  return (dispatch) => {
    dispatch({ type: SAVE_AVATAR_OPTION_DATA, payload: avatarObject });
  };
};

export const generateRandomAvatar = (userData) => {
  const isStudent =
    userData?.usertype === ALLOWED_USER_TYPES.student ||
    userData?.membertype === ALLOWED_USER_TYPES.student;
  const isMale = userData?.gender === "male";

  let payloadObj = _.cloneDeep(DEFAULT_DB_AVATAR);

  payloadObj.skincolor = `s${_.random(1, AVATAR_TYPE.counts.skinColor)}`;
  payloadObj.haircolor = `h${_.random(1, AVATAR_TYPE.counts.hairColor)}`;
  payloadObj.beardcolor = `h${_.random(1, AVATAR_TYPE.counts.hairColor)}`;
  payloadObj.eyebrowcolor = `h${_.random(1, AVATAR_TYPE.counts.hairColor)}`;
  payloadObj.face = [];

  _.map(payloadObj.avatar, (part, partKey) => {
    let partNumber = _.random(1, AVATAR_TYPE.counts[part?.partName]);

    if (partNumber < 10) {
      partNumber = `0${partNumber}`;
    } else {
      partNumber = _.toString(partNumber);
    }

    if (
      ((!isStudent && !isMale) || isStudent) &&
      part.partName === AVATAR_TYPE.parts.beard
    ) {
      part.partNumber = AVATAR_TYPE.noBeard;
    } else {
      part.partNumber = partNumber;
    }

    if (!_.includes(AVATAR_TYPE.bodyParts, part.partName)) {
      payloadObj.face.push(part);
    }
  });

  return (dispatch) =>
    dispatch({ type: SAVE_AVATAR_OPTION_DATA, payload: payloadObj });
};

export const restoreAvatarData = (avatarObject) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_AVATAR_OPTION_DATA,
      payload: avatarObject,
    });
  };
};

export const fetchAvatarDBData = (session, userid, callback = null) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers/${userid}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        if (data.avatar) {
          dispatch({
            type: FETCH_AVATAR_OPTION_DATA,
            payload: JSON.parse(data.avatar),
          });
          callback &&
            callback({
              success: 1,
              response: JSON.parse(data.avatar),
              error: null,
            });
        } else {
          callback && callback({ success: 0, response: [], error: null });
        }
      },
      (error) => {
        callback && callback({ success: 0, response: [], error });
      }
    );
  };
};

export const updateAvatarData = (session, id, resource, callback) => {
  let patchUrl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers/${id}`,
    clearUrl = `https://img.tc.teks.co.in/clearimg.php?uid=${session.userid}`,
    postBody = {
      eventid: session.eventid,
      userid: session.userid,
      called_from: "avatar",
    },
    postUrl = `${BASE_URL}api/v2/videoqueue_script`;

  let customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };

  return (dispatch) => {
    patchRequest(
      patchUrl,
      resource,
      session,
      dispatch,
      (response) => {
        dispatch(fetchAvatarDBData(session, session.userid));
        callback({ success: 1 });

        // ----- this clears the server side avatar cache data for gallery
        getRequest(clearUrl, session, dispatch, () => {});

        // ----- this is for the video queue
        postRequest(
          postUrl,
          postBody,
          customSession,
          dispatch,
          (res) => {},
          (error) => {}
        );
      },
      (error) => {}
    );
  };
};
