import React, { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useToasts } from "react-toast-notifications";

// Constants
import { AVATAR_TYPE } from "../../constants/types-constant";
import LocaleStrings from "../../languages";

// Actions
import {
  restoreAvatarData,
  updateAvatarData,
  fetchAvatarDBData,
  generateRandomAvatar,
} from "./actions";
import { fetchHomeScreenData } from "../home/actions";

// Components
import Options from "./optionPannel";
import DrawAvatar from "./drawAvatar";

// Files
import cross from "../../assets/img/cross.png";
import checkmark from "./../../assets/img/icons8-checkmark-100.png";

const AvatarBuilder = (props) => {
  const {
    // state
    session,
    AvatarReducer,
    FetchAvatarReducer,
    fetchAvatarDBData,

    // component
    showAvatarModal,
    setShowAvatarModal,
    memberData = null,
    showMemberModal = null,
  } = props;

  const { addToast } = useToasts();
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [navOpt, setNavOpt] = useState(AVATAR_TYPE.parts.top);
  const [optSkinColor, setOptSkinColor] = useState(
    FetchAvatarReducer?.skincolor
  );
  const [optHairColor, setOptHairColor] = useState(
    FetchAvatarReducer?.haircolor
  );
  const [optBeardColor, setOptBeardColor] = useState(
    FetchAvatarReducer?.beardcolor
  );
  const [optEyebrowColor, setOptEyebrowColor] = useState(
    FetchAvatarReducer?.eyebrowcolor
  );
  const [editMemberData, setEditMemberData] = useState(null);
  const navigationArr = [
    { type: AVATAR_TYPE.parts.top, name: LocaleStrings.avatar_top },
    { type: AVATAR_TYPE.parts.bottom, name: LocaleStrings.avatar_bottom },
    { type: AVATAR_TYPE.parts.shoes, name: LocaleStrings.avatar_shoes },
    { type: AVATAR_TYPE.parts.head, name: LocaleStrings.avatar_head },
    { type: AVATAR_TYPE.parts.hair, name: LocaleStrings.avatar_hair },
    { type: AVATAR_TYPE.parts.eyes, name: LocaleStrings.avatar_eyes },
    { type: AVATAR_TYPE.parts.eyeBrow, name: LocaleStrings.avatar_eyeBrow },
    { type: AVATAR_TYPE.parts.nose, name: LocaleStrings.avatar_nose },
    { type: AVATAR_TYPE.parts.mouth, name: LocaleStrings.avatar_mouth },
    { type: AVATAR_TYPE.parts.beard, name: LocaleStrings.avatar_beard },
    { type: AVATAR_TYPE.parts.glasses, name: LocaleStrings.avatar_glasses },
  ];

  useEffect(() => {
    if (!_.isEmpty(memberData)) {
      const avatarData = JSON.parse(memberData.avatar);

      setEditMemberData(avatarData);
      setOptSkinColor(avatarData?.skincolor);
      setOptHairColor(avatarData?.haircolor);
      setOptBeardColor(avatarData?.beardcolor);
      setOptEyebrowColor(avatarData?.eyebrowcolor);
      props.restoreAvatarData(avatarData);
    } else {
      props.restoreAvatarData(FetchAvatarReducer);
    }
  }, []);

  useEffect(() => {
    setOptSkinColor(AvatarReducer?.skincolor);
    setOptHairColor(AvatarReducer?.haircolor);
    setOptBeardColor(AvatarReducer?.beardcolor);
    setOptEyebrowColor(AvatarReducer?.eyebrowcolor);
  }, [AvatarReducer, memberData]);

  const closeModal = () => {
    fetchAvatarDBData(session, session.userid, (res) => {});
    setShowAvatarModal(false);

    if (showMemberModal) showMemberModal(false);
  };

  const handleColorChange = (color) => {
    switch (navOpt) {
      case AVATAR_TYPE.parts.hair:
        setOptHairColor(color);
        break;
      case AVATAR_TYPE.parts.head:
        setOptSkinColor(color);
        break;
      case AVATAR_TYPE.parts.top:
        setOptSkinColor(color);
        break;
      case AVATAR_TYPE.parts.beard:
        setOptBeardColor(color);
        break;
      case AVATAR_TYPE.parts.eyeBrow:
        setOptEyebrowColor(color);
        break;

      default:
        break;
    }
  };

  const saveAvatarData = () => {
    if (loading) return;

    setLoading(true);
    let avRed = AvatarReducer;
    avRed.skincolor = optSkinColor;
    avRed.haircolor = optHairColor;
    avRed.beardcolor = optBeardColor;
    avRed.eyebrowcolor = optEyebrowColor;

    let avatar = JSON.stringify(avRed);

    // isavatarupdated means for dynamic videos don't use default avatar
    let resource = {
      avatar: avatar,
      isavatarupdated: 1,
    };

    const userId = !_.isEmpty(memberData) ? memberData?.id : session.userid;
    props.updateAvatarData(session, userId, resource, (res) => {
      setLoading(false);
      if (res?.success) {
        addToast(LocaleStrings.avatar_save_success, {
          appearance: "success",
          autoDismiss: true,
        });
      }

      if (!_.isEmpty(memberData)) {
        props.fetchHomeScreenData(session, {}, (res) => {});
      }
      closeModal();
    });
  };

  const handleRandomAvatarGenerate = () => {
    props.generateRandomAvatar(_.isEmpty(memberData) ? memberData : session);
  };

  return (
    <Transition.Root show={showAvatarModal} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="modal relative w-avatar-body-width mx-auto max-w-avatar-body-width mapmarker-container">
                {/*content*/}
                <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-2 border-b  rounded-t w-avatar-header-width h-avatar-header-height">
                    <p className="p-2 font-bold">
                      {LocaleStrings.avatar_builder}
                    </p>
                    <button
                      className="p-3 ml-auto bg-transparent border-0 text-black opacity-100 float-right"
                      onClick={closeModal}
                    >
                      <img src={cross} className="cursor-pointer" />
                    </button>
                  </div>

                  {/*body*/}
                  <div
                    className={
                      "relative flex-auto flex-grow-1  w-avatar-body-width "
                    }
                    style={{ height: "582px" }}
                  >
                    <div className="flex bg-white border-b border-solid border-blueGray-200  ">
                      {/* loads the drawn avatar */}
                      <div
                        className={
                          "w-avatar-content-left px-2 pb-2 sm:px-4 sm:pb-4  border-secondary-400 border-r"
                        }
                      >
                        <div className="w-full flex justify-end items-start mt-1 mb-2 sm:mt-2 sm:mb-3">
                          <span
                            className="cursor-pointer text-sm text-primary underline"
                            onClick={handleRandomAvatarGenerate}
                          >
                            {LocaleStrings.avatar_regenerate}
                          </span>
                        </div>

                        <DrawAvatar
                          optSkinColor={optSkinColor}
                          optHairColor={optHairColor}
                          optBeardColor={optBeardColor}
                          optEyebrowColor={optEyebrowColor}
                          memberData={memberData ? editMemberData : null}
                        />

                        {/* hair-color & skin tones goes here */}
                        {/* skin */}
                        {_.includes(
                          [AVATAR_TYPE.parts.top, AVATAR_TYPE.parts.head],
                          navOpt
                        ) ? (
                          <div
                            className="flex shadow-xl"
                            style={{
                              height: 40,
                              width: 160,
                              marginTop: 416,
                              marginLeft: 35,
                            }}
                          >
                            {_.map(
                              AVATAR_TYPE.skinColor,
                              (colorItem, colorKey) => {
                                return (
                                  <div
                                    key={`${navOpt}_${colorKey}${colorItem}`}
                                    className={
                                      optSkinColor == colorKey
                                        ? "border-primary"
                                        : ""
                                    }
                                    style={{
                                      backgroundColor: colorItem,
                                      height: 40,
                                      width: 40,
                                    }}
                                    onClick={() => handleColorChange(colorKey)}
                                  >
                                    {optSkinColor == colorKey ? (
                                      <span className="">
                                        <img
                                          src={checkmark}
                                          className="p-3"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* hair */}
                        {_.includes(
                          [
                            AVATAR_TYPE.parts.hair,
                            AVATAR_TYPE.parts.beard,
                            AVATAR_TYPE.parts.eyeBrow,
                          ],
                          navOpt
                        ) ? (
                          <div
                            className="flex border"
                            style={{ height: 40, width: 230, marginTop: 416 }}
                          >
                            {_.map(
                              AVATAR_TYPE.hairColor,
                              (colorItem, colorKey) => {
                                return (
                                  <div
                                    key={`${navOpt}_${colorKey}${colorItem}`}
                                    className=""
                                    style={{
                                      backgroundColor: colorItem,
                                      height: 40,
                                      width: 40,
                                    }}
                                    onClick={() => handleColorChange(colorKey)}
                                  >
                                    {optHairColor == colorKey ? (
                                      <span className="">
                                        <img
                                          src={checkmark}
                                          className="p-3"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* render the options for the avatar */}
                      <Options
                        navOpt={navOpt}
                        optSkinColor={optSkinColor}
                        optHairColor={optHairColor}
                        optBeardColor={optBeardColor}
                        optEyebrowColor={optEyebrowColor}
                      />
                    </div>

                    {/* new option carousel */}
                    <div className="h-avatar-opt-height w-avatar-opt-width">
                      <Carousel
                        plugins={[
                          {
                            resolve: slidesToShowPlugin,
                            options: {
                              numberOfSlides: 4,
                            },
                          },
                          {
                            resolve: arrowsPlugin,
                            options: {
                              arrowLeft: (
                                <div className="carousel-arrow-container-avatar-option">
                                  <i
                                    className="arrow left-static"
                                    style={{ marginTop: 12 }}
                                  ></i>
                                </div>
                              ),
                              arrowLeftDisabled: (
                                <div className="carousel-arrow-container-avatar-option-disabled">
                                  <i
                                    className="arrow-disabled left-static"
                                    style={{ marginTop: 12 }}
                                  ></i>
                                </div>
                              ),
                              arrowRight: (
                                <div className="carousel-arrow-container-avatar-option">
                                  <i
                                    className="arrow right-static"
                                    style={{ marginTop: 12 }}
                                  ></i>
                                </div>
                              ),
                              arrowRightDisabled: (
                                <div
                                  className="carousel-arrow-container-avatar-option-disabled"
                                  style={{
                                    cursor: "not-allowed",
                                  }}
                                >
                                  <i
                                    className="arrow-disabled right-static"
                                    style={{ marginTop: 12 }}
                                  ></i>
                                </div>
                              ),
                              addArrowClickHandler: true,
                            },
                          },
                        ]}
                      >
                        {_.map(navigationArr, (item) => {
                          return (
                            <div
                              className={
                                navOpt === item.type
                                  ? "is-active border-t-2 border-primary ml-1 p-3 font-bold cursor-pointer"
                                  : "ml-1 p-3 text-secondary hover:text-refresh hover:bg-grey-300 font-bold cursor-pointer"
                              }
                              onClick={() => setNavOpt(item.type)}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </Carousel>
                    </div>

                    {/*footer*/}
                    <div className="flex items-center bg-white justify-end p-2 border-t border-solid border-blueGray-200 rounded-b h-avatar-footer-height">
                      <button
                        className="text-secondary font-bold background-transparent px-6 py-2 text-sm mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={closeModal}
                      >
                        {LocaleStrings.button_cancel}
                      </button>
                      <button
                        className="bg-refresh flex gap-1 text-white rounded px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={saveAvatarData}
                        disabled={loading}
                      >
                        {loading && (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                            viewBox="0 0 24 24"
                          ></svg>
                        )}
                        {LocaleStrings.button_save} {LocaleStrings.avatar}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    AvatarReducer: state.AvatarReducer,
    FetchAvatarReducer: state.FetchAvatarReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      restoreAvatarData,
      updateAvatarData,
      fetchAvatarDBData,
      generateRandomAvatar,
      fetchHomeScreenData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AvatarBuilder);
