import _ from "lodash";
import {
  postRequest,
  getRequest,
} from "../../../components/Common/network-call";
import {
  BASE_URL,
  APP_API_KEY,
  OPEN_ACCESS,
} from "../../../components/Common/constant";

const customSession = { apiKey: OPEN_ACCESS };

export const POST_TEACHER_REQUEST = "POST_TEACHER_REQUEST";
export const REGISTRATION_STEP_COUNTER = "REGISTRATION_STEP_COUNTER";
export const SAVE_REG_REQ_DETAILS = "SAVE_REG_REQ_DETAILS";
export const CLASS_REGISTRATION_RESULT = "CLASS_REGISTRATION_RESULT";
export const SET_CONTACT_US = "SET_CONTACT_US";

var regFormSeries = {};

export const fetchRegistrationRequests = (session, regReqId, callback) => {
  var url = `${BASE_URL}api/v2/eventclientinfo?regreqid=${regReqId}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (res) => {
        if (res.is_error) {
          dispatch({
            type: SAVE_REG_REQ_DETAILS,
            payload: {},
          });
          callback({
            success: 1,
            linkExpired: 0,
            data: {},
            message: res.message,
          });
        } else if (res.link_expired) {
          dispatch({
            type: SAVE_REG_REQ_DETAILS,
            payload: {},
          });
          callback({
            success: 1,
            linkExpired: 1,
            data: {},
            message: res.message,
          });
        } else if (_.isNull(res.resource?.teamidfk)) {
          dispatch({
            type: SAVE_REG_REQ_DETAILS,
            payload: res.resource,
          });
          callback({
            success: 1,
            linkExpired: 0,
            data: res.resource,
            message:
              "Client " +
              res.message +
              " but Team is not associated with this request. Contact Admin to avoid any failure",
          });
        } else {
          dispatch({
            type: SAVE_REG_REQ_DETAILS,
            payload: res.resource,
          });
          callback({
            success: 2,
            linkExpired: 0,
            data: res.resource,
            message: res.message,
          });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const fetchMasterRegion = (session, callback) => {
  let filter = encodeURI(`isdeleted=0`);
  let url = `${BASE_URL}api/v2/teamchallenge/_table/masterregion?include_count=true&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (res) => {
        if (res.meta.count && res.resource) {
          callback({ success: 1, count: res.meta.count, data: res.resource });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const addTeacherInterest = (session, values, callback) => {
  let url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests`;
  let body = {
    resource: values,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        let obj = {
          type: "welcome",
          eventid: session.eventid,
          first_name: values.firstname,
          // email: values.email,
        };
        let successArr = [];
        let middleArr = [];
        _.map(values, (v) => {
          dispatch(
            requestMail(
              session,
              {
                eventid: session.eventid,
                email: v.email,
                first_name: v.firstname,
                type: "welcome",
              },
              (res) => {
                res.success
                  ? successArr.push(v.email)
                  : middleArr.push(v.email);
              }
            )
          );
        });
        callback({
          success: 1,
          data: { mail: successArr, noMail: middleArr },
        });
      },
      (error) => {
        callback({
          success: 0,
          message: "Error while creating request",
          error: error,
        });
      }
    );
  };
};

export const validateRequestUser = (session, email, callback) => {
  // let filter = encodeURI(`email=${email}`);
  // let dfUrl = `${BASE_URL}api/v2/system/user?include_count=true&filter=(${filter})`;
  let regReqFilter = encodeURI(
    `(email=${email})AND(eventidfk=${session.eventid})AND(isremoved=0)`
  );
  let clientFilter = encodeURI(`adminname=${email}`);
  let regReqUrl = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests?filter=(${regReqFilter})`;
  let clientUrl = `${BASE_URL}api/v2/teamchallenge/_table/clients?filter=(${clientFilter})`;

  return (dispatch) => {
    getRequest(
      clientUrl,
      session,
      dispatch,
      (res) => {
        if (res.resource && res.resource.length == 0) {
          // not found in this table, checking for regReq table
          getRequest(
            regReqUrl,
            session,
            dispatch,
            (regres) => {
              if (regres.resource && regres.resource.length == 0) {
                // not found in this table
                callback({ success: 2 });
              } else if (regres.resource && regres.resource[0].email == email) {
                // found in this table
                callback({ success: 1 });
              }
            },
            (regerror) => {
              callback({
                success: 0,
                message: "Can not authenticate email.",
                error: regerror,
              });
            }
          );
        } else if (res.resource && res.resource[0].email == email) {
          // found in this table
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({
          success: 0,
          message: "Can not authenticate email.",
          error: error,
        });
      }
    );
  };
};

export const regFormAction = (resource = [], callback) => {
  regFormSeries = Object.assign(regFormSeries, resource[1]);
  if (resource[1]?.pedometer) {
    return (dispatch) => {
      dispatch(
        regFormSeriesSubmit(resource[0], (res) => {
          callback({ message: res.message });
        })
      );
    };
  }

  return (dispatch) => {
    dispatch({
      type: REGISTRATION_STEP_COUNTER,
      payload: resource[0],
    });
  };
};

const regFormSeriesSubmit = (value, callback) => {
  var session = { ...customSession, eventid: regFormSeries.eventid };
  var url1 = `${BASE_URL}api/v2/registration_script`;
  let body = { ...regFormSeries };

  return (dispatch) => {
    postRequest(
      url1,
      body,
      session,
      dispatch,
      (response) => {
        // handles script response
        if (response.is_error == 1) {
          // it means some server side process error has occured
          dispatch({
            type: CLASS_REGISTRATION_RESULT,
            payload: { success: 1, message: response.message },
          });
          callback({ success: 1, message: response.message });
        } else {
          // process complete
          dispatch({
            type: CLASS_REGISTRATION_RESULT,
            payload: {
              success: 2,
              message: response.message,
              data: {
                teamid: response.teamid,
                teacherid: response.teacherid,
                other_members: response.other_members,
                problem_members: response.problem_members,
                reg_status_res: response.reg_status_res,
              },
            },
          });
          callback({
            success: 2,
            message: response.message,
            data: {
              teamid: response.teamid,
              teacherid: response.teacherid,
              other_members: response.other_members,
              problem_members: response.problem_members,
              reg_status_res: response.reg_status_res,
            },
          });
        }

        // to itterate the layout
        dispatch({
          type: REGISTRATION_STEP_COUNTER,
          payload: value,
        });
      },
      (error) => {
        dispatch({
          type: CLASS_REGISTRATION_RESULT,
          payload: {
            success: 0,
            message: "Something went wrong while processing request.",
            error: error,
          },
        });
        callback({
          success: 0,
          message: "Something went wrong while processing request.",
          error: error,
        });
      }
    );
  };
};

export const requestMail = (session, values, callback) => {
  let url = `${BASE_URL}api/v2/sendemail`;

  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (res) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const fetchSchoolInfo = (session, values, callback) => {
  let url = "";
  if (values.type == "initial") {
    url = `${BASE_URL}api/v2/teamchallenge/_table/client_info?include_count=true&externalcall=true&eventid=${session.eventid}&limit=25`;
  } else {
    let filter = encodeURI(`(orgname like %${values.name}%)`);
    url = `${BASE_URL}api/v2/teamchallenge/_table/client_info?include_count=true&externalcall=true&eventid=${session.eventid}&filter=(${filter})`;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (res) => {
        let data = [];
        if (res.resource.length > 0) {
          data = res.resource;
        }
        callback({ success: 1, count: res.meta.count, data: data });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const setContactUs = (value) => {
  // {show: true/false, call: 'regform'/'regseries'}
  return (dispatch) => dispatch({ type: SET_CONTACT_US, payload: value });
};
