/* eslint-disable no-unused-vars */
import _ from "lodash";
import {
  getRequest,
  postRequest,
} from "../../../components/Common/network-call";
import { BASE_URL, LOCAL_TIMEZONE } from "../../../components/Common/constant";
import {
  loadLocalStorage,
  saveInLocalStorage,
} from "../../../reducers/localStorage";
import {
  APP_LOCALSTORAGE_KEYS,
  APP_POPUP_INIT,
  APP_POPUP_TYPES,
} from "../../../constants/types-constant";

export const FETCH_HOME_SCREEN_DATA = "FETCH_HOME_SCREEN_DATA";
export const SHOW_MEMBER_MODAL = "SHOW_MEMBER_MODAL";
export const SET_HOME_DATA = "SET_HOME_DATA";
export const SHOW_LEG_VIDEO_MODAL = "SHOW_LEG_VIDEO_MODAL";
export const SET_HOME_MAP = "SET_HOME_MAP";
export const SET_HOME_MARKER = "SET_HOME_MARKER";
export const LAST_LEG_SHOW = "LAST_LEG_SHOW";
export const FIREWORK_SHOW = "FIREWORK_SHOW";
export const CERTIFICATE_IMAGE_URL = "CERTIFICATE_IMAGE_URL";
export const LAST_LEG_ALREADY_SHOW = "LAST_LEG_ALREADY_SHOW";

export const fetchHomeScreenData = (
  session,
  extra = { userId: null, userType: "" },
  callback = null
) => {
  const userId = !_.isNil(extra?.userId) ? extra?.userId : session.userid;
  const userType =
    extra?.userType && !_.isEmpty(extra?.userType)
      ? extra?.userType
      : session.usertype;

  const url = `${BASE_URL}api/v2/custom_script?type=getHomeScreenData&eventid=${session.eventid}&userid=${userId}&usertype=${userType}&timezone=${LOCAL_TIMEZONE}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({ type: FETCH_HOME_SCREEN_DATA, payload: { data: response } });
        callback && callback({ success: 1, data: response });
      },
      (error) => {
        callback && callback({ success: 0, data: [] });
      }
    );
  };
};

export function showMemberModal(obj) {
  return (dispatch) => {
    dispatch({ type: SHOW_MEMBER_MODAL, payload: obj });
  };
}

export function setHomeData(obj = {}) {
  return (dispatch) => {
    dispatch({ type: SET_HOME_DATA, payload: obj });
  };
}

export const showLegVideoModal = (
  session = null,
  renderModal = false,
  legLocationName = "",
  callback
) => {
  let fetchUrl = "";
  if (session) {
    let legName = _.toLower(legLocationName);

    legName =
      legName == "new zealand"
        ? "nz"
        : legName == "italy"
        ? "rome"
        : legName == "new york city"
        ? "nyc"
        : legName == "mozambique"
        ? "moz"
        : legName;
    // let fetchFilter = encodeURI(
    //   `(eventid=${session.eventid})AND(teamid=${session.teamid})AND(teammemberid=${session.userid})AND(gallery=${legName})AND(videopath IS NOT NULL)`
    // );
    let fetchFilter = encodeURI(
      `(eventid=${session.eventid})AND(teamid=${session.teamid})AND(gallery=${legName})AND(videopath IS NOT NULL)`
    );
    fetchUrl = `${BASE_URL}api/v2/teamchallenge/_table/video_queue?filter=(${fetchFilter})`;
  }

  return (dispatch) => {
    if (session == undefined || !session) {
      let obj = { show: false, obj: {} };
      dispatch({ type: SHOW_LEG_VIDEO_MODAL, payload: obj });
    } else {
      getRequest(
        fetchUrl,
        session,
        dispatch,
        (response) => {
          // let animationvideo = "finishvideo/data_entry_finish_video.webm";
          let animationvideo = "";
          if (response.resource.length > 0) {
            let orderArr = _.orderBy(response.resource, "readydate", "desc");
            animationvideo = orderArr[0]?.videopath;
          }

          dispatch({
            type: SHOW_LEG_VIDEO_MODAL,
            payload: {
              show: animationvideo == "" ? false : true,
              obj: { animationvideo },
            },
          });
        },
        (error) => {
          callback({ success: 0 });
        }
      );
    }
  };
};

export function setHomeMarker(markerIndex = 0) {
  return (dispatch) => {
    dispatch({ type: SET_HOME_MARKER, payload: markerIndex });
  };
}

export function setHomeMap(obj = [{ x: 0, y: 0 }]) {
  return (dispatch) => {
    dispatch({ type: SET_HOME_MAP, payload: obj });
  };
}

export function isLastLegShow(show) {
  return (dispatch) => {
    dispatch({ type: LAST_LEG_SHOW, payload: show });
  };
}

export const isFirework = (show) => {
  let localPopup = loadLocalStorage(APP_LOCALSTORAGE_KEYS.popups);

  if (!localPopup) {
    // undefined
    localPopup = _.cloneDeep(APP_POPUP_INIT);
    localPopup[APP_POPUP_TYPES.fireworks] = show;
    saveInLocalStorage(localPopup, APP_LOCALSTORAGE_KEYS.popups);
    return (dispatch) => {
      dispatch({ type: FIREWORK_SHOW, payload: show });
    };
  } else {
    if (localPopup[APP_POPUP_TYPES.fireworks]) return (dispatch) => {};
    else {
      localPopup[APP_POPUP_TYPES.fireworks] = show;
      saveInLocalStorage(localPopup, APP_LOCALSTORAGE_KEYS.popups);
      return (dispatch) => {
        dispatch({ type: FIREWORK_SHOW, payload: show });
      };
    }
  }
};

export const storeCertificate = (session, dataObj, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/mastercertificate`;

  let obj = {
    name: dataObj.certificateName,
    content: JSON.stringify(dataObj.content),
  };
  let resource = [obj];

  var body = {
    resource: resource,
  };

  return (dispatch) => {};
};

export function lastLegAlreadyShown(show) {
  return (dispatch) => {
    dispatch({ type: LAST_LEG_ALREADY_SHOW, payload: show });
  };
}

export const setLegCompleteLog = (session, value, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/legcompletelog`;

  var body = {
    resource: value,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, error });
      }
    );
  };
};

export const fetchLegCompleteLog = (session, legid, callback) => {
  var filter = encodeURI(
    `(eventidfk=${session.eventid})AND(teamidfk=${session.teamid})AND(legidfk=${legid})AND(teammemberidfk=${session.userid})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/legcompletelog?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // dispatch({});
        if (response.resource.length > 0) {
          // user exists
          callback({
            success: 2,
          });
        } else {
          // user doesn't exists
          callback({ success: 1 });
        }
      },
      (err) => {
        callback({ success: 0, err });
      }
    );
  };
};
