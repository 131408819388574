/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deviceSize, checkEvent } from "./actions";

import routes from "./routes";
import Navbar from "./components/Navbar";
import Dropdown from "./components/Dropdown";
import Loader from "./components/Common/loader";
import { BASE_FILES_URL, FILE_ACCESS_KEY } from "./components/Common/constant";

const App = (props) => {
  const { deviceSizeReducer, session, isLoggedIn, eventDetails } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEventLoad, setEventLoad] = useState("");
  const [showNav, setShowNav] = useState(true);
  const [regSeriesCall, setRegSeriesCall] = useState(false);

  const history = useHistory();
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    var hostname = window.location.hostname;
    var subdomain = hostname.split(".")[0];

    props.checkEvent(session, subdomain, (response) => {
      if (response.success) {
        setEventLoad("loadedcorrect");
      } else {
        setEventLoad("loadedwrong");
      }
    });

    if (!isLoggedIn) {
      if (
        history?.location?.pathname.includes("/registration-form") ||
        history?.location?.pathname.includes("/registration-series") ||
        history?.location?.pathname.includes("/terms-and-conditions")
      ) {
        if (
          history?.location?.pathname.includes("/registration-form") ||
          history?.location?.pathname.includes("/registration-series") ||
          history?.location?.pathname.includes("/terms-and-conditions")
        ) {
          history.push(history?.location?.pathname);
        }
        setRegSeriesCall(true);
      } else if (
        history?.location?.pathname.includes("/share") ||
        history?.location?.pathname.includes("/certificate")
      ) {
        if (
          history?.location?.pathname.includes("/share") ||
          history?.location?.pathname.includes("/certificate")
        ) {
          setShowNav(false);
        }
        history.push(history?.location?.pathname);
      } else if (
        history?.location?.pathname !== "/forgot-password" &&
        history?.location?.pathname !== "/resetpassword"
      ) {
        setShowNav(true);
        const path = "/login";
        history.push(path);
      }
    } else if (
      history?.location?.pathname.includes("/share") ||
      history?.location?.pathname.includes("/certificate")
    ) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [history]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    if (window.innerWidth < 576) {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: true,
      });
    } else if (window.screen.width < 576) {
      props.deviceSize({
        width: window.screen.width,
        height: window.screen.height,
        isMobile: true,
      });
    } else {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
      });
    }
  };

  const getRoutes = (routes) => {
    return routes.map((item, idx) => {
      return (
        <Route path={item.path} exact component={item.component} key={idx} />
      );
    });
  };

  let backgroundimage = "";
  if (eventDetails?.resource?.bgtype === "image") {
    backgroundimage = `${BASE_FILES_URL}${eventDetails.resource.bgvalue}?api_key=${FILE_ACCESS_KEY}`;
  }

  return (
    <div>
      {isEventLoad !== "" ? (
        isEventLoad === "loadedcorrect" &&
        session &&
        session.eventid &&
        session.eventid !== "" ? (
          <div
            className="bg-no-repeat bg-cover md:px-8 lg:px-8 xl:px-32 pt-2 antialiased"
            style={
              eventDetails.resource.bgtype === "image"
                ? {
                    height: deviceSizeReducer.height > 766 ? "100vh" : "100%",
                    backgroundImage: `url(${backgroundimage})`,
                  }
                : {
                    minHeight: "100vh",
                    backgroundColor: `${eventDetails.resource.bgvalue}`,
                  }
            }
          >
            <div
              className={deviceSizeReducer.width > 1440 ? "container" : ""}
              style={deviceSizeReducer.width > 1440 ? { width: 1180 } : {}}
            >
              <div>
                {showNav ? (
                  <>
                    <Navbar
                      routes={routes}
                      toggle={toggle}
                      regSeriesCall={regSeriesCall}
                    />
                    <Dropdown
                      key="menu-dropdown"
                      routes={routes}
                      isOpen={isOpen}
                      toggle={toggle}
                      regSeriesCall={regSeriesCall}
                    />
                  </>
                ) : (
                  ""
                )}

                <div
                  style={deviceSizeReducer.width > 1440 ? { width: 1180 } : {}}
                >
                  <Switch>{getRoutes(routes)}</Switch>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            <Redirect from="/" to="/404" />
          </Switch>
        )
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
    session: state.session,
    deviceSizeReducer: state.deviceSize,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deviceSize, checkEvent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
