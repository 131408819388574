/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
  hashHistory,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { ToastProvider } from "react-toast-notifications";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-lazy-load-image-component/src/effects/blur.css";

ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </Provider>
  </HashRouter>,
  document.getElementById("root")
);
