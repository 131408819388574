// Components
import Home from "./pages/home";
import ViewAllTeams from "./pages/viewallteam";
import ManageTeam from "./pages/manageteam";
import MyData from "./pages/mydata";
import Support from "./pages/support";
import Language from "./pages/language";
import CourseVideo from "./pages/coursevideo";
import CourseMap from "./pages/coursemap";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/forgotpassword/resetpwd";
import BarCharts from "./pages/mydata/components/barcharts";
import DataEntry from "./pages/mydata/components/dataentry";
import ClassEntry from "./pages/mydata/components/classentry";
import RegForm from "./pages/registrationforms";
import RegFormSeries from "./pages/registrationforms/pages/regFormSeries";
import Certificate from "./components/Common/Certificate";
import GalleryImage from "./components/Common/GalleryImage";

// Icons
// import appIcons from "./components/Common/appIcons";

// Languages
import LocaleStrings from "./languages";

const routes = [
  // {
  //   path: "/",
  //   name: LocaleStrings.Home,
  //   // icon: appIcons.koachifyexploreIcon,
  //   component: Home,
  //   display: false,
  //   source: "web",
  // },
  {
    path: "/",
    name: LocaleStrings.Home,
    component: Home,
    display: true,
    source: "web",
  },
  {
    path: "/view-all-teams",
    name: LocaleStrings.view_all_teams,
    // icon: null,
    component: ViewAllTeams,
    display: false,
    source: "web",
  },
  // {
  //   path: "/view-my-team",
  //   name: LocaleStrings.view_my_team,
  //   // icon: null,
  //   component: ViewAllTeams,
  //   display: true,
  //   source: "web",
  // },
  {
    path: "/manage-team",
    name: LocaleStrings.manage_team,
    // icon: null,
    component: ManageTeam,
    display: true,
    source: "web",
  },
  {
    path: "/manage-class",
    name: LocaleStrings.manage_class,
    // icon: null,
    component: ManageTeam,
    display: true,
    source: "web",
  },
  {
    path: "/my-data",
    name: LocaleStrings.my_data,
    // icon: null,
    component: MyData,
    display: true,
    source: "web",
  },
  {
    path: "/data-entry/:memberId?",
    name: LocaleStrings.my_data,
    // icon: null,
    component: DataEntry,
    display: false,
    source: "web",
  },
  {
    path: "/class-entry",
    name: LocaleStrings.my_data,
    // icon: null,
    component: ClassEntry,
    display: false,
    source: "web",
  },
  {
    path: "/faq",
    name: LocaleStrings.faq,
    // icon: null,
    component: Support,
    display: true,
    source: "web",
  },
  // {
  //   path: "/language",
  //   name: LocaleStrings.language,
  //   // icon: null,
  //   component: Language,
  //   display: true,
  //   source: "web",
  // },
  {
    path: "/course-video",
    name: "",
    icon: null,
    component: CourseVideo,
    display: false,
    source: "web",
  },
  {
    path: "/course-map",
    name: "",
    icon: null,
    component: CourseMap,
    display: false,
    source: "web",
  },
  {
    path: "/login",
    name: "",
    icon: null,
    component: Login,
    display: false,
    source: "web",
  },
  {
    path: "/registration-form",
    name: "",
    icon: null,
    component: RegForm,
    display: false,
    source: "registration",
  },
  {
    path: "/registration-series/:requestid",
    name: "",
    icon: null,
    component: RegFormSeries,
    display: false,
    source: "registration",
  },
  {
    path: "/terms-and-conditions",
    name: "",
    icon: null,
    component: RegFormSeries,
    display: false,
    source: "registration",
  },
  {
    path: "/certificate/:details",
    name: "",
    icon: null,
    component: Certificate,
    display: false,
    source: "registration",
  },
  {
    path: "/share/:details",
    name: "",
    icon: null,
    component: GalleryImage,
    display: false,
    source: "registration",
  },
  {
    path: "/forgot-password",
    name: "",
    icon: null,
    component: ForgotPassword,
    display: false,
    source: "web",
  },
  {
    path: "/resetpassword",
    name: "",
    icon: null,
    component: ResetPassword,
    display: false,
    source: "web",
  },
];
export default routes;
