import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import classnames from "classnames";
import { useToasts } from "react-toast-notifications";

// constants
import {
  renderFieldText,
  renderSelect,
  renderHiddenFieldText,
} from "../../../components/Common/base-components";
import {
  LABEL_POSITION_TOP,
  COMMON_FAIL_MESSAGE,
  DEFAULT_PASSWORD,
} from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";

// actions
import {
  handleAddTeamMemberModal,
  fetchTeamClientEntrantCount,
  updateTeamMember,
  createTeamMember,
  fetchTeamData,
  addEntrantPoints,
} from "../actions";

// files
import cross from "../../../assets/img/cross.png";

const AddTeamMember = (props) => {
  const {
    session,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    teamMemberAddModal,
    toEdit,
    initialValues,
    teamDetails,
  } = props;
  const { addToast } = useToasts();
  const [password, setPassword] = useState(DEFAULT_PASSWORD);
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    if (toEdit) {
      setPassword(initialValues?.password);
    }
  }, [toEdit]);

  const closeModal = () => {
    props.handleAddTeamMemberModal({ showModal: false });
    setShowNewPassword(false);
  };

  const handlePassword = () => {
    const min = 100000;
    const max = 999999;
    const random = Math.floor(min + Math.random() * (max - min));
    var password = random;

    props.autofill("setpassword", password);
    setPassword(password);
    setShowNewPassword(true);
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    if (toEdit) {
      // Update Team Member
      let data = {
        id: values.id,
        dfid: values.dfid,
        firstname: values.firstname,
        lastname: values.lastname,
        gender: values.gender,
        ismanager: values.ismanager,
        ...(values?.setpassword
          ? {
              setpassword: values.setpassword,
              old_password: values.password,
              password: values.setpassword
                ? values.setpassword
                : values.password,
            }
          : {}),
      };

      props.updateTeamMember(session, data, (response) => {
        setLoading(false);
        if (response.success === 0) {
          addToast(LocaleStrings.common_fail_message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(LocaleStrings.teammember_form_updated_success, {
            appearance: "success",
            autoDismiss: true,
          });
          props.finishOperationsCallback();
          closeModal();
        }
      });
    } else {
      // Add Team Member
      var user = (values.firstname + values.lastname)
        .split(" ")
        .join("")
        .toLowerCase();
      values = {
        ...values,
        username: user,
        password: values.setpassword ? values.setpassword : password,
        membertype: "student",
        ismanager: 0,
        teamid: session.teamid,
      };

      props.fetchTeamClientEntrantCount(session, (res) => {
        if (
          teamDetails?.data?.eventclient_by_eventclientidfk?.maxteammember >
            res?.data?.teamentrantcount &&
          teamDetails?.data?.eventclient_by_eventclientidfk?.totalmembers >
            res?.data?.cliententrantcount
        ) {
          props.createTeamMember(session, values, (response) => {
            setLoading(false);
            if (response.success === 0) {
              addToast(LocaleStrings.common_fail_message, {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              let userid = response?.data?.content?.resource[0]?.id;
              props.addEntrantPoints(session, userid, (res) => {
                if (res.success === 0) {
                  addToast(
                    LocaleStrings.teammember_form_create_success_points_not_updated,
                    {
                      appearance: "success",
                      autoDismiss: true,
                    }
                  );
                  props.fetchTeamClientEntrantCount(session);
                  props.finishOperationsCallback();
                  closeModal();
                } else {
                  addToast(LocaleStrings.teammember_form_create_success, {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  props.fetchTeamClientEntrantCount(session);
                  props.finishOperationsCallback();
                  closeModal();
                }
              });
            }
          });
        } else {
          props.fetchTeamData(session);
          addToast(LocaleStrings.teammember_form_validation_team_full, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    }
  };

  var genderOptions = [
    { text: LocaleStrings.male, value: "male" },
    { text: LocaleStrings.female, value: "female" },
    { text: LocaleStrings.other, value: "others" },
  ];

  return (
    <>
      {teamMemberAddModal?.showModal ? (
        <>
          <div className="modal">
            <div className="relative w-80	md:w-2/6 my-6 mx-2">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start">
                    <h3 className="text-base font-medium">
                      {toEdit
                        ? LocaleStrings.teammember_edit_title
                        : LocaleStrings.teammember_add_title}
                    </h3>
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  {/*body*/}
                  <div className="modal-body">
                    <h4 className="mb-4">
                      {toEdit
                        ? LocaleStrings.teammember_edit_section_change_name
                        : LocaleStrings.teammember_add_section_change_name}
                    </h4>
                    <div className="pl-5">
                      {toEdit ? (
                        <div className="form-group row">
                          <label className="control-label-top col-sm-12">
                            {LocaleStrings.teammember_form_label_uname}:{" "}
                            <b>{initialValues.username}</b>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      <Field
                        name="firstname"
                        label={LocaleStrings.teammember_form_label_fname}
                        placeholder={LocaleStrings.teammember_form_ph_fname}
                        type="text"
                        mandatory="true"
                        component={renderFieldText}
                        labelposition={LABEL_POSITION_TOP}
                      />
                      <Field
                        name="lastname"
                        label={LocaleStrings.teammember_form_label_lname}
                        placeholder={LocaleStrings.teammember_form_ph_lname}
                        type="text"
                        mandatory="true"
                        maxlength="1"
                        component={renderFieldText}
                        labelposition={LABEL_POSITION_TOP}
                      />
                      <Field
                        name="gender"
                        label={LocaleStrings.teammember_form_label_gender}
                        placeholder={LocaleStrings.teammember_form_ph_gender}
                        mandatory="true"
                        component={renderSelect}
                        labelposition={LABEL_POSITION_TOP}
                        opts={genderOptions}
                      />
                    </div>

                    <h4 className="mb-4">
                      {toEdit
                        ? LocaleStrings.teammember_edit_section_set_password
                        : LocaleStrings.teammember_add_section_set_password}
                    </h4>
                    <div className="pl-5">
                      <div className="form-group">
                        <label className="control-label-top">
                          {LocaleStrings.teammember_form_label_password}
                        </label>
                        <div className="flex items-center justify-between">
                          <h4>{showNewPassword && <>{password}</>}</h4>
                          <button
                            type="button"
                            className="btn-primary-md"
                            onClick={handlePassword}
                          >
                            <span className="text-base">
                              {LocaleStrings.button_password_generate}
                            </span>
                          </button>
                        </div>
                      </div>
                      <Field
                        name="setpassword"
                        type="text"
                        component={renderHiddenFieldText}
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="modal-footer">
                    <button
                      className="btn-secondary-md mr-2"
                      onClick={() => closeModal()}
                    >
                      <span className="text-base">
                        {LocaleStrings.button_close}
                      </span>
                    </button>
                    <button
                      type="submit"
                      className={classnames(
                        pristine || invalid || submitting || loading
                          ? "btn-primary-disabled-md"
                          : "btn-primary-md"
                      )}
                      disabled={pristine || invalid || submitting || loading}
                    >
                      <span className="text-base">
                        {loading
                          ? LocaleStrings.button_saving
                          : LocaleStrings.button_save}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var firstname = values["firstname"];
  var lastname = values["lastname"];
  var gender = values["gender"];

  if (!firstname || firstname.trim() === "") {
    errors["firstname"] = LocaleStrings.requird;
  }
  if (!lastname || lastname.trim() === "") {
    errors["lastname"] = LocaleStrings.requird;
  }
  if (lastname && lastname.length > 1) {
    errors["lastname"] =
      LocaleStrings.teammember_form_validation_lastname_max_length;
  }
  if (!gender || gender.trim() === "") {
    errors["gender"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.teamMemberAddModal?.value);
  var initVals = {};

  if (toEdit) {
    initVals = state?.teamMemberAddModal?.value;
    initVals.lastname = state?.teamMemberAddModal?.value?.lastname.charAt(0);
  }

  return {
    session: state.session,
    teamMemberAddModal: state.teamMemberAddModal,
    initialValues: initVals,
    toEdit,
    teamDetails: state.teamDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleAddTeamMemberModal,
      fetchTeamClientEntrantCount,
      updateTeamMember,
      createTeamMember,
      fetchTeamData,
      addEntrantPoints,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "TeamMemberForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddTeamMember)
);
