import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Field, FieldArray, reduxForm } from "redux-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// constants
import LocaleStrings from "../../../languages";
import { DATE_FORMAT } from "../../../components/Common/constant";

// actions
import {
  showViewDataModal,
  fetchActivityList,
  fetchBonusPointsList,
} from "../actions";

// files
import cross from "../../../assets/img/cross.png";
import info_white from "../../../assets/img/info-white.svg";
import glass_of_water from "../../../assets/img/glass-of-water.png";
import grapes from "../../../assets/img/fruits-and-vegetables-fruit-food-grapes.png";
import carrot from "../../../assets/img/fruits-and-vegetables-vegetable-food-carrot.png";
import burger_cola from "../../../assets/img/fastfood-burger-cola.png";
import food_spoon_jar from "../../../assets/img/kids-baby-food-spoon-jar.png";
import halloween from "../../../assets/img/halloween-moon-clouds-night.png";
import computers_phone from "../../../assets/img/computers-and-hardware-screen-pc-smart-phone.png";
import brain from "../../../assets/img/brain.png";

const renderField = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  meta: { touched, error },
}) => (
  <div>
    <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
      {label}
    </label>
    <div className="mt-1">
      <input
        className="enter-data-steps-input cursor-not-allowed"
        {...input}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
      />
      {touched && error && (
        <span className="text-sm text-red-500">{error}</span>
      )}
    </div>
  </div>
);

const SLIDE_ARR_INIT = [
  {
    key: "water",
    colorstopval: 0,
    colorstopsecondarycolor: "#7EE076",
    value: -1,
    unit: "Cups",
  },
  {
    key: "fruit",
    colorstopval: 0,
    colorstopsecondarycolor: "#FF8327",
    value: -1,
    unit: "",
  },
  {
    key: "veges",
    colorstopval: 0,
    colorstopsecondarycolor: "#FF4A4A",
    value: -1,
    unit: "",
  },
  // {
  //   key: "foods",
  //   colorstopval: 0,
  //   colorstopsecondarycolor: "#7EE076",
  //   value: -1,
  //   unit: "",
  // },
  {
    key: "sugar",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD8326",
    value: -1,
    unit: "Tsp.",
  },
  {
    key: "sleep",
    colorstopval: 0,
    colorstopsecondarycolor: "#7EE076",
    value: 0,
    unit: "h",
  },
  {
    key: "screentime",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD4A4A",
    value: 2,
    unit: "",
  },
  {
    key: "mindfulness",
    colorstopval: 0,
    colorstopsecondarycolor: "#FD8326",
    value: -1,
    unit: "",
  },
];

const ViewData = (props) => {
  const {
    // state
    showModal,
    session,
    handleSubmit,
    reset,

    // action
    showViewDataModal,
    fetchActivityList,
    fetchBonusPointsList,
  } = props;

  const intensityOpts = [
    { value: 1100, name: "High" },
    { value: 1020, name: "Medium" },
    { value: 1001, name: "Low" },
  ];
  const durationOpts = [
    { value: 10, name: "10 mins" },
    { value: 20, name: "20 mins" },
    { value: 30, name: "30 mins" },
    { value: 40, name: "40 mins" },
    { value: 50, name: "50 mins" },
    { value: 60, name: "60 mins" },
    { value: 70, name: "70 mins" },
    { value: 80, name: "80 mins" },
    { value: 90, name: "90 mins" },
  ];

  const [activityOpts, setActivityOpts] = useState([]);
  const [sliderArr, setSliderArr] = useState(SLIDE_ARR_INIT);
  const [bonusPointsArr, setBonusPointsArr] = useState([]);

  useEffect(() => {
    fetchActivityList(session, (res) => {
      if (res.success) {
        let arr = [];
        _.map(res.data, (item) => {
          arr.push({ value: item.id, name: item.name });
        });

        setActivityOpts(arr);
      }
    });

    fetchBonusPointsList(session, (res) => {
      if (res.success) {
        let uniqArr = _.uniqBy(res.data, "bonustype");
        let uniqBonusTypes = _.map(uniqArr, "bonustype");
        let filter = [];
        let sorted = [];
        let length = 0;

        // --- for water ---
        let objWater = {};
        filter = _.filter(res.data, { bonustype: "water" });
        sorted = _.sortBy(filter, ["quantity"]);
        length = sorted.length;
        objWater.data = sorted;
        objWater.min = parseInt(showModal.data?.item?.waterstartrange);
        objWater.defaultvalue = parseInt(
          showModal.data?.item?.waterselectedvalue
        );
        objWater.max = parseInt(showModal.data?.item?.waterendrange);
        objWater.step = 1;
        objWater.value = parseInt(showModal.data?.item?.waterselectedvalue);

        // --- for fruit ---
        let objFruit = {};
        filter = _.filter(res.data, { bonustype: "fruit" });
        sorted = _.sortBy(filter, ["quantity"]);
        length = sorted.length;
        objFruit.data = sorted;
        objFruit.min = parseInt(showModal.data?.item?.fruitstartrange);
        objFruit.defaultvalue = parseInt(
          showModal.data?.item?.fruitselectedvalue
        );
        objFruit.max = parseInt(showModal.data?.item?.fruitendrange);
        objFruit.step = 1;
        objFruit.value = parseInt(showModal.data?.item?.fruitselectedvalue);

        // --- for veges ---
        let objVeges = {};
        filter = _.filter(res.data, { bonustype: "veges" });
        sorted = _.sortBy(filter, ["quantity"]);
        length = sorted.length;
        objVeges.data = sorted;
        objVeges.min = parseInt(showModal.data?.item?.vegesstartrange);
        objVeges.defaultvalue = parseInt(
          showModal.data?.item?.vegesselectedvalue
        );
        objVeges.max = parseInt(showModal.data?.item?.vegesendrange);
        objVeges.step = 1;
        objVeges.value = parseInt(showModal.data?.item?.vegesselectedvalue);

        // --- for foods ---
        // let objFoods = {};
        // filter = _.filter(res.data, { bonustype: "foods" });
        // sorted = _.sortBy(filter, ["quantity"]);
        // length = sorted.length;
        // objFoods.data = sorted;
        // objFoods.min = parseInt(showModal.data?.item?.foodsstartrange);
        // objFoods.defaultvalue = parseInt(
        //   showModal.data?.item?.foodsselectedvalue
        // );
        // objFoods.max = parseInt(showModal.data?.item?.foodsendrange);
        // objFoods.step = 1;
        // objFoods.value = parseInt(showModal.data?.item?.foodsselectedvalue);

        // --- for sugar ---
        let objSugar = {};
        filter = _.filter(res.data, { bonustype: "sugar" });
        sorted = _.sortBy(filter, ["quantity"]);
        length = sorted.length;
        objSugar.data = sorted;
        objSugar.min = parseInt(showModal.data?.item?.sugarstartrange);
        objSugar.defaultvalue = parseInt(
          showModal.data?.item?.sugarselectedvalue
        );
        objSugar.max = parseInt(showModal.data?.item?.sugarendrange);
        objSugar.step = 1;
        objSugar.value = parseInt(showModal.data?.item?.sugarselectedvalue);

        // --- for sleep ---
        let objSleep = {};
        filter = _.filter(res.data, { bonustype: "sleep" });
        sorted = _.sortBy(filter, ["starttime"]);
        length = sorted.length;
        objSleep.data = sorted;
        objSleep.min = parseInt(showModal.data?.item?.sleepstartrange);
        objSleep.defaultvalue = parseInt(
          showModal.data?.item?.sleepselectedvalue
        );
        objSleep.max = parseInt(showModal.data?.item?.sleependrange);
        objSleep.step = 1;
        objSleep.value = parseInt(showModal.data?.item?.sleepselectedvalue);

        // --- for screentime ---
        let objScreentime = {};
        filter = _.filter(res.data, { bonustype: "screentime" });
        sorted = _.sortBy(filter, ["starttime"]);
        length = sorted.length;
        objScreentime.data = sorted;
        objScreentime.min = parseInt(
          showModal.data?.item?.screentimestartrange
        );
        objScreentime.defaultvalue = parseInt(
          showModal.data?.item?.screentimeselectedvalue
        );
        objScreentime.max = parseInt(showModal.data?.item?.screentimeendrange);
        objScreentime.step = 1;
        objScreentime.value = parseInt(
          showModal.data?.item?.screentimeselectedvalue
        );

        // --- for mindfulness ---
        let objMindfulness = {};
        filter = _.filter(res.data, { bonustype: "mindfulness" });
        sorted = _.sortBy(filter, ["quantity"]);
        length = sorted.length;
        objMindfulness.data = sorted;
        objMindfulness.min = parseInt(
          showModal.data?.item?.mindfulnessstartrange
        );
        objMindfulness.defaultvalue = parseInt(
          showModal.data?.item?.mindfulnessselectedvalue
        );
        objMindfulness.max = parseInt(
          showModal.data?.item?.mindfulnessendrange
        );
        objMindfulness.step = 1;
        objMindfulness.value = parseInt(
          showModal.data?.item?.mindfulnessselectedvalue
        );

        // setting the dynamic sliders
        let bonusArr = [];
        _.map(uniqBonusTypes, (type) => {
          if (type == "water") {
            bonusArr.push({
              name: "water",
              img: glass_of_water,
              thumb: "glass-of-water-slider-thumb-with-bg.png",
              qty: "6 Cups",
              unit: "of Water",
              data: objWater,
            });
          } else if (type == "fruit") {
            bonusArr.push({
              name: "fruit",
              img: grapes,
              thumb: "fruits-and-vegetables-fruit-food-grapes-slider-thumb.png",
              qty: "6",
              unit: "Fruit",
              data: objFruit,
            });
          } else if (type == "veges") {
            bonusArr.push({
              name: "veges",
              img: carrot,
              thumb:
                "fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png",
              qty: "1",
              unit: "Vegetables",
              data: objVeges,
            });
          } else if (type == "foods") {
            // bonusArr.push({
            //   name: "foods",
            //   img: burger_cola,
            //   thumb: "fastfood-burger-cola.png",
            //   qty: "6",
            //   unit: "Snacks",
            //   data: objFoods,
            // });
          } else if (type == "sugar") {
            bonusArr.push({
              name: "sugar",
              img: food_spoon_jar,
              thumb: "kids-baby-food-spoon-jar-slider-thumb.png",
              qty: "3 Tsp.",
              unit: "of Sugar",
              data: objSugar,
            });
          } else if (type == "sleep") {
            bonusArr.push({
              name: "sleep",
              img: halloween,
              thumb: "halloween-moon-clouds-night-slider-thumb.png",
              qty: "8h 53m",
              unit: "Sleep Time",
              data: objSleep,
            });
          } else if (type == "screentime") {
            bonusArr.push({
              name: "screentime",
              img: computers_phone,
              thumb:
                "computers-and-hardware-screen-pc-smart-phone-slider-thumb.png",
              qty: "2h 30m",
              unit: "Screen Time",
              data: objScreentime,
            });
          } else if (type == "mindfulness") {
            bonusArr.push({
              name: "mindfulness",
              img: brain,
              thumb: "brain-slider-thumb.png",
              qty: "1-10",
              unit: "Mindfulness",
              data: objMindfulness,
            });
          }
        });
        setBonusPointsArr(bonusArr);
      }
    });
  }, []);

  const closeModal = () => {
    showViewDataModal({ show: false, data: null });
    setSliderArr(SLIDE_ARR_INIT);
    reset();
  };

  const renderSelect = (field) => {
    return (
      <div className={field.margin ? field.margin : ""}>
        <div className="">
          <select
            className="enter-data-activity-select cursor-not-allowed"
            {...field.input}
            style={{ width: field.width }}
            disabled={field.disabled}
          >
            <option value="" key={field.label}>
              {field.placeholder}
            </option>
            {field.opts.map((object, index) => {
              return (
                <option key={`${object.value}-${index}`} value={object.value}>
                  {object.name}
                </option>
              );
            })}
          </select>
          <div className="text-sm text-red-500">
            {field.meta.touched ? field.meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const renderActivity = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => {
    return (
      <ul>
        {fields.map((activity, index) => (
          <li key={index} className={index == 0 ? "mt-4" : "mt-1"}>
            <label className="block text-sm text-secondary-4">
              {LocaleStrings.activity_text}#{index + 1}
            </label>

            <div className="mt-1 flex items-center">
              <Field
                name={`${activity}.name`}
                label={`${LocaleStrings.activity_text} ${LocaleStrings.name}`}
                placeholder={LocaleStrings.activity_text}
                component={renderSelect}
                opts={activityOpts}
                width={142}
                disabled={true}
              />
              <Field
                name={`${activity}.intensity`}
                label={`${LocaleStrings.activity_text} ${LocaleStrings.activity_intencity}`}
                placeholder={LocaleStrings.activity_intencity}
                component={renderSelect}
                opts={intensityOpts}
                width={84}
                margin="ml-2"
                disabled={true}
              />
              <Field
                name={`${activity}.duration`}
                label={`${LocaleStrings.activity_text} ${LocaleStrings.activity_duration}`}
                placeholder={LocaleStrings.activity_duration}
                component={renderSelect}
                opts={durationOpts}
                width={81}
                margin="ml-2"
                disabled={true}
              />
            </div>
          </li>
        ))}

        {submitFailed && error && (
          <span className="text-sm text-red-500">{error}</span>
        )}
      </ul>
    );
  };

  const renderDatePickerWithLabel = (field) => {
    var {
      input,
      selected,
      disabled,
      label,
      labelposition,
      meta,
      maxDate,
      minDate,
      placeholder,
    } = field;

    var value = input.value
      ? moment(input.value).format(DATE_FORMAT)
      : selected
      ? moment(selected).format(DATE_FORMAT)
      : null;

    return (
      <div>
        {label !== "" ? (
          <label className="block text-sm text-secondary-4 sm:mt-px sm:pt-2">
            {label}
          </label>
        ) : null}

        <div className="">
          <DatePicker
            className={
              disabled
                ? "cursor-not-allowed enter-data-steps-input"
                : "enter-data-steps-input"
            }
            name={input.name}
            placeholderText={placeholder}
            {...input}
            value={value}
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
          />
          <div className="text-sm text-red-500">
            {meta.touched ? meta.error : ""}
          </div>
        </div>
      </div>
    );
  };

  const onSubmitForm = (values, dispatch) => {
    // values.date = moment(values.date).format(DATE_FORMAT);
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container">
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <div className="flex justify-start items-center">
                    <h3 className="text-base font-medium">
                      {moment(showModal.data?.item?.date).format(
                        "DD MMM YYYY"
                      ) === moment().format("DD MMM YYYY")
                        ? `Today, ${showModal.data?.name}`
                        : `${moment(showModal.data?.item?.date).format(
                            "DD MMM YYYY"
                          )}, ${
                            showModal.data.callfrom == "classentry"
                              ? LocaleStrings.data_entered_by_teacher
                              : showModal.data?.name
                          }`}
                    </h3>

                    {showModal.data?.item?.maxallocated ? (
                      <span className="font-bold text-red-500 text-xs pl-3">
                        {LocaleStrings.max_data_cap}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="modal-close-btn"
                    onClick={() => closeModal()}
                  >
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <div className="enter-data-modal-body relative flex-auto">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2">
                      <div className="sm:border-0 md:border-r">
                        <div className="points-entry-container">
                          <div>{LocaleStrings.activity_points_entry}</div>
                          <div>
                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                              style={{ width: 300 }}
                              className="myPopup"
                            >
                              <div
                                className="w-full text-sm"
                                style={{
                                  maxHeight: 200,
                                  overflow: "auto",
                                }}
                              >
                                <p className="px-3 pb-2">
                                  <span>{LocaleStrings.activity_desc}</span>
                                </p>
                                <p className="px-3 pb-2">
                                  <span>
                                    {LocaleStrings.activity_desc_intencity_high}
                                  </span>
                                </p>
                                <p className="px-3 pb-2">
                                  <span>
                                    {
                                      LocaleStrings.activity_desc_intencity_medium
                                    }
                                  </span>
                                </p>
                                <p className="px-3">
                                  <span>
                                    {LocaleStrings.activity_desc_intencity_low}
                                  </span>
                                </p>
                              </div>
                            </Popup>
                          </div>
                        </div>

                        <div className="p-4">
                          <Field
                            name="date"
                            label={LocaleStrings.date}
                            placeholder={`${LocaleStrings.enter} ${LocaleStrings.date}`}
                            component={renderDatePickerWithLabel}
                            disabled={true}
                          />

                          <Field
                            name="steps"
                            type="number"
                            component={renderField}
                            label={LocaleStrings.steps}
                            placeholder="Enter Steps"
                            disabled={true}
                          />

                          <FieldArray
                            name="activity"
                            component={renderActivity}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="points-entry-container">
                          <div>{LocaleStrings.bonus_points_entry}</div>
                          <div>
                            <Popup
                              trigger={
                                <img
                                  className="cursor-pointer"
                                  src={info_white}
                                />
                              }
                              position="left center"
                              on="hover"
                              style={{ width: 300 }}
                              className="myPopup"
                            >
                              <div
                                className="w-full text-sm"
                                style={{
                                  maxHeight: 200,
                                  overflow: "auto",
                                }}
                              >
                                <p className="pb-1">
                                  <b>{LocaleStrings.bonus_fruit}:</b>
                                  <span>{LocaleStrings.bonus_fruit_desc}</span>
                                </p>
                                <p className="pb-1">
                                  <b>{LocaleStrings.bonus_water}:</b>
                                  <span>{LocaleStrings.bonus_water_desc}</span>
                                </p>
                                <p className="pb-1">
                                  <b>{LocaleStrings.bonus_veges}:</b>
                                  <span>{LocaleStrings.bonus_veges_desc}</span>
                                </p>
                                {/* <p className="pb-1">
                                  <b>{LocaleStrings.bonus_snacks}:</b>
                                  <span>{LocaleStrings.bonus_snacks_desc}</span>
                                </p> */}
                                <p className="pb-1">
                                  <b>{LocaleStrings.bonus_mindfulness}:</b>
                                  <span>
                                    {LocaleStrings.bonus_mindfulness_desc}
                                  </span>
                                </p>
                                <p className="pb-1">
                                  <b>{LocaleStrings.bonus_sleep}:</b>
                                  <span>{LocaleStrings.bonus_sleep_desc}</span>
                                </p>
                                <p>
                                  <b>{LocaleStrings.bonus_screen}:</b>
                                  <span>{LocaleStrings.bonus_screen_desc}</span>
                                </p>
                              </div>
                            </Popup>
                          </div>
                        </div>

                        {_.map(bonusPointsArr, (item, i) => {
                          let filter = _.filter(sliderArr, function (o) {
                            return o.key == item.name;
                          });

                          return (
                            <div className="p-4 border-b flex items-center">
                              <div className="" style={{ width: 21 }}>
                                <img src={item.img} />
                              </div>
                              <div className="ml-4">
                                <div>
                                  <input
                                    className={"slider-" + item.name}
                                    type="range"
                                    min={item.data.min}
                                    max={item.data.max}
                                    step={item.data.step}
                                    value={item.data.value}
                                    style={
                                      filter.length > 0
                                        ? {
                                            backgroundImage: `-webkit-gradient(linear, left top, right top, color-stop(${
                                              (item.data.value -
                                                item.data.min) /
                                              (item.data.max - item.data.min)
                                            }, ${
                                              filter[0].colorstopsecondarycolor
                                            }), color-stop(${
                                              (item.data.value -
                                                item.data.min) /
                                              (item.data.max - item.data.min)
                                            }, #e6e8f2))`,
                                          }
                                        : {}
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="ml-4 flex justify-end"
                                style={{ width: 52 }}
                              >
                                <div>
                                  <div className="text-right text-sm font-medium">
                                    {filter.length > 0 ? (
                                      <div className="text-right text-sm font-medium">
                                        {filter[0].key == "screentime"
                                          ? (item.data.value % 60) + "h"
                                          : item.data.value == -1
                                          ? `0 ${filter[0].unit}`
                                          : `${item.data.value} ${filter[0].unit}`}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="text-right"
                                    style={{ fontSize: 8 }}
                                  >
                                    {item.unit}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const validate = (values) => {
  var errors = {};
  return errors;
};

const mapStateToProps = (state) => {
  let activityArr = [];
  _.map(
    state.showViewDataModalReducer.data?.item?.memberliveactivity,
    (item) => {
      activityArr.push({
        name: parseInt(item.activityidfk),
        intensity:
          item.intensity == "high"
            ? 1100
            : item.intensity == "medium"
            ? 1020
            : item.intensity == "low"
            ? 1001
            : "",
        duration: parseInt(item.activitytime),
      });
    }
  );

  var initial = {
    date: moment(state.showViewDataModalReducer.data?.item?.date).format(
      DATE_FORMAT
    ),
    steps: state.showViewDataModalReducer.data?.item?.steps,
    activity: JSON.parse(JSON.stringify(activityArr)),
  };

  return {
    deviceSize: state.deviceSize,
    showModal: state.showViewDataModalReducer,
    session: state.session,
    activityList: state.activityListReducer,
    bonusPointsList: state.bonusPointsListReducer,
    initialValues: initial,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showViewDataModal,
      fetchActivityList,
      fetchBonusPointsList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ViewDataForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ViewData)
);
