import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fireworks, useFireworks } from "fireworks-js/dist/react";

const Firework = (props) => {
  const { setEnabled, setOptions, enabled, options } = useFireworks({
    initialStart: true,
    initialOptions: {
      hue: {
        min: 0,
        max: 345,
      },
      delay: {
        min: 4,
        max: 4,
      },
      rocketsPoint: 50,
      speed: 10,
      acceleration: 1.2,
      friction: 0.96,
      gravity: 1,
      particles: 90,
      trace: 3,
      explosion: 6,
      autoresize: true,
      brightness: {
        min: 50,
        max: 80,
        decay: {
          min: 0.015,
          max: 0.03,
        },
      },
      boundaries: {
        visible: false,
      },
      sound: {
        enabled: true,
        files: [
          "https://fireworks.js.org/sounds/explosion0.mp3",
          "https://fireworks.js.org/sounds/explosion1.mp3",
          "https://fireworks.js.org/sounds/explosion2.mp3",
        ],
        volume: {
          min: 1,
          max: 2,
        },
      },
      mouse: {
        click: true,
        move: false,
        max: 1,
      },
    },
  });
  const fireworkstyle = {
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
  };
  return (
    <>
      <Fireworks style={fireworkstyle} enabled={enabled} options={options}>
        <div
          style={{
            gap: "6px",
            padding: "6px",
            display: "flex",
            background: "#607d8b",
          }}
        ></div>
      </Fireworks>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Firework);
