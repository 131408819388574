import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

// constants
import LocaleStrings from "../../../languages";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
} from "../../../components/Common/constant";

// actions
import { showCourseDetailsModal } from "../actions";

// files
import cross from "../../../assets/img/cross.png";
import NoImgIcon from "../../../assets/placeholders/NoImg.svg";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseDetails = (props) => {
  const { showModal, showCourseDetailsModal, deviceSize } = props;

  const [value, setValue] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    let courseDetailsImages = [];

    if (!_.isEmpty(showModal.item?.locationimage)) {
      let imageArr = _.split(showModal.item?.locationimage, ",");

      _.map(imageArr, (galImg, galImgKey) => {
        courseDetailsImages.push(
          <div key={`gal_img_${galImgKey}`}>
            <img
              className="object-cover rounded-0 sm:rounded-bl-lg w-full"
              src={`${BASE_AZIONV2_URL}/${galImg}?api_key=${FILE_ACCESS_KEY}`}
              style={{ height: deviceSize.width > 640 ? 425 : 235 }}
              alt={`gal_img_${galImgKey}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImgIcon;
              }}
            />
          </div>
        );
      });
    }
    setSlides(courseDetailsImages);
  }, []);

  const closeModal = () => {
    showCourseDetailsModal({ show: false, item: null });
  };

  const onchange = (value) => {
    setValue(value);
  };

  return (
    <>
      {showModal && showModal.show ? (
        <>
          <div className="modal">
            <div className="modal-container" style={{ maxWidth: 1088 }}>
              {/*content*/}
              <div className="modal-content">
                {/*header*/}
                <div className="modal-header">
                  <h3 className="text-base font-medium">
                    {LocaleStrings.view_course_details}
                  </h3>
                  <button className="modal-close-btn" onClick={closeModal}>
                    <img src={cross} />
                  </button>
                </div>

                {/*body*/}
                <div className="relative flex-auto rounded-b">
                  <div
                    className={`grid grid-cols-1 sm:grid-cols-${
                      slides.length ? "2" : "1"
                    }`}
                  >
                    <div
                      className={`course-details-carousel-container ${
                        slides.length ? "flex" : "hidden"
                      } items-center justify-center relative`}
                    >
                      {slides.length === 1 ? (
                        slides[0]
                      ) : (
                        <>
                          <Carousel
                            value={value}
                            slides={slides}
                            onChange={onchange}
                          />
                          <Dots
                            value={value}
                            onChange={onchange}
                            number={slides.length}
                          />
                        </>
                      )}
                      {/* --------------- END * Custom Dot Carousel --------------- */}
                    </div>

                    <div className="p-5">
                      {showModal.item?.hotzonename && (
                        <div
                          className="text-xl text-primary-black font-medium"
                          onClick={() => onchange(value + 1)}
                        >
                          {showModal.item?.hotzonename?.capitalize()}
                        </div>
                      )}
                      <div className="mt-7.5">
                        <div
                          className="mt-2 text-sm text-primary-black overflow-auto course-details-overview"
                          style={{ height: 295 }}
                          dangerouslySetInnerHTML={{
                            __html: showModal.item?.hotzonedetails,
                          }}
                        >
                          {/* {showModal.item?.hotzonedetails} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-background"></div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceSize: state.deviceSize,
    showModal: state.showCourseDetailsModalReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ showCourseDetailsModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
