import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { isAfter, isBefore } from "date-fns";
import { toPng } from "html-to-image";

// Constants
import Loader from "../../components/Common/loader";
import {
  FILE_ACCESS_KEY,
  BASE_AZIONV2_URL,
} from "../../components/Common/constant";
import LocaleStrings from "../../languages";
import { downloadFileFromDataURL } from "../../utils/common-utils";
import {
  loadLocalStorage,
  saveInLocalStorage,
} from "../../reducers/localStorage";
import {
  APP_LOCALSTORAGE_KEYS,
  APP_POPUP_INIT,
  APP_POPUP_TYPES,
} from "../../constants/types-constant";

// Actions
import {
  fetchHomeScreenData,
  showMemberModal,
  setHomeData,
  setHomeMarker,
  setHomeMap,
  isLastLegShow,
  isFirework,
  lastLegAlreadyShown,
  fetchLegCompleteLog,
  setLegCompleteLog,
  showLegVideoModal,
} from "./actions";
import { showEnterDataModal, fetchEventClientLegs } from "../mydata/actions";
import { isBonusLegShow } from "../coursevideo/actions";
import { fetchAvatarDBData } from "../avatarBuilder/actions";
import { fetchCourseVideo } from "../coursevideo/actions";
import { chartWiseData } from "../mydata/actions";

// Components
import Member from "./components/member";
import Timer from "./components/timer";
import AvatarBuilder from "../avatarBuilder";
import MyAvatar from "../avatarBuilder/MyAvatar";
import AvatarFace from "../avatarBuilder/MyAvatarFace";
import LegVideo from "./components/leg-video";
import FinishLeg from "./components/finish-leg";
import Fireworks from "./components/fireworks";
import Certificate from "./components/certificate";

// Files
import locationstar from "../../assets/img/locationstar.png";
import watch_video from "../../assets/img/WatchVideo.png";
import markerRed from "../../assets/img/marker_red.png";
import downloadIcon from "../../assets/img/download-line.png";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const Home = (props) => {
  const {
    deviceSize,
    fetchHomeScreenData,
    session,
    showMemberModal,
    homeScreenData,
    fetchAvatarDBData,
    showMemberModalReducer,
    fetchCourseVideo,
    fetchEventClientLegs,
    homeData,
    showLegVideo,
    eventDetails,
    homeMapList,
    homeMarkerIndex,
    showLastLeg,
    showFirework,
    lastLegShown,
  } = props;

  const history = useHistory();
  const avatarRef = useRef();
  const [loading, setLoading] = useState(true);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [legno, setLegno] = useState("");
  const [legname, setLegname] = useState("");
  const [teamavgtotal, setTeamavgtotal] = useState("");
  const [teamavgdaily, setTeamavgdaily] = useState("");
  const [pointsreqtofinish, setPointsreqtofinish] = useState(0);
  const [percentageComplete, setPercentageComplete] = useState(0);
  const [legmappath, setLegmappath] = useState("");
  const [isFireworkStart, setIsFireworkStart] = useState(false);
  const [courseComplete, setCourseComplete] = useState(false);

  var challengeStartDate =
    eventDetails?.resource?.eventsettings_by_eventidfk[0]
      ?.challengecoursestartdate;
  var thisDay = moment();

  useEffect(() => {
    fetchHomeScreenData(session, {}, (res) => {
      if (res.data) {
        let userLegId = JSON.parse(res.data?.currentlegid);
        let starttime = new Date(res.data.challengecoursestartdate);
        let endtime = new Date(res.data.challengecourseclosedate);
        let currentDate = new Date();

        // Leg count based popups
        const teamCurrentlyAt =
          typeof res?.data?.legno === "string"
            ? parseInt(res?.data?.legno)
            : res?.data?.legno;
        const userKnowsTeamAt = homeData?.legno
          ? typeof homeData?.legno === "string"
            ? parseInt(homeData?.legno)
            : homeData?.legno
          : 0;

        const eventStandardLegCount = parseInt(
          eventDetails?.resource?.nooflegs
        );
        const maxOrderLeg = _.maxBy(res.data.eventclientlegs, (o) => o.order);
        const eventLegCount = parseInt(maxOrderLeg?.order);

        if (
          teamCurrentlyAt === eventLegCount && // current leg is last event leg
          res.data.pointsreqtofinish <= 0 // no remaining points
        ) {
          setCourseComplete(true);
        }

        if (_.isEmpty(homeData)) {
          makeAllData(res.data, 1);
        } else {
          if (userKnowsTeamAt === teamCurrentlyAt) {
            // Checking if final leg has been shown
            if (
              teamCurrentlyAt === eventLegCount && // current leg is last event leg
              res.data.pointsreqtofinish <= 0 // no remaining points
            ) {
              if (lastLegShown) {
                makeAllData(res.data, 1);
              } else {
                makeAllData(homeData, 2);

                if (!isBefore(currentDate, starttime)) {
                  setTimeout(() => {
                    makeConfirmationLast(res.data);
                  }, 1000);
                }

                confirmAlert({
                  title: LocaleStrings.congratulation_on_complete_course,
                  message: LocaleStrings.congratulation_on_complete_course_desc,
                  buttons: [
                    {
                      label: LocaleStrings.ok,
                      onClick: () => {},
                    },
                  ],
                });
              }
            } else {
              makeAllData(res.data, 4);
            }
          } else {
            makeAllData(homeData, 2);
            if (!isBefore(currentDate, starttime)) {
              setTimeout(
                () =>
                  makeConfirmation(homeData.legname, res.data, {
                    autoDetetctVideo: true,
                    bonusStart: teamCurrentlyAt > eventStandardLegCount,
                  }),
                1000
              );
            }
          }
        }

        // action to maintain teammember leg complete log
        if (!isBefore(currentDate, starttime)) {
          props.fetchLegCompleteLog(session, userLegId, (resLeg) => {
            if (resLeg.success === 1) {
              let values = {
                eventidfk: session.eventid,
                teamidfk: session.teamid,
                legidfk: userLegId,
                teammemberidfk: session.userid,
              };

              props.setLegCompleteLog(session, values, (resSetLeg) => {});
              if (res.data.legno != "1" || res.data.legno != 1) {
                let currentOrder = parseInt(res.data.legno);
                let prevOrder = currentOrder - 1;
                let challengeArr = [];
                if (prevOrder > 0) {
                  challengeArr = _.filter(res.data.eventclientlegs, (obj) => {
                    return obj.order == currentOrder - 1;
                  });
                }
                let previousLegName =
                  challengeArr.length > 0
                    ? challengeArr[0].eventleg_by_eventlegidfk.locationtitle
                      ? challengeArr[0].eventleg_by_eventlegidfk.locationtitle
                      : ""
                    : "";

                previousLegName =
                  previousLegName != "" ? _.startCase(previousLegName) : "";

                setTimeout(() => {
                  makeConfirmation(previousLegName, res.data, {
                    autoDetetctVideo: false,
                    bonusStart: teamCurrentlyAt > eventStandardLegCount,
                  });
                }, 1000);
              }
            }
          });
        }

        if (
          isAfter(currentDate, endtime) &&
          !isBefore(currentDate, starttime) &&
          !showFirework
        ) {
          setIsFireworkStart(true);
          setTimeout(() => {
            props.isFirework(true);
            setIsFireworkStart(false);
            makeConfirmationLast(res.data);
          }, 10000);
        }
      }

      fetchAvatarDBData(session, session.userid, (resAv) => {});
      fetchCourseVideo(session, (res) => {});
      fetchEventClientLegs(session, (res) => {});
      setLoading(false);
    });
  }, []);

  const renderLegVideo = (legname = "") => {
    let legLocationName = homeData.legname ? _.toLower(homeData.legname) : "";
    if (legname !== "") legLocationName = _.toLower(legname);

    legLocationName =
      legLocationName == "new zealand"
        ? "nz"
        : legLocationName == "italy"
        ? "rome"
        : legLocationName == "new york city"
        ? "nyc"
        : legLocationName == "mozambique"
        ? "moz"
        : legLocationName;
    props.showLegVideoModal(session, true, legLocationName, (res) => {});
  };

  const ticCounter = (minimum, maximum, sec, action) => {
    if (action == "increment") {
      if (sec == "finish") {
        let diff = maximum - minimum;
        let inc = diff >= 20000 ? 200 : diff >= 10000 ? 20 : 10;
        for (let count = minimum; count <= maximum; count += inc) {
          setTimeout(() => {
            setPointsreqtofinish(count);
          }, 1000);
        }
      }

      if (sec == "percentage") {
        for (let count = minimum; count <= maximum; count++) {
          setTimeout(() => {
            setPercentageComplete(count);
          }, 1000);
        }
      }
    } else {
      if (sec == "finish") {
        let diff = maximum - minimum;
        let inc = diff >= 20000 ? 200 : diff >= 10000 ? 20 : 10;
        for (let count = maximum; count >= minimum; count -= inc) {
          setTimeout(() => {
            setPointsreqtofinish(count);
          }, 1000);
        }
      }

      if (sec == "percentage") {
        for (let count = maximum; count >= minimum; count--) {
          setTimeout(() => {
            setPercentageComplete(count);
          }, 1000);
        }
      }
    }
  };

  const makeAllData = (obj, isLeg) => {
    setLegmappath(obj.legmappath);
    setLegno(obj.legno);
    setLegname(obj.legname);
    setTeamavgtotal(Math.floor(obj.teamavgtotal));
    setTeamavgdaily(Math.floor(obj.teamavgdaily));

    if (isLeg == 2) {
      ticCounter(
        eventDetails.resource?.teamavgptreqtofinisheachlegopen,
        Math.floor(obj.pointsreqtofinish),
        "finish",
        "decreament"
      );
      ticCounter(
        Math.floor(obj.percentagecomplete),
        100,
        "percentage",
        "increment"
      );
    } else if (isLeg == 4) {
      ticCounter(
        Math.floor(obj.pointsreqtofinish),
        Math.floor(homeData.pointsreqtofinish),
        "finish",
        "decreament"
      );
      ticCounter(
        Math.floor(homeData.percentagecomplete),
        Math.floor(obj.percentagecomplete),
        "percentage",
        "increment"
      );
    } else {
      ticCounter(
        Math.floor(obj.pointsreqtofinish),
        eventDetails.resource?.teamavgptreqtofinisheachlegopen,
        "finish",
        "decreament"
      );
      ticCounter(
        0,
        Math.floor(obj.percentagecomplete),
        "percentage",
        "increment"
      );
    }

    let percentageDiff = 1;
    let markerIndex = 0;
    let arr = [{ x: 0, y: 0 }];
    if (obj.legmappoints) {
      let pointsArr = JSON.parse(obj.legmappoints);

      arr = pointsArr.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.x === item.x && t.y === item.y)
      );

      percentageDiff = 100 / arr.length;
      var indexCalculation = Math.floor(
        obj.percentagecomplete / percentageDiff
      );

      if (isLeg == 2) {
        markerIndex = arr.length - 1;
        props.setHomeMap(arr);
        props.setHomeMarker(markerIndex);
      } else if (isLeg == 3) {
        markerIndex = 0;
        props.setHomeMap(arr);
        props.setHomeMarker(markerIndex);
      } else {
        props.setHomeMap(arr);
        markerIndex = arr?.[indexCalculation]
          ? indexCalculation
          : arr.length - 1;

        if (isLeg == 1 || isLeg == 4) {
          let oldIndex = 0;
          if (!_.isEmpty(homeData)) {
            indexCalculation = Math.floor(
              homeData.percentagecomplete / percentageDiff
            );
            oldIndex = arr?.[indexCalculation]
              ? indexCalculation
              : arr.length - 1;
          }

          markerMove(arr, markerIndex, oldIndex);
        }
      }
    }
  };

  const makeChangeData = (obj) => {
    setLegmappath(obj.legmappath);
    setLegno(obj.legno);
    setLegname(obj.legname);
    setTeamavgtotal(Math.floor(obj.teamavgtotal));
    setTeamavgdaily(Math.floor(obj.teamavgdaily));
    ticCounter(
      Math.floor(obj.pointsreqtofinish),
      eventDetails.resource?.teamavgptreqtofinisheachlegopen,
      "finish",
      "decreament"
    );
    ticCounter(
      0,
      Math.floor(obj.percentagecomplete),
      "percentage",
      "increment"
    );

    let percentageDiff = 1;
    let markerIndex = 0;
    let arr = [{ x: 0, y: 0 }];
    if (obj.legmappoints) {
      let pointsArr = JSON.parse(obj.legmappoints);

      arr = pointsArr.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.x === item.x && t.y === item.y)
      );

      percentageDiff = 100 / arr.length;
      var indexCalculation = Math.floor(
        obj.percentagecomplete / percentageDiff
      );

      markerIndex = arr?.[indexCalculation] ? indexCalculation : arr.length - 1;

      props.setHomeMap(arr);
      props.setHomeMarker(markerIndex);
    }

    let currentOrder = obj.legno;

    let challengeArr = _.filter(obj.eventclientlegs, function (obj) {
      return obj.type == "challenge";
    });

    let bonusArr = _.filter(obj.eventclientlegs, function (obj) {
      return obj.type == "bonus";
    });

    let maxOrder = _.maxBy(challengeArr, function (o) {
      return o.order;
    });

    let highestOrder = _.maxBy(obj.eventclientlegs, function (o) {
      return o.order;
    });

    if (
      bonusArr.length > 0 &&
      parseInt(currentOrder) == parseInt(highestOrder?.order) &&
      obj.pointsreqtofinish <= 0 &&
      !lastLegShown
    ) {
      props.isLastLegShow(true);
      props.lastLegAlreadyShown(true);
    } else if (
      parseInt(currentOrder) > parseInt(maxOrder?.order) &&
      bonusArr.length > 0
    ) {
      if (!props.showBonusLeg) {
        confirmAlert({
          title: "",
          message:
            "You’ve done a great job and have finished the course, so we have loaded up a bonus leg for you so you can keep going until the end",
          buttons: [
            {
              label: "OK",
              onClick: () => {
                props.isBonusLegShow(true);
              },
            },
          ],
        });
      }
    }
  };

  const markerMove = (totalPoints, newIndex, oldIndex) => {
    if (
      parseInt(newIndex) >= 0 &&
      parseInt(oldIndex) >= 0 &&
      parseInt(newIndex) > parseInt(oldIndex) &&
      totalPoints &&
      totalPoints.length > 0
    ) {
      for (let i = oldIndex; i <= newIndex; i++) {
        setTimeout(() => props.setHomeMarker(i), 1000);
      }
    }
  };

  const makeConfirmationLast = (obj) => {
    confirmAlert({
      title: LocaleStrings.congratulation_on_complete_course,
      message: LocaleStrings.congratulation_on_complete_course_desc,
      buttons: [
        {
          label: LocaleStrings.button_view_video_now,
          onClick: () => {
            renderLegVideo("");
          },
        },
        {
          label: LocaleStrings.button_view_later,
          onClick: () => {
            makeChangeData(obj);
          },
        },
      ],
    });
  };

  const makeConfirmation = (
    slegname,
    obj,
    { autoDetetctVideo = true, bonusStart = false }
  ) => {
    let localPopup = loadLocalStorage(APP_LOCALSTORAGE_KEYS.popups);

    if (
      localPopup &&
      _.includes(localPopup[APP_POPUP_TYPES.coveredLegs], slegname)
    )
      return;

    if (!localPopup) {
      // undefined
      localPopup = _.cloneDeep(APP_POPUP_INIT);
      localPopup[APP_POPUP_TYPES.coveredLegs] = [slegname];
    } else {
      if (_.isEmpty(localPopup[APP_POPUP_TYPES.coveredLegs]))
        localPopup[APP_POPUP_TYPES.coveredLegs] = [];
      localPopup[APP_POPUP_TYPES.coveredLegs].push(slegname);
    }
    saveInLocalStorage(localPopup, APP_LOCALSTORAGE_KEYS.popups);

    confirmAlert({
      title: bonusStart
        ? LocaleStrings.congratulation_on_complete.replace(
            "#LEGNAME#",
            slegname
          )
        : "",
      message: bonusStart
        ? LocaleStrings.congratulation_on_complete_standard_legs
        : LocaleStrings.congratulation_on_complete.replace(
            "#LEGNAME#",
            slegname
          ),
      buttons: [
        {
          label: LocaleStrings.button_view_video_now,
          onClick: () => {
            if (autoDetetctVideo) renderLegVideo("");
            else renderLegVideo(slegname);
          },
        },
        {
          label: LocaleStrings.button_view_later,
          onClick: () => {
            makeChangeData(obj);
          },
        },
      ],
    });
  };

  const class_edit_your_avatar =
    "underline text-primary sm:text-sm cursor-pointer";
  const class_my_avatar =
    "flex justify-center mt-7 sm:mt-6 sm:mb-6 h-home-canvas-height";

  const handleCourseVideo = () => {
    const path = "/course-video";
    history.push(path);
  };

  const handleCourseMap = () => {
    const path = "/course-map";
    history.push(path);
  };

  const handleAvatar = () => {
    setShowAvatarModal(true);
  };

  const handleAvatarDownload = () => {
    toPng(avatarRef.current).then((dataUrl) => {
      downloadFileFromDataURL(
        dataUrl,
        `${session.eventid}E${session?.teamid}T${session?.userid}U.png`
      );
    });
  };

  const openMemberModal = (data) => {
    showMemberModal({ show: true, data: data });
  };

  const redirectToDataEntry = (memberId = null) => {
    let starttime = homeScreenData.data?.challengecoursestartdate;
    let dataentryclosedate = homeScreenData.data?.dataentryclosedate;

    if (starttime && dataentryclosedate) {
      let currentDate = moment();

      let validflag = true;
      let isClosed = false;

      if (
        currentDate.isSameOrAfter(moment(starttime)) &&
        currentDate.isSameOrBefore(moment(dataentryclosedate))
      ) {
        validflag = true;
      } else if (
        moment(currentDate.format("YYYY-MM-DD")).isSame(dataentryclosedate)
      ) {
        validflag = true;
      } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(starttime)) {
        validflag = true;
      } else if (currentDate.isAfter(moment(dataentryclosedate), "date")) {
        validflag = false;
        isClosed = true;
      } else {
        validflag = false;
      }

      if (validflag) {
        props.setHomeData(homeScreenData.data);
        const path = `/data-entry${!_.isNull(memberId) ? `/${memberId}` : ""}`;
        history.push(path);
      } else {
        if (isClosed) {
          confirmAlert({
            title: "",
            message: "Data Entry is now Closed",
            buttons: [
              {
                label: LocaleStrings.button_close,
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "",
            message: _.replace(
              LocaleStrings.data_entry_opens_on,
              "#STARTIME#",
              moment(starttime).format("DD MMM YYYY")
            ),
            buttons: [
              {
                label: LocaleStrings.button_close,
                onClick: () => {},
              },
            ],
          });
        }
      }
    }
  };

  const redirectToClassEntry = () => {
    let starttime = homeScreenData.data?.challengecoursestartdate;
    let endtime = homeScreenData.data?.challengecourseclosedate;
    let dataentryclosedate = homeScreenData.data?.dataentryclosedate;

    if (starttime && dataentryclosedate) {
      let currentDate = moment();
      let validflag = true;
      let isClosed = false;

      if (
        currentDate.isSameOrAfter(moment(starttime)) &&
        currentDate.isSameOrBefore(moment(dataentryclosedate))
      ) {
        validflag = true;
      } else if (
        moment(currentDate.format("YYYY-MM-DD")).isSame(dataentryclosedate)
      ) {
        validflag = true;
      } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(starttime)) {
        validflag = true;
      } else if (currentDate.isAfter(moment(dataentryclosedate), "date")) {
        validflag = false;
        isClosed = true;
      } else {
        validflag = false;
      }

      if (validflag) {
        props.setHomeData(homeScreenData.data);
        const path = "/class-entry";
        history.push(path);
      } else {
        if (isClosed) {
          confirmAlert({
            title: "",
            message: "Data Entry is now Closed",
            buttons: [
              {
                label: "Close",
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "",
            message: _.replace(
              LocaleStrings.data_entry_opens_on,
              "#STARTIME#",
              moment(starttime).format("DD MMM YYYY")
            ),
            buttons: [
              {
                label: "Close",
                onClick: () => {},
              },
            ],
          });
        }
      }
    }
  };

  let memberName =
    homeScreenData?.data?.membername.split(" ")[0] +
    " " +
    homeScreenData?.data?.membername.split(" ")[1].charAt(0);

  let starttime = homeScreenData?.data?.challengecoursestartdate;
  let endtime = homeScreenData?.data?.challengecourseclosedate;

  let currentDate = moment();
  let targetVal = "";
  let courseCompleteFlag = false;
  let dataEntryComplete = false;

  if (
    starttime &&
    currentDate.isSameOrAfter(moment(starttime)) &&
    endtime &&
    currentDate.isSameOrBefore(moment(endtime))
  ) {
    targetVal = "";
  } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(endtime)) {
    targetVal = "";
  } else if (moment(currentDate.format("YYYY-MM-DD")).isSame(starttime)) {
    targetVal = "";
  } else if (currentDate.isAfter(moment(endtime))) {
    targetVal = 100;
    courseCompleteFlag = true;
  }

  let dataEntryColoseTime = homeScreenData?.data?.dataentryclosedate;

  if (currentDate.isAfter(moment(dataEntryColoseTime), "date")) {
    dataEntryComplete = true;
  }

  const _renderLeftSection = () => {
    return (
      <>
        <div className="">
          {/* ----- Team name - Leg order - Leg name ----- */}
          <div className="bg-refresh text-white rounded-t-lg p-4 grid grid-cols-3">
            <div className="col-span-1">
              <span className=" text-xl font-semibold">
                {homeScreenData?.data?.teamname}
              </span>
            </div>

            <div className="col-span-2">
              <div className="flex gap-2 text-base">
                <span>
                  {LocaleStrings.leg} {legno}:
                </span>
                <span className="font-medium">{legname?.capitalize()}</span>
              </div>

              <div
                className={`${!dataEntryComplete ? "flex" : "hidden"} gap-3`}
              >
                <div className="text-xs flex gap-2">
                  <span className="">
                    {LocaleStrings.points_required_to_finish}:
                  </span>
                  <span className="font-medium">
                    {pointsreqtofinish >= 0
                      ? Math.floor(pointsreqtofinish ?? 0)
                      : 0}
                  </span>
                </div>

                <div className="text-xs flex gap-2">
                  <span>{LocaleStrings.percentage_complete}:</span>
                  <span className="font-medium">
                    {percentageComplete <= 100
                      ? Math.floor(percentageComplete ?? 0)
                      : 100}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Avatar - Avatar edit - Enter data (individual or w/ team) - Team points - Team members - Leg complete progress bars ----- */}
          <div
            className={
              deviceSize.isMobile ||
              (deviceSize.width >= 1024 && deviceSize.width <= 1150) ||
              (deviceSize.width >= 1280 && deviceSize.width <= 1340)
                ? "grid grid-cols-2 bg-white"
                : "grid grid-cols-3 bg-white"
            }
          >
            <div className="col-span-1 px-2 py-1 sm:p-4 border-secondary3 border-r border-b">
              {/* --- Attendee name --- */}
              <div className="pb-1 text-xl">{memberName}</div>

              {/* --- Avatar edit link --- */}
              <div className="flex justify-between items-center">
                <a
                  className={
                    deviceSize.width <= 375
                      ? `${class_edit_your_avatar} text-xs`
                      : `${class_edit_your_avatar} text-sm`
                  }
                  onClick={handleAvatar}
                >
                  {LocaleStrings.edit_your_avatar}
                </a>

                <img
                  className="w-6 cursor-pointer"
                  src={downloadIcon}
                  alt="Download"
                  onClick={handleAvatarDownload}
                />
              </div>

              {/* --- Attendee avatar --- */}
              <div
                className={
                  deviceSize.width <= 375
                    ? `${class_my_avatar}`
                    : `${class_my_avatar} mb-6`
                }
                style={deviceSize.width <= 375 ? { marginBottom: 50 } : {}}
              >
                <MyAvatar avatarRef={avatarRef} />
              </div>

              {/* --- Member's daily avg point --- */}
              <div className="flex justify-between text-xs font-medium">
                <span>
                  {LocaleStrings.daily_avg} {LocaleStrings.points}
                </span>
                <span>
                  {Math.floor(homeScreenData?.data?.memberdailyavg ?? 0)}
                </span>
              </div>

              {/* --- Member's total points --- */}
              <div className="flex justify-between text-xs font-medium">
                <span>
                  {LocaleStrings.total} {LocaleStrings.points}
                </span>
                <span>
                  {Math.floor(homeScreenData?.data?.membertotalpoints ?? 0)}
                </span>
              </div>

              {/* --- Class/team points enter button | Hiding after team completes final course --- */}
              {!courseComplete ? (
                <>
                  {session.ismanager &&
                  homeScreenData?.data?.isallowteammanager ? (
                    <button
                      className="mt-2 btn bg-point text-white font-medium rounded-lg h-9"
                      onClick={redirectToClassEntry}
                    >
                      <span className="ml-2 text-xs sm:text-sm">
                        {session.eventtype === "school"
                          ? LocaleStrings.enter_class_data
                          : LocaleStrings.enter_team_data}
                      </span>
                    </button>
                  ) : (
                    ""
                  )}

                  <div
                    className="mt-2 text-xs sm:text-sm underline flex justify-center"
                    onClick={() => redirectToDataEntry(null)}
                  >
                    <span className="cursor-pointer">
                      {LocaleStrings.enter_my_data}
                    </span>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div
              className={`col-span-${
                deviceSize.isMobile ||
                (deviceSize.width >= 1024 && deviceSize.width <= 1150) ||
                (deviceSize.width >= 1280 && deviceSize.width <= 1340)
                  ? "1"
                  : "2"
              } p-2 sm:p-4 border-secondary3 border-b flex flex-col justify-between`}
            >
              {/* --- Team avg points + Member list --- */}
              <div className="">
                {/* - Team avg points - */}
                <div className="flex justify-between">
                  <div>
                    <div className="text-sm">
                      {LocaleStrings.team_avg_daily}
                    </div>
                    <div className="text-lg text-point">
                      {Math.floor(teamavgdaily ?? 0)}
                      <span className="text-xs">{LocaleStrings.pt}</span>
                    </div>
                  </div>

                  <div>
                    <div className="text-sm">
                      {LocaleStrings.team_avg_total}
                    </div>
                    <div className="text-lg text-point">
                      {Math.floor(teamavgtotal ?? 0)}
                      <span className="text-xs">pt</span>
                    </div>
                  </div>
                </div>

                {/* --- Member list --- */}
                <div
                  className="mt-4"
                  style={{
                    height:
                      deviceSize.width == 1024
                        ? 145
                        : (deviceSize.width >= 1024 &&
                            deviceSize.width <= 1150) ||
                          (deviceSize.width >= 1280 && deviceSize.width <= 1340)
                        ? 288
                        : 330,
                    // 200
                  }}
                >
                  <div
                    className={
                      deviceSize.isMobile || deviceSize.width == 1024
                        ? "grid grid-cols-2 gap-2 mapmarker-container"
                        : "grid grid-cols-3 gap-2 overflow-auto mapmarker-container-"
                    }
                    style={
                      deviceSize.width == 1024
                        ? { maxHeight: 226 }
                        : (deviceSize.width >= 1024 &&
                            deviceSize.width <= 1150) ||
                          (deviceSize.width >= 1280 && deviceSize.width <= 1340)
                        ? { maxHeight: 274 }
                        : {
                            maxHeight: 330,
                          }
                    }
                  >
                    {_.map(
                      homeScreenData?.data?.teammemberdata,
                      (member, idx) => {
                        return (
                          <div
                            key={idx}
                            className="cursor-pointer teammember-face hover:bg-gray-100 hover:underline hover:text-primary rounded"
                            onClick={() => openMemberModal(member)}
                          >
                            <div className="flex justify-center h-home-face-h">
                              <AvatarFace
                                memberAvatarObj={JSON.parse(member.avatar)}
                              />
                            </div>
                            <div className="text-xs sm:text-sm flex justify-center">
                              {/* {member.firstname} */}
                              <span>
                                {member.firstname.split(" ").length > 1
                                  ? member.firstname.split(" ")[0] +
                                    " " +
                                    member?.lastname?.charAt(0)
                                  : member.firstname +
                                    " " +
                                    member?.lastname?.charAt(0)}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>

              {/* --- Course complete percentage --- */}
              <div className={deviceSize.width >= 640 ? "mt-2" : "mt-1"}>
                <div className="text-xs sm:text-sm mb-1">
                  {LocaleStrings.percentage_of_course_completed}
                </div>
                {/* - Target - */}
                <div>
                  <div className="relative pt-1">
                    <div className="progress-bar-container h-6 mb-1">
                      <div
                        className={`progress-bar bg-progressbarprogress2`}
                        style={{
                          width:
                            targetVal == 100
                              ? "100%"
                              : `${Math.floor(
                                  homeScreenData?.data?.target ?? 0
                                )}%`,
                        }}
                      ></div>
                      <span className="absolute left-2 top-2 text-white">
                        {!thisDay.isBefore(challengeStartDate, "date")
                          ? targetVal == 100
                            ? 100
                            : Math.floor(homeScreenData?.data?.target) >= 100
                            ? 100
                            : Math.floor(homeScreenData?.data?.target ?? 0)
                          : 0}
                        %
                      </span>
                      <span
                        className={`absolute right-2 top-2 ${
                          Math.floor(homeScreenData?.data?.target) < 90
                            ? "text-progressbarright"
                            : "text-white"
                        }`}
                      >
                        {LocaleStrings.target}
                      </span>
                    </div>
                  </div>
                </div>

                {/* - Actual - */}
                <div>
                  <div className="relative pt-1">
                    <div className="progress-bar-container h-6 mb-0">
                      <div
                        className={`progress-bar ${
                          Math.floor(homeScreenData?.data?.actual) >=
                          Math.floor(homeScreenData?.data?.target)
                            ? "bg-green-500"
                            : Math.floor(homeScreenData?.data?.actual) < 20
                            ? "bg-chart-orange"
                            : Math.floor(homeScreenData?.data?.actual) < 100
                            ? "bg-red-500"
                            : "bg-green-500"
                        }`}
                        style={{
                          width: `${homeScreenData?.data?.actual}%`,
                        }}
                      ></div>
                      <span className="absolute left-2 top-2 text-white">
                        {!thisDay.isBefore(challengeStartDate, "date")
                          ? homeScreenData && homeScreenData.data
                            ? homeScreenData.data.actual &&
                              parseInt(homeScreenData.data.actual) > 100
                              ? 100
                              : Math.floor(homeScreenData.data.actual)
                            : 0
                          : 0}
                        %
                      </span>
                      <span
                        className={`absolute right-2 top-2 ${
                          Math.floor(homeScreenData?.data?.actual) < 90
                            ? "text-progressbarright"
                            : "text-white"
                        }`}
                      >
                        {LocaleStrings.actual}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Course close timer ----- */}
          <Timer data={homeScreenData?.data} />
        </div>
      </>
    );
  };

  const _renderRightSection = () => {
    return (
      <>
        <div>
          <div className="w-full mapmarker-container pb-4 rounded-lg">
            {!dataEntryComplete ? (
              <div
                className="col-span-2 bg-cover rounded-lg relative"
                style={{
                  height: 565,
                  backgroundImage: `url("${BASE_AZIONV2_URL}/${legmappath}?api_key=${FILE_ACCESS_KEY}")`,
                  ...(deviceSize.isMobile ? { width: 537 } : {}),
                }}
              >
                <img
                  id="marker"
                  className="absolute transition-all duration-1000 ease-in-out"
                  src={markerRed}
                  style={
                    deviceSize.width == 768
                      ? {
                          top: homeMapList[homeMarkerIndex]?.y + 36,
                          left: homeMapList[homeMarkerIndex]?.x + 45,
                        }
                      : deviceSize.width == 812
                      ? {
                          top: homeMapList[homeMarkerIndex]?.y + 55,
                          left: homeMapList[homeMarkerIndex]?.x + 65,
                        }
                      : deviceSize.width == 823
                      ? {
                          top: homeMapList[homeMarkerIndex]?.y + 62,
                          left: homeMapList[homeMarkerIndex]?.x + 68,
                        }
                      : {
                          top: homeMapList[homeMarkerIndex]?.y - 15,
                          left: homeMapList[homeMarkerIndex]?.x,
                        }
                  }
                />
              </div>
            ) : (
              <>
                <Certificate />
              </>
            )}
          </div>

          {deviceSize.isMobile && !courseCompleteFlag ? (
            <div className="w-full py-2 sm:py-4 ">
              <div className="rounded-t h-10 bg-primary text-white text-sm py-2 px-3">
                {legname?.capitalize()}
              </div>
              <div className="rounded-b bg-white">
                <div className="grid grid-cols-2 px-4 sm:px-3 py-2 sm:py-3">
                  <div className="flex items-center col-span-2 sm:col-span-1 pb-2 sm:pb-0 border-secondary3 border-b sm:border-b-0 border-r-0 sm:border-r">
                    <div className="ml-4">
                      <div className="text-xs">
                        {LocaleStrings.points_required_to_finish}
                      </div>
                    </div>
                    <div className="pl-3 text-base">
                      {pointsreqtofinish >= 0
                        ? Math.floor(pointsreqtofinish ?? 0)
                        : 0}
                    </div>
                  </div>

                  <div className="flex items-center col-span-2 sm:col-span-1 pt-2 sm:pt-0 sm:pl-3">
                    <div className="ml-4">
                      <div className="text-xs">
                        {LocaleStrings.percentage_complete}
                      </div>
                    </div>
                    <div className="pl-3 text-base">
                      {percentageComplete <= 100
                        ? Math.floor(percentageComplete ?? 0)
                        : 100}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
            <div
              className="col-span-2 sm:col-span-1 bg-primary course-video-map-container"
              onClick={() => handleCourseVideo()}
            >
              <div>
                <img src={watch_video} />
              </div>
              <div className="ml-3">
                <div className="text-sm">{LocaleStrings.course_video}</div>
                <div className="text-xs">
                  {(deviceSize.width >= 1024 && deviceSize.width <= 1150) ||
                  (deviceSize.width >= 1280 && deviceSize.width <= 1340)
                    ? LocaleStrings.click_to_watch_your_videos_short
                    : LocaleStrings.click_to_watch_your_videos}
                </div>
              </div>
            </div>
            <div
              className="col-span-2 sm:col-span-1 bg-teamdata course-video-map-container"
              onClick={() => handleCourseMap()}
            >
              <div className="">
                <img src={locationstar} />
              </div>
              <div className="ml-4">
                <div className="text-sm">{LocaleStrings.course_map}</div>
                <div className="text-xs">
                  {LocaleStrings.view_the_full_standings_map}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="p-0 sm:p-2">
      {isFireworkStart ? <Fireworks /> : ""}

      {!loading ? (
        <>
          {/* ===== Page contents ===== */}
          <div
            className="bg-secondary grid grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-4 p-2 sm:p-4"
            style={
              deviceSize.width >= 640
                ? { borderRadius: 13 }
                : { borderRadius: 0 }
            }
          >
            {/* === left div === */}
            {_renderLeftSection()}

            {/* === right div === */}
            {_renderRightSection()}
          </div>

          {/* ===== Modals ===== */}
          {/* --- Member avatar with details --- */}
          {showMemberModalReducer && showMemberModalReducer.show ? (
            <Member redirectHandler={redirectToDataEntry} />
          ) : (
            ""
          )}
          {/* --- Own avatar builder --- */}
          {showAvatarModal === true ? (
            <AvatarBuilder
              showAvatarModal={showAvatarModal}
              setShowAvatarModal={setShowAvatarModal}
            />
          ) : (
            ""
          )}
          {/* --- Leg completion video modal --- */}
          {showLegVideo && showLegVideo.show ? (
            <LegVideo makeChangeData={makeChangeData} />
          ) : (
            ""
          )}
          {/* --- Leg finish video modal --- */}
          {showLastLeg ? <FinishLeg /> : ""}
        </>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    homeScreenData: state.homeScreenDataReducer,
    showEnterDataModalReducer: state.showEnterDataModalReducer,
    showMemberModalReducer: state.showMemberModalReducer,
    courseVideoReducer: state.courseVideoReducer,
    eventClientLegsListReducer: state.eventClientLegsListReducer,
    homeData: state.homeData,
    showLegVideo: state.showLegVideo,
    eventDetails: state.eventDetails,
    homeMapList: state.homeMapList,
    homeMarkerIndex: state.homeMarkerIndex,
    showBonusLeg: state.showBonusLeg,
    showLastLeg: state.showLastLeg,
    showFirework: state.showFirework,
    lastLegShown: state.lastLegShown,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchHomeScreenData,
      showMemberModal,
      showEnterDataModal,
      fetchAvatarDBData,
      fetchCourseVideo,
      fetchEventClientLegs,
      setHomeData,
      showLegVideoModal,
      setHomeMarker,
      setHomeMap,
      isBonusLegShow,
      chartWiseData,
      isLastLegShow,
      isFirework,
      lastLegAlreadyShown,
      fetchLegCompleteLog,
      setLegCompleteLog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
