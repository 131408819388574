import {
  SHOW_PHOTO_VIEW_MODAL,
  FETCH_COURSE_VIDEO_DATA,
  COURSE_VIDEO_CURRENT_LEG,
  BONUS_LEG_SHOW,
  FALSE_STATE_UPDATE,
} from "../actions";

export function showPhotoViewModalReducer(state = {}, action) {
  if (action.type === SHOW_PHOTO_VIEW_MODAL) {
    return action.payload;
  }
  return state;
}

export function courseVideoReducer(state = {}, action) {
  if (action.type === FETCH_COURSE_VIDEO_DATA) {
    return action.payload;
  }
  return state;
}

export function courseCurrentLeg(state = {}, action) {
  if (action.type === COURSE_VIDEO_CURRENT_LEG) {
    return action.payload;
  }
  return state;
}

export function showBonusLeg(state = false, action) {
  if (action.type === BONUS_LEG_SHOW) {
    return action.payload;
  }
  return state;
}

export const falseUpdate = (state = 0, action) => {
  if (action.type === FALSE_STATE_UPDATE) {
    return action.payload;
  }
  return state;
};
