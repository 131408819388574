import React from "react";

const TandCPage = (props) => {
  const { part } = props;
  return (
    <div>
      {part === 1 ? (
        <>
          <div className="p-0 sm:p-2">
            <div className="bg-secondary p-2 sm:p-4 rounded-md">
              <div className="rounded-lg">
                <div className="rounded-lg lg:justify-center pl-3 text-center"></div>
                <div className="bg-secondary-2 py-8 px-12 rounded-lg text-gray-600">
                  <h3 className="text-3xl py-2">Terms of Use</h3>
                  <div className="">
                    <h4 className="text-xl py-1">
                      Privacy and security of data
                    </h4>

                    <p className="text-sm">
                      Any personal information that you submit will primarily be
                      used to process your class and personal registration. For
                      privacy reasons we ask that you only enter your student’s
                      first name and last name initial (and we only have the
                      initial as you may have more than one student with the
                      same first name).
                    </p>
                    <p className="text-sm">
                      The Parents event is a separate event and they cannot see
                      any student's profiles or data. Your password is encrypted
                      and all data is stored by Amazon web services one of the
                      largest secure cloud based providers in the world.
                    </p>

                    <h4 className="text-xl py-1">
                      Disclaimer and Limitation of Liability
                    </h4>
                    <p className="text-sm">
                      <strong>
                        All information, articles, programs, advice, software
                        and products are designed for the purpose of educating
                        the user about health and wellbeing. In no way should
                        any information contained in the web sites or mobile
                        applications be used for medical diagnosis or treatment.
                        It should not be used in place of medical advice from
                        your medical practitioner or physician and we strongly
                        recommend consulting your medical practitioner before
                        beginning any type of walking/exercise/diet program.
                      </strong>
                    </p>

                    <h4 className="text-xl py-1">Copyright Notice</h4>
                    <p className="text-sm">
                      The entire contents of the site are copyright Young and
                      Healthy 2019 Trust and Azion Holdings Ltd. All rights
                      reserved. Young and Healthy 2019 Trust and Azion Holdings
                      Ltd authorises you to view and download the information on
                      the site ONLY for your own personal, non-commercial use.
                      This authorisation is not a transfer of any intellectual
                      property rights, title or ownership.
                    </p>
                    <p className="text-sm">
                      You may not modify the material in any way or reproduce or
                      publicly display, perform or distribute or otherwise use
                      them for any public or commercial purpose without the
                      express written permission from the directors of Young and
                      Healthy Trust 2019 and Azion Holdings Ltd. You must retain
                      all copyright and other proprietary notices contained in
                      the material. &gt;
                    </p>

                    <h4 className="text-xl">Terms and Conditions</h4>
                    <p className="text-sm">
                      By accessing and using the website or mobile application
                      you agree to be bound by the terms and conditions of this
                      online service agreement. If you do not agree to abide by
                      this agreement, DO NOT USE the website or mobile
                      application.
                    </p>
                    <p className="text-sm">
                      We do not represent or warrant that the website or mobile
                      application will be accessible or available on a timely
                      basis, or that access to this web site or mobile
                      application will be interrupted or error free and may make
                      improvements and/or changes in this web site or mobile
                      application at any time.
                    </p>

                    <h5 className="text-xl">Information on Site:</h5>
                    <p className="text-sm">
                      The material on this site may contain inaccuracies and
                      typographical errors. You acknowledge that any reliance on
                      any such opinion, advice, statement, email or information
                      shall be at your own risk.{" "}
                    </p>
                    <h5 className="text-xl">Code of Conduct: </h5>
                    <p className="text-sm">
                      While using the site or mobile application you agree not
                      to:{" "}
                    </p>
                    <ul className="text-sm px-4">
                      <li className="my-1">
                        Restrict or inhibit any other user from using and
                        enjoying the site and its services
                      </li>
                      <li className="my-1">
                        Log into a server or account which you are not
                        authorized to access
                      </li>
                      <li className="my-1">
                        Post or transmit any unlawful, fraudulent, threatening,
                        abusive, libellous, defamatory, obscene or otherwise
                        objectionable or harmful information of any kind.{" "}
                      </li>
                      <li className="my-1">
                        Post or transmit any advertising, promotion or
                        solicitation of goods or services for commercial
                        purposes.
                      </li>
                      <li className="my-1">
                        Post or transmit any information or software that
                        contains a virus, worms, Trojan horse or other harmful
                        or disruptive component.{" "}
                      </li>
                      <li className="my-1">
                        Post or transmit materials in violation of another
                        party's copyright or intellectual property rights.{" "}
                      </li>
                      <li className="my-1">
                        Use the site for any commercial or unlawful research and
                        information gathering purposes
                      </li>
                      <li className="my-1">
                        Modify, adapt, sublicense, translate, sell, reverse
                        engineer, decompile or disassemble any portion of the
                        site.{" "}
                      </li>
                    </ul>

                    <h5 className="text-lg px-1">
                      Termination/suspension of site access:{" "}
                    </h5>
                    <p className="text-sm">
                      We may terminate or suspend your right to use the site or
                      mobile application at any time without prior notice if we
                      suspect that including without limitations any of the
                      above Code of Conducts have been violated. We reserve all
                      rights, including without limitation the right to
                      investigate and to involve law enforcement and pursue
                      criminal prosecution for any alleged or actual illegal
                      activities involving the site or the services we offer.{" "}
                    </p>

                    <h5 className="text-lg py-1">
                      Modification of Terms and Conditions:{" "}
                    </h5>
                    <p className="text-sm">
                      We reserve the right to change this Agreement at any time
                      by revising the terms and conditions herein. Continued use
                      of the web site or mobile application following such
                      changes shall constitute the users acceptance of such
                      changes.{" "}
                    </p>

                    <h4 className="text-xl py-1">
                      Password and Account Security
                    </h4>
                    <p className="text-sm">
                      You are entirely responsible for maintaining the
                      confidentiality of your passwords and account details. You
                      are also entirely responsible for any transactions or
                      activities that occur under your account regardless as to
                      whether such activities were made with or without your
                      knowledge.
                    </p>

                    <h4 className="text-xl py-1">Privacy</h4>
                    <p className="text-sm">
                      We respect the privacy of your personal information. It is
                      important to us that our online environment and mobile
                      applications are not only easy to use and convenient, but
                      private and secure as well. We are fully committed to
                      protecting your privacy.
                    </p>
                    <p className="text-sm">
                      This privacy policy is intended to help you understand how
                      our systems work and what safeguards are in place to
                      protect your privacy.
                    </p>
                    <p className="text-sm">
                      By using this site, you agree to the Challenge event
                      collection and use of your information according to the
                      terms described on this privacy page.
                    </p>
                    <p className="text-sm">
                      Any personal information that you submit will primarily be
                      used to process your registrations. You can also choose to
                      opt in or out of further communications. You may also
                      choose to opt out of further communications from our event
                      sponsors.
                    </p>
                    <p className="text-sm">
                      We may disclose information when required by law, or when,
                      in good faith, we need to protect the rights, property, or
                      safety of the Challenge, its customers, or others.
                    </p>
                    <p className="text-sm">
                      We reserve the right to pass on any information relating
                      to suspected fraudulent activity to the appropriate
                      authorities.
                    </p>
                    <p className="text-sm">
                      Please note that the Challenge event Web site may contain
                      links to other Web sites; however, we are not responsible
                      for their privacy policies.
                    </p>

                    <h4 className="text-xl py-1">
                      What else you should know about privacy?
                    </h4>
                    <p className="text-sm">
                      If using our website remember to close your browser when
                      you have finished your user session. This is to ensure
                      that others cannot access your personal information and
                      correspondence if you share a computer with someone else
                      or are using a computer in a public place like a library
                      or Internet cafe. You as an individual are responsible for
                      the security of and access to your own computer.
                    </p>
                    <p className="text-sm">
                      Please be aware that whenever you voluntarily disclose
                      personal information over the Internet (for example: in
                      discussion forums, via email, or in chat sites) that this
                      information can be collected and used by others. In short,
                      if you post personal information in publicly accessible
                      online forums, you may receive unsolicited messages from
                      other parties in return.
                    </p>
                    <p className="text-sm">
                      Ultimately, you are solely responsible for maintaining the
                      secrecy of your username and passwords and/or any account
                      information. Please be careful and responsible whenever
                      you are using the Internet.
                    </p>

                    <h4 className="text-xl py-1">Contacting us</h4>
                    <p className="text-sm">
                      If you have any questions about our privacy policy, please
                      email{" "}
                      <a
                        className="mail-text-hover"
                        target="_blank"
                        href={`mailto: kim.harvey@youngandhealthy.org.nz`}
                      >
                        kim.harvey@youngandhealthy.org.nz
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {/* ------------ bottom part ------------ */}
      {part === 2 ? (
        <>
          <div className="col-xs-12 col-sm-12">
            <span className="text-xl py-1">
              <strong>What we will use your contact details for</strong>
            </span>
            <br />
            <p className="text-sm">
              We need to communicate to you about the event that includes things
              such as; event dates and details, e-newsletters, delivery of any
              event collateral and prizes and other matters related to the event
              and relevant health and wellbeing activities. Please note we will
              never share your contact details with a third party.
              <br />
            </p>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TandCPage;
