import moment from "moment";
import LocalStrings from "../../languages";

export const BASE_URL = "https://api.tc.teks.co.in/";

export const BASE_FILES_URL = `${BASE_URL}api/v2/files/`;
export const BASE_AZIONV2_URL = `${BASE_URL}api/v2/azionv2`;
// export var BASE_CERTIFICATE_IMAGE_URL = "https://img.tc.teks.co.in/";
// export var BASE_CERTIFICATE_IMAGE_URL = "https://img.tc.teks.co.in/backup/";
export const BASE_GALLERY_IMAGE_URL =
  "https://img.tc.teks.co.in/galleries/standard/";
export const BASE_CERTIFICATE_IMAGE_URL =
  "https://img.tc.teks.co.in/galleries/cert/";

export const APP_API_KEY =
  "f9ff29a2c43c7f0598b50de6ca8cef7abdf65c4b8f20d5ea442eb4703831baee";
export const FILE_ACCESS_KEY =
  "bcc63b14eb9bb09f0dfc6462f172eeb849f841d1d70efffac510e6c217fa830a";
export const OPEN_ACCESS =
  "6a8acaee64a69d4503cceaf0c6e86fb6c79e5515709bd4883f977ed2159bf115";
export const SITE_URL = `healthiestschools.co.nz`;

export const COMMON_FAIL_MESSAGE = LocalStrings.common_fail_message;
export const CUSTOM_ITEM_COUNT = 10;
export const LABEL_POSITION_TOP = "TOP";
export const LABEL_POSITION_LEFT = "LEFT";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATE_TIME_FORMAT_WITH_SECOND = "YYYY-MM-DD HH:mm:ss";
export const LOCAL_TIMEZONE = localTimezone();
export const LOCAL_TO_GMT_TIME = converLocalDateIntoGMT();
export const DEFAULT_PASSWORD = "123456";
export const MINIMUM_STEP = 1001;

export const CONTACT_EMAIL = "kim.harvey@youngandhealthy.org.nz";

function localTimezone() {
  // const tz = momentz.tz.guess();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tz;
}

function converLocalDateIntoGMT() {
  var today = new Date();
  var str = today.toGMTString();
  var datetimeGMT = moment(str, "ddd, DD MMM YYYY HH:mm:ss"); // Parse string in local time
  var datetime = datetimeGMT.format("YYYY-MM-DD HH:mm:ss");

  return datetime;
}

export function converDateIntoLocal(date) {
  var stillUtc = moment.utc(date).toDate();
  var local = moment(stillUtc).local();
  // .format('YYYY-MM-DD HH:mm:ss');
  return local;
}
