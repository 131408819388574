export const loadLocalStorage = (key = null) => {
  try {
    const serializedState = localStorage.getItem(key ?? "state");

    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveInLocalStorage = (payload, key = null) => {
  try {
    //Choose what we need to save in local storage to handle refresh
    let stateToSave = {};
    if (!key) {
      stateToSave = {
        isLoggedIn: payload.isLoggedIn,
        session: payload.session,
      };
    } else {
      stateToSave = payload;
    }
    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem(key ?? "state", serializedState);
  } catch (err) {}
};

export const resetLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (err) {}
};
