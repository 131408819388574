import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, FieldArray, reduxForm } from "redux-form";
import _ from "lodash";

// constants
import {
  renderFieldText,
  renderFieldTextarea,
} from "../../../components/Common/base-components";
import { OPEN_ACCESS } from "../../../components/Common/constant";

// actions
import { setContactUs, requestMail } from "../action";

// files
import backArrow from "../../../assets/img/backarrow.png";

const ContactUsForm = (props) => {
  const {
    deviceSize,
    session,
    contactUsLayout,
    handleSubmit,
    pristine,
    submitting,
    eventDetails,
  } = props;

  const customSession = { apiKey: OPEN_ACCESS, eventid: session.eventid };
  const [loading, setLoading] = useState(false);
  const [mailSent, setMailSent] = useState(false);

  const handleBack = () => {
    props.setContactUs(false);
  };

  const onSubmitForm = (values) => {
    setLoading(true);

    let resObj = {
      eventid: eventDetails.resource.eventid,
      usercontact: values.email,
      sender: values.sender,
      content: values.body,
      type: "contactus",
    };

    props.requestMail(customSession, resObj, (res) => {
      if (res.success) {
        setLoading(false);
        setMailSent(true);
      }
    });
  };

  return (
    <>
      <div className="p-0 sm:p-2">
        <div
          className="bg-secondary p-2 sm:p-4"
          style={
            deviceSize.width > 640 ? { borderRadius: 13 } : { borderRadius: 0 }
          }
        >
          <div className="flex items-center mb-2">
            <div
              className="bg-primary p-3 rounded cursor-pointer"
              onClick={handleBack}
            >
              <img src={backArrow} />
            </div>
            <div className="text-base ml-3">
              Back
              {!_.includes(window.location.hash, "terms-and-conditions")
                ? " to form"
                : ""}
            </div>
          </div>
          <div className="rounded-lg">
            <div className="rounded-lg lg:justify-center pl-3 text-center"></div>
            <div
              className="bg-secondary-2 py-8 px-12 rounded-lg text-gray-600"
              style={mailSent ? { height: 300 } : {}}
            >
              {!mailSent ? (
                <>
                  <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="px-12- pt-6-">
                      <Field
                        name="sender"
                        type="text"
                        component={renderFieldText}
                        label="Your Name"
                        mandatory
                      />
                    </div>
                    <div className="px-12- pt-6-">
                      <Field
                        name="email"
                        type="email"
                        component={renderFieldText}
                        label="Your Contact Email"
                        mandatory
                      />
                    </div>
                    <div className="px-12- pt-6-">
                      <Field
                        name="body"
                        // type="text"
                        component={renderFieldTextarea}
                        rows={3}
                        label="Your query"
                        mandatory
                      />
                    </div>

                    {/* form submit */}
                    <div className="flex justify-center m-6">
                      <button
                        type="submit"
                        disabled={pristine || submitting || loading}
                        className="mt-2 btn bg-refresh text-white rounded-lg h-9"
                        style={{ width: 200 }}
                      >
                        {loading ? (
                          <svg
                            className="animate-spin h-5 w-5 rounded-full border-b border-white mr-3"
                            viewBox="0 0 24 24"
                          ></svg>
                        ) : (
                          ""
                        )}
                        Send
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <span className="">
                    Your query is sent successfully. Admin will contact you
                    shortly.
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const validate = (values) => {
  let errors = {};

  if (!values?.sender) {
    errors.sender = "Required*";
  }
  if (!values?.email) {
    errors.email = "Required*";
  }
  if (!values?.body) {
    errors.body = "Required*";
  }
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    contactUsLayout: state.contactUsLayout,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setContactUs, requestMail }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ContactUsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(ContactUsForm)
);
