import React, { useEffect, useState, useCallback, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

// constants
import { BASE_CERTIFICATE_IMAGE_URL } from "../../../components/Common/constant";
import LocaleStrings from "../../../languages";
import Loader from "../../../components/Common/loader";

const Certificate = (props) => {
  const {
    deviceSize,
    FetchAvatarReducer,
    homeScreenDataReducer,
    eventDetails,
    loadCertificate,
  } = props;

  const ref = useRef(null);
  const [courseCompleteSrc, setCourseCompleteSrc] = useState("");
  var certificateSrc = "";

  useEffect(() => {
    if (loadCertificate) {
      generateCertificate();
    }
  }, [loadCertificate, FetchAvatarReducer]);

  const generateCertificate = () => {
    let gallery = `nz-gallery`;
    let avatar = "av01";
    var shirt = parseInt(FetchAvatarReducer?.avatar[0]?.partNumber);
    var pants = parseInt(FetchAvatarReducer?.avatar[1]?.partNumber);
    var shoes = parseInt(FetchAvatarReducer?.avatar[2]?.partNumber);
    var head = parseInt(FetchAvatarReducer?.avatar[3]?.partNumber);
    var hair = parseInt(FetchAvatarReducer?.avatar[4]?.partNumber);
    var eyes = parseInt(FetchAvatarReducer?.avatar[5]?.partNumber);
    var eyebrow = parseInt(FetchAvatarReducer?.avatar[6]?.partNumber);
    var nose = parseInt(FetchAvatarReducer?.avatar[7]?.partNumber);
    var mouth = parseInt(FetchAvatarReducer?.avatar[8]?.partNumber);
    var beard = parseInt(FetchAvatarReducer?.avatar[9]?.partNumber);
    var glasses = parseInt(FetchAvatarReducer?.avatar[10]?.partNumber);

    var skin = "";
    switch (FetchAvatarReducer?.skincolor) {
      case "s1":
        skin = "cc9966";
        break;

      case "s2":
        skin = "996614";
        break;

      case "s2":
        skin = "fee4be";
        break;

      case "s4":
        skin = "fd9966";
        break;

      default:
        break;
    }

    var haircolor = "";
    switch (FetchAvatarReducer?.haircolor) {
      case "h1":
        haircolor = "D5D5D5";
        break;

      case "h2":
        haircolor = "B53A3A";
        break;

      case "h3":
        haircolor = "E6BA29";
        break;

      case "h4":
        haircolor = "D08F37";
        break;

      case "h5":
        haircolor = "663308";
        break;

      case "h6":
        haircolor = "000000";
        break;

      default:
        break;
    }

    var beardcolor = "";
    switch (FetchAvatarReducer?.beardcolor) {
      case "h1":
        beardcolor = "D5D5D5";
        break;

      case "h2":
        beardcolor = "B53A3A";
        break;

      case "h3":
        beardcolor = "E6BA29";
        break;

      case "h4":
        beardcolor = "D08F37";
        break;

      case "h5":
        beardcolor = "663308";
        break;

      case "h6":
        beardcolor = "000000";
        break;

      default:
        break;
    }

    var eyebrowcolor = "";
    switch (FetchAvatarReducer?.eyebrowcolor) {
      case "h1":
        eyebrowcolor = "D5D5D5";
        break;

      case "h2":
        eyebrowcolor = "B53A3A";
        break;

      case "h3":
        eyebrowcolor = "E6BA29";
        break;

      case "h4":
        eyebrowcolor = "D08F37";
        break;

      case "h5":
        eyebrowcolor = "663308";
        break;

      case "h6":
        eyebrowcolor = "000000";
        break;

      default:
        break;
    }

    let galleryFrame = parseInt("1");

    let congoMessage = encodeURI(
      `${
        homeScreenDataReducer?.data?.membername.split(" ")[0]
      } you have successfully completed ${
        eventDetails?.resource?.name
      } with teammates from ${homeScreenDataReducer?.data?.teamname}`
    );
    let imgUrl =
      "https://schools2021.healthiestschools.co.nz/static/media/zespri_certificate_2021.66e394e8.png";

    certificateSrc = `${BASE_CERTIFICATE_IMAGE_URL}${gallery}/?gallerymessage=${congoMessage}&gallerycertimageurl=${imgUrl}&showGalleryDownloadButton=true&avatar=${avatar}&shirt=${shirt}&pants=${pants}&shoes=${shoes}&head=${head}&hair=${hair}&hc=0x${haircolor}&eyes=${eyes}&eyebrow=${eyebrow}&ebc=0x${eyebrowcolor}&nose=${nose}&mouth=${mouth}&beard=${beard}&bc=0x${beardcolor}&glasses=${glasses}&skin=0x${skin}`;

    setCourseCompleteSrc(certificateSrc);
  };

  //   styles
  let crtStyle = {
    certscale: 0.5,
    certx: 150,
    certy: 0,
    gWidth:
      deviceSize.width >= 1440
        ? 512
        : deviceSize.width >= 1199
        ? 480
        : deviceSize.width >= 991
        ? 400
        : deviceSize.width >= 768 && deviceSize.width <= 820
        ? deviceSize.width - 163
        : deviceSize.width - 75,
    // gHeight: style.height * 1.0901639344
    gHeight:
      deviceSize.width == 1024
        ? 375
        : deviceSize.width < 1024
        ? deviceSize.width >= 768 && deviceSize.width <= 820
          ? 460
          : 280
        : 438,
  };

  return (
    <div
      className="col-span-2 bg-cover rounded-lg"
      style={{
        height: deviceSize.width <= 500 ? crtStyle.gHeight + 90 : 565,
        backgroundColor: "white",
      }}
    >
      <div className="" style={{ paddingTop: "12px" }}>
        {courseCompleteSrc !== "" ? (
          <div className="px-6" ref={ref} style={{ paddingTop: 32 }}>
            <iframe
              className="my-0 md:my-1 lg:my-2 xl:my-3 custom-iframe-width"
              src={
                courseCompleteSrc +
                `&certscale=${crtStyle.certscale}&certx=${crtStyle.certx}&certy=${crtStyle.certy}&gallerywidth=${crtStyle.gWidth}&galleryheight=${crtStyle.gHeight}`
              }
              // width={style.width}
              height={deviceSize.width <= 500 ? crtStyle.gHeight + 20 : 470}
            ></iframe>
          </div>
        ) : (
          <div className="flex justify-center items-center h-screen">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  var loadCertificate = false;
  if (
    !_.isEmpty(state.FetchAvatarReducer) &&
    !_.isEmpty(state.homeScreenDataReducer) &&
    !_.isEmpty(state.eventDetails)
  ) {
    loadCertificate = true;
  }

  return {
    deviceSize: state.deviceSize,
    FetchAvatarReducer: state.FetchAvatarReducer,
    homeScreenDataReducer: state.homeScreenDataReducer,
    eventDetails: state.eventDetails,
    loadCertificate,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
