import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// react-share
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import LocaleStrings from "../../languages";
import Loader from "../../components/Common/loader";
import {
  BASE_AZIONV2_URL,
  FILE_ACCESS_KEY,
  BASE_GALLERY_IMAGE_URL,
} from "../../components/Common/constant";

import PhotoView from "./components/photo-view";
import LegVideo from "../home/components/leg-video";

import {
  showPhotoViewModal,
  fetchCourseVideo,
  fetchCurrentLeg,
  isBonusLegShow,
  falseStateUpdate,
} from "./actions";
import { showLegVideoModal } from "../home/actions";
import { fetchAvatarDBData } from "../avatarBuilder/actions";

import backArrow from "../../assets/img/backarrow.png";
import coursevideobg from "../../assets/img/coursevideobg.png";
import leftarrow from "../../assets/img/leftarrow.png";
import rightarrow from "../../assets/img/rightarrow.png";
import watch_your_video_play from "../../assets/img/watch-your-video-play.png";
import iconFacebook from "../../assets/img/icons-facebook.png";
import iconTwitter from "../../assets/img/icons-twitter.png";
import iconInstagram from "../../assets/img/icons-instagram.png";
import iconWhatsApp from "../../assets/img/icons-whatsapp.png";
import iconMail from "../../assets/img/icons-mail.png";
import downloadIcon from "../../assets/img/download.png";
import { confirmAlert } from "react-confirm-alert";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const CourseVideo = (props) => {
  const {
    session,
    deviceSize,
    showPhotoViewModal,
    fetchCourseVideo,
    courseVideoReducer,
    courseCurrentLeg,
    showLegVideo,
    avatarData,
    fetchAvatarDBData,
    falseStateUpdate,
    eventDetails,
  } = props;

  const history = useHistory();
  const [currentLegIndex, setCurrentLegIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [currentLegId, setCurrentLegId] = useState(0);
  const [imageArr, setImageArr] = useState([]);
  const [galImgUrlObjArr, setGalImgUrlObjArr] = useState([]);
  const [frame, setFrame] = useState("1");
  const [hostname, setHostname] = useState(window.location.hostname);
  const [encodedString, setEncodedString] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [layoutChange, setLayoutChange] = useState(0); // layout not decided, 1 mob, 2 web

  const [loading, setLoading] = useState(
    _.isEmpty(courseVideoReducer) ? true : false
  );

  useEffect(() => {
    let hostName = window.location.hostname;
    setHostname(hostName);

    props.fetchCurrentLeg(session, (res) => {
      let currentlegid = res.currentlegid;

      setCurrentLegId(currentlegid);
      if (_.isEmpty(courseVideoReducer)) {
        fetchCourseVideo(session, (res) => {
          setLoading(false);

          let str = res?.data?.eventclientlegs
            ? JSON.stringify(res.data.eventclientlegs)
            : "";
          let arr = JSON.parse(str);

          let obj = {
            main: {},
            other: [],
          };

          if (arr.length > 0) {
            let leggallery = arr[0].leggallery;
            if (leggallery.length > 0) {
              let mainFilter = _.filter(leggallery, { ismain: true });
              let otherFilter = _.filter(leggallery, { ismain: false });

              obj = {
                main: mainFilter?.length > 0 ? mainFilter[0] : {},
                other: otherFilter?.length > 0 ? otherFilter : [],
              };
            }
          }

          setGalleryImages(obj);
        });
      } else {
        let arr = JSON.parse(
          JSON.stringify(courseVideoReducer?.data?.eventclientlegs)
        );

        let obj = {
          main: {},
          other: [],
        };

        if (arr.length > 0) {
          let leggallery = arr[0].leggallery;
          if (leggallery.length > 0) {
            let mainFilter = _.filter(leggallery, { ismain: true });
            let otherFilter = _.filter(leggallery, { ismain: false });

            obj = {
              main: mainFilter?.length > 0 ? mainFilter[0] : {},
              other: otherFilter?.length > 0 ? otherFilter : [],
            };
          }
        }

        setGalleryImages(obj);
      }

      let str =
        res?.data?.eventclientlegs && !_.isEmpty(res.data.eventclientlegs)
          ? JSON.stringify(res.data.eventclientlegs)
          : "";
      let arr = str != "" ? JSON.parse(str) : [];
      !_.isEmpty(arr) &&
        fetchAvatarDBData(session, session.userid, (res) => {
          if (res) {
            var avatar = res?.response;
            getCertificateImageUrl(avatar, arr);
          }
        });
    });
  }, []);

  useEffect(() => {
    fetchCourseVideo(session, (res) => {
      let str = res?.data?.eventclientlegs
        ? JSON.stringify(res?.data?.eventclientlegs)
        : "";
      let arr = JSON.parse(str);
      fetchAvatarDBData(session, session.userid, (res) => {
        if (res) {
          var avatar = res?.response;
          getCertificateImageUrl(avatar, arr);
        }
      });
    });
    props.fetchCurrentLeg(session, (res) => {
      let currentlegid = res.currentlegid;
      setCurrentLegId(currentlegid);
    });
  }, []);

  const getCertificateImageUrl = (avatarData, eventLegs) => {
    let imgArr = [],
      objArr = [];

    _.map(eventLegs, (leg, key) => {
      var legname = leg?.eventleg_by_eventlegidfk?.locationtitle.toLowerCase();
      legname =
        legname == "new zealand"
          ? "nz"
          : legname == "italy"
          ? "rome"
          : legname == "new york city"
          ? "nyc"
          : legname == "mozambique"
          ? "moz"
          : legname;
      var gallery = `${legname}-gallery`;

      var avatar = "av01";
      var shirt = parseInt(avatarData?.avatar[0]?.partNumber);
      var pants = parseInt(avatarData?.avatar[1]?.partNumber);
      var shoes = parseInt(avatarData?.avatar[2]?.partNumber);
      var head = parseInt(avatarData?.avatar[3]?.partNumber);
      var hair = parseInt(avatarData?.avatar[4]?.partNumber);
      var eyes = parseInt(avatarData?.avatar[5]?.partNumber);
      var eyebrow = parseInt(avatarData?.avatar[6]?.partNumber);
      var nose = parseInt(avatarData?.avatar[7]?.partNumber);
      var mouth = parseInt(avatarData?.avatar[8]?.partNumber);
      var beard = parseInt(avatarData?.avatar[9]?.partNumber);
      var glasses = parseInt(avatarData?.avatar[10]?.partNumber);

      var skin = "";
      switch (avatarData?.skincolor) {
        case "s1":
          skin = "cc9966";
          break;

        case "s2":
          skin = "996614";
          break;

        case "s3":
          skin = "fee4be";
          break;

        case "s4":
          skin = "fd9966";
          break;

        default:
          break;
      }

      var haircolor = "";
      switch (avatarData?.haircolor) {
        case "h1":
          haircolor = "D5D5D5";
          break;

        case "h2":
          haircolor = "B53A3A";
          break;

        case "h3":
          haircolor = "E6BA29";
          break;

        case "h4":
          haircolor = "D08F37";
          break;

        case "h5":
          haircolor = "663308";
          break;

        case "h6":
          haircolor = "000000";
          break;

        default:
          break;
      }

      var beardcolor = "";
      switch (avatarData?.beardcolor) {
        case "h1":
          beardcolor = "D5D5D5";
          break;

        case "h2":
          beardcolor = "B53A3A";
          break;

        case "h3":
          beardcolor = "E6BA29";
          break;

        case "h4":
          beardcolor = "D08F37";
          break;

        case "h5":
          beardcolor = "663308";
          break;

        case "h6":
          beardcolor = "000000";
          break;

        default:
          break;
      }

      var eyebrowcolor = "";
      switch (avatarData?.eyebrowcolor) {
        case "h1":
          eyebrowcolor = "D5D5D5";
          break;

        case "h2":
          eyebrowcolor = "B53A3A";
          break;

        case "h3":
          eyebrowcolor = "E6BA29";
          break;

        case "h4":
          eyebrowcolor = "D08F37";
          break;

        case "h5":
          eyebrowcolor = "663308";
          break;

        case "h6":
          eyebrowcolor = "000000";
          break;

        default:
          break;
      }

      var url1 = `${BASE_GALLERY_IMAGE_URL}${gallery}/?avatar=${avatar}&shirt=${shirt}&pants=${pants}&shoes=${shoes}&head=${head}&hair=${hair}&hc=0x${haircolor}&eyes=${eyes}&eyebrow=${eyebrow}&ebc=0x${eyebrowcolor}&nose=${nose}&mouth=${mouth}&beard=${beard}&bc=0x${beardcolor}&glasses=${glasses}&skin=0x${skin}&galleryframe=1&showGalleryDownloadButton=true`;
      var url2 = `${BASE_GALLERY_IMAGE_URL}${gallery}/?avatar=${avatar}&shirt=${shirt}&pants=${pants}&shoes=${shoes}&head=${head}&hair=${hair}&hc=0x${haircolor}&eyes=${eyes}&eyebrow=${eyebrow}&ebc=0x${eyebrowcolor}&nose=${nose}&mouth=${mouth}&beard=${beard}&bc=0x${beardcolor}&glasses=${glasses}&skin=0x${skin}&galleryframe=2&showGalleryDownloadButton=true`;
      var url3 = `${BASE_GALLERY_IMAGE_URL}${gallery}/?avatar=${avatar}&shirt=${shirt}&pants=${pants}&shoes=${shoes}&head=${head}&hair=${hair}&hc=0x${haircolor}&eyes=${eyes}&eyebrow=${eyebrow}&ebc=0x${eyebrowcolor}&nose=${nose}&mouth=${mouth}&beard=${beard}&bc=0x${beardcolor}&glasses=${glasses}&skin=0x${skin}&galleryframe=3&showGalleryDownloadButton=true`;

      var localArr = [url1, url2, url3];
      imgArr.push(localArr);

      var galImgUrlObj = {
        gallery: gallery,
        shoes: shoes,
        eyes: eyes,
        beard: beard,
        hair: hair,
        skin: skin,
        pant: pants,
        shirt: shirt,
      };
      objArr.push(galImgUrlObj);
    });

    setGalImgUrlObjArr(objArr);
    setImageArr(imgArr);
    setCurrentLegIndex(0);
  };

  const handleBack = () => {
    const path = "/";
    history.push(path);
  };

  const openModal = (imageArrEle, place) => {
    let arrCopy = imageArr;
    let obj = imageArr[currentLegIndex];
    let temp = obj?.main;
    obj.main = imageArrEle;
    obj.other[place] = temp;

    arrCopy[currentLegIndex] = obj;
    setImageArr(arrCopy);
    falseStateUpdate();

    getGalleryFrame(obj.main);
    encodeMainUrl(obj.main);
  };

  const getGalleryFrame = (url) => {
    let search = url;
    let frame = new URLSearchParams(search).get("galleryframe");
    setFrame(frame);
  };

  const encodeMainUrl = (url) => {
    let mainString = url;
    let encodedString = window.btoa(mainString);
    let uriEncodedString = encodeURIComponent(encodedString);
    setEncodedString(uriEncodedString);
  };

  const handleRightSlider = (e) => {
    let index = currentLegIndex;

    let arr = JSON.parse(
      JSON.stringify(courseVideoReducer.data.eventclientlegs)
    );
    let currentOrder = _.filter(arr, function (o) {
      return o.id == currentLegId;
    });
    let bonusLeg = false;
    let challengeArr = [];
    let bonusArr = [];

    if (currentOrder.length > 0) {
      challengeArr = _.filter(arr, function (obj) {
        return obj.type == "challenge";
      });

      bonusArr = _.filter(arr, function (obj) {
        return obj.type == "bonus";
      });
      let maxOrder = _.maxBy(challengeArr, function (o) {
        return o.order;
      });
      if (currentOrder[0].order > maxOrder.order && bonusArr.length > 0) {
        bonusLeg = true;
      }
    }

    if (index == arr.length - 1) {
      index = 0;
    } else {
      if (bonusLeg) {
        index++;
      } else {
        if (index < challengeArr.length - 1) {
          index++;
        } else {
          index = 0;
        }
      }
    }

    if (arr.length > 0) {
      let filterArr = [];
      if (bonusLeg) {
        let type = arr[index].type;

        if (type == "bonus" && !props.showBonusLeg) {
          confirmAlert({
            title: "",
            message:
              "You’ve done a great job and have finished the course, so we have loaded up a bonus leg for you so you can keep going until the end",
            buttons: [
              {
                label: "OK",
                onClick: () => {
                  let leggallery = arr[index].leggallery;
                  let obj = {
                    main: {},
                    other: [],
                  };

                  if (leggallery.length > 0) {
                    let mainFilter = _.filter(leggallery, { ismain: true });
                    let otherFilter = _.filter(leggallery, { ismain: false });

                    obj = {
                      main: mainFilter?.length > 0 ? mainFilter[0] : {},
                      other: otherFilter?.length > 0 ? otherFilter : [],
                    };
                  }

                  setCurrentLegIndex(index);
                  setGalleryImages(obj);
                  props.isBonusLegShow(true);
                },
              },
            ],
          });
        } else {
          let leggallery = arr[index].leggallery;
          let obj = {
            main: {},
            other: [],
          };

          if (leggallery.length > 0) {
            let mainFilter = _.filter(leggallery, { ismain: true });
            let otherFilter = _.filter(leggallery, { ismain: false });

            obj = {
              main: mainFilter?.length > 0 ? mainFilter[0] : {},
              other: otherFilter?.length > 0 ? otherFilter : [],
            };
          }

          setCurrentLegIndex(index);
          setGalleryImages(obj);
          falseStateUpdate();
        }
      } else {
        filterArr = _.filter(arr, function (obj) {
          return obj.type == "challenge";
        });
        let leggallery = filterArr[index].leggallery;
        let obj = {
          main: {},
          other: [],
        };

        if (leggallery.length > 0) {
          let mainFilter = _.filter(leggallery, { ismain: true });
          let otherFilter = _.filter(leggallery, { ismain: false });

          obj = {
            main: mainFilter?.length > 0 ? mainFilter[0] : {},
            other: otherFilter?.length > 0 ? otherFilter : [],
          };
        }

        setCurrentLegIndex(index);
        setGalleryImages(obj);
        falseStateUpdate();
        setImageIndex(0);
      }
    }
    setFrame("1");
    encodeMainUrl(imageArr[index].main);
  };

  const handleLeftSlider = (e) => {
    let index = currentLegIndex;

    let arr = JSON.parse(
      JSON.stringify(courseVideoReducer.data.eventclientlegs)
    );

    let currentOrder = _.filter(arr, function (o) {
      return o.id == currentLegId;
    });

    let bonusLeg = false;
    let challengeArr = [];
    let bonusArr = [];
    if (currentOrder.length > 0) {
      challengeArr = _.filter(arr, function (obj) {
        return obj.type == "challenge";
      });

      bonusArr = _.filter(arr, function (obj) {
        return obj.type == "bonus";
      });

      let maxOrder = _.maxBy(challengeArr, function (o) {
        return o.order;
      });

      if (currentOrder[0].order > maxOrder.order && bonusArr.length > 0) {
        bonusLeg = true;
      }
    }

    if (index == 0) {
      index = bonusLeg ? arr.length - 1 : challengeArr.length - 1;
    } else {
      index--;
    }

    if (arr.length > 0) {
      let filterArr = [];
      if (bonusLeg) {
        let type = arr[index].type;

        if (type == "bonus" && !props.showBonusLeg) {
          confirmAlert({
            title: "",
            message:
              "You’ve done a great job and have finished the course, so we have loaded up a bonus leg for you so you can keep going until the end",
            buttons: [
              {
                label: "OK",
                onClick: () => {
                  let leggallery = arr[index].leggallery;
                  let obj = {
                    main: {},
                    other: [],
                  };

                  if (leggallery.length > 0) {
                    let mainFilter = _.filter(leggallery, { ismain: true });
                    let otherFilter = _.filter(leggallery, { ismain: false });

                    obj = {
                      main: mainFilter?.length > 0 ? mainFilter[0] : {},
                      other: otherFilter?.length > 0 ? otherFilter : [],
                    };
                  }

                  setCurrentLegIndex(index);
                  setGalleryImages(obj);
                  props.isBonusLegShow(true);
                },
              },
            ],
          });
        } else {
          let leggallery = arr[index].leggallery;
          let obj = {
            main: {},
            other: [],
          };

          if (leggallery.length > 0) {
            let mainFilter = _.filter(leggallery, { ismain: true });
            let otherFilter = _.filter(leggallery, { ismain: false });

            obj = {
              main: mainFilter?.length > 0 ? mainFilter[0] : {},
              other: otherFilter?.length > 0 ? otherFilter : [],
            };
          }

          setCurrentLegIndex(index);
          setGalleryImages(obj);
        }
      } else {
        filterArr = _.filter(arr, function (obj) {
          return obj.type == "challenge";
        });
        let leggallery = filterArr[index].leggallery;
        let obj = {
          main: {},
          other: [],
        };

        if (leggallery.length > 0) {
          let mainFilter = _.filter(leggallery, { ismain: true });
          let otherFilter = _.filter(leggallery, { ismain: false });

          obj = {
            main: mainFilter?.length > 0 ? mainFilter[0] : {},
            other: otherFilter?.length > 0 ? otherFilter : [],
          };
        }

        setCurrentLegIndex(index);
        setGalleryImages(obj);
      }
    }
    setFrame("1");

    setImageIndex(0);
  };

  const watchVideo = () => {
    let arr = JSON.parse(
      JSON.stringify(courseVideoReducer.data.eventclientlegs)
    );

    if (
      arr.length > 0 &&
      courseCurrentLeg.data &&
      courseCurrentLeg.data.length > 0
    ) {
      let index = currentLegIndex;
      let eventclientleg = arr[index];

      let legLocationName = eventclientleg.eventleg_by_eventlegidfk
        .locationtitle
        ? _.toLower(eventclientleg.eventleg_by_eventlegidfk.locationtitle)
        : "";

      legLocationName =
        legLocationName == "new zealand"
          ? "nz"
          : legLocationName == "italy"
          ? "rome"
          : legLocationName == "new york city"
          ? "nyc"
          : legLocationName == "mozambique"
          ? "moz"
          : legLocationName;
      props.showLegVideoModal(session, true, legLocationName, (res) => {});
    }
  };

  const handleImageIndexChange = (side) => {
    let indx = imageIndex;

    switch (side) {
      case "r":
        if (indx !== 2) indx += 1;
        break;

      case "l":
        if (indx !== 0) indx -= 1;
        break;

      case "reset":
        indx = 0;
        break;

      default:
        break;
    }

    setImageIndex(indx);
  };

  // style vars
  if (deviceSize.width <= 575 && (layoutChange == 0 || layoutChange == 2)) {
    setLayoutChange(1); // mobile
  } else if (
    deviceSize.width > 575 &&
    (layoutChange == 0 || layoutChange == 1)
  ) {
    setLayoutChange(2); // web
  }

  var style = {
    height:
      layoutChange != 0 && layoutChange == 1
        ? 240
        : deviceSize.height > 800 ||
          (deviceSize.width >= 768 && deviceSize.width <= 820)
        ? deviceSize.width >= 768 && deviceSize.width <= 820
          ? 400
          : 530
        : deviceSize.height - 270,
  };
  style.width = style.height * 1.8;

  return (
    <>
      {!loading ? (
        <div
          className={
            deviceSize.isMobile ? "p-0 sm:p-2 content-container" : "p-0 sm:p-2"
          }
        >
          <div
            className="bg-secondary p-2 h-full"
            style={
              deviceSize.width > 640
                ? { borderRadius: 13 }
                : { borderRadius: 5 }
            }
          >
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex items-center">
                <div
                  className="bg-primary p-3 rounded cursor-pointer"
                  onClick={() => handleBack()}
                >
                  <img src={backArrow} />
                </div>
                <div className="text-base ml-3">
                  {courseVideoReducer?.data?.eventclientlegs?.length > 0
                    ? `${courseVideoReducer.data.eventclientlegs[
                        currentLegIndex
                      ]?.masterleg_by_legidfk?.name?.capitalize()}`
                    : ""}
                </div>
              </div>
              <div className="col-span-1 grid justify-items-end">
                <div
                  className="bg-primary p-3 flex items-center rounded cursor-pointer text-white"
                  onClick={watchVideo}
                >
                  <img
                    src={watch_your_video_play}
                    alt=""
                    style={{ height: 24, marginRight: 8 }}
                  />
                  <span>
                    {layoutChange != 0 && layoutChange == 2
                      ? LocaleStrings.watch_your_video
                      : LocaleStrings.watch_video}
                  </span>
                </div>
              </div>
            </div>

            {layoutChange !== 0 && layoutChange === 2 ? (
              <div className="p-2 grid">
                <div className="col-span-10_ grid">
                  <div className="pl-8">
                    <iframe
                      className="my-0 md:my-1 lg:my-2 xl:my-3 custom-iframe-width"
                      src={
                        imageArr.length > 0
                          ? imageArr[currentLegIndex][imageIndex] +
                            `&gallerywidth=${style.width}&galleryheight=${
                              style.height - 10
                            }`
                          : ""
                      }
                      // width={style.width}
                      height={style.height}
                    ></iframe>
                  </div>
                  <div className="flex justify-between">
                    {/* left leg slider */}
                    <div
                      className="bg-white p-2 flex items-center rounded cursor-pointer"
                      onClick={handleLeftSlider}
                    >
                      <img src={leftarrow} alt="" />
                      <span className="ml-2">
                        {courseVideoReducer?.data?.eventclientlegs?.length > 0
                          ? `${courseVideoReducer.data.eventclientlegs[
                              currentLegIndex == 0
                                ? courseVideoReducer?.data?.eventclientlegs
                                    ?.length - 1
                                : currentLegIndex - 1
                            ]?.masterleg_by_legidfk?.name?.capitalize()}`
                          : ""}
                      </span>
                    </div>

                    {/* frame slider & indicator */}
                    <div className="flex justify-between">
                      {/* left frame slider */}
                      <div className="col-span-1 grid justify-center items-center">
                        <div
                          className={
                            imageIndex == 0
                              ? "bg-gray-400 rounded-full p-3 cursor-not-allowed"
                              : "bg-white rounded-full p-3 cursor-pointer"
                          }
                          onClick={
                            imageIndex !== 0
                              ? () => handleImageIndexChange("l")
                              : null
                          }
                        >
                          <img src={leftarrow} />
                        </div>
                      </div>

                      {/* indicator */}
                      <div className="flex items-center rounded gap-1 px-8">
                        <div
                          className={
                            imageIndex == 0
                              ? "bg-blue-500 text-blue-500 rounded-full"
                              : "bg-secondary-4 text-gray-500 rounded-full"
                          }
                          style={{ height: 12, width: 12 }}
                        ></div>
                        <div
                          className={
                            imageIndex == 1
                              ? "bg-blue-500 text-blue-500 rounded-full"
                              : "bg-secondary-4 text-gray-500 rounded-full"
                          }
                          style={{ height: 12, width: 12 }}
                        ></div>
                        <div
                          className={
                            imageIndex == 2
                              ? "bg-blue-500 text-blue-500 rounded-full"
                              : "bg-secondary-4 text-gray-500 rounded-full"
                          }
                          style={{ height: 12, width: 12 }}
                        ></div>
                      </div>

                      {/* right frame slider */}
                      <div className="col-span-1 grid justify-center items-center">
                        <div
                          className={
                            imageIndex == 2
                              ? "bg-gray-400 rounded-full p-3 cursor-not-allowed"
                              : "bg-white rounded-full p-3 cursor-pointer"
                          }
                          onClick={
                            imageIndex !== 2
                              ? () => handleImageIndexChange("r")
                              : null
                          }
                        >
                          <img src={rightarrow} />
                        </div>
                      </div>
                    </div>

                    {/* right leg slider */}
                    <div
                      className="bg-white p-2 flex items-center rounded cursor-pointer"
                      onClick={handleRightSlider}
                    >
                      <span>
                        {courseVideoReducer?.data?.eventclientlegs?.length > 0
                          ? `${courseVideoReducer.data.eventclientlegs[
                              currentLegIndex ==
                              courseVideoReducer?.data?.eventclientlegs
                                ?.length -
                                1
                                ? 0
                                : currentLegIndex + 1
                            ]?.masterleg_by_legidfk?.name?.capitalize()}`
                          : ""}
                      </span>
                      <img src={rightarrow} alt="" className="ml-2" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid gap-4 mt-2 py-1 px-2">
                <div className="pl-3">
                  <iframe
                    className="my-0 md:my-1 lg:my-2 xl:my-3 custom-iframe-width"
                    src={
                      imageArr.length > 0
                        ? imageArr[currentLegIndex][imageIndex] +
                          `&gallerywidth=${
                            deviceSize.width - 60
                          }&galleryheight=${style.height - 20}`
                        : ""
                    }
                    // width={style.width}
                    height={style.height}
                  ></iframe>
                </div>

                {/* image toggle */}
                <div className="">
                  <div className="flex justify-between">
                    {/* left img slider */}
                    <div
                      className={
                        imageIndex == 0
                          ? "bg-gray-400 rounded-full p-3 cursor-not-allowed"
                          : "bg-white rounded-full p-3 cursor-pointer"
                      }
                      onClick={
                        imageIndex !== 0
                          ? () => handleImageIndexChange("l")
                          : null
                      }
                    >
                      <img src={leftarrow} />
                    </div>
                    {/* indicator */}
                    <div className="flex items-center rounded gap-1">
                      <div
                        className={
                          imageIndex == 0
                            ? "bg-blue-500 text-blue-500 rounded-full"
                            : "bg-secondary-4 text-gray-500 rounded-full"
                        }
                        style={{ height: 12, width: 12 }}
                      ></div>
                      <div
                        className={
                          imageIndex == 1
                            ? "bg-blue-500 text-blue-500 rounded-full"
                            : "bg-secondary-4 text-gray-500 rounded-full"
                        }
                        style={{ height: 12, width: 12 }}
                      ></div>
                      <div
                        className={
                          imageIndex == 2
                            ? "bg-blue-500 text-blue-500 rounded-full"
                            : "bg-secondary-4 text-gray-500 rounded-full"
                        }
                        style={{ height: 12, width: 12 }}
                      ></div>
                    </div>
                    {/* right img slide */}
                    <div
                      className={
                        imageIndex == 2
                          ? "bg-gray-400 rounded-full p-3 cursor-not-allowed"
                          : "bg-white rounded-full p-3 cursor-pointer"
                      }
                      onClick={
                        imageIndex !== 2
                          ? () => handleImageIndexChange("r")
                          : null
                      }
                    >
                      <img src={rightarrow} />
                    </div>
                  </div>
                </div>

                {/* leg toggle */}
                <div className="flex justify-between">
                  <div
                    className="bg-white p-2 flex items-center rounded cursor-pointer"
                    onClick={handleLeftSlider}
                  >
                    <img src={leftarrow} alt="" />
                    <span className="ml-2">
                      {courseVideoReducer?.data?.eventclientlegs?.length > 0
                        ? `${courseVideoReducer.data.eventclientlegs[
                            currentLegIndex == 0
                              ? courseVideoReducer?.data?.eventclientlegs
                                  ?.length - 1
                              : currentLegIndex - 1
                          ]?.masterleg_by_legidfk?.name?.capitalize()}`
                        : ""}
                    </span>
                  </div>

                  <div
                    className="bg-white p-2 flex items-center rounded cursor-pointer"
                    onClick={handleRightSlider}
                  >
                    <span>
                      {courseVideoReducer?.data?.eventclientlegs?.length > 0
                        ? `${courseVideoReducer.data.eventclientlegs[
                            currentLegIndex ==
                            courseVideoReducer?.data?.eventclientlegs?.length -
                              1
                              ? 0
                              : currentLegIndex + 1
                          ]?.masterleg_by_legidfk?.name?.capitalize()}`
                        : ""}
                    </span>
                    <img src={rightarrow} alt="" className="ml-2" />
                  </div>
                </div>
              </div>
            )}

            <PhotoView />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      )}

      {showLegVideo && showLegVideo.show ? (
        <LegVideo origin="coursevideo" />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    courseVideoReducer: state.courseVideoReducer,
    courseCurrentLeg: state.courseCurrentLeg,
    showLegVideo: state.showLegVideo,
    showBonusLeg: state.showBonusLeg,
    avatarData: state.FetchAvatarReducer,
    eventDetails: state.eventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showPhotoViewModal,
      fetchCourseVideo,
      fetchCurrentLeg,
      showLegVideoModal,
      isBonusLegShow,
      fetchAvatarDBData,
      falseStateUpdate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideo);
